<div class="container-fluid">
  <ng-container *ngIf="onboarded == 'uncomplete' && ui != 'noc'">
    <spacer>{{ 'completeOnboarding' | translate }}</spacer>
  </ng-container>
  <ng-container *ngIf="onboarded == 'complete' || ui == 'noc'">
    <div
      *ngIf="
        !allDevices.length && location?.profile === 'auto' && !isUpriseHomePass && !haahsEnabled && !location?.flex
      "
    >
      <spacer>{{ message | translate }}</spacer>
    </div>
    <div
      *ngIf="
        location?.id &&
        (allDevices.length || location?.profile !== 'auto' || isUpriseHomePass || haahsEnabled || location?.flex)
      "
    >
      <spacer class="mobile">{{ 'sidemenu.devices' | translate }}</spacer>
      <spacer class="content">
        <div class="info">
          <div class="column" [ngPlural]="allDevices.length">
            <div class="header">
              <div class="title">
                <ng-template ngPluralCase="=1">
                  {{ allDevices.length }} {{ 'devices.deviceAccount' | translate }}
                </ng-template>
                <ng-template ngPluralCase="other">
                  {{ allDevices.length }} {{ 'devices.devicesAccount' | translate }}
                </ng-template>
              </div>

              <div class="mobile-stats">
                <i>{{ online?.length }} {{ 'devices.online' | translate }}, </i>
                <i>{{ offline?.length }} {{ 'devices.offline' | translate }}, </i>
                <i>{{ alarms?.length }} {{ 'devices.alarms' | translate }}</i>
              </div>

              <div class="filter" *ngIf="location?.flex && !flexSupportUser">
                <slidetoggler [options]="flexItems" (toggle)="changeFlexFilter($event)"></slidetoggler>
              </div>

              <div class="filter" *ngIf="haahsEnabled">
                <slidetoggler [options]="haahsItems" (toggle)="changeHaahsFilter($event)"></slidetoggler>
              </div>

              <div class="filter" *ngIf="location?.profile !== 'auto'">
                <slidetoggler [options]="smallbusinessItems" (toggle)="changeVapFilter($event)"></slidetoggler>
                <icon
                  name="icon-wrench"
                  tooltip="{{ 'devices.deviceGroupManagement' | translate }}"
                  (click)="showDeviceGroupManagement = true"
                  *ngIf="smallbusinessFilter != 'captivePortal' && location?.profile === 'smallbusiness'"
                ></icon>
              </div>
              <div class="filter" *ngIf="isUpriseHomePass">
                <slidetoggler [options]="upriseHomePassItems" (toggle)="changeVapFilter($event)"></slidetoggler>
              </div>

              <span
                *ngIf="
                  (!initialLoad &&
                    devices.length != allDevices.length &&
                    location?.profile === 'auto' &&
                    !location?.flex &&
                    !isUpriseHomePass) ||
                  haahsEnabled
                "
                class="filter-counts"
              >
                ({{ devices.length }} {{ 'devices.devicesVisible' | translate }} /
                {{ deviceResponse.length - devices.length }} {{ 'devices.devicesFiltered' | translate }})
              </span>
            </div>
          </div>
          <div class="column right" [class.auto]="location?.profile === 'auto' && !location.flex && !isUpriseHomePass">
            <span
              class="stats"
              *ngIf="(location?.profile === 'auto' && !isUpriseHomePass && !location.flex) || flexSupportUser"
            >
              <i>{{ online?.length }} {{ 'devices.online' | translate }}, </i>
              <i>{{ offline?.length }} {{ 'devices.offline' | translate }}, </i>
              <i>{{ alarms?.length }} {{ 'devices.alarms' | translate }}</i>
            </span>
            <span class="search">
              <drop-down-icon headerIcon="icon-sort" tooltip="{{ 'devices.sort' | translate }}">
                <li class="select" (click)="sortBy('online')">
                  <icon class="sortType" name="icon-sort-online"></icon>
                  {{ 'devices.sortByOnline' | translate }}
                  <icon name="icon-check" class="check" [class.selected]="sortType == 'online'"></icon>
                </li>
                <li class="select" (click)="sortBy('alphabet')">
                  <icon class="sortType" name="icon-sort-alphabet"></icon>
                  {{ 'devices.sortByName' | translate }}
                  <icon name="icon-check" class="check" [class.selected]="sortType == 'alphabet'"></icon>
                </li>
                <li class="select" (click)="sortBy('connection')">
                  <icon class="sortType" name="icon-sort-connection"></icon>
                  {{ 'devices.sortByConnection' | translate }}
                  <icon name="icon-check" class="check" [class.selected]="sortType == 'connection'"></icon>
                </li>
              </drop-down-icon>

              <drop-down-icon
                headerIcon="icon-controls"
                tooltip="{{ 'devices.filter.filter' | translate }}"
                (click)="initFilters()"
                (opened)="filterOpenStateChanged()"
              >
                <filterdropdown
                  #filter
                  *ngIf="location?.profile === 'auto'"
                  [objects]="searchDevices"
                  [filters]="deviceFilters"
                  (update)="updateFilter($event)"
                  (selectedFilters)="updateSelectedFilters($event)"
                >
                </filterdropdown>
                <filterdropdown
                  #filter
                  *ngIf="location?.profile !== 'auto'"
                  [objects]="searchDevices"
                  [filters]="smallbusinessDeviceFilters"
                  (update)="updateFilter($event)"
                  (selectedFilters)="updateSelectedFilters($event)"
                >
                </filterdropdown>
              </drop-down-icon>

              <div class="search-input">
                <icon name="icon-search"></icon>
                <input #searchFilter type="text" placeholder="{{ 'devices.search' | translate }}" (keyup)="search()" />
              </div>
            </span>
          </div>
        </div>
        <div class="sub-info" *ngIf="location?.profile !== 'auto' || isUpriseHomePass">
          <span class="smallbusiness-stats">
            <i>{{ online?.length }} {{ 'devices.online' | translate }}, </i>
            <i>{{ offline?.length }} {{ 'devices.offline' | translate }}, </i>
            <i>{{ alarms?.length }} {{ 'devices.alarms' | translate }}</i>
          </span>
        </div>
        <div class="sub-info" *ngIf="location?.flex && !flexSupportUser">
          <span class="smallbusiness-stats">
            <i>{{ online?.length }} {{ 'devices.online' | translate }}, </i>
            <i>{{ offline?.length }} {{ 'devices.offline' | translate }}, </i>
            <i>{{ alarms?.length }} {{ 'devices.alarms' | translate }}</i>
          </span>
        </div>
      </spacer>
      <div class="row" *ngIf="selectedFilters.length">
        <span> {{ 'devices.filter.filters' | translate }}</span>
        <div class="filter-options" *ngFor="let filter of selectedFilters">
          {{ filter.option | translate }}: {{ filter.stateTranslation | translate }}
          <div class="button cancel" (click)="disableFilterOption(filter)">
            <icon name="icon-close" class="size-0_6"></icon>
          </div>
        </div>
      </div>

      <div *ngIf="allDevices.length">
        <device
          [attr.id]="device.mac"
          [device]="device"
          [smallbusinessFilter]="smallbusinessFilter"
          (update)="getAuthorizedClients()"
          (openFocusModalEmitter)="openModal('focus', device)"
          (openIpv6ModalEmitter)="openModal('ipv6', device)"
          (openDeviceDetailsModalEmitter)="openModal('details', device)"
          (openCloudSteeringModalEmitter)="openModal('cloudSteering', device)"
          (openSteerModalEmitter)="openModal('steer', device)"
          (openQoeModalEmitter)="openModal('qoe', device)"
          (openPriorityModalEmitter)="openModal('priority', device)"
          (openOhpDetailsModalEmitter)="openModal('ohp', device)"
          (openLanLatencyModalEmitter)="openModal('lanLatency', device)"
          *ngFor="let device of devices; trackBy: track"
        ></device>
      </div>
      <div *ngIf="!allDevices.length">
        <spacer> {{ message | translate }} </spacer>
      </div>
      <div *ngIf="allDevices.length && !devices.length && !initialLoad">
        <spacer class="warn"> {{ 'devices.messageAllFiltered' | translate }} </spacer>
      </div>
    </div>
  </ng-container>
</div>

<focus-modal
  [device]="selectedDevice"
  (refreshDevice)="refreshDevice($event)"
  (closeFocusModal)="closeModal('focus')"
  *ngIf="focusModalOpen"
></focus-modal>
<ipv6-modal [device]="selectedDevice" (closeIpv6Modal)="closeModal('ipv6')" *ngIf="ipv6ModalOpen"></ipv6-modal>
<device-details-modal
  [device]="selectedDevice"
  (closeDeviceDetailsModal)="closeModal('details')"
  *ngIf="deviceDetailsModalOpen"
></device-details-modal>
<cloud-steering-modal
  [device]="selectedDevice"
  (closeCloudSteeringModal)="closeModal('cloudSteering')"
  *ngIf="cloudSteeringModalOpen"
></cloud-steering-modal>
<steer-modal [device]="selectedDevice" (closeSteerModal)="closeModal('steer')" *ngIf="steerModalOpen"></steer-modal>
<qoe-modal [device]="selectedDevice" (closeQoeModal)="closeModal('qoe')" *ngIf="qoeModalOpen"></qoe-modal>
<priority-modal
  [device]="selectedDevice"
  (closePriorityModal)="closeModal('priority')"
  *ngIf="priorityModalOpen"
></priority-modal>
<ohp-details-modal
  [device]="selectedDevice"
  (closeOhpDetailsModal)="closeModal('ohp')"
  *ngIf="ohpDetailsModalOpen"
></ohp-details-modal>
<lan-latency-modal
  [device]="selectedDevice"
  (closeLanLatencyModal)="closeModal('lanLatency')"
  *ngIf="lanLatencyModalOpen"
></lan-latency-modal>

<modal
  (overlayAction)="showDeviceGroupManagement = $event"
  *ngIf="location?.profile === 'smallbusiness' && showDeviceGroupManagement"
>
  <ng-container title>{{ 'devices.deviceGroupManagement' | translate }}</ng-container>
  <ng-container content>
    <devicegroupmanagement [mode]="smallbusinessFilter" [allDevices]="deviceResponse"></devicegroupmanagement>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showDeviceGroupManagement = false">
      {{ 'devices.close' | translate }}
    </button>
  </ng-container>
</modal>
