<svg id="help-health-locationHistoryChart" width="100%" height="95" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <linearGradient [id]="partialGradientUniqueId" x1="0" x2="0" y1="0" y2="1">
      <stop class="partial start" offset="0%" />
      <stop class="partial end" offset="100%" />
    </linearGradient>
  </defs>
  <ng-container *ngFor="let data of groupedDataSet; let index = index">
    <svg:g *ngIf="data.state === 'online'">
      <svg:line
        [attr.x1]="timeToPx(data.timeStart) + 3"
        [attr.x2]="timeToPx(data.timeEnd) - 1"
        [attr.y1]="1"
        [attr.y2]="1"
        class="onlineLine"
      />
      <svg:rect
        (mouseenter)="hover(data, $event)"
        (mousemove)="mouseMove($event)"
        (mouseleave)="hover(null, $event)"
        [attr.x]="timeToPx(data.timeStart) + 2"
        [attr.y]="2"
        [attr.width]="widthOfBlock(timeToPx(data.timeStart) + 2, timeToPx(data.timeEnd), groupedDataSet[index + 1])"
        [attr.height]="50"
        class="onlineRect"
      />
    </svg:g>
    <svg:rect
      *ngIf="!data.state"
      (mouseenter)="hover(data, $event)"
      (mousemove)="mouseMove($event)"
      (mouseleave)="hover(null, $event)"
      [attr.x]="timeToPx(data.timeStart) + 2"
      [attr.y]="2"
      [attr.width]="widthOfBlock(timeToPx(data.timeStart) + 2, timeToPx(data.timeEnd), groupedDataSet[index + 1])"
      [attr.height]="50"
      class="nullRect"
    />
    <svg:rect
      *ngIf="data.state === 'offline'"
      (mouseenter)="hover(data, $event)"
      (mousemove)="mouseMove($event)"
      (mouseleave)="hover(null, $event)"
      [attr.x]="timeToPx(data.timeStart) + 2"
      [attr.y]="2"
      [attr.width]="widthOfBlock(timeToPx(data.timeStart) + 2, timeToPx(data.timeEnd))"
      [attr.height]="50"
      rx="4"
      ry="4"
      class="offlineRect"
    />
    <svg:rect
      *ngIf="data.state === 'partial'"
      (mouseenter)="hover(data, $event)"
      (mousemove)="mouseMove($event)"
      (mouseleave)="hover(null, $event)"
      [attr.x]="timeToPx(data.timeStart) + 2"
      [attr.y]="2"
      [attr.width]="widthOfBlock(timeToPx(data.timeStart) + 2, timeToPx(data.timeEnd))"
      [attr.height]="50"
      rx="4"
      ry="4"
      [attr.fill]="'url(#' + partialGradientUniqueId + ')'"
    />
  </ng-container>
  <ng-container *ngFor="let axisLabel of axisLabelData; let first = first">
    <svg:text
      #thisText
      *ngIf="timeToPx(axisLabel.time) > 20"
      class="axisLabel"
      [attr.x]="timeToPxNoOverflowRight(axisLabel.time, thisText)"
      y="88"
    >
      {{ first ? ('components.networkStatusGraph.lastAxisLabel.' + axisTypeValue | translate) : axisLabel.label }}
    </svg:text>
  </ng-container>
</svg>

<div
  #tooltipElm
  class="tooltip"
  *ngIf="tooltip.visible"
  [style.top.px]="tooltip.top"
  [style.left.px]="tooltip.left"
  [class.shiftRight]="tooltip.shiftRight"
>
  {{ 'components.networkStatusGraph.toolTipFrom' | translate : { from: tooltip.data.timeFrom } }}
  <br />
  {{ 'components.networkStatusGraph.toolTipTo' | translate : { to: tooltip.data.timeTo } }}
  <br />
  {{
    'components.networkStatusGraph.toolTipState'
      | translate : { state: 'components.networkStatusGraph.state.' + (tooltip.data.state || 'unknown') | translate }
  }}
</div>
