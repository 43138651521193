export class Point {
  label: string;
  value: number | null;

  // Label can be either a string or a Date or a Moment?
  // For sanity I'm keeping this as any for now
  constructor(label: any, value: number | null) {
    this.label = label;
    this.value = value;
  }
}
