import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PageScrollService } from 'ngx-page-scroll-core';
import { Parser } from 'json2csv';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { GroupService } from 'src/app/lib/services/group.service';
import { IFilteredGroup } from 'src/app/lib/interfaces/groups.interface';
import { IFirmwareUpgradeCriteria, IFirmwareUpgradeReport, IVersionMatrices } from 'src/app/lib/interfaces/interface';

interface ModelObject {
  downloadUrl: string;
  encryptedDownloadUrl: string;
  firmwareVersion: string;
  model: string;
  residentialGateway: boolean;
}

@Component({
  templateUrl: './upgradefirmware.component.html',
  styleUrls: ['./upgradefirmware.component.scss']
})
export class UpgradeFirmwareComponent implements OnInit {
  locationIDFormControl: UntypedFormControl = new UntypedFormControl();
  randomLocationFormControl: UntypedFormControl = new UntypedFormControl();
  startTimeFormControl: UntypedFormControl = new UntypedFormControl();
  endTimeFormControl: UntypedFormControl = new UntypedFormControl();

  selectedVersionMatrixes: UntypedFormControl = new UntypedFormControl();
  selectedTargetVersions: UntypedFormControl = new UntypedFormControl();

  batchFirmwareUpgrade: any = null;
  report: IFirmwareUpgradeReport = null;
  helper: GeneralHelper = new GeneralHelper();

  locationIDItems: any[] = [];
  membersItems: any[] = [];
  membersOptions: any[] = [];
  versionItems: any[] = [];
  modelsItems: any[] = [];

  editLocationID: boolean = false;
  editTimeWindowStart: boolean = false;
  editTimeWindowEnd: boolean = false;
  groupValues: {
    text: string;
    value: string;
    object: IFilteredGroup;
  }[] = [];
  groupsSelected: string[] = [];
  modelValues: {
    text: string;
    value: string;
    object: ModelObject;
  }[] = [];
  modelsSelected: string[] = [];
  shardValues: {
    text: string;
    value: string;
  }[] = [];
  shardSelected = '';

  versionMatrix: any = {
    show: false,
    current: null
  };

  showMatrixSelect: boolean = false;

  allMatrices: IVersionMatrices[] = [];
  matrices: any[] = [];
  locationModels: string[] = [];
  search: UntypedFormControl = new UntypedFormControl();
  filterIncludeModels: boolean = false;

  downgradeItems: any[] = [];
  asapItems: any[] = [];

  asap: boolean = false;
  downgrade: boolean = false;

  selectedVersion: any = null;
  versionDetails: any = null;

  modalType: string = null;

  locationIDSelector: string = 'off';
  groupMembersSelector: string = 'off';
  versionMatrixSelector: string = 'off';
  groupMembersTypeSelector: string = 'name';
  modelSelector: string = 'off';

  loadingLocations: boolean = false;
  loadingRequestUpgrade: boolean = false;

  locations: any[] = [];
  batchSize: number = null;

  previewTriggered: boolean = false;

  @ViewChild('versionMatrixInput')
  versionMatrixInput: any;

  constructor(
    private plume: PlumeService,
    private mixpanel: MixpanelService,
    private toast: ToastService,
    private pagescroll: PageScrollService,
    private troubleshootingService: TroubleshootingService,
    private groupService: GroupService
  ) {}

  ngOnInit(): void {
    this.initTogglers();
    this.getGroups();
    this.getVersionMatrices();
    this.getShardIDs();
    this.startTimeFormControl.setValue('02:00');
    this.endTimeFormControl.setValue('05:00');
  }

  action(command: string, action: string): void {
    switch (command) {
      case 'locationID':
        this.locationIDSelector = action;
        break;
      case 'groupMembers':
        this.groupMembersSelector = action;
        break;
      case 'versionMatrix':
        this.versionMatrixSelector = action;
        break;
      case 'groupMembersOptions':
        this.groupMembersTypeSelector = action;
        break;
      case 'model':
        this.modelSelector = action;
        break;
    }
  }

  getShardIDs(): void {
    this.troubleshootingService.getShardIDs$().subscribe((response) => {
      this.shardValues = [
        { text: 'netops.firmware.clearShards', value: '' },
        ...response.shardIds.map((shard) => ({ text: shard, value: shard }))
      ];
    });
  }

  selectVersionMatix(): void {
    if (this.modalType === 'versionMatrix') {
      if (this.selectedVersionMatrixes.value?.split(', ').includes(this.selectedVersion.versionMatrix)) {
        this.toast.warning(
          'netops.firmware.errors.versionMatrixExistMessage',
          'netops.firmware.errors.versionMatrixExistHeader'
        );
        return;
      }

      const newValue = this.selectedVersionMatrixes.value
        ? `${this.selectedVersionMatrixes.value}, ${this.selectedVersion.versionMatrix}`
        : this.selectedVersion.versionMatrix;

      this.selectedVersionMatrixes.setValue(newValue);
      this.resize({ target: this.versionMatrixInput.nativeElement });
    } else {
      this.selectedTargetVersions.setValue(this.selectedVersion.versionMatrix);
    }

    this.setAsap();

    this.showMatrixSelect = false;
    this.selectedVersion = null;
    this.modalType = null;
  }

  setAsap(): void {
    if (this.asap) {
      this.startTimeFormControl.setValue('00:00');
      this.endTimeFormControl.setValue('23:59');
    } else {
      this.startTimeFormControl.setValue('02:00');
      this.endTimeFormControl.setValue('05:00');
    }
  }

  clear(): void {
    this.selectedTargetVersions.setValue('clear');

    this.setAsap();

    this.showMatrixSelect = false;
    this.selectedVersion = null;
    this.modalType = null;
  }

  disable(): void {
    this.selectedTargetVersions.setValue('disable');

    this.setAsap();

    this.showMatrixSelect = false;
    this.selectedVersion = null;
    this.modalType = null;
  }

  buildDataObject(): IFirmwareUpgradeCriteria {
    return {
      locationCriteria: {
        ...(this.groupsSelected?.length > 0 && { groups: this.groupsSelected }),
        ...(this.selectedVersionMatrixes.value?.length &&
          this.versionMatrixSelector !== 'off' && {
            activeVersionMatrices: this.selectedVersionMatrixes.value.split(',').map((item: string) => item.trim())
          }),
        ...(this.modelsSelected?.length &&
          this.modelSelector !== 'off' && {
            nodeModels: this.modelsSelected
          }),
        ...(this.locationIDFormControl.value?.length &&
          this.locationIDSelector !== 'off' && {
            locations: this.locationIDFormControl.value.split(',').map((item: string) => item.trim())
          }),
        ...(this.randomLocationFormControl.value >= 0 &&
          this.locationIDSelector !== 'include-only' && {
            numberOfLocations: parseInt(this.randomLocationFormControl.value, 10)
          }),
        ...(this.versionMatrixSelector !== 'off' && { activeVersionMatricesModifier: this.versionMatrixSelector }),
        ...(this.groupMembersSelector !== 'off' && { groupsModifier: this.groupMembersSelector }),
        ...(this.locationIDSelector !== 'off' && { locationsModifier: this.locationIDSelector }),
        ...(this.modelSelector !== 'off' && { nodeModelsModifier: this.modelSelector }),

        ...(this.shardSelected && { shardIds: [this.shardSelected] })
      },
      upgradeParameters: {
        preventDowngrade: this.downgrade,
        schedule: {
          startTime: this.startTimeFormControl.value,
          endTime: this.endTimeFormControl.value
        },
        ...(this.selectedTargetVersions.value && { targetVersionMatrix: this.selectedTargetVersions.value })
      }
    };
  }

  requestUpgrade(): void {
    this.loadingRequestUpgrade = true;
    const dataObject = this.buildDataObject();

    this.troubleshootingService.firmwareUpgrade$(dataObject).subscribe(
      (response) => {
        this.locations = response.locations;
        this.batchFirmwareUpgrade = response.batchFirmwareUpgrade;
        this.loadingRequestUpgrade = false;
        this.toast.warning('netops.firmware.upgradeMsg', 'netops.firmware.upgradeTitle');

        this.troubleshootingService.firmwareUpgradeReport$(this.batchFirmwareUpgrade.id).subscribe((response) => {
          this.report = response.batchFirmwareUpgrade;
        });
      },
      (error) => {
        this.loadingRequestUpgrade = false;
        this.batchFirmwareUpgrade = null;
        this.toast.warning(error.error.error.message, error.error.error.name);
      }
    );
  }

  preview(): void {
    this.report = null;
    this.previewTriggered = true;
    this.loadingLocations = true;

    const dataObject = this.buildDataObject();

    this.troubleshootingService.firmwareUpgradePreview$(dataObject).subscribe(
      (response) => {
        this.locations = response.locations;
        this.batchSize = response.batchFirmwareUpgrade.batchSize;
        this.loadingLocations = false;

        if (this.locations.length) {
          this.scrollTo('#preview');
        }
      },
      (error) => {
        this.loadingLocations = false;
        if (error.error.error) {
          this.toast.warning(error.error.error.message, error.error.error.name);
        } else {
          this.toast.warning(error.message, error.name);
        }
      }
    );
  }

  getGroups(offset: number = 0, previousValue: IFilteredGroup[] = []): void {
    const limit = 500;
    this.groupService.getGroups$({ order: 'name ASC', limit, offset }).subscribe((response) => {
      const list = [...previousValue, ...response];
      if (response.length === limit) {
        this.getGroups(list.length, list);
      } else {
        this.groupValues = list.map((obj) => ({
          text: obj.name,
          value: obj.id,
          object: obj
        }));
      }
    });
  }

  groupFilterFunction(value: { text: string; value: string; object?: IFilteredGroup }, filterText: string): boolean {
    return (
      value.object.name.toLowerCase().includes(filterText.toLowerCase()) ||
      value.object.id.toLowerCase().includes(filterText.toLowerCase())
    );
  }

  getVersionMatrices(): void {
    this.troubleshootingService.getVersionMatrices$().subscribe((response) => {
      this.allMatrices = response;
      this.getModels(response);
      this.filterSearch();
    });
  }

  getModels(responses: IVersionMatrices[]): void {
    const models = {};

    responses?.map((response) => {
      response.models.map((model) => {
        const modelName = model.model;
        if (!models[modelName]) {
          models[modelName] = model;
        }
      });
    });

    const groupedModels: ModelObject[] = Object.keys(models).map((key) => models[key]);
    this.modelValues = groupedModels.map((model) => ({
      text: model.model,
      value: model.model,
      object: model
    }));
  }

  openMatrixSelect(type: any): void {
    this.modalType = type;
    this.showMatrixSelect = true;
    this.mixpanel.storeEvent('LOCATION_FIRMWARE_MATRIX_SELECT_MODAL', { LOCATIONID: this.plume.locationid });
  }

  filterSearch(): void {
    let matrices = [];

    if (this.search.value && this.search.value.length) {
      matrices = this.allMatrices.filter((matrice) => {
        if (matrice.versionMatrix.toLowerCase().includes(this.search.value.toLowerCase())) {
          return true;
        }

        if (this.filterIncludeModels) {
          for (const model of matrice.models) {
            if (model.firmwareVersion.toLowerCase().includes(this.search.value.toLowerCase())) {
              return true;
            }
          }
        }

        return false;
      });
    } else {
      matrices = JSON.parse(JSON.stringify(this.allMatrices));
    }

    if (this.locationModels.length) {
      this.matrices = matrices.filter((matrice: any) => {
        let add = false;

        for (const models of matrice.models) {
          if (this.locationModels.indexOf(models.model) > -1) {
            add = true;
            break;
          }
        }

        return add;
      });
    } else {
      this.matrices = matrices;
    }
  }

  filterIncludeModelsToggle(): void {
    this.filterIncludeModels = !this.filterIncludeModels;
    this.filterSearch();
  }

  initTogglers(): void {
    this.locationIDItems = [
      { value: 'include-only', translation: 'netops.firmware.specific', selected: false },
      { value: 'include', translation: 'netops.firmware.include', selected: false },
      { value: 'exclude', translation: 'netops.firmware.exclude', selected: false },
      { value: 'off', translation: 'netops.firmware.off', selected: true }
    ];

    this.membersItems = [
      { value: 'include', translation: 'netops.firmware.include', selected: false },
      { value: 'exclude', translation: 'netops.firmware.exclude', selected: false },
      { value: 'off', translation: 'netops.firmware.off', selected: true }
    ];

    this.versionItems = [
      { value: 'include', translation: 'netops.firmware.include', selected: false },
      { value: 'exclude', translation: 'netops.firmware.exclude', selected: false },
      { value: 'off', translation: 'netops.firmware.off', selected: true }
    ];

    this.membersOptions = [
      { value: 'id', translation: 'netops.firmware.id', selected: false },
      { value: 'name', translation: 'netops.firmware.name', selected: true }
    ];

    this.modelsItems = [
      { value: 'include', translation: 'netops.firmware.include', selected: false },
      { value: 'exclude', translation: 'netops.firmware.exclude', selected: false },
      { value: 'off', translation: 'netops.firmware.off', selected: true }
    ];

    this.downgradeItems = [
      { value: true, translation: 'configurations.firmware.preventDowngrades', selected: this.downgrade },
      { value: false, translation: 'configurations.firmware.enableDowngrades', selected: !this.downgrade }
    ];

    this.asapItems = [
      { value: true, translation: 'configurations.firmware.now', selected: this.asap },
      { value: false, translation: 'configurations.firmware.later', selected: !this.asap }
    ];
  }

  toggleDowngrade(value: boolean): void {
    this.downgrade = value;
    this.initTogglers();
  }

  toggleAsap(value: boolean): void {
    this.asap = value;
    this.initTogglers();
  }

  resize(e: any): void {
    setTimeout(() => {
      e.target.style.height = 'auto';
      e.target.style.height = e.target.scrollHeight + 'px';
    }, 0);
  }

  scrollTo(selector: string): void {
    setTimeout(() => {
      this.pagescroll.scroll({ document: window.document, scrollTarget: selector });
    }, 100);
  }

  getGroupNames(ids: string[], values: { text: string; value: string }[]): string[] {
    return ids?.map((id) => values?.find((val) => val.value === id)?.text ?? id) ?? [];
  }

  copyGroups(): void {
    navigator.clipboard.writeText(this.getGroupNames(this.groupsSelected, this.groupValues).join('\n'));
    this.toast.success('netops.firmware.copyGroupsMsg', 'netops.firmware.copyGroupsTitle');
  }

  copyModels(): void {
    navigator.clipboard.writeText(this.modelsSelected.join('\n'));
    this.toast.success('netops.firmware.copyModelsMsg', 'netops.firmware.copyModelsTitle');
  }

  downloadCSV(): void {
    const data = {
      LocationId: this.report.locationIds.join('\n')
    };

    const universalBOM = '\uFEFF';
    this.mixpanel.storeEvent('FIRMWARE_UPGRADE_SAVE_CSV');

    const worker = new Parser();
    const csv = worker.parse(data);
    const blob = new Blob([universalBOM + csv], { type: 'text/csv;charset=utf8;' });
    const filename = this.report.id + '-locations.csv';

    this.helper.download(blob, filename);
  }
}
