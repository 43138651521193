import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartDataset, ChartEvent, ChartOptions, TooltipItem } from 'chart.js';
import * as moment from 'moment';
import { QoeHelper } from 'src/app/lib/helpers/qoe.helper';
import { MacAddrPipe } from 'src/app/lib/pipes/mac-addr.pipe';
import { IconService } from 'src/app/lib/services/icon.service';
import { ThemeService } from 'src/app/lib/services/theme.service';
import 'chartjs-adapter-moment';

@UntilDestroy()
@Component({
  selector: 'qoesummary',
  templateUrl: './qoesummary.component.html',
  styleUrls: ['./qoesummary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QoesummaryComponent implements OnChanges {
  @Input() nodes;
  @Input() devices;
  @Output() clickedDevice = new EventEmitter<string>();
  @Output() clickedNode = new EventEmitter<string>();
  helper: QoeHelper = new QoeHelper();
  nodesAndDevices = [];
  itemsInTooltip = 5;
  scatterChartOptions = this.chartDefinition();
  scatterChartData: ChartDataset[] = [];
  disabled: { [id: string]: true } = {};
  hovered: { [id: string]: true } | null = null;
  heroBoardHovered = false;
  qoeRange = {
    min: 0,
    max: 5
  };
  frameInProgress = 0;

  constructor(
    public iconService: IconService,
    private cdr: ChangeDetectorRef,
    private theme: ThemeService,
    private macAddrPipe: MacAddrPipe,
    private translate: TranslateService
  ) {
    this.graphThemeChange();
  }

  ngOnChanges(): void {
    this.calculateGraphPoints();
  }

  colorByScore(score: number): string {
    return this.helper.gradeColors[Math.floor(score) - 1];
  }

  sortHeroBoard(arr: { id: string; name: string; type: string; qoe: { qoe: number } }[]): any[] {
    return [...arr].sort((a, b) => {
      if (a.type > b.type) {
        return -1;
      }
      if (a.type < b.type) {
        return 1;
      }
      if ((a.qoe.qoe === null || a.qoe.qoe === undefined) && b.qoe.qoe !== null && b.qoe.qoe !== undefined) {
        return 1;
      }
      if ((b.qoe.qoe === null || b.qoe.qoe === undefined) && a.qoe.qoe !== null && a.qoe.qoe !== undefined) {
        return -1;
      }
      if (a.qoe.qoe > b.qoe.qoe) {
        return 1;
      }
      if (a.qoe.qoe < b.qoe.qoe) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.id.toLowerCase() > b.id.toLowerCase()) {
        return 1;
      }
      if (a.id.toLowerCase() < b.id.toLowerCase()) {
        return -1;
      }
      return 0;
    });
  }

  toggle(id: string): void {
    if (this.disabled[id]) {
      delete this.disabled[id];
    } else {
      this.disabled[id] = true;
    }
    this.calculateGraphPoints();
  }

  hover(id: string[] | null): void {
    if (JSON.stringify(this.hovered ? Object.keys(this.hovered) : this.hovered) === JSON.stringify(id)) {
      return;
    }
    this.hovered =
      id?.reduce(
        (acc, value) => ({ ...acc, [value]: true }),
        {} as {
          [id: string]: true;
        }
      ) ?? null;
    this.calculateGraphPoints();
    this.cdr.markForCheck();
  }

  track(index: number, item: { id: string }): any {
    return item.id;
  }

  deviceOrPodClicked(deviceOrPod: any): void {
    if (deviceOrPod.type === 'device') {
      this.clickedDevice.emit(deviceOrPod.id);
    } else {
      this.clickedNode.emit(deviceOrPod.id);
    }
  }

  qoeRangeChanged(): void {
    cancelAnimationFrame(this.frameInProgress);
    requestAnimationFrame(() => {
      this.calculateGraphPoints();
    });
  }

  showAll(): void {
    this.disabled = {};
    this.calculateGraphPoints();
  }

  hideAll(): void {
    this.disabled = this.nodesAndDevices.reduce(
      (acc, value) => ({ ...acc, [value.id]: true }),
      {} as {
        [id: string]: true;
      }
    );
    this.calculateGraphPoints();
  }

  showAllEnabled(): boolean {
    return Object.keys(this.disabled).length > 0;
  }

  hideAllEnabled(): boolean {
    return Object.keys(this.disabled).length < this.nodesAndDevices.length;
  }

  private calculateGraphPoints(): void {
    this.nodesAndDevices = [...this.nodes, ...this.devices]
      .filter((nodeOrDevice) => nodeOrDevice.qoe.qoe >= this.qoeRange.min && nodeOrDevice.qoe.qoe <= this.qoeRange.max)
      .sort((a, b) => {
        const suppressA = this.hovered && !this.hovered[a.id];
        const suppressB = this.hovered && !this.hovered[b.id];
        if (suppressA === suppressB) {
          return 0;
        }
        if (suppressA && !suppressB) {
          return 1;
        }
        return -1;
      });
    this.scatterChartData = this.nodesAndDevices
      .filter((item) => !this.disabled[item.id])
      .map((node) => {
        const suppress = this.hovered && !this.hovered[node.id];
        const colors =
          node?.qoeMetrics?.weightedQoeScore?.map((score) =>
            suppress ? 'rgba(255,255,255)' : this.helper.gradeColors[Math.floor(score.value) - 1]
          ) ?? [];
        return {
          data:
            node?.qoeMetrics?.weightedQoeScore?.map((score) => ({ x: new Date(score.timestamp), y: score.value })) ??
            [],
          backgroundColor: colors,
          borderColor: colors,
          hoverBackgroundColor: colors,
          hoverBorderColor: colors
        };
      });
  }

  private graphThemeChange(): void {
    this.theme.listener.pipe(untilDestroyed(this)).subscribe((theme) => {
      const options = this.scatterChartOptions as ChartConfiguration['options'];
      if (!options?.scales?.y || !options?.scales?.x || !options?.plugins?.tooltip) return;

      if (theme === 'dark') {
        options.scales.y.grid = {
          ...options.scales.y.grid,
          color: '#232323',
          borderColor: '#434546'
        };
        options.scales.y.ticks = {
          ...options.scales.y.ticks,
          color: '#8F9397'
        };
        options.scales.x.grid = {
          ...options.scales.x.grid,
          color: '#232323',
          borderColor: '#434546'
        };
        options.scales.x.ticks = {
          ...options.scales.x.ticks,
          color: '#8F9397'
        };
        options.plugins.tooltip.backgroundColor = '#1c1b1b';
        options.plugins.tooltip.borderColor = '#8F9397';
      } else {
        delete options.scales.y.grid.color;
        delete options.scales.y.grid.borderColor;
        delete options.scales.y.ticks.color;
        delete options.scales.x.grid.color;
        delete options.scales.x.grid.borderColor;
        delete options.scales.x.ticks.color;
        delete options.plugins.tooltip.backgroundColor;
        delete options.plugins.tooltip.borderColor;
      }
      this.scatterChartOptions = { ...this.scatterChartOptions };
    });
  }

  private tooltipLabel(tooltipItem: TooltipItem<'scatter'>): string {
    const item = this.nodesAndDevices.filter((item) => !this.disabled[item.id])[tooltipItem.datasetIndex];
    const index = Object.keys(this.hovered).indexOf(item.id);
    if (index > this.itemsInTooltip) {
      return '';
    }
    if (index === this.itemsInTooltip) {
      return this.translate.instant('qoe.moreDevices', {
        count: Object.keys(this.hovered).length - this.itemsInTooltip
      });
    }
    return `${this.macAddrPipe.transform(item.name, true)}`;
  }

  private tooltipTitle(items: TooltipItem<'scatter'>[]): string {
    const hoveredNodesOrDevices = items.map(
      (item) => this.nodesAndDevices.filter((item) => !this.disabled[item.id])[item.datasetIndex]
    );
    this.hover(hoveredNodesOrDevices.map((nodeOrDevice) => nodeOrDevice.id));
    const timeStamp = hoveredNodesOrDevices[0].qoeMetrics.weightedQoeScore[items[0].dataIndex].timestamp;
    return `${moment(timeStamp).format('lll')}\n${this.translate.instant('qoe.score')}: ${
      Math.round(items[0].parsed.y * 100) / 100
    }`;
  }

  private pointClicked(): void {
    if (!this.hovered) {
      return;
    }
    this.disabled = this.nodesAndDevices
      .filter((nodeOrDevice) => !this.hovered[nodeOrDevice.id])
      .reduce(
        (acc, value) => ({ ...acc, [value.id]: true }),
        {} as {
          [id: string]: true;
        }
      );
    this.calculateGraphPoints();
    this.cdr.markForCheck();
  }

  private chartDefinition(): ChartOptions<'scatter'> {
    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 1);
    const maxDate = new Date();
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          max: 5.5,
          grid: {},
          ticks: {
            stepSize: 0.5
          }
        },
        x: {
          type: 'time',
          min: minDate.toISOString(),
          max: maxDate.toISOString(),
          grid: {}
        }
      },
      animation: {
        duration: 0
      },
      interaction: {
        mode: 'nearest'
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          displayColors: false,
          padding: 10,
          titleMarginBottom: 15,
          borderWidth: 1,
          callbacks: {
            title: (items) => this.tooltipTitle(items),
            label: (tooltipItem) => this.tooltipLabel(tooltipItem)
          }
        }
      },
      onHover: (e: ChartEvent, activeElements: Array<any>) => {
        e.native.stopPropagation();
        if (activeElements.length === 0) {
          this.hover(null);
        }
      },
      onClick: () => {
        this.pointClicked();
      }
    };
  }
}
