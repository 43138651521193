import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IDeviceLanLatency, ILanLatencyValue, INodeLanLatency } from 'src/app/lib/interfaces/interface';

@Component({
  selector: 'lan-latency-modal',
  templateUrl: './lan-latency-modal.component.html',
  styleUrls: ['./lan-latency-modal.component.scss']
})
export class LanLatencyModalComponent implements OnInit, OnChanges {
  @Input()
  device = null;

  @Input()
  node = null;

  @Output()
  closeLanLatencyModal = new EventEmitter<void>();

  lanLatencyData: {
    nodeId?: string;
    parentNodeId?: string;
    hideUpstream?: boolean;
    data: {
      class: string;
      up: number;
      down: number;
    }[];
  } = null;

  constructor() {}

  ngOnInit(): void {
    if (this.node?.currentLanLatency) this.getLanLatencyData(this.node?.currentLanLatency);
    if (this.device?.currentLanLatency) this.getLanLatencyData(this.device?.currentLanLatency);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.node) {
      this.getLanLatencyData(changes.node.currentValue.currentLanLatency);
    }

    if (changes.device) {
      this.getLanLatencyData(changes.device.currentValue.currentLanLatency);
    }
  }

  getLanLatencyData(lanLatency: INodeLanLatency | IDeviceLanLatency) {
    if (!lanLatency) {
      this.lanLatencyData = null;
      return;
    }

    const isNode = !!this.node;
    const upstream = isNode ? (lanLatency as INodeLanLatency)?.latencyToParent?.upstream : undefined;
    const downstream = isNode ? lanLatency?.latencyToParent?.downstream : lanLatency?.latencyToGateway?.downstream;

    const data = {};

    upstream?.forEach((latency: ILanLatencyValue) => {
      data[latency.class] = { ...data[latency.class], up: latency.avgInMs };
    });

    downstream?.forEach((latency: ILanLatencyValue) => {
      data[latency.class] = { ...data[latency.class], down: latency.avgInMs };
    });

    this.lanLatencyData = {
      ...(isNode && {
        nodeId: (lanLatency as INodeLanLatency).id,
        parentNodeId: (lanLatency as INodeLanLatency).parentId
      }),
      hideUpstream: !isNode,
      data: Object.keys(data).map((key) => ({
        class: key,
        up: data[key].up,
        down: data[key].down
      }))
    };
  }

  closeModal() {
    this.closeLanLatencyModal.emit();
  }
}
