<div class="name">
  <div class="circle">
    <icon [path]="getIcon(node?.model)" class="size-1_4"></icon>
    <icon
      [path]="gateWayIcon$ | async"
      class="appendix"
      *ngIf="node?.connectionState == 'connected' && node?.isGateway"
    ></icon>
  </div>
  <div class="data">
    <div class="nickname" #name [style.maxWidth]="maxWidth + 'px'">
      {{ node.nickname || node.defaultName }}
    </div>
    <div class="sn">{{ node.id }}</div>
  </div>
</div>
