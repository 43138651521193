import { createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { DeepReadonly, IConfigAndStateWan, ILocation, IModeString } from 'src/app/lib/interfaces/interface';
import { AppState } from '../reducers';
import { makeFilterDistinctPipeSelect } from '../state-interfaces';

export const selectCustomerIdentification = (state: AppState) => state.customer.identification?.data;
export const selectBaseUrl = ({ customerId, locationId }: { customerId?: string; locationId?: string } = {}) =>
  createSelector(
    selectCustomerIdentification,
    (customer) => `/Customers/${customerId ?? customer.customerid}/locations/${locationId ?? customer.locationid}`
  );
export const selectLocationConfigStateLoading = (state: AppState) =>
  state.customer.locationConfigState?.state === 'loading' || state.customer.locationConfigState?.state === 'loadedNext';
export const selectLocationConfigState = (state: AppState) => state.customer.locationConfigState?.data;
export const selectLocationConfig = (state: AppState) => state.customer.locationConfigState;

export const selectPipeLocationOnChange = makeFilterDistinctPipeSelect(
  (state) =>
    state.customer.location.state !== 'loadedNext' &&
    state.customer.location.state !== 'loading' &&
    !!state.customer.location.data?.id,
  (state) =>
    ({
      ...state.customer.location?.data,
      updatedAt: null,
      loadedTime: null
    } as DeepReadonly<ILocation>)
);

// fires more events than selectPipeLocationOnChange - even optimistic events before reloading location (loadedNext state)
export const selectPipeLocationOnChangeOptimistic = makeFilterDistinctPipeSelect(
  (state) => !!state.customer.location.data?.id,
  (state) =>
    ({
      ...state.customer.location?.data,
      updatedAt: null,
      loadedTime: null
    } as DeepReadonly<ILocation>)
);

export const selectPipeLocationAuthorizationsOnChangeOptimistic = makeFilterDistinctPipeSelect(
  (state) => !!state.customer.location.data?.id,
  (state) => state.customer.location?.data.authorizations
);

export const selectLocationLoading = (state: AppState) => state.customer.location?.state === 'loading';

export const selectLocationNotReady = (state: AppState) =>
  !state.customer.location?.data?.id || state.customer.location?.state === 'error';

export const selectLocationStateObject = (state: AppState) => state.customer.location;

export const selectCustomer = (state: AppState) => state.customer.customer?.data;
export const selectCustomerLoading = (state: AppState) => state.customer.customer?.state === 'loading';
export const selectCustomerStateObject = (state: AppState) => state.customer.customer;
export const selectLocationList = (state: AppState) =>
  state.customer.locationList.data?.map((location) => ({ ...location, name: location.name?.substring(0, 10000) }));

export const selectWPA3Recommendation = (state: AppState) =>
  state.customer?.capabilities?.data?.wpa3?.recommendation &&
  state.customer.capabilities.data.wpa3.recommendation.toLowerCase() !== 'none'
    ? state.customer.capabilities.data.wpa3.recommendation
    : null;
export const selectPrimarySecondaryNetworks = (state: AppState) => state.customer?.primarySecondaryNetworks.data;
export const selectSecondaryNetworks = (state: AppState) => state.customer?.secondaryNetworks.data;

export const selectOffline = (
  state: AppState
): boolean | undefined => // undefined - loading, boolean - real offline state => loading converted to boolean makes it online
  state?.polling?.topology?.data?.vertices?.reduce((acc, val) => acc && val.connectionState !== 'connected', true);

export const selectWan = (state: AppState) =>
  // null in no nodes have wan
  state.customer.locationConfigState.data?.state?.nodeState
    ?.filter((node) => node.wan && node.wan.length > 0)
    ?.reduce(
      (acc, node) => ({ ...(acc ?? {}), [node.nodeId]: node.wan }),
      null as { [key: string]: IConfigAndStateWan[] }
    );
export const selectSingleCustomerId = pipe(
  select(selectCustomerIdentification),
  map((identification) => identification.customerid),
  take(1)
);
export const selectPowerManagementState = (state: AppState) => ({
  state: state.customer.locationConfigState.data?.state?.powerManagement,
  capable: state.customer.locationConfigState.data?.state?.capabilities?.powerManagement,
  mode: state.customer.locationConfigState.data?.config?.powerManagement?.mode
});
export const selectPartnerIdLoading = (state: AppState) => state.customer.partnerIdLoading;
export const selectPartnerId = (state: AppState) => state.customer.location.data?.partnerId;

export const selectPartnerIsFlex = (state: AppState) => state.customer.location.data?.partnerId === 'Flex-Plume-Demo';
export const selectPartnerIsLteDemo = (state: AppState) => state.customer.location.data?.partnerId === 'Lte-Plume-Demo';

export const selectReducedMode = (state: AppState) =>
  !!(
    state.customer.location.data?.controlMode === 'reduced' ||
    (state.customer.location.data?.controlMode as IModeString)?.mode === 'reduced'
  );
export const selectMonitorMode = (state: AppState) =>
  !!(
    state.customer.location.data?.controlMode === 'monitor' ||
    (state.customer.location.data?.controlMode as IModeString)?.mode === 'monitor'
  );

export const selectBatteryMode = (state: AppState) =>
  !!(
    state.customer.location.data?.controlMode === 'battery' ||
    (state.customer.location.data?.controlMode as IModeString)?.mode === 'battery'
  );

export const selectReducedModeCapable = (state: AppState) =>
  state.customer.location.data?.capabilities.controlMode.reduced;
export const selectMonitorModeCapable = (state: AppState) =>
  state.customer.location.data?.capabilities.controlMode.monitor;
export const selectBatteryModeCapable = (state: AppState) =>
  state.customer.location.data?.capabilities.controlMode.battery;
export const selectFullModeCapable = (state: AppState) => state.customer.location.data?.capabilities.controlMode.full;
export const selectNonFullModeCapable = (state: AppState) =>
  state.customer.location.data?.capabilities?.controlMode?.reduced ||
  state.customer.location.data?.capabilities?.controlMode?.monitor ||
  state.customer.location.data?.capabilities?.controlMode?.battery;

export const selectBaseDeviceUrl = ({ mac, isFlexRole }: { mac: string; isFlexRole: boolean }) =>
  createSelector(selectBaseUrl(), (url: string) => `${url}/${isFlexRole ? 'flex/' : ''}devices/${mac}`);

export const selectBaseNodeUrl = ({ nodeId }: { nodeId: string }) =>
  createSelector(selectBaseUrl(), (url: string) => `${url}/nodes/${nodeId}`);

export const selectIsFlexLocation = (state: AppState) => state.customer.location.data?.flex;

export const selectIsUpriseLocation = (state: AppState) => state.customer.location.data?.uprise;
export const selectLocationProfile = (state: AppState) => state.customer.location.data?.profile;
export const selectIsHomePass = (state: AppState) => state.customer.location.data?.profile === 'auto';
export const selectIsWorkPass = (state: AppState) => state.customer.location.data?.profile === 'smallbusiness';
export const selectIsProperty = (state: AppState) => state.customer.location.data?.profile === 'property';

export const selectNetworkModeEditable = ({ permissions }: { permissions: any }) =>
  createSelector(
    (state: AppState) => state.customer.location.data,
    (location: ILocation) =>
      location &&
      permissions?.uiFeatures?.editNetworkMode &&
      (!location.flex || (location.networkMode !== 'router' && location.networkModeRealized !== 'router'))
  );

export const selectAppPrioritization = (state: AppState) => state.customer.trafficClass.appPrioritization.data;
export const selectAppPrioritizationV2 = (state: AppState) => state.customer.trafficClass.appPrioritizationV2.data;
export const selectAppPrioritizationMonitoring = (state: AppState) => state.customer.trafficClass.monitoring.data;
export const selectGranularAppPrioritizationMonitoring = (state: AppState) =>
  state.customer.trafficClass.granularMonitoring.data;
export const selectLogpullHistory = (state: AppState) => state.customer.logpullHistory.data;
export const selectMobilizeProblemsHistory = (state: AppState) => state.customer.mobilizeProblemsHistory.data;
export const selectAppTime = (state: AppState) => state.customer.appTime.data;

export const selectIPv6MapTE = (state: AppState) => state.customer.locationConfigState.data.state.mapTe;

export const selectConfigAndState = (state: AppState) => state.customer.locationConfigState.data;
