import { Component, Input } from '@angular/core';

@Component({
  selector: 'header-service-level',
  templateUrl: './header-service-level.component.html',
  styleUrls: ['./header-service-level.component.scss']
})
export class HeaderServiceLevelComponent {
  @Input() serviceLevel: string | null;

  constructor() {}
}
