import { createReducer, on } from '@ngrx/store';
import { setErrorState, setInitialState, setLoadedState } from '../state-interfaces';
import {
  alarmReportsLoaded,
  alarmReportsLoadedError,
  broadbandEfficiencyAlertLoaded,
  broadbandEfficiencyAlertLoadedError,
  currentLanLatencyLoaded,
  devicesLoaded,
  devicesLoadedError,
  internetLoaded,
  internetLoadedError,
  locationQoeLoaded,
  locationQoeLoadedError,
  nodesLoaded,
  nodesLoadedError,
  optimizationChanged,
  powerManagementLoaded,
  stabilityLoaded,
  stabilityLoadedError,
  topologyChanged,
  topologyChangedError
} from './polling.actions';

export const initialState = {
  nodes: setInitialState(null),
  devices: setInitialState(null),
  locationQoE: setInitialState(null),
  bbe: setInitialState(null),
  internet: setInitialState(null),
  stability: setInitialState(null),
  alarmReports: setInitialState(null),
  topology: setInitialState(null),
  optimization: setInitialState(null),
  powerManagement: setInitialState(null),
  currentLanLatency: setInitialState(null)
};

export const PollingReducer = createReducer(
  initialState,
  on(nodesLoaded, (state, { nodes }) => ({
    ...state,
    nodes: setLoadedState(nodes)
  })),
  on(nodesLoadedError, (state, { error }) => ({
    ...state,
    nodes: setErrorState(state.nodes, error)
  })),
  on(devicesLoaded, (state, { devices }) => ({
    ...state,
    devices: setLoadedState(devices)
  })),
  on(devicesLoadedError, (state, { error }) => ({
    ...state,
    devices: setErrorState(state.devices, error)
  })),
  on(locationQoeLoaded, (state, { qoe }) => ({
    ...state,
    locationQoE: setLoadedState(qoe)
  })),
  on(locationQoeLoadedError, (state, { error }) => ({
    ...state,
    locationQoE: setErrorState(state.locationQoE, error, { devices: [], nodes: [] })
  })),
  on(broadbandEfficiencyAlertLoaded, (state, { bbe }) => ({
    ...state,
    bbe: setLoadedState(bbe)
  })),
  on(broadbandEfficiencyAlertLoadedError, (state, { error }) => ({
    ...state,
    bbe: setErrorState(state.bbe, error)
  })),
  on(internetLoaded, (state, { internet }) => ({
    ...state,
    internet: setLoadedState(internet)
  })),
  on(internetLoadedError, (state, { error }) => ({
    ...state,
    internet: setErrorState(state.internet, error)
  })),
  on(stabilityLoaded, (state, { stability }) => ({
    ...state,
    stability: setLoadedState(stability)
  })),
  on(stabilityLoadedError, (state, { error }) => ({
    ...state,
    stability: setErrorState(state.stability, error)
  })),
  on(alarmReportsLoaded, (state, { alarmReports }) => ({
    ...state,
    alarmReports: setLoadedState(alarmReports)
  })),
  on(alarmReportsLoadedError, (state, { error }) => ({
    ...state,
    alarmReports: setErrorState(state.alarmReports, error)
  })),
  on(topologyChanged, (state, { topology }) => ({
    ...state,
    topology: setLoadedState(topology)
  })),
  on(topologyChangedError, (state, { error }) => ({
    ...state,
    topology: setErrorState(state.topology, error)
  })),
  on(optimizationChanged, (state, { optimization }) => ({
    ...state,
    optimization: setLoadedState(optimization)
  })),
  on(powerManagementLoaded, (state, { powerManagement }) => ({
    ...state,
    powerManagement: setLoadedState(powerManagement)
  })),
  on(currentLanLatencyLoaded, (state, { currentLanLatency }) => ({
    ...state,
    currentLanLatency: setLoadedState(currentLanLatency)
  }))
);
