<div class="row">
  <div class="col-xs-12">
    <span class="title" *ngIf="!bare">
      {{ 'health.sectionTitleCongestion' | translate }}
    </span>
  </div>
</div>
<span>
  <div class="row">
    <div class="col-xs-12">
      <div class="bubble">
        <p *ngIf="hasAlarms">{{ 'health.environmentCongestionMessage1' | translate }}</p>
        <p>{{ 'health.networkPerformance.congestionAlarm.listOfNodes' | translate }}:</p>
        <div class="data" id="help-health-nodeCongestionItem" *ngFor="let data of alarm; trackBy: track">
          <collapsiblebox
            [collapsible]="true"
            [showDivider]="false"
            [show]="opened[data.node.id]?.open"
            (toggle)="toggleHistory($event, data)"
          >
            <ng-container header>
              <div class="big-outage">
                <div class="node">
                  <icon [path]="getIcon(data.node.model)" class="size-1_4"></icon>
                  <div class="details">
                    <div class="name">{{ data.node.nickname || data.node.name || data.node.defaultName }}</div>
                    <div class="id">{{ data.node.id }}</div>
                  </div>
                </div>
                <div
                  class="bands"
                  *ngIf="
                    data.data['2.4G'] || data.data['5G'] || data.data['5GL'] || data.data['5GU'] || data.data['6G']
                  "
                >
                  <div class="header">
                    <div class="label">{{ 'health.networkPerformance.congestionAlarm.band' | translate }}</div>
                    <div class="utilization">
                      {{ 'health.networkPerformance.congestionAlarm.congestion' | translate }}
                    </div>
                  </div>
                  <div class="band" *ngIf="data.data['2.4G']">
                    <div class="label">{{ 'health.networkPerformance.congestionAlarm.2.4G' | translate }}</div>
                    <div class="utilization">
                      {{ data.data['2.4G'][0].avgutilization }}%
                      <div class="alarmed" *ngIf="data.data['2.4G'][0].alarmUtilization"></div>
                    </div>
                  </div>
                  <div class="band" *ngIf="data.data['5G']">
                    <div class="label">{{ 'health.networkPerformance.congestionAlarm.5G' | translate }}</div>
                    <div class="utilization">
                      {{ data.data['5G'][0].avgutilization }}%
                      <div class="alarmed" *ngIf="data.data['5G'][0].alarmUtilization"></div>
                    </div>
                  </div>
                  <div class="band" *ngIf="data.data['5GL']">
                    <div class="label">{{ 'health.networkPerformance.congestionAlarm.5GL' | translate }}</div>
                    <div class="utilization">
                      {{ data.data['5GL'][0].avgutilization }}%
                      <div class="alarmed" *ngIf="data.data['5GL'][0].alarmUtilization"></div>
                    </div>
                  </div>
                  <div class="band" *ngIf="data.data['5GU']">
                    <div class="label">{{ 'health.networkPerformance.congestionAlarm.5GU' | translate }}</div>
                    <div class="utilization">
                      {{ data.data['5GU'][0].avgutilization }}%
                      <div class="alarmed" *ngIf="data.data['5GU'][0].alarmUtilization"></div>
                    </div>
                  </div>
                  <div class="band" *ngIf="data.data['6G']">
                    <div class="label">{{ 'health.networkPerformance.congestionAlarm.6G' | translate }}</div>
                    <div class="utilization">
                      {{ data.data['6G'][0].avgutilization }}%
                      <div class="alarmed" *ngIf="data.data['6G'][0].alarmUtilization"></div>
                    </div>
                  </div>
                </div>
                <div
                  class="offline"
                  *ngIf="
                    !data.data['2.4G'] && !data.data['5G'] && !data.data['5GL'] && !data.data['5GU'] && !data.data['6G']
                  "
                >
                  {{ 'health.networkPerformance.congestionAlarm.offline' | translate }}
                </div>
              </div>
              <div class="small-outage">
                <div class="small-bands">
                  <div class="node">
                    <icon [path]="getIcon(data.node.model)" class="size-1_4"></icon>
                    <div class="details">
                      <div class="name">{{ data.node.nickname || data.node.name || data.node.defaultName }}</div>
                      <div class="id">{{ data.node.id }}</div>
                    </div>
                  </div>

                  <div
                    class="small-header"
                    *ngIf="
                      data.data['2.4G'] || data.data['5G'] || data.data['5GL'] || data.data['5GU'] || data.data['6G']
                    "
                  >
                    <div class="label small-label">
                      {{ 'health.networkPerformance.congestionAlarm.band' | translate }}
                    </div>
                    <div class="utilization">
                      {{ 'health.networkPerformance.congestionAlarm.congestion' | translate }}
                    </div>
                  </div>

                  <div class="band small-band" *ngIf="data.data['2.4G']">
                    <div class="label small-label">
                      {{ 'health.networkPerformance.congestionAlarm.2.4G' | translate }}
                    </div>
                    <div class="utilization">
                      {{ data.data['2.4G'][0].avgutilization }}%
                      <div class="alarmed" *ngIf="data.data['2.4G'][0].alarmUtilization"></div>
                    </div>
                  </div>
                  <div class="band small-band" *ngIf="data.data['5G']">
                    <div class="label small-label">
                      {{ 'health.networkPerformance.congestionAlarm.5G' | translate }}
                    </div>
                    <div class="utilization">
                      {{ data.data['5G'][0].avgutilization }}%
                      <div class="alarmed" *ngIf="data.data['5G'][0].alarmUtilization"></div>
                    </div>
                  </div>
                  <div class="band small-band" *ngIf="data.data['5GL']">
                    <div class="label small-label">
                      {{ 'health.networkPerformance.congestionAlarm.5GL' | translate }}
                    </div>
                    <div class="utilization">
                      {{ data.data['5GL'][0].avgutilization }}%
                      <div class="alarmed" *ngIf="data.data['5GL'][0].alarmUtilization"></div>
                    </div>
                  </div>
                  <div class="band small-band" *ngIf="data.data['5GU']">
                    <div class="label small-label">
                      {{ 'health.networkPerformance.congestionAlarm.5GU' | translate }}
                    </div>
                    <div class="utilization">
                      {{ data.data['5GU'][0].avgutilization }}%
                      <div class="alarmed" *ngIf="data.data['5GU'][0].alarmUtilization"></div>
                    </div>
                  </div>
                  <div class="band small-band" *ngIf="data.data['6G']">
                    <div class="label small-label">
                      {{ 'health.networkPerformance.congestionAlarm.6G' | translate }}
                    </div>
                    <div class="utilization">
                      {{ data.data['6G'][0].avgutilization }}%
                      <div class="alarmed" *ngIf="data.data['6G'][0].alarmUtilization"></div>
                    </div>
                  </div>
                </div>
                <div
                  class="offline-small"
                  *ngIf="
                    !data.data['2.4G'] && !data.data['5G'] && !data.data['5GL'] && !data.data['5GU'] && !data.data['6G']
                  "
                >
                  {{ 'health.networkPerformance.congestionAlarm.offline' | translate }}
                </div>
              </div>
            </ng-container>

            <ng-container container>
              <div id="help-health-nodeCongestionChart" class="loading" *ngIf="!opened[data.node.id]?.chartData">
                {{ 'health.networkPerformance.congestionAlarm.loading' | translate }}
              </div>
              <ng-container *ngIf="opened[data.node.id]?.chartData">
                <p>{{ 'health.networkPerformance.congestionAlarm.chartTitle' | translate }}</p>
                <qoelivechart
                  id="help-health-nodeCongestionChart"
                  [autoscale]="true"
                  [series]="opened[data.node.id].chartSeries"
                  [data]="opened[data.node.id].chartData"
                  [scale]="{ range: [0, 100], symbol: '%', timeSpan: 24 * 60 * 60 * 1000 - 15 * 60 * 1000 }"
                ></qoelivechart>
              </ng-container>
            </ng-container>
          </collapsiblebox>
        </div>
      </div>
    </div>
  </div>
</span>
