import { Component, OnInit, OnChanges, Output, EventEmitter, Input } from '@angular/core';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { TranslateService } from '@ngx-translate/core';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectConfigAndState } from 'src/app/store/customer/customer.selectors';
import { threadNetworkSet, threadNetworkSetFinished } from 'src/app/store/threadnetwork/threadnetwork.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IConfigAndState } from 'src/app/lib/interfaces/interface';
import { Actions, ofType } from '@ngrx/effects';

@UntilDestroy()
@Component({
  selector: 'threadnetwork',
  templateUrl: './threadnetwork.component.html',
  styleUrls: ['./threadnetwork.component.scss']
})
export class ThreadNetworkComponent implements OnInit, OnChanges {
  expand: boolean = false;
  configExpand: boolean = false;
  stateExpand: boolean = false;
  threadInterface: UntypedFormControl = new UntypedFormControl('');
  reportingInterval: UntypedFormControl = new UntypedFormControl('');
  configAndState: IConfigAndState;
  editing: boolean = false;
  enableItems = [];
  networkEnabled: boolean = false;
  doneEditing: boolean = true;

  @Input()
  open: number = 0;

  @Output()
  toggle = new EventEmitter();

  @Output()
  filter = new EventEmitter();

  @Output()
  clearFilter = new EventEmitter<{ section: string }>();

  constructor(
    public plume: PlumeService,
    private mixpanel: MixpanelService,
    private translate: TranslateService,
    private store: Store,
    private actions: Actions
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectConfigAndState)
      .pipe(untilDestroyed(this))
      .subscribe((configAndState) => {
        this.configAndState = configAndState;
        this.registerFilter();
        this.setEnableItems();

        if (this.doneEditing) {
          this.editing = false;
        }
      });

    this.actions.pipe(untilDestroyed(this), ofType(threadNetworkSetFinished)).subscribe(() => {
      this.doneEditing = true;
    });
  }

  ngOnChanges(changes: any): void {
    this.expand = changes.open.currentValue;
  }

  toggleExpand(): void {
    this.toggle.emit(!this.expand);

    if (!this.expand) {
      this.mixpanel.storeEvent('CONFIGURATION_THREAD_NETWORK_SCREEN');
    }
  }

  registerFilter(): void {
    this.clearFilter.emit({ section: 'threadnetwork' });

    if (!this.configAndState?.state?.capabilities?.thread) {
      return;
    }

    this.translate
      .get('configurations.threadnetwork.networkEnabled')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'threadnetwork', property: 'networkEnabled', translation: translated })
      );

    this.translate
      .get('configurations.threadnetwork.threadInterface')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'threadnetwork', property: 'threadInterface', translation: translated })
      );

    this.translate
      .get('configurations.threadnetwork.reportingInterval')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'threadnetwork', property: 'reportingInterval', translation: translated })
      );
  }

  setEnableItems(): void {
    this.enableItems = [
      {
        value: true,
        translation: 'enabled',
        selected: this.configAndState.config?.thread?.enable
      },
      {
        value: false,
        translation: 'disabled',
        selected: !this.configAndState.config?.thread?.enable
      }
    ];
  }

  editNetwork(): void {
    this.networkEnabled = this.configAndState.config?.thread?.enable;
    this.threadInterface.setValue(this.configAndState.config?.thread?.threadInterface || null);
    this.reportingInterval.setValue(this.configAndState.config?.thread?.reportingInterval || null);
    this.editing = true;
    this.doneEditing = false;
  }

  cancelEditNetwork(): void {
    this.editing = false;
    this.doneEditing = true;
  }

  setNetwork(): void {
    if (!this.doneEditing) {
      this.store.dispatch(
        threadNetworkSet({
          enable: this.networkEnabled,
          threadInterface: this.threadInterface.value,
          reportingInterval: this.reportingInterval.value
        })
      );
    }
  }
}
