import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { ModelRefService } from 'src/app/lib/services/modelref.service';
import { ThemeService } from 'src/app/lib/services/theme.service';
import { selectNodeIcon } from 'src/app/store/lte/lte.selectors';
import { selectNodes } from 'src/app/store/polling/polling.selector';

@Component({
  selector: 'extenderconnections',
  templateUrl: './extenderconnections.component.html',
  styleUrls: ['./extenderconnections.component.scss']
})
export class ExtenderconnectionsComponent implements OnInit, OnDestroy {
  pollingSubscription: any;
  themeSubscription: any;
  nodes: any[] = [];
  helper: GeneralHelper = new GeneralHelper();
  gatewayIcon$ = this.store.pipe(selectNodeIcon(''));

  constructor(private store: Store, private modelRef: ModelRefService, private theme: ThemeService) {}

  ngOnInit(): void {
    this.pollingSubscription = this.store.select(selectNodes).subscribe((nodes) => {
      if (nodes?.length > 0) {
        this.nodes = JSON.parse(JSON.stringify(nodes));
        this.prepare();
      }
    });

    this.themeSubscription = this.theme.listener.subscribe(() => {
      this.prepare();
    });
  }

  prepare(): void {
    this.nodes.forEach((node: any) => {
      const nModel = this.modelRef.get(node.model).icon;

      if (!node.kind) {
        node.kind = {};
      }

      node.kind.icon = nModel;
      node.isGateway = this.helper.isGateway(node.id, this.nodes);
    });
  }

  ngOnDestroy(): void {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }

    if (this.themeSubscription) {
      this.themeSubscription.unsubscribe();
    }
  }
}
