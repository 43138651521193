import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ToastService } from 'src/app/lib/services/toast.service';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { ApiService } from 'src/app/lib/services/api.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ModalService } from 'src/app/lib/services/modal.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { NodeService } from 'src/app/lib/services/nodes.service';
import { selectNodeIcon } from 'src/app/store/lte/lte.selectors';
import { Store } from '@ngrx/store';
import { selectCapabilities } from 'src/app/store/customer/capabilities.selector';
import { combineLatest, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { selectIsUpriseLocation, selectPowerManagementState } from 'src/app/store/customer/customer.selectors';
import { pollingPull } from 'src/app/store/polling/polling.actions';
import { IPowerState, LPMStatus } from 'src/app/lib/interfaces/interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';
import { selectLocationOptimization } from 'src/app/store/polling/polling.selector';

@UntilDestroy()
@Component({
  selector: 'nodestrip',
  templateUrl: './nodestrip.component.html',
  styleUrls: ['./nodestrip.component.scss']
})
export class NodestripComponent implements OnInit, OnDestroy {
  @Input()
  node: any;

  @Output()
  delete = new EventEmitter<string>();

  speedtestTimeout: any;
  timeout: number = 60000;
  speedtestCheckTimeout: any;
  name: UntypedFormControl = new UntypedFormControl();
  email: UntypedFormControl = new UntypedFormControl();
  speedtest: any = { download: 0, upload: 0 };
  isRename: boolean = false;
  showModal: boolean = false;
  swapModal: boolean = false;
  testPending: boolean = false;
  loading: boolean = false;
  permissions: any;
  subscription: any;
  optimizationState = 'none';
  speedTestDisabled$ = of(true);
  isUprise$ = this.store.select(selectIsUpriseLocation);
  gateWayIcon$ = this.store.pipe(selectNodeIcon(''));
  capabilities$ = this.store.select(selectCapabilities);

  powerPermission: boolean = false;
  powerState: IPowerState = null;
  powerCapable: boolean = false;
  powerMode: string = '';

  constructor(
    public plume: PlumeService,
    private toast: ToastService,
    private logging: LoggingService,
    private api: ApiService,
    private troubleshoot: TroubleshootingService,
    private modal: ModalService,
    private mixpanel: MixpanelService,
    private nodeService: NodeService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectPowerManagementState)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.powerState = data.state;
        this.powerCapable = data.capable;
        this.powerMode = data.mode;
      });

    this.subscription = this.plume.permissions.subscribe((data: any) => {
      this.permissions = data;
      this.powerPermission = data.uiFeatures?.powerManagement || false;
    });

    this.speedTestDisabled$ = combineLatest([this.capabilities$, this.plume.permissions]).pipe(
      map(([capabilities, permissions]) =>
        this.isDisabled(
          this.node,
          permissions,
          capabilities?.ispSpeedTest?.capable,
          capabilities?.nodeIspSpeedTest?.capable
        )
      )
    );

    this.store
      .select(selectLocationOptimization)
      .pipe(untilDestroyed(this))
      .subscribe((response: any) => {
        if (response) {
          this.optimizationState = response.state;
        }
      });

    this.gateWayIcon$ = this.store.pipe(selectNodeIcon(this.node?.id));
  }

  isVolt(): boolean {
    return (
      this.powerPermission &&
      this.powerCapable &&
      this.powerState?.status !== LPMStatus.STATUS_INACTIVE &&
      this.powerState?.status !== LPMStatus.STATUS_UNKNOWN
    );
  }

  launchRename(): void {
    if (!this.node.isRename) {
      this.nodeService.setLedMode$(this.node.id, 'locate').subscribe(() => {
        this.mixpanel.storeEvent('TD_NODE_RENAME_ENABLE', { NODE_ID: this.node.id });
        this.node.isRename = true;
      });
    } else {
      this.node.isRename = false;
    }
  }

  deleteNode(id: string): void {
    this.isUprise$
      .pipe(
        take(1),
        filter((isUprise) => !isUprise),
        switchMap(() => this.nodeService.deleteNode$(id, false, true))
      )
      .subscribe(
        () => {
          this.delete.emit(id);
          this.mixpanel.storeEvent('TD_NODE_DELETE_SUCCESS', { NODE_ID: id });
          this.toast.success('toast.technician.successNodeDeletedMessage', 'toast.technician.successNodeDeletedTitle', {
            params: {
              id
            }
          });
        },
        (error: any) => {
          this.mixpanel.storeEvent('TD_NODE_DELETE_ERROR', { NODE_ID: id, ERROR: error.error.error.message });
          this.toast.error(error.error.error.message, 'toast.technician.errorFindNodeTitle');
        }
      );
  }

  launchDeleteNode(): void {
    this.isUprise$
      .pipe(
        take(1),
        filter((isUprise) => !isUprise),
        tap(() => {
          this.logging.log('<launchDeleteNode> ', this.node);
          this.mixpanel.storeEvent('TD_NODE_DELETE_DIALOG', { NODE_ID: this.node.id });
        }),
        switchMap(() =>
          this.modal.showDialog('techdash.nodeStrip.modal.message', 'techdash.nodeStrip.modal.title', {
            params: { name: this.node.nickname || this.node.defaultName },
            buttons: [
              { style: 'tertiary light', value: 'techdash.nodeStrip.modal.cancel' },
              { style: 'super-primary', value: 'techdash.nodeStrip.modal.confirm' }
            ]
          })
        )
      )
      .subscribe((response) => {
        if (response.item?.value === 'techdash.nodeStrip.modal.confirm') {
          this.deleteNode(this.node.id);
        }
      });
  }

  startWps(): void {
    this.api
      .post('/Customers/' + this.plume.customerid + '/locations/' + this.plume.locationid + '/startWps', {
        nodeId: this.node.id
      })
      .subscribe(
        () => {
          this.mixpanel.storeEvent('TD_WPSSTART_SUCCESS');
          this.toast.success(
            'configurations.onboarding.toastWpsMessageSuccess',
            'configurations.onboarding.toastWpsTitle'
          );
        },
        (error: any) => {
          this.mixpanel.storeEvent('TD_WPSSTART_FAILURE', {
            ERROR: error.error.error.message
          });
          this.toast.error(error.error.error.message, 'configurations.onboarding.toastWpsTitle');
        }
      );
  }

  confirmRename(): void {
    this.nodeService.setLedMode$(this.node.id, 'normal').subscribe(() => {
      this.nodeService.rename$(this.node.id, this.name.value).subscribe(
        (response: any) => {
          this.mixpanel.storeEvent('TD_NODE_RENAME_SUCCESS', {
            NODE_ID: this.node.id,
            OLD_NAME: this.node.nickname,
            NEW_NAME: this.name.value
          });

          this.logging.log('Node Renamed', response);
          this.node.nickname = this.name.value;
          this.node.isRename = false;
          this.name.reset();

          this.store.dispatch(pollingPull({ debugSource: 'node strip rename' }));

          this.toast.success('toast.nodeStrip.successNameMessage', 'toast.nodeStrip.successNameTitle');
        },
        (error: any) => {
          this.mixpanel.storeEvent('TD_NODE_RENAME_ERROR', {
            NODE_ID: this.node.id,
            OLD_NAME: this.node.nickname,
            NEW_NAME: this.name.value,
            ERROR: error.error.error.message
          });

          this.logging.error('Name update error: ', error.error.error);
          this.node.isRename = false;
          this.name.reset();

          this.toast.error('toast.nodeStrip.errorNameMessage', 'toast.nodeStrip.errorNameTitle', {
            params: {
              error: error.error.error.message
            }
          });
        }
      );
    });
  }

  cancelRename(): void {
    this.nodeService.setLedMode$(this.node.id, 'normal').subscribe(() => {
      this.mixpanel.storeEvent('TD_NODE_RENAME_DISABLE', { NODE_ID: this.node.id });
      this.node.isRename = false;
      this.name.reset();
    });
  }

  setSpeedtestTimeout(): void {
    this.logging.log('speedtest set timeout: ' + this.timeout / 1000 + ' sec');
    this.clearSpeedtestTimeout();
    this.speedtestTimeout = setTimeout(() => {
      this.logging.error('Speed test timeout with no response!');
      this.toast.error('toast.nodeStrip.errorSpeedMessage', 'toast.nodeStrip.errorSpeedTitle', {
        disableTimeOut: true
      });
      this.clearSpeedtestTimeout();
    }, this.timeout);
  }

  clearSpeedtestTimeout(): void {
    if (this.speedtestTimeout) {
      clearTimeout(this.speedtestTimeout);
    }
    if (this.speedtestCheckTimeout) {
      clearTimeout(this.speedtestCheckTimeout);
    }
    this.testPending = false;
  }

  launchSpeedTest(): void {
    if (this.optimizationState === 'initiated' || this.optimizationState === 'inProgress') {
      this.toast.warning(
        'toast.speedTestOptimizationInProgress.message',
        'toast.speedTestOptimizationInProgress.title',
        {
          disableTimeOut: true
        }
      );
      return;
    }

    if (this.isVolt() === true && !this.node.isGateway) {
      this.toast.warning('toast.speedTestLowPower.message', 'toast.speedTestLowPower.title', {
        disableTimeOut: true
      });
    }
    if (!this.testPending) {
      const testStart = +new Date();
      this.logging.log('SPEEDTEST from node strip start at ' + moment(testStart).format('YYYY-MM-DD hh:mm:ss'));
      this.setSpeedtestTimeout();
      this.testPending = true;

      this.mixpanel.storeEvent('TD_NODE_SPEED_TEST', { NODE_ID: this.node.id });
      this.troubleshoot.triggerNodeSpeedTest(this.node.id).subscribe(() => {
        this.logging.log('TRIGGER SPEEDTEST from nodeStrip', this.node);
        this.checkSpeedTest(testStart);
      });
    } else {
      this.toast.warning('toast.nodeStrip.warningMessage', 'toast.nodeStrip.warningTitle');
    }
  }

  isDisabled(node: any, permissions: any, ispSpeedTestCapable: boolean, nodeIspSpeedTestCapable: boolean): boolean {
    if (!permissions?.uiFeatures?.speedTestsConfiguration) {
      return true;
    } else {
      if (node.residentialGateway === true) {
        return !ispSpeedTestCapable;
      } else if (node.backhaulType === 'ethernet' && (ispSpeedTestCapable || nodeIspSpeedTestCapable)) {
        return false;
      } else if (nodeIspSpeedTestCapable === true) {
        return false;
      } else {
        return true;
      }
    }
  }

  checkSpeedTest(testStart: number): void {
    this.speedtestCheckTimeout = setTimeout(() => {
      this.troubleshoot.checkSpeedTestStatus(this.node.id).subscribe((response: any) => {
        this.logging.log('SPEEDTEST CHECK ' + moment().format('YYYY-MM-DD hh:mm:ss'), response);
        if (response.downloadSpeeds[0]) {
          const testTime = +new Date(response.downloadSpeeds[0].timestamp);
          if (testTime - testStart > 0) {
            this.clearSpeedtestTimeout();
            this.speedtest.download = response.downloadSpeeds[0].value;
            this.speedtest.upload = response.uploadSpeeds[0].value;
            // speedTest object of node may not be here for first install, creating an empty object to avoid Null object exception
            if (!this.node.speedTest) {
              this.node.speedTest = {};
            }
            this.node.speedTest.download = this.speedtest.download;
            this.toast.success('toast.nodeStrip.successSpeedMessage', 'toast.nodeStrip.successSpeedTitle', {
              params: {
                name: this.node.nickname || this.node.defaultName,
                speed: Math.round(this.speedtest.download)
              }
            });
            this.logging.log(' speedtest.download: ', this.speedtest.download);
          } else {
            if (this.speedtestCheckTimeout) {
              this.checkSpeedTest(testStart);
            }
          }
        } else {
          if (this.speedtestCheckTimeout) {
            this.checkSpeedTest(testStart);
          }
        }
      });
    }, 3000);
  }

  swapGateway(id: any): void {
    this.isUprise$
      .pipe(
        take(1),
        filter((isUprise) => !isUprise),
        tap(() => {
          this.loading = true;
        }),
        switchMap(() => this.nodeService.create$(id))
      )
      .subscribe(
        (response: any) => {
          this.logging.log('New node created ' + id, response);

          this.nodeService.deleteNode$(this.node.id, false, false).subscribe((response: any) => {
            this.logging.log('Old node deleted ' + this.node.id, response);
            this.logging.log('Gateway swap complete');
            this.toast.success('toast.nodeStrip.gatewaySwapMessage', 'toast.nodeStrip.gatewaySwapTitle');
            this.store.dispatch(pollingPull({ debugSource: 'node strip - gateway swap' }));
            this.loading = false;
            this.swapModal = false;
          });
        },
        (error: any) => {
          this.logging.error('Node creation error', error.error.error);
          this.logging.error('Gateway swap failed');
          this.toast.error('toast.technician.errorNodeAddMessage', 'toast.technician.errorNodeAddTitle', {
            disableTimeOut: true,
            params: {
              error: error.error.error.message
            }
          });

          this.loading = false;
          this.swapModal = false;
        }
      );
  }

  isWpsRole(): boolean {
    // SomeSupportRole not allowed below version 1.97
    return !this.plume.isSomeSupportRole() || this.plume.cloudVersionAbove1_97();
  }

  ngOnDestroy(): void {
    this.clearSpeedtestTimeout();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
