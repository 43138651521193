import { ActionReducer } from '@ngrx/store';
import { ConfigEffects } from './configview/config.effects';
import { CustomerInfoEffects } from './customer-info/customer-info.effects';
import { CustomerEffects } from './customer/customer.effects';
import { CustomerReducer } from './customer/customer.reducer';
import { IPv6Effects } from './ipv6/ipv6.effects';
import { LteEffects } from './lte/lte.effects';
import { lteReducer } from './lte/lte.reducer';
import { MixpanelEffects } from './mixpanel/mixpanel.effects';
import { PollingEffects } from './polling/polling.effects';
import { PollingReducer } from './polling/polling.reducer';
import { RouterEffects } from './router/router.effects';
import { SpeedTestEffects } from './speed-test/speed-test.effects';
import { ThreadNetworkEffects } from './threadnetwork/threadnetwork.effects';
import { guidedTroubleshootingReducer } from './guidedTroubleshooting/guidedTroubleshooting.reducer';
import { NeighborReportCnsReducer, qoe5gMetricsReducer } from './qoeCellular/qoeCellular.reducer';
import { QoE5gMetricsEffects } from './qoeCellular/qoeCellular.effects';
import { helpReducer } from './help/help.reducer';

export const reducers = {
  customer: CustomerReducer,
  lte: lteReducer,
  polling: PollingReducer,
  guidedTroubleshooting: guidedTroubleshootingReducer,
  qoe5gMetrics: qoe5gMetricsReducer,
  neighborReportCns: NeighborReportCnsReducer,
  help: helpReducer
};
export const effects = [
  RouterEffects,
  CustomerEffects,
  LteEffects,
  CustomerInfoEffects,
  SpeedTestEffects,
  IPv6Effects,
  ThreadNetworkEffects,
  MixpanelEffects,
  ConfigEffects,
  PollingEffects,
  QoE5gMetricsEffects
];

export type RemoveSingleActionReducer<T> = T extends ActionReducer<infer K> ? K : null;

export type RemoveActionReducer<T> = {
  [P in keyof T]: T[P] extends ActionReducer<infer K> ? K : null;
};

export type AppState = RemoveActionReducer<typeof reducers>;
