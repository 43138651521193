import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { hostNameParse, hostNameWithoutDomain } from 'src/environments/shared/okta';
import { ApiService } from './api.service';
import { FirebaseService } from './firebase.service';
import { MixpanelService } from './mixpanel.service';
import { OktaService } from './okta.service';
import { PlumeService } from './plume.service';
import { ToastService } from './toast.service';
import { CloudEnvironment } from 'src/environments/shared/environments';

@Injectable()
export class AuthService {
  oktaInterval: any;
  toastTimeout1: any;
  toastTimeout2: any;
  toastTimeout3: any;
  timeout: any;

  constructor(
    private api: ApiService,
    private router: Router,
    private okta: OktaService,
    private plume: PlumeService,
    private firebase: FirebaseService,
    private toast: ToastService,
    private mixpanel: MixpanelService,
    private translate: TranslateService
  ) {}

  setToken(token: string, otl: boolean): void {
    if (otl) {
      localStorage.setItem('otl', 'true');
      localStorage.setItem('auth', token);
    } else {
      sessionStorage.setItem('auth', token);
    }
  }

  setOktaToken(token: string, otl: boolean): void {
    if (otl) {
      localStorage.setItem('otl', 'true');
      localStorage.setItem('oktaAuth', token);
    } else {
      sessionStorage.setItem('oktaAuth', token);
    }
  }

  setFlexToken(token: string, otl: boolean): void {
    if (otl) {
      localStorage.setItem('otl', 'true');
      localStorage.setItem('flexAuth', token);
    } else {
      sessionStorage.setItem('flexAuth', token);
    }
  }

  setUpriseToken(token: string, otl: boolean): void {
    if (otl) {
      localStorage.setItem('otl', 'true');
      localStorage.setItem('upriseAuth', token);
    } else {
      sessionStorage.setItem('upriseAuth', token);
    }
  }

  setExtendedAccessToken(token: string, otl: boolean): void {
    if (otl) {
      localStorage.setItem('otl', 'true');
      localStorage.setItem('extendedAccessAuth', token);
    } else {
      sessionStorage.setItem('extendedAccessAuth', token);
    }
  }

  getToken(): string {
    if (this.isOkta()) {
      return localStorage.getItem('oktaAuth') || sessionStorage.getItem('oktaAuth');
    } else {
      return localStorage.getItem('auth') || sessionStorage.getItem('auth');
    }
  }

  getFlexToken(): string {
    return localStorage.getItem('flexAuth') || sessionStorage.getItem('flexAuth') || '';
  }

  getUpriseToken(): string {
    return localStorage.getItem('upriseAuth') || sessionStorage.getItem('upriseAuth') || '';
  }

  getExtendedAccessToken(): string {
    return localStorage.getItem('extendedAccessAuth') || sessionStorage.getItem('extendedAccessAuth') || '';
  }

  getHeaders(headers?: any, urlIsLocationSpecific?: boolean): any {
    const token = this.getToken() || '';

    if (headers) {
      headers = headers.set('Accept-Language', this.translate.currentLang);

      if (this.isOkta()) {
        if (this.isExtendedAccess() && urlIsLocationSpecific) {
          return headers.set('Authorization', this.getExtendedAccessToken());
        }

        if (this.isFlex()) {
          return headers.set('Authorization', this.getFlexToken());
        }

        if (this.isUprise()) {
          return headers.set('Authorization', this.getUpriseToken());
        }

        return headers.set('Authorization', 'Bearer ' + token);
      } else {
        return headers.set('Authorization', token);
      }
    } else {
      if (this.isOkta()) {
        if (this.isExtendedAccess() && urlIsLocationSpecific) {
          return {
            'Accept-Language': this.translate.currentLang,
            Authorization: this.getExtendedAccessToken()
          };
        }

        if (this.isFlex()) {
          return {
            'Accept-Language': this.translate.currentLang,
            Authorization: this.getFlexToken()
          };
        }

        if (this.isUprise()) {
          return {
            'Accept-Language': this.translate.currentLang,
            Authorization: this.getUpriseToken()
          };
        }

        return {
          'Accept-Language': this.translate.currentLang,
          Authorization: 'Bearer ' + token
        };
      } else {
        return {
          'Accept-Language': this.translate.currentLang,
          Authorization: token
        };
      }
    }
  }

  getFrontlineHeader(headers?: any): any {
    const token = this.getToken() || '';

    if (headers) {
      headers = headers.set('Accept-Language', this.translate.currentLang);

      if (this.isOkta()) {
        return headers.set('Authorization', 'Bearer ' + token);
      } else {
        return headers.set('Authorization', token);
      }
    } else {
      if (this.isOkta()) {
        return {
          'Accept-Language': this.translate.currentLang,
          Authorization: 'Bearer ' + token
        };
      } else {
        return {
          'Accept-Language': this.translate.currentLang,
          Authorization: token
        };
      }
    }
  }

  getParams(params?: any): any {
    if (params) {
      return params.set('client_id', environment.okta.clientId);
    } else {
      return 'client_id=' + environment.okta.clientId;
    }
  }

  isAuthenticated(): boolean {
    if (
      localStorage.getItem('auth') ||
      sessionStorage.getItem('auth') ||
      localStorage.getItem('oktaAuth') ||
      sessionStorage.getItem('oktaAuth')
    ) {
      if (localStorage.getItem('user') || sessionStorage.getItem('user')) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isOkta(): boolean {
    return localStorage.getItem('oktaAuth') || sessionStorage.getItem('oktaAuth') ? true : false;
  }

  isFlex(): boolean {
    return localStorage.getItem('flexAuth') || sessionStorage.getItem('flexAuth') ? true : false;
  }

  isUprise(): boolean {
    return localStorage.getItem('upriseAuth') || sessionStorage.getItem('upriseAuth') ? true : false;
  }

  isExtendedAccess(): boolean {
    return localStorage.getItem('extendedAccessAuth') || sessionStorage.getItem('extendedAccessAuth') ? true : false;
  }

  getAccessToken(url: string, locationId: string): Observable<any> {
    return this.api.raw(
      'POST',
      url + '/v1/locations/' + locationId + '/access-tokens',
      {},
      { headers: { Authorization: 'Bearer ' + this.getToken() } }
    );
  }

  mintExtendedAccessToken$(url: string, locationId: string): Observable<any> {
    return this.api.raw(
      'POST',
      `${url}/odm-access/locations/${locationId}/mint-token`,
      {},
      { headers: { Authorization: 'Bearer ' + this.getToken() } }
    );
  }

  getEnvFromUrl(): CloudEnvironment {
    const { cloud } = hostNameParse();

    if (cloud) {
      return environment.environments.find((env) => env.id === cloud);
    } else {
      return null;
    }
  }

  storeSession(user: any, hostname: string, ttl: number): void {
    const env = this.getEnvFromUrl();
    const api = this.api.get('/version').pipe(catchError((error: any) => of(error)));
    const introspect = this.api.get('/introspect', 'auth').pipe(catchError((error: any) => of(error)));

    forkJoin({ api, introspect }).subscribe(
      (responses: any) => {
        if (responses.api instanceof HttpErrorResponse) {
          this.mixpanel.storeEvent('STORE_SESSION_FAILURE', {
            ERROR_TEXT: 'publicApiAccess',
            ERROR: responses.api,
            HOSTNAME: hostname,
            USER: user.userId || user.id
          });
          return this.logout({ error: 'publicApiAccess' });
        }

        if (responses.introspect instanceof HttpErrorResponse) {
          this.mixpanel.storeEvent('STORE_SESSION_FAILURE', {
            ERROR_TEXT: 'introspectApiAccess',
            ERROR: responses.introspect,
            HOSTNAME: hostname,
            USER: user.userId || user.id
          });

          if (responses.introspect.status === 401) {
            return this.logout({ error: 'introspectUnauthorized' });
          } else {
            return this.logout({ error: 'introspectApiAccess' });
          }
        }

        if (responses.introspect?.roles?.length) {
          const role = responses.introspect.roles[0];
          const groups = responses.introspect.groups;
          const apiVersion = responses.api.apiVersion;

          user.role = role;
          user.groups = groups;
          user.partnerId = responses.introspect.partnerId || '';
          user.accessibleLeafPartnerIds = responses.introspect.accessibleLeafPartnerIds || [];

          this.createSession(user, role, groups, env, apiVersion, ttl);
        } else {
          this.mixpanel.storeEvent('STORE_SESSION_FAILURE', {
            ERROR_TEXT: 'role',
            ERROR: responses,
            HOSTNAME: hostname,
            USER: user.userId || user.id
          });
          this.logout({ error: 'role' });
        }
      },
      (error: any) => {
        this.mixpanel.storeEvent('STORE_SESSION_FAILURE', {
          ERROR_TEXT: 'unknown',
          ERROR: error,
          HOSTNAME: hostname,
          USER: user.userId || user.id
        });
        return this.logout({ error: 'unknown' });
      }
    );
  }

  createSession(user: any, role: string, groups: any, env: CloudEnvironment, apiVersion: string, ttl: number): void {
    const domain = hostNameWithoutDomain().split('.').reverse().splice(1).reverse().join('-');
    this.plume.setUser(user);
    this.plume.setRole(role);
    this.plume.setGroups(groups);
    this.plume.setEnv({
      id: env.id,
      cloudId: env.cloudId,
      hostname: env.url,
      version: apiVersion,
      subdomain: domain,
      lteUrl: env.lteUrl,
      flexUrl: env.flexUrl,
      upriseUrl: env.upriseUrl,
      upriseApplicationUrl: env.upriseApplicationUrl,
      partnerPortalUrl: env.partnerPortalUrl,
      signalUrl: env.signalUrl,
      metasaurusUrl: env.metasaurusUrl
    });

    this.mixpanel.setUser(user.userId || user.id, user.email, role, user.partnerId);

    if (ttl) {
      this.plume.setTTL(ttl);
      this.authWarning();
    }

    const permissions = this.firebase.calculatePermissions(this.firebase.snapshot.config, role, domain, user);

    this.plume.setPermissions(permissions);

    if (permissions.siteAccess) {
      const authType = this.plume.getObject('authType');
      this.mixpanel.storeEvent('AUTHENTICATION_SUCCESS', authType);
      this.plume.removeObject('authType');

      const referral = this.plume.getObject('referral', false);

      if (referral) {
        const url = referral.split('#');
        const pathname = url[0];
        const hash = url[1] || null;

        this.plume.removeObject('referral');
        this.router.navigate([pathname], hash ? { fragment: hash } : {});
      } else {
        this.router.navigate(['/']);
      }
    } else {
      this.mixpanel.storeEvent('AUTHENTICATION_FAILURE', { ERROR_TEXT: 'NO_SITE_ACCESS' });
      this.logout({ error: 'noSiteAccess' });
    }
  }

  // sanitize language or if not set browser language. If can not be determined which language from list should be used, use english
  sanitizeLanguage(originalLanguage?: string): string {
    let selectedLang = originalLanguage || this.translate.getBrowserCultureLang();
    let language = environment.languages.find((lang: any) => lang.id === selectedLang)?.id || null;

    if (!language) {
      if (selectedLang.indexOf('-') > -1) {
        selectedLang = selectedLang.split('-')[0];

        language = environment.languages.find((lang: any) => lang.id === selectedLang)?.id || null;

        if (!language) {
          language = 'en';
        }
      } else {
        language = 'en';
      }
    }
    return language;
  }

  logout(queryParams: any = null): void {
    const otl = localStorage.getItem('otl');
    const cookie = localStorage.getItem('cookie');
    const theme = localStorage.getItem('theme');
    const rn = localStorage.getItem('rn');
    const idp = localStorage.getItem('idp');
    const inactivityTimer = localStorage.getItem('inactivityTimer');
    const inactivityTime = localStorage.getItem('inactivityTime');
    const token = localStorage.getItem('auth') || sessionStorage.getItem('auth') || null;
    const oktaToken = localStorage.getItem('oktaAuth') || sessionStorage.getItem('oktaAuth') || null;
    const power = localStorage.getItem('powerManagement');

    this.clearAuthWarning();

    if (token || oktaToken) {
      if (oktaToken) {
        if (queryParams) {
          Object.keys(queryParams).map((key: string) => this.plume.saveObject(key, queryParams[key], false));
        }
        this.okta.deleteSession(() => {
          this.clearStorage(otl, cookie, theme, rn, idp, inactivityTimer, inactivityTime, power);
          this.router.navigate(['/login'], { state: { okta: 'logout' }, queryParams });
        });
      } else {
        this.api
          .raw(
            'post',
            this.api.apiURL('api') + '/Customers/logout',
            {},
            { headers: { Authorization: token, 'Plume-Application-Name': 'PlumeCentral' } }
          )
          .subscribe(
            () => {
              this.clearStorage(otl, cookie, theme, rn, idp, inactivityTimer, inactivityTime, power);
              this.router.navigate(['/login'], queryParams ? { queryParams } : {});
            },
            () => {
              this.clearStorage(otl, cookie, theme, rn, idp, inactivityTimer, inactivityTime, power);
              this.router.navigate(['/login'], queryParams ? { queryParams } : {});
            }
          );
      }
    } else {
      this.okta.deleteSession(() => {
        this.clearStorage(otl, cookie, theme, rn, idp, inactivityTimer, inactivityTime, power);
        this.router.navigate(['/login'], queryParams ? { queryParams } : {});
      });
    }
  }

  clearStorage(
    otl: any,
    cookie: any,
    theme: any,
    rn: any,
    idp: any,
    inactivityTimer: any,
    inactivityTime: any,
    power: any
  ): void {
    localStorage.clear();
    sessionStorage.clear();

    if (otl === 'true') {
      localStorage.setItem('otl', otl);
    }

    if (cookie === 'true') {
      localStorage.setItem('cookie', cookie);
    }

    if (theme) {
      localStorage.setItem('theme', theme);
    }

    if (rn) {
      localStorage.setItem('rn', rn);
    }

    if (idp) {
      localStorage.setItem('idp', idp);
    }

    if (inactivityTimer) {
      localStorage.setItem('inactivityTimer', inactivityTimer);
    }

    if (inactivityTime) {
      localStorage.setItem('inactivityTime', inactivityTime);
    }

    if (power) {
      localStorage.setItem('powerManagement', power);
    }

    this.translate.use(this.sanitizeLanguage()); // set browser language
  }

  authWarning(): void {
    const ttl = this.plume.getTTL();
    this.clearAuthWarning();

    if (ttl) {
      if (this.isOkta()) {
        this.oktaInterval = setInterval(() => {
          if (Date.now() > this.plume.getTTL() - 5 * 60 * 1000) {
            this.okta.getToken((data: any) => {
              this.mixpanel.storeEvent('OKTA_TOKEN_EXTENSION');

              if (data) {
                this.setOktaToken(data.token, false);
                this.plume.setTTL(data.ttl);
                this.authWarning();
              } else {
                this.mixpanel.storeEvent('OKTA_TOKEN_EXTENSION_FAILED');
                this.toast.warning('toast.role.warningMessage1', 'toast.role.warningTitle');
                this.logout();
              }
            });
          }
        }, 60 * 1000);
      } else {
        const toast = (message: string, seconds: number) => {
          const time = ttl - Date.now() - seconds * 1000;

          if (time > 0) {
            return setTimeout(() => this.toast.warning(message, 'toast.role.warningTitle', { global: true }), time);
          }
        };

        this.toastTimeout1 = toast('toast.role.warningMessage2', 60 * 60); // 1h
        this.toastTimeout2 = toast('toast.role.warningMessage3', 10 * 60); // 10min
        this.toastTimeout3 = toast('toast.role.warningMessage4', 60); // 1min

        this.timeout = setTimeout(() => {
          this.logout();
          this.toast.warning('toast.role.warningMessage1', 'toast.role.warningTitle');
        }, ttl - Date.now());
      }
    }
  }

  clearAuthWarning(): void {
    if (this.oktaInterval) {
      clearInterval(this.oktaInterval);
    }

    if (this.toastTimeout1) {
      clearTimeout(this.toastTimeout1);
    }

    if (this.toastTimeout2) {
      clearTimeout(this.toastTimeout2);
    }

    if (this.toastTimeout3) {
      clearTimeout(this.toastTimeout3);
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
}
