import { Component, EventEmitter, HostListener, Input, OnChanges, Output } from '@angular/core';
import { Link } from 'src/app/lib/d3/models/objects/link';
import { ChannelHelper } from 'src/app/lib/helpers/channel.helper';
import { PlumeService } from 'src/app/lib/services/plume.service';

@Component({
  selector: '[linkVisual]',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkVisualComponent implements OnChanges {
  ui: string = '';
  showTimeout: ReturnType<typeof setTimeout>;
  showBandwidthCircle: boolean = false;
  tooltip: Record<string, boolean | string | null> = {
    freqBand: null,
    id: null,
    name: null,
    type: null
  };

  @Input()
  linkVisual: Link;

  @Input()
  showChannel: boolean;

  @Input()
  showBandwidth: boolean;

  @Output()
  tooltipState: any = new EventEmitter();

  constructor(private plume: PlumeService) {}

  ngOnChanges(): void {
    this.ui = this.plume.getUI();
    this.setShowBandwidthCircle();
  }

  getChannelColor(wave: { d: string; radio: string; channel: number }): string {
    return wave.channel ? new ChannelHelper().getColor(wave.channel) : 'var(--link)';
  }

  getCircleColor(): string {
    if (this.linkVisual.options?.isMlo) return 'var(--link-mlo)';

    return this.linkVisual.options?.metadata?.channel
      ? new ChannelHelper().getColor(this.linkVisual.options.metadata.channel)
      : 'var(--link)';
  }

  getOnboardingEffect(): string {
    return this.linkVisual.options?.metadata?.parentVapType === 'onboard' ? 'pulsing 1.5s infinite' : 'none';
  }

  calculate(node: string): number {
    const radius = 35;
    const distance = Math.sqrt(
      Math.pow(this.linkVisual.source.x - this.linkVisual.target.x, 2) +
        Math.pow(this.linkVisual.source.y - this.linkVisual.target.y, 2)
    );

    switch (node) {
      case 'sourcex':
        return (
          this.linkVisual.source.x + (radius * (this.linkVisual.target.x - this.linkVisual.source.x)) / distance || 0
        );
      case 'sourcey':
        return (
          this.linkVisual.source.y + (radius * (this.linkVisual.target.y - this.linkVisual.source.y)) / distance || 0
        );
      case 'targetx':
        return (
          this.linkVisual.target.x + (radius * (this.linkVisual.source.x - this.linkVisual.target.x)) / distance || 0
        );
      case 'targety':
        return (
          this.linkVisual.target.y + (radius * (this.linkVisual.source.y - this.linkVisual.target.y)) / distance || 0
        );
      default:
        return 0;
    }
  }

  calculateChannel(): string {
    const middleX = (this.linkVisual?.source?.x + this.linkVisual?.target?.x) / 2;
    const middleY = (this.linkVisual?.source?.y + this.linkVisual?.target?.y) / 2;
    return 'translate(' + middleX + ', ' + middleY + ')';
  }

  setShowBandwidthCircle() {
    if (this.linkVisual.options?.isMlo) {
      const viableLink = this.linkVisual.options?.metadata?.mloConfig?.multiLinks?.find(
        (multiLink) => multiLink.channelWidth
      );
      if (viableLink) {
        this.showBandwidthCircle = true;
        return;
      }
    } else {
      if (this.linkVisual.options?.metadata?.channelWidth) {
        this.showBandwidthCircle = true;
        return;
      }
    }
    this.showBandwidthCircle = false;
  }

  @HostListener('mouseenter')
  @HostListener('touchstart')
  showTooltip(): void {
    if (this.linkVisual.options?.isMlo) {
      this.showTimeout = setTimeout(() => {
        const tooltipValues = [];

        this.linkVisual.options?.metadata?.mloConfig?.multiLinks?.forEach((multiLink) => {
          if (this.showBandwidth) {
            if (multiLink.channelWidth) {
              tooltipValues.push({
                property: multiLink.freqBand,
                value: multiLink.channelWidth
              });
            }
          } else {
            if (multiLink.channel) {
              tooltipValues.push({
                property: multiLink.freqBand,
                value: multiLink.channel
              });
            }
          }
        });

        this.tooltipState.emit({
          show: true,
          values: tooltipValues
        });
      }, 1000);
      return;
    }
  }

  @HostListener('mouseleave')
  @HostListener('touchend')
  hideTooltip(): void {
    clearTimeout(this.showTimeout);
    this.tooltipState.emit({
      show: false,
      values: []
    });
  }
}
