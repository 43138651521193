import { createReducer, on } from '@ngrx/store';
import { ICaptivePortalConfig, ICaptivePortalLoginOptions } from 'src/app/lib/interfaces/captive-portal';
import {
  backgroundColorChanged,
  brandingFooterTextChanged,
  brandingTitleTextChanged,
  campaignDataLoaded,
  campaignDataLoadedError,
  prepareCaptivePortal,
  loginOptionSetCode,
  loginOptionEnabledChanged,
  customTermsTextChanged,
  logoLoadingModeChanged,
  logoSizeChanged,
  logoEnabledChanged,
  logoUploading,
  logoUploadingError,
  logoUploadingFinished,
  previewClosed,
  previewLoaded,
  previewLoadedError,
  previewOpened,
  publishOpened,
  publishError,
  publishLoaded,
  redirectEnabledChanged,
  redirectTimeChanged,
  redirectUrlChanged,
  scanningUrlForLogo,
  scanningUrlForLogoError,
  scanningUrlForLogoFinished,
  textColorChanged,
  publishClosed,
  languageChanged,
  multilingualChanged,
  toggleMarketingConsent,
  backgroundImageUploading,
  backgroundImageUploadingFinished,
  backgroundImageUploadingError,
  layerUpdated,
  backgroundRepeatAlignmentChanged,
  backgroundImageDeleted
} from './captive-portal.actions';
import produce from 'immer';

export const featureName = 'captivePortal';

interface CaptivePortalState {
  identification: {
    network: string | null;
    mode: string | null;
    // 'loading' | 'editor' | 'error';
    state: string;
    campaignErrorMsg: string | null;
  };
  loginOptions: ICaptivePortalLoginOptions[] | null;
  config: ICaptivePortalConfig | null;
  logoLoadingMode: 'loading' | 'init' | 'scan';
  preview: {
    mode: 'opened' | 'closed' | 'error';
    url: string | null;
    errorMsg: string | null;
  };
  publish: {
    mode: 'loading' | 'closed' | 'success' | 'error';
    url: string | null;
    errorMsg: string | null;
  };
}

const initialState: CaptivePortalState = {
  identification: {
    network: null,
    mode: null,
    state: 'loading',
    campaignErrorMsg: null
  },
  loginOptions: null,
  config: null,
  logoLoadingMode: 'init',
  preview: { mode: 'closed', url: null, errorMsg: null },
  publish: {
    mode: 'closed',
    url: null,
    errorMsg: null
  }
};

export const reducer = createReducer(
  initialState,
  on(prepareCaptivePortal, (state, { network, mode }) => ({
    ...state,
    identification: {
      network,
      mode,
      state: 'loading',
      campaignErrorMsg: ''
    }
  })),
  on(campaignDataLoadedError, (state, { error }) => ({
    ...state,
    identification: { ...state.identification, state: 'error', campaignErrorMsg: error }
  })),
  on(campaignDataLoaded, (state, { config, loginOptions }) => {
    const campaign = JSON.parse(JSON.stringify(config));

    if (campaign.postLogin?.redirect?.enabled === false) {
      campaign.postLogin.redirect.options.forEach((option: any) => {
        if (option.action === 'URL') {
          option.enabled = false;
          option.url = null;
        }
      });
    }

    return {
      ...state,
      config: campaign,
      loginOptions,
      identification: { ...state.identification, state: 'editor' }
    };
  }),
  on(loginOptionEnabledChanged, (state, { option }) => {
    const newOptList = !option.enabled
      ? [...state.config.loginOptions.options, option.data]
      : state.config.loginOptions.options.filter((opt) => opt.name !== option.data.name);
    return {
      ...state,
      config: {
        ...state.config,
        loginOptions: {
          ...state.config.loginOptions,
          options: newOptList,
          order: newOptList.map((option) => option.name).sort((a, b) => (a > b ? 1 : -1))
        }
      }
    };
  }),
  on(toggleMarketingConsent, (state) =>
    produce(state, (newState) => {
      newState.config.advancedOptions.consent.enabled = !state.config.advancedOptions.consent.enabled;
    })
  ),
  on(loginOptionSetCode, (state, { code, name }) => {
    const optionIdex = state.config.loginOptions.options.findIndex((option) => option.name === name);
    if (optionIdex === -1) {
      return state;
    }
    const newOptList = [
      ...state.config.loginOptions.options.slice(0, optionIdex),
      { ...state.config.loginOptions.options[optionIdex], code },
      ...state.config.loginOptions.options.slice(optionIdex + 1)
    ];

    return {
      ...state,
      config: {
        ...state.config,
        loginOptions: {
          ...state.config.loginOptions,
          options: newOptList
        }
      }
    };
  }),
  on(languageChanged, (state, { lang, lang_code }) =>
    produce(state, (newState) => {
      newState.config.advancedOptions.language.defaultLanguage = {
        lang,
        lang_code
      };
    })
  ),
  on(multilingualChanged, (state, { multilingual }) =>
    produce(state, (newState) => {
      newState.config.advancedOptions.language.showMultilingual = multilingual;
    })
  ),
  on(textColorChanged, (state, { color }) =>
    produce(state, (newState) => {
      newState.config.branding.textColor = color;
    })
  ),
  on(backgroundColorChanged, (state, { color }) =>
    produce(state, (newState) => {
      newState.config.branding.background = color;
    })
  ),
  on(layerUpdated, (state, { layer }) =>
    produce(state, (newState) => {
      newState.config.branding.layer.enabled = layer.enabled;
      newState.config.branding.layer.layerColor = layer.layerColor;
    })
  ),
  on(backgroundRepeatAlignmentChanged, (state, { backgroundRepeatAlignment }) =>
    produce(state, (newState) => {
      if (!newState.config.branding.backgroundRepeatAlignment) {
        newState.config.branding.backgroundRepeatAlignment = { label: '', value: '' };
      }
      newState.config.branding.backgroundRepeatAlignment.label = backgroundRepeatAlignment.label;
      newState.config.branding.backgroundRepeatAlignment.value = backgroundRepeatAlignment.value;
    })
  ),
  on(redirectTimeChanged, (state, { time }) =>
    produce(state, (newState) => {
      newState.config.postLogin.redirect.redirectTime = time;
    })
  ),
  on(redirectEnabledChanged, (state, { enabled }) =>
    produce(state, (newState) => {
      newState.config.postLogin.redirect.enabled = enabled;
      if (!enabled) {
        const urlOption = newState.config.postLogin.redirect.options.find((opt) => opt.action === 'URL');
        urlOption.url = null;
        urlOption.enabled = false;
      }
    })
  ),
  on(redirectUrlChanged, (state, { url, toggle }) =>
    produce(state, (newState) => {
      newState.config.postLogin.redirect.enabled = toggle;
      const urlOption = newState.config.postLogin.redirect.options.find((opt) => opt.action === 'URL');
      urlOption.url = url ? url : null;
      urlOption.enabled = !!url;
    })
  ),
  on(logoSizeChanged, (state, { size }) =>
    produce(state, (newState) => {
      newState.config.branding.logoImageSize = size;
    })
  ),
  on(logoLoadingModeChanged, (state, { mode }) =>
    produce(state, (newState) => {
      newState.logoLoadingMode = mode;
    })
  ),
  on(logoEnabledChanged, (state, { enabled, url }) =>
    produce(state, (newState) => {
      newState.config.branding.logo = enabled ? url : '';
    })
  ),
  on(backgroundImageDeleted, (state, { enabled, url }) =>
    produce(state, (newState) => {
      newState.config.branding.background = enabled ? url : '#FFFFFF80';
    })
  ),
  on(brandingTitleTextChanged, (state, { text }) =>
    produce(state, (newState) => {
      newState.config.branding.titleText = text;
    })
  ),
  on(brandingFooterTextChanged, (state, { text }) =>
    produce(state, (newState) => {
      newState.config.branding.campaignFooter.support = text;
    })
  ),
  on(customTermsTextChanged, (state, { text }) =>
    produce(state, (newState) => {
      newState.config.advancedOptions.termsCondition.customTerms.text = text;
      newState.config.advancedOptions.termsCondition.customTerms.enabled = !!text;
    })
  ),
  on(scanningUrlForLogo, (state) =>
    produce(state, (newState) => {
      newState.logoLoadingMode = 'loading';
    })
  ),
  on(scanningUrlForLogoFinished, (state, { imageUrl }) =>
    produce(state, (newState) => {
      newState.logoLoadingMode = 'init';
      newState.config.branding.logo = imageUrl;
    })
  ),
  on(scanningUrlForLogoError, (state) =>
    produce(state, (newState) => {
      newState.logoLoadingMode = 'init';
    })
  ),
  on(logoUploading, (state) =>
    produce(state, (newState) => {
      newState.logoLoadingMode = 'loading';
    })
  ),
  on(logoUploadingFinished, (state, { url }) =>
    produce(state, (newState) => {
      newState.logoLoadingMode = 'init';
      newState.config.branding.logo = url;
    })
  ),
  on(logoUploadingError, (state) =>
    produce(state, (newState) => {
      newState.logoLoadingMode = 'init';
    })
  ),
  on(backgroundImageUploading, (state) =>
    produce(state, (newState) => {
      newState.logoLoadingMode = 'loading';
    })
  ),
  on(backgroundImageUploadingFinished, (state, { url }) =>
    produce(state, (newState) => {
      newState.logoLoadingMode = 'init';
      newState.config.branding.background = url;
    })
  ),
  on(backgroundImageUploadingError, (state) =>
    produce(state, (newState) => {
      newState.logoLoadingMode = 'init';
    })
  ),

  on(previewOpened, (state) =>
    produce(state, (newState) => {
      newState.preview.mode = 'opened';
    })
  ),
  on(previewClosed, (state) =>
    produce(state, (newState) => {
      newState.preview.mode = 'closed';
    })
  ),
  on(previewLoaded, (state, { previewUrl }) =>
    produce(state, (newState) => {
      newState.preview.url = previewUrl;
    })
  ),
  on(previewLoadedError, (state, { error }) =>
    produce(state, (newState) => {
      newState.preview.mode = newState.preview.mode === 'closed' ? 'closed' : 'error';
      newState.preview.errorMsg = error.error.error.message;
    })
  ),
  on(publishOpened, (state) =>
    produce(state, (newState) => {
      newState.preview.mode = 'closed';
      newState.publish.mode = 'loading';
    })
  ),
  on(publishClosed, (state) =>
    produce(state, (newState) => {
      newState.publish.mode = 'closed';
    })
  ),
  on(publishLoaded, (state) =>
    produce(state, (newState) => {
      newState.publish.mode = newState.publish.mode === 'closed' ? 'closed' : 'success';
    })
  ),
  on(publishError, (state) =>
    produce(state, (newState) => {
      newState.publish.mode = newState.publish.mode === 'closed' ? 'closed' : 'error';
    })
  )
);
