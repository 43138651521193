import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';

enum FeedbackStep {
  Performance = 'performance',
  Improve = 'improve',
  ThankYou = 'thankYou'
}

enum FeedbackPerformance {
  Good = 'good',
  NotGood = 'not good'
}

@Component({
  selector: 'feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  animations: [
    trigger('overlayAnimation', [
      state(
        'collapsed',
        style({
          opacity: 0,
          display: 'none'
        })
      ),
      state(
        'expanded',
        style({
          opacity: 1,
          display: 'initial'
        })
      ),
      transition('collapsed <=> expanded', [animate('200ms ease-in-out')])
    ])
  ]
})
export class FeedbackComponent {
  @Input()
  open: boolean = false;

  @Output()
  closeFeedbackModal = new EventEmitter<void>();

  step: FeedbackStep = FeedbackStep.Performance;
  performance: FeedbackPerformance = null;
  improve: FormControl = new FormControl();

  constructor(private mixpanel: MixpanelService) {}

  feedbackAction(action: string): void {
    switch (action) {
      case 'yes':
        this.performance = FeedbackPerformance.Good;
        this.step = FeedbackStep.Improve;
        break;
      case 'no':
        this.performance = FeedbackPerformance.NotGood;
        this.step = FeedbackStep.Improve;
        break;
      case 'submit':
        this.step = FeedbackStep.ThankYou;
        this.mixpanel.storeEvent('FEEDBACK', {
          performance: this.performance,
          message: this.improve.value
        });
        break;
    }
  }

  closeFeedback(): void {
    this.step = FeedbackStep.Performance;
    this.improve.reset();
    this.closeFeedbackModal.emit();
  }
}
