<div *ngIf="nodes && !nodes.length && devices && !devices.length; else noNodesOrDevices">
  {{ 'qoe.noNodesOrDevices' | translate }}
</div>
<ng-template #noNodesOrDevices>
  <div class="chartSection">
    <canvas baseChart [datasets]="scatterChartData" [options]="scatterChartOptions" [type]="'scatter'"></canvas>
  </div>

  <div class="filterSectionHeader">{{ 'qoe.summaryFilterHeader' | translate }}</div>
  <div class="filterSection">
    <range-slider
      [min]="0"
      [max]="5"
      [(lower)]="qoeRange.min"
      [(higher)]="qoeRange.max"
      (lowerChange)="qoeRangeChanged()"
      (higherChange)="qoeRangeChanged()"
      [step]="1"
    ></range-slider>
    <div class="buttonBox">
      <button class="small action" (click)="showAll()" [disabled]="!showAllEnabled()">
        {{ 'qoe.showAll' | translate }}
      </button>
      <button class="small action" (click)="hideAll()" [disabled]="!hideAllEnabled()">
        {{ 'qoe.hideAll' | translate }}
      </button>
    </div>
  </div>

  <div class="heroBoardContainer">
    <div class="heroBoard" (mouseenter)="heroBoardHovered = true" (mouseleave)="heroBoardHovered = false">
      <ng-container *ngFor="let deviceOrNode of sortHeroBoard(nodesAndDevices); trackBy: track">
        <div
          class="nodeOrDevice"
          [class.device]="deviceOrNode.type === 'device'"
          (mouseenter)="hover([deviceOrNode.id])"
          (mouseleave)="hover(null)"
          *ngIf="!hovered || hovered[deviceOrNode.id] || heroBoardHovered"
          [class.disabled]="disabled[deviceOrNode.id]"
        >
          <icon *ngIf="deviceOrNode.type === 'pod'" [path]="deviceOrNode?.icon" class="size-2 node"></icon>
          <icon
            *ngIf="deviceOrNode.type === 'device' && !deviceOrNode.qoe.iconV3"
            [path]="iconService.getPath(deviceOrNode.qoe.iconV2)"
            class="size-2"
          ></icon>
          <div class="icon-container" *ngIf="deviceOrNode.qoe.iconV3">
            <icon [path]="iconService.getV3Path(deviceOrNode.qoe.iconV3, 'small')" class="size-2"></icon>
          </div>
          <div class="nameBox">
            <div
              class="name"
              *ngIf="deviceOrNode.type === 'pod'"
              [tooltip]="deviceOrNode.name"
              [onlyOverflowText]="true"
            >
              {{ deviceOrNode.name }}
            </div>
            <div
              class="name"
              *ngIf="deviceOrNode.type === 'device'"
              [tooltip]="deviceOrNode.name | macAddr : true"
              [onlyOverflowText]="true"
            >
              {{ deviceOrNode.name | macAddr : true }}
            </div>
            <div class="id">
              {{ (deviceOrNode.type === 'device' ? 'qoe.mac' : 'qoe.id') | translate }}:
              {{ deviceOrNode.id | macAddr : true }}
            </div>
            <div class="stateLine">
              <ng-container *ngIf="deviceOrNode.status === 'connected' && deviceOrNode.connection !== 'qoe.ethernet'">
                <div class="label">{{ 'qoe.avgScore' | translate }}:</div>
                <div [style.color]="colorByScore(deviceOrNode.qoe.qoe)">
                  {{ deviceOrNode.qoe.qoe === 0 || deviceOrNode.qoe.qoe ? deviceOrNode.qoe.qoe : '-' }}
                </div>
              </ng-container>
              <div *ngIf="deviceOrNode.connection === 'qoe.ethernet' && deviceOrNode.status === 'connected'">
                {{ 'qoe.ethernet' | translate }}
              </div>
              <div *ngIf="deviceOrNode.status != 'connected'">
                {{ 'qoe.offline' | translate }}
              </div>
              <a href="javascript:void(0)" (click)="deviceOrPodClicked(deviceOrNode)">{{
                'qoe.moreDetails' | translate
              }}</a>
            </div>
          </div>
          <div class="toggler" (click)="toggle(deviceOrNode.id)">
            <div class="checkbox"><label class="toggle" [class.checked]="!disabled[deviceOrNode.id]"></label></div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
