<div class="speedTestNodeSummary">
  <div class="noNodes" *ngIf="!nodes || nodes.length === 0">
    <div class="label">{{ 'nodes.messageNoNodes' | translate }}</div>
  </div>
  <ng-container *ngIf="nodes && nodes.length > 0">
    <div class="column">
      <div class="label header">{{ 'speedtests.nodeSummary.maxResults' | translate }}</div>
      <nodename *ngFor="let node of nodes" [node]="node" [maxWidth]="120"></nodename>
    </div>
    <div class="column">
      <div class="label header">
        <icon name="icon-down-circle" class="size-0_7"></icon>
        {{ highestDownloadSpeed | number : '1.0-0' }} Mbps
      </div>
      <div class="label" *ngFor="let node of nodes">
        {{ nodeSpeedTestResult[node.id]?.latestResult.download | speedTestRatio : highestDownloadSpeed }}
      </div>
    </div>
    <div class="column">
      <div class="label header">
        <icon name="icon-up-circle" class="size-0_7"></icon>
        {{ highestUploadSpeed | number : '1.0-0' }} Mbps
      </div>
      <div class="label" *ngFor="let node of nodes">
        {{ nodeSpeedTestResult[node.id]?.latestResult.upload | speedTestRatio : highestUploadSpeed }}
      </div>
    </div>
    <div class="column date">
      <div class="label header">{{ 'speedtests.nodeSummary.date' | translate }}</div>
      <div class="label" *ngFor="let node of nodes">
        <ng-container *ngIf="nodeSpeedTestResult[node.id]?.latestResult.date">{{
          nodeSpeedTestResult[node.id]?.latestResult.date | date : 'll'
        }}</ng-container>
        <ng-container *ngIf="!nodeSpeedTestResult[node.id]?.latestResult.date">N/A</ng-container>
      </div>
    </div>
  </ng-container>
</div>
