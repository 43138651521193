import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { selectIsFlexLocation, selectIsUpriseLocation } from './customer.selectors';

const selectCapabilityNotReady = (state: AppState) =>
  state.customer.capabilities.state === 'initial' ||
  state.customer.location.state === 'initial' ||
  state.customer.locationConfigState.state === 'initial';

const selectFullState = (state: AppState) => state;

export const selectCapabilities = createSelector(
  selectCapabilityNotReady,
  selectFullState,
  selectIsFlexLocation,
  selectIsUpriseLocation,
  (notReady, state, isFlex, isUprise) =>
    notReady
      ? undefined
      : //  === false used because capable is disabled and not undefined
        {
          puncturing: {
            translate: 'configurations.capabilities.puncturing',
            capable: state?.customer?.locationConfigState?.data?.state?.capabilities?.puncturing
          },
          flex: {
            translate: 'configurations.capabilities.flex',
            capable: state?.customer?.locationConfigState?.data?.state?.capabilities?.flex
          },
          captivePortal: {
            translate: 'configurations.capabilities.captivePortal',
            capable: state?.customer?.locationConfigState?.data?.state?.capabilities?.captivePortal
          },
          captivePortalFronthaul: {
            translate: 'configurations.capabilities.captivePortalFronthaul',
            capable: state?.customer?.locationConfigState?.data?.state?.capabilities?.captivePortalFronthaul
          },
          captivePortalV2: {
            translate: 'configurations.capabilities.captivePortalV2',
            capable: state?.customer?.locationConfigState?.data?.state?.capabilities?.captivePortalV2
          },
          fronthauls: {
            translate: 'configurations.capabilities.fronthauls',
            capable: state?.customer?.locationConfigState?.data?.state?.capabilities?.fronthauls
          },
          bandwidthThrottling: {
            translate: 'configurations.capabilities.bandwidthThrottling',
            capable: state?.customer?.locationConfigState?.data?.state?.capabilities?.bandwidthThrottling
          },
          bandSteering: {
            translate: 'configurations.capabilities.bandSteering',
            capable: state?.customer?.location?.data?.capabilities?.bandSteering
          },
          clientSteering: {
            translate: 'configurations.capabilities.clientSteering',
            capable: state?.customer?.location?.data?.capabilities?.clientSteering
          },
          wifiMotion: {
            translate: 'configurations.capabilities.wifiMotion',
            capable: state?.customer?.capabilities?.data?.wifiMotion?.disabled === false,
            reason: state?.customer?.capabilities?.data?.wifiMotion?.reason
          },
          radioDisableSupported: {
            translate: 'configurations.capabilities.radioDisabling',
            capable: state?.customer?.location?.data?.capabilities?.isRadioDisableSupported
          },
          multiPasswordSSID: {
            translate: 'configurations.capabilities.homepass',
            capable: state?.customer?.capabilities?.data?.multiPasswordSSID?.disabled === false,
            reason: state?.customer?.capabilities?.data?.multiPasswordSSID?.reason
          },
          deviceFreeze: {
            translate: 'configurations.capabilities.deviceFreeze',
            capable: state?.customer?.capabilities?.data?.deviceFreeze?.disabled === false,
            reason: state?.customer?.capabilities?.data?.deviceFreeze?.reason
          },
          appTime: {
            translate: 'configurations.capabilities.apptime',
            capable: state?.customer?.capabilities?.data?.appTime?.disabled === false,
            reason: state?.customer?.capabilities?.data?.appTime?.reason
          },
          networkMode: {
            translate: 'configurations.capabilities.networkMode',
            capable: state?.customer?.capabilities?.data?.networkConfiguration?.networkMode.disabled === false
          },
          controlMode: {
            translate: 'configurations.capabilities.controlModeMonitor',
            capable: state?.customer?.location?.data?.capabilities?.controlMode?.monitor
          },
          ipv6: {
            translate: 'configurations.capabilities.ipv6',
            capable: state?.customer?.location?.data?.capabilities?.ipv6?.mode
          },
          ispSpeedTest: {
            translate: 'configurations.capabilities.ispSpeedTest',
            capable: state?.customer?.capabilities?.data?.ispSpeedTest?.disabled === false,
            reason: state?.customer?.capabilities?.data?.ispSpeedTest?.reason
          },
          nodeIspSpeedTest: {
            translate: 'configurations.capabilities.nodeIspSpeedTest',
            capable: state?.customer?.capabilities?.data?.nodeIspSpeedTest?.disabled === false,
            reason: state?.customer?.capabilities?.data?.nodeIspSpeedTest?.reason
          },
          upnp: {
            translate: 'configurations.capabilities.upnp',
            capable: state?.customer?.capabilities?.data?.networkConfiguration?.upnp?.disabled === false,
            reason: state?.customer?.capabilities?.data?.networkConfiguration?.upnp?.reason
          },
          dnsServers: {
            translate: 'configurations.capabilities.dnsServers',
            capable: state?.customer?.capabilities?.data?.networkConfiguration?.dnsServers?.disabled === false,
            reason: state?.customer?.capabilities?.data?.networkConfiguration?.dnsServers?.reason
          },
          dhcp: {
            translate: 'configurations.capabilities.dhcp',
            capable: state?.customer?.capabilities?.data?.networkConfiguration?.dhcp?.disabled === false,
            reason: state?.customer?.capabilities?.data?.networkConfiguration?.dhcp?.reason
          },
          dhcpReservations: {
            translate: 'configurations.capabilities.dhcpReservations',
            capable: state?.customer?.capabilities?.data?.networkConfiguration?.dhcpReservations?.disabled === false,
            reason: state?.customer?.capabilities?.data?.networkConfiguration?.dhcpReservations?.reason
          },
          portForward: {
            translate: 'configurations.capabilities.portForward',
            capable: state?.customer?.capabilities?.data?.networkConfiguration?.portForward?.disabled === false,
            reason: state?.customer?.capabilities?.data?.networkConfiguration?.portForward?.reason
          },
          fastTransition: {
            translate: 'configurations.capabilities.fastTransition',
            capable: state?.customer?.location?.data?.capabilities?.networkConfiguration?.fastTransition
          },
          igmpProxy: {
            translate: 'configurations.capabilities.igmpProxy',
            capable: state?.customer?.capabilities?.data?.networkConfiguration?.igmpProxy.disabled === false,
            reason: state?.customer?.capabilities?.data?.networkConfiguration?.igmpProxy.reason
          },
          igmpSnooping: {
            translate: 'configurations.capabilities.igmpSnooping',
            capable: state?.customer?.capabilities?.data?.networkConfiguration?.igmpSnooping.disabled === false,
            reason: state?.customer?.capabilities?.data?.networkConfiguration?.igmpSnooping.reason
          },
          macPrioritization: {
            translate: 'configurations.capabilities.macPrioritization',
            capable: state?.customer?.location?.data?.capabilities?.macPrioritization
          },
          minWifiMode24: {
            translate: 'configurations.capabilities.minWifiMode24',
            capable: state?.customer?.location?.data?.capabilities?.networkConfiguration?.minWifiMode24
          },
          mldProxy: {
            translate: 'configurations.capabilities.mldProxy',
            capable: state?.customer?.capabilities?.data?.networkConfiguration?.mldProxy.disabled === false,
            reason: state?.customer?.capabilities?.data?.networkConfiguration?.mldProxy.reason
          },
          multicastToUnicast: {
            translate: 'configurations.capabilities.multicastToUnicast',
            capable: state?.customer?.capabilities?.data?.networkConfiguration?.multicastToUnicast.disabled === false,
            reason: state?.customer?.capabilities?.data?.networkConfiguration?.multicastToUnicast.reason
          },
          onboardNetwork: {
            translate: 'configurations.capabilities.onboardNetwork',
            capable: state?.customer?.location?.data?.capabilities?.onboardNetwork
          },
          dynamicBeacon: {
            translate: 'configurations.capabilities.dynamicBeacon',
            capable: state?.customer?.location?.data?.capabilities?.backhaul?.dynamicBeacon
          },
          hitlessTopology: {
            translate: 'configurations.capabilities.hitlessTopology',
            capable: state?.customer?.location?.data?.capabilities?.backhaul?.hitlessTopology
          },
          backhaul: {
            translate: 'configurations.capabilities.wds',
            capable: state?.customer?.location?.data?.capabilities?.backhaul?.wds
          },
          dnsCategoryBlocking: {
            translate: 'configurations.capabilities.dnsCategoryBlocking',
            capable: state?.customer?.capabilities?.data?.security?.dnsCategoryBlocking?.disabled === false,
            reason: state?.customer?.capabilities?.data?.security?.dnsCategoryBlocking?.reason
          },
          security: {
            translate: 'configurations.capabilities.anomaly',
            capable: state?.customer?.location?.data?.capabilities?.security?.anomaly
          },
          wps: {
            translate: 'configurations.capabilities.wps',
            capable: state?.customer?.location?.data?.capabilities?.wps
          },
          persistConfigurationOnGateway: {
            translate: 'configurations.capabilities.persistConfigurationOnGateway',
            capable:
              state?.customer?.capabilities?.data?.networkConfiguration?.persistConfigurationOnGateway?.disabled ===
              false,
            reason: state?.customer?.capabilities?.data?.networkConfiguration?.persistConfigurationOnGateway?.reason
          },
          dpp: {
            translate: 'configurations.capabilities.dpp',
            capable: state?.customer?.location?.data?.capabilities?.dpp
          },
          haahs: {
            translate: 'configurations.capabilities.haahs',
            capable: state?.customer?.location?.data?.capabilities?.haahs
          },
          lte: {
            translate: 'configurations.capabilities.lte',
            capable: !isFlex && !isUprise && state?.customer?.locationConfigState?.data?.state?.capabilities?.lte,
            reason: isFlex || isUprise ? 'configurations.capabilities.lteReasonIsFlexOrUprise' : undefined
          },
          mdns: {
            translate: 'configurations.capabilities.mdns',
            capable: state?.customer?.capabilities?.data?.mdns
          },
          samKnows: {
            translate: 'configurations.capabilities.samKnows',
            capable: state?.customer?.locationConfigState?.data?.state?.capabilities?.samKnows
          },
          sipAlg: {
            translate: 'configurations.capabilities.sipAlg',
            capable: state?.customer?.locationConfigState?.data?.state?.capabilities?.sipAlg
          },
          AppQoE: {
            translate: 'configurations.capabilities.appQoe',
            capable: state?.customer?.locationConfigState?.data?.state.capabilities?.appQoe
          },
          powerManagement: {
            translate: 'configurations.capabilities.powerManagement',
            capable: state?.customer?.locationConfigState?.data?.state?.capabilities?.powerManagement
          },
          AppPrioritization: {
            translate: 'configurations.capabilities.appPrioritization',
            capable: state?.customer?.locationConfigState?.data?.state.capabilities?.appPrioritization
          },
          thread: {
            translate: 'configurations.capabilities.thread',
            capable: state?.customer?.locationConfigState?.data?.state?.capabilities?.thread
          },
          cellularStatistics: {
            translate: 'configurations.capabilities.cellularStatistics',
            capable: state?.customer?.locationConfigState?.data?.state?.capabilities?.cellularStatistics
          },
          blockActiveAppFlows: {
            translate: 'configurations.capabilities.blockActiveAppFlows',
            capable: state?.customer?.locationConfigState?.data?.state?.capabilities?.blockActiveAppFlows
          },
          lanLatency: {
            translate: 'configurations.capabilities.lanLatency',
            capable: state?.customer?.location?.data?.capabilities?.lanLatency
          },
          wag: {
            translate: 'configurations.capabilities.wag',
            capable: state?.customer?.location?.data?.capabilities?.wag
          },
          hotspot: {
            translate: 'configurations.capabilities.hotspot',
            capable: state?.customer?.location?.data?.capabilities?.hotspot
          }
        }
);
export const selectCapabilityWpa3 = (state: AppState) =>
  state.customer.location.data?.capabilities?.wifi?.wpaModes?.includes('sae-mixed');
export const selectCapabilitySomeSecurity = (state: AppState) =>
  state?.customer?.location?.data?.capabilities?.security;
