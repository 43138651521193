import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Parser } from 'json2csv';
import * as moment from 'moment';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { IFirmwareBatchReport, IFirmwareUpgrade } from 'src/app/lib/interfaces/interface';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';

@Component({
  templateUrl: './upgradereports.component.html',
  styleUrls: ['./upgradereports.component.scss']
})
export class UpgradeReportsComponent implements OnInit {
  asap: boolean = true;

  showReports: boolean = false;
  loadingReports: boolean = false;
  reports: any[] = [];

  startTime = '';
  endTime = '';
  scheduledStart: string = '';
  editBatchIDsFormControl: UntypedFormControl = new UntypedFormControl();
  searchByIDFormControl: UntypedFormControl = new UntypedFormControl();

  selectedReport: any = {};

  excludeOnboarding: boolean = false;
  helper: GeneralHelper = new GeneralHelper();
  upgradeReportResults: IFirmwareBatchReport = null;
  approveReportResults: IFirmwareUpgrade['batchFirmwareUpgrade'] = null;
  requestClearResults: IFirmwareUpgrade['batchFirmwareUpgrade'] = null;
  searchResults: any = null;

  showLoading: boolean = false;

  reportsOffset: number = 0;
  reportsCount: number = null;
  dataExpand: boolean = false;

  minDate: string = null;
  maxDate: string = null;

  constructor(
    private mixpanel: MixpanelService,
    private toast: ToastService,
    private troubleshootingService: TroubleshootingService
  ) {}

  ngOnInit(): void {
    this.getReports();

    const format = 'YYYY-MM-DD[T]HH:mm';
    const dateNow = moment().format(format);
    const dateTomorrow = moment().add(1, 'days').format(format);

    this.minDate = moment().subtract(1, 'years').format(format);

    this.maxDate = moment().add(1, 'years').format(format);

    this.startTime = dateNow;
    this.endTime = dateTomorrow;
  }

  updateExcludeOnboarding(): void {
    this.excludeOnboarding = !this.excludeOnboarding;
    this.getReports();
  }

  requestReport(): void {
    this.showLoading = true;

    const batchId = this.editBatchIDsFormControl.value;
    const start = moment(this.startTime).toISOString();
    const end = moment(this.endTime).toISOString();

    if (start > end) {
      this.toast.error('devices.device.endAfterStart', 'devices.device.error');
      this.showLoading = false;
      return;
    }
    if (this.minDate > start) {
      this.toast.error('netops.reports.startBefore1Year', 'devices.device.error');
      this.showLoading = false;
      return;
    }
    if (this.maxDate < end) {
      this.toast.error('netops.reports.endAfter1Year', 'devices.device.error');
      this.showLoading = false;
      return;
    }

    this.troubleshootingService.firmwareBatchReports$(batchId, start, end).subscribe(
      (response) => {
        this.upgradeReportResults = response;
        this.showLoading = false;
      },
      (error: any) => {
        this.showLoading = false;
        this.toast.warning(error.error.error.message, error.error.error.name);
      }
    );
  }

  approveUpgrade(): void {
    this.showLoading = true;
    const batchId = this.editBatchIDsFormControl.value;
    this.troubleshootingService.firmwareBatchApprove$(batchId).subscribe(
      (response) => {
        this.showLoading = false;
        this.approveReportResults = response.batchFirmwareUpgrade;
      },
      (error: any) => {
        this.showLoading = false;
        this.approveReportResults = null;
        this.toast.warning(error.error.error.message, error.error.error.name);
      }
    );
  }

  downloadCSV(): void {
    const data = {
      LocationId: this.selectedReport.locationIds.join('\n')
    };

    const universalBOM = '\uFEFF';
    this.mixpanel.storeEvent('FIRMWARE_REPORTS_SAVE_CSV');

    const worker = new Parser();
    const csv = worker.parse(data);
    const blob = new Blob([universalBOM + csv], { type: 'text/csv;charset=utf8;' });
    const filename = this.selectedReport.id + '-locations.csv';

    this.helper.download(blob, filename);
  }

  requestClear(): void {
    this.showLoading = true;
    const batchId = this.editBatchIDsFormControl.value;
    this.troubleshootingService.firmwareBatchClear$(batchId).subscribe(
      (response) => {
        this.requestClearResults = response;
        this.showLoading = false;
      },
      (error: any) => {
        this.showLoading = false;
        this.toast.warning(error.error.error.message, error.error.error.name);
      }
    );
  }

  searchByID(): void {
    this.showLoading = true;
    const batchId = this.searchByIDFormControl.value || null; // send null instead of empty string
    this.searchResults = null;

    this.troubleshootingService.firmwareUpgradeReport$(batchId).subscribe(
      (response) => {
        this.searchResults = response?.batchFirmwareUpgrade;
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        this.toast.warning(error.error.error.message, error.error.error.name);
      }
    );
  }

  selectBatchID(event: any): void {
    const format = 'YYYY-MM-DD[T]HH:mm';
    const dateNow = moment(event.report.timestamp).format(format);
    const dateTomorrow = moment(event.report.timestamp).add(1, 'days').format(format);

    this.startTime = dateNow;
    this.endTime = dateTomorrow;
    this.editBatchIDsFormControl.setValue(event.report.id);
    this.selectedReport = event.report;
    this.scheduledStart = moment
      .utc(event.report.upgradeParameters?.schedule?.startDate)
      .add(moment.duration(event.report.upgradeParameters?.schedule?.startTime))
      .format('L LT');

    this.requestClearResults = null;
    this.approveReportResults = null;
    this.upgradeReportResults = null;
  }

  getReports(direction: string = null): void {
    this.showReports = true;
    this.loadingReports = true;

    if (direction) {
      this.reportsOffset = direction === 'up' ? this.reportsOffset + 500 : this.reportsOffset - 500;
    }

    try {
      this.troubleshootingService
        .firmwareReports$({
          order: 'id DESC',
          limit: 500,
          offset: this.reportsOffset,
          ...(this.excludeOnboarding
            ? { where: { userIdOfRequestor: { neq: 'system:onboardingNightlyUpgrade' } } }
            : {})
        })
        .subscribe((response) => {
          this.loadingReports = false;
          this.reports = response.batchFirmwareUpgrade;
        });
    } catch (e) {
      if (e.message) {
        this.toast.warning('netops.reports.networkError', e.message);
      }
    }

    if (!direction) {
      this.troubleshootingService.firmwareReportsCounts$(this.excludeOnboarding).subscribe((response) => {
        this.reportsCount = response.count;
      });
    }
  }

  resize(e: any): void {
    setTimeout(() => {
      e.target.style.height = 'auto';
      e.target.style.height = e.target.scrollHeight + 'px';
    }, 0);
  }
}
