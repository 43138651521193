import {
  Component,
  Input,
  ElementRef,
  OnChanges,
  AfterViewInit,
  OnDestroy,
  OnInit,
  EventEmitter,
  Output
} from '@angular/core';
import { D3Service } from 'src/app/lib/d3/service';
import { LineChart } from 'src/app/lib/d3/models/charts/line.chart';
import { Tick } from 'src/app/lib/d3/models/objects/tick';
import { Line } from 'src/app/lib/d3/models/objects/line';
import { Series } from 'src/app/lib/d3/models/objects/series';
import * as erdm from 'element-resize-detector';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import moment from 'moment';
import { Dot } from '../../../models/objects/dot';

type Tooltip = { key: string; date: string; data: any[]; show: boolean; region: any; style: any };

@Component({
  selector: 'linechart',
  templateUrl: './linechart.component.html',
  styleUrls: ['./linechart.component.scss']
})
export class LineChartVisualComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input()
  title: string;
  @Input()
  series: Series[];
  @Input()
  scale: { symbol?: string } = null;
  @Input()
  data: Line[];
  @Input()
  period: '3h' | '24h' | '7d' | '30d' = '30d';
  @Input()
  options: any = {
    xaxis: true,
    yaxisleft: true,
    yaxisright: false
  };

  @Output()
  regionClick = new EventEmitter<string>();

  chart: LineChart;
  lines: Line[];
  regions: { x: number; y: number; width: number; height: number; hover: boolean; key: string }[];
  tooltips: Tooltip[];
  xAxis: Tick[];
  yAxisLeft: Tick[];
  yAxisRight: Tick[];
  margins: any = {
    top: 20,
    right: 20,
    bottom: 20,
    left: 20
  };

  width: number;
  height: number;
  id = new GeneralHelper().generateRandomString(5);

  erd: any = erdm({ strategy: 'scroll' });

  constructor(private d3: D3Service, private host: ElementRef) {
    this.chart = this.d3.generate('linechart');
  }

  ngOnInit(): void {
    this.width = this.chart.calculateWidth(this.host.nativeElement.clientWidth);
    this.height = this.chart.calculateHeight(this.host.nativeElement.clientHeight);
  }

  ngAfterViewInit(): void {
    this.erd.listenTo(this.host.nativeElement, () => {
      this.render();
    });
  }

  ngOnChanges(): void {
    this.render();
  }

  render(): void {
    this.margins.bottom = this.options.xaxis ? 60 : 30;
    this.margins.left = 70;
    this.margins.right = this.options.yaxisright ? 100 : 50;

    this.lines = this.chart.update(
      this.data,
      this.host.nativeElement.clientWidth,
      this.host.nativeElement.clientHeight,
      this.margins,
      this.period
    );

    if (this.options.xaxis) {
      this.xAxis = this.chart.xAxis();
    }
    if (this.options.yaxisleft) {
      this.yAxisLeft = this.chart.yAxisLeft();
    }
    if (this.options.yaxisright) {
      this.yAxisRight = this.chart.yAxisRight();
    }

    const { tooltips, regions } = this.generateTooltipsAndRegions();
    this.tooltips = tooltips;
    this.regions = regions;
  }

  generateTooltipsAndRegions() {
    const tooltips: Tooltip[] = [];
    const regions: { x: number; y: number; width: number; height: number; hover: boolean; key: string }[] = [];
    const temp: { [key: string]: { series: string; value: number; color: string }[] } = {};
    const startDate = new Date(this.chart.xDomain()[0]).getTime();

    this.lines?.forEach((line: Line & { paths: string[]; fills: string[] }) => {
      if (line.data) {
        line.data.forEach((data: any) => {
          if (data.value || data.value === 0) {
            const date = this.chart.roundDate(new Date(data.label)).toString();
            const timestamp = new Date(date).valueOf();

            if (timestamp >= startDate && timestamp <= Date.now()) {
              if (date in temp) {
                temp[date].push({
                  series: line.series.translation,
                  value: this.round(data.value, 4),
                  color: line.series.color
                });
              } else {
                temp[date] = [
                  {
                    series: line.series.translation,
                    value: this.round(data.value, 4),
                    color: line.series.color
                  }
                ];
              }
            }
          }
        });
      }
    });

    let width = this.width;

    if (this.period === '3h' || this.period === '24h') {
      width = this.width / 96;
    }

    if (this.period === '7d') {
      width = this.width / 168;
    }

    if (this.period === '30d') {
      width = this.width / 30;
    }

    Object.keys(temp)
      .sort((a: any, b: any) => new Date(b).getTime() - new Date(a).getTime())
      .forEach((key: any) => {
        const left = this.chart.scaleValue('x', new Date(key)) - width / 2;
        const leftTransform = '0';
        const leftPosition = left < this.width / 2 ? width / 2 + 10 : 'auto';
        const rightPosition = left >= this.width / 2 ? width / 2 + 10 : 'auto';

        const tooltip = {
          key,
          date:
            moment(new Date(key)).format('ll') +
            (this.period !== '30d' ? ', ' + moment(new Date(key)).format('LT') : ''),
          data: temp[key],
          show: false,
          region: {
            'width.px': width,
            'left.px': left,
            'height.px': this.chart.calculateHeight() + 35
          },
          style: {
            'top.px': this.chart.calculateHeight() / 2,
            'left.px': leftPosition,
            'right.px': rightPosition,
            transform: `translate(${leftTransform}, -50%)`
          }
        };

        let halfDataPoint = [0.5, 'days'];

        if (this.period === '3h') {
          halfDataPoint = [15, 'minutes'];
        }

        if (this.period === '24h') {
          halfDataPoint = [30, 'minutes'];
        }

        if (this.period === '7d') {
          halfDataPoint = [3.5, 'hours'];
        }

        if (this.period === '30d') {
          halfDataPoint = [0.5, 'days'];
        }

        const region = {
          x: this.chart.scaleValue('x', moment(key).subtract(...halfDataPoint)),
          y: 0,
          width:
            (this.chart.scaleValue('x', new Date(key)) -
              this.chart.scaleValue('x', moment(key).subtract(...halfDataPoint))) *
            2,
          height: this.chart.calculateHeight(),
          hover: false,
          key
        };

        regions.push(region);
        tooltips.push(tooltip);
      });

    return { tooltips, regions };
  }

  showTooltip(data: any, mode: boolean): void {
    data.show = mode;
    this.lines?.forEach((line: Line & { paths: string[]; fills: string[] }) => {
      line.dots.forEach((dot: Dot) => {
        if (new Date(dot.label).valueOf() === new Date(data.key).valueOf()) {
          dot.show = mode;
        }
      });
    });

    this.regions.forEach(
      (region: { x: number; y: number; width: number; height: number; hover: boolean; key: string }) => {
        if (region.key === data.key) {
          region.hover = mode;
        }
      }
    );
  }

  onClick() {
    const region = this.regions.find((region) => region.hover);
    if (!region) return;
    this.regionClick.emit(new Date(region.key).toISOString());
  }

  round(value: number, decimalPlaces: number = 0): number {
    const multiplier = Math.pow(10, decimalPlaces);
    return Math.round(value * multiplier + Number.EPSILON) / multiplier;
  }

  ngOnDestroy(): void {
    this.erd.uninstall(this.host.nativeElement);
  }
}
