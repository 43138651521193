<div class="info">
  <ng-container *ngIf="!flexSupportUser">
    <tile
      class="break-anywhere"
      tileIcon="icon-wifi"
      [tileWhiteSpacePre]="true"
      [title]="location?.flex ? 'health.networkInformation.ssidHome' : 'health.networkInformation.ssid'"
      [options]="location?.flex ? permissions?.uiFeatures.editSSIDHome : permissions?.uiFeatures.editSSID"
      optionsTooltip="health.networkInformation.editNetworkConfiguration"
      optionsBadgeTooltip="health.networkInformation.badgeWarning"
      [optionsBadge]="network.ssid && (WPA3Recommendation$ | async)"
      (action)="openNetworkSettings()"
      *ngIf="!network.wpa2ssid && !network.wpa3ssid"
    >
      {{ network.ssid || ('health.networkInformation.na' | translate) }}
    </tile>
    <tile
      class="break-anywhere"
      tileIcon="icon-wifi"
      [tileWhiteSpacePre]="true"
      [title]="location?.flex ? 'health.networkInformation.ssidHomeWpa2' : 'health.networkInformation.wpa2ssid'"
      [options]="location?.flex ? permissions?.uiFeatures.editSSIDHome : permissions?.uiFeatures.editSSID"
      [disabled]="!network.wpa2enabled"
      optionsTooltip="health.networkInformation.editNetworkConfiguration"
      optionsBadgeTooltip="health.networkInformation.badgeWarning"
      [optionsBadge]="WPA3Recommendation$ | async"
      (action)="openNetworkSettings('wpa2')"
      *ngIf="network.wpa2ssid"
    >
      {{ network.wpa2ssid }}
    </tile>
    <tile
      class="break-anywhere"
      tileIcon="icon-wifi"
      [tileWhiteSpacePre]="true"
      [title]="location?.flex ? 'health.networkInformation.ssidHomeWpa3' : 'health.networkInformation.wpa3ssid'"
      [options]="location?.flex ? permissions?.uiFeatures.editSSIDHome : permissions?.uiFeatures.editSSID"
      [disabled]="!network.wpa3enabled"
      optionsTooltip="health.networkInformation.editNetworkConfiguration"
      optionsBadgeTooltip="health.networkInformation.badgeWarning"
      [optionsBadge]="WPA3Recommendation$ | async"
      (action)="openNetworkSettings('wpa3')"
      *ngIf="network.wpa3ssid"
    >
      {{ network.wpa3ssid }}
    </tile>

    <tile
      class="break-anywhere"
      tileIcon="icon-wifi"
      [tileWhiteSpacePre]="true"
      [title]="'health.networkInformation.ssidSecondary'"
      [options]="permissions?.uiFeatures.editSSID"
      optionsTooltip="health.networkInformation.editNetworkConfiguration"
      (action)="openNetworkSettings('secondary')"
      *ngIf="secondaryNetworksData?.secondary?.ssid"
    >
      {{ secondaryNetworksData.secondary?.ssid }}
    </tile>
  </ng-container>
  <ng-container *ngIf="location?.flex">
    <tile
      class="break-anywhere"
      tileIcon="icon-vpn"
      [tileWhiteSpacePre]="true"
      [title]="'health.networkInformation.ssidWork'"
      [options]="permissions?.uiFeatures.editSSIDWork"
      optionsTooltip="health.networkInformation.editNetworkConfiguration"
      (action)="openNetworkSettings()"
    >
      {{ 'health.networkInformation.workVpn' | translate }}
    </tile>
  </ng-container>

  <tile
    [tileImage]="plumePods ? 'assets/raster/network_pods.png' : 'assets/raster/network_gateway.png'"
    [title]="'health.networkInformation.gateway'"
  >
    <div *ngIf="(gateway | json) == '{}'">{{ 'health.networkInformation.na' | translate }}</div>
    <div *ngIf="(gateway | json) != '{}'">
      <div *ngFor="let model of gateway | keyvalue" [hidden]="model.value === 0">
        <div *ngIf="model.value === 1">{{ model.key }}</div>
        <div *ngIf="model.value !== 1">{{ model.key }} ({{ model.value }})</div>
      </div>
    </div>
  </tile>

  <tile tileImage="assets/raster/network_extenders.png" [title]="'health.networkInformation.extenders'">
    <div *ngIf="(extenderModels | json) == '{}'">{{ 'health.networkInformation.na' | translate }}</div>
    <div *ngIf="(extenderModels | json) != '{}'">
      <div *ngFor="let model of extenderModels | keyvalue" [hidden]="model.value == 0">
        {{ model.key }} ({{ model.value }})
      </div>
    </div>
  </tile>

  <tile tileImage="assets/raster/network_devices.png" [title]="'health.networkInformation.devices'">
    {{ devicesOnline.length }}/{{ devicesOnline.length + devicesOffline.length }}
    {{ 'health.networkInformation.online' | translate }}
  </tile>

  <tile
    [tileImage]="location?.profile !== 'auto' ? 'assets/raster/network_vsb.png' : 'assets/raster/network_app.png'"
    [largeImage]="true"
    [title]="(customer$ | async)?.name | customerName"
  >
    <div>
      {{ 'health.networkInformation.locationCreated' | translate }}:
      <span [innerHtml]="getDate(location.createdAt)"></span>
    </div>
    <ng-container *ngIf="permissions?.uiFeatures.showMobileLogin">
      <div *ngIf="(customer$ | async)?.provisioningSsoAuditTrail !== true">
        {{ 'health.networkInformation.mobileSignin' | translate }}:
        <span [innerHtml]="getDate((customer$ | async)?.firstKnownLoginTimestamp) | translate"></span>
      </div>
      <a
        *ngIf="(plume.isSomeAdminRole() || plume.isSomeSupportRole()) && (customer$ | async)?.provisioningSsoAuditTrail"
        [routerLink]="[]"
        (click)="showMobileSignInHistory = true"
      >
        {{ 'health.networkInformation.MobileSignInHistory' | translate }}
      </a>
    </ng-container>
    <div *ngIf="location.onboardingAnalitics">
      {{ 'health.networkInformation.onboardedWith' | translate }} {{ location.onboardingAnalitics.appOs }}
    </div>
    <locationproducts
      [locationId]="location.id"
      [partnerId]="location.partnerId"
      [isUprise]="location.uprise"
    ></locationproducts>
  </tile>
</div>

<modal (overlayAction)="showNetworksettings = $event" *ngIf="showNetworksettings">
  <ng-container content>
    <networksettings [mode]="mode"></networksettings>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="closeNetworkSettings()">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
  </ng-container>
</modal>

<modal mode="wide" (overlayAction)="showMobileSignInHistory = $event" *ngIf="showMobileSignInHistory">
  <ng-container content><sso-audit-trail></sso-audit-trail></ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="showMobileSignInHistory = false">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
  </ng-container>
</modal>
