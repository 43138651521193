<ng-container>
  <header
    (openFeedbackModal)="openFeedbackModal()"
    placeholder="header.searchCustomers"
    [enableDeleteCustomer]="true"
  ></header>

  <side-menu
    class="desktop"
    [class.volt]="isVolt()"
    *ngIf="(plume.permissions | async)?.uiFeatures.sidebars"
  ></side-menu>

  <div
    id="customer-view"
    [class.sideMenu]="(plume.permissions | async)?.uiFeatures.sidebars"
    [class.volt]="isVolt()"
    cdk-scrollable
    *ngIf="!reloadComponents"
  >
    <router-outlet></router-outlet>
  </div>

  <div
    class="banners"
    [class.sideMenu]="(plume.permissions | async)?.uiFeatures.sidebars"
    *ngIf="isVolt() || serviceLevel"
  >
    <div id="volt" *ngIf="isVolt()">
      <header-volt [powerState]="powerState"></header-volt>
    </div>
    <div id="serviceLevel" *ngIf="serviceLevel">
      <header-service-level [serviceLevel]="serviceLevel"></header-service-level>
    </div>
  </div>
  <customerinfo *ngIf="(plume.permissions | async)?.uiFeatures.sidebars"></customerinfo>
</ng-container>

<help (openFeedbackModal)="openFeedbackModal()"></help>
<feedback (closeFeedbackModal)="closeFeedbackModal()" [open]="feedbackModalOpen"></feedback>

<modal mode="compact" (overlayAction)="migrate = $event" *ngIf="migrate">
  <ng-container title>{{ 'customer.migrated.title' | translate }}</ng-container>
  <ng-container content>
    {{
      'customer.migrated.message' | translate : { cloud: migrate.cloud.name, date: migrate.date, time: migrate.time }
    }}
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="migrate = null">
      {{ 'customer.migrated.close' | translate }}
    </button>
    <button class="super-primary" (click)="gotoMigrated()">
      {{ 'customer.migrated.switchClouds' | translate : { cloud: migrate.cloud.name } }}
    </button>
  </ng-container>
</modal>
