import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import * as erdm from 'element-resize-detector';
import * as moment from 'moment';
import { NodeChart, NodeChartMargins } from 'src/app/lib/d3/models/charts/node.chart';
import { Dot } from 'src/app/lib/d3/models/objects/dot';
import { Line } from 'src/app/lib/d3/models/objects/line';
import { Series } from 'src/app/lib/d3/models/objects/series';
import { Tick } from 'src/app/lib/d3/models/objects/tick';
import { D3Service } from 'src/app/lib/d3/service';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';

@Component({
  selector: 'nodechart',
  templateUrl: './nodechart.component.html',
  styleUrls: ['./nodechart.component.scss']
})
export class NodeChartVisualComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
  @Input()
  series: Series[];
  @Input()
  data: Line[];
  @Input()
  scale: any = null;
  @Input()
  period: number = null;
  @Input()
  isFilled: boolean = true;
  @Input()
  autosize: boolean = false;

  @Input()
  margins: NodeChartMargins = {
    top: 10,
    right: 20,
    bottom: 30,
    left: 60
  };
  @Input()
  itemCount = 0;

  @Output()
  domains: any = new EventEmitter();

  debouncerTimeout: any;
  chart: NodeChart;
  lines: (Line & { paths: string[]; fills: string[] })[];
  xAxis: Tick[];
  yAxis: Tick[];
  tooltips: any[];
  width: number;
  height: number;
  id: string;

  erd: any = erdm({ strategy: 'scroll' });
  helper: GeneralHelper = new GeneralHelper();

  constructor(private d3: D3Service, private host: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    this.chart = this.d3.generate('nodechart');
    this.width = this.chart.calculateWidth(this.host.nativeElement.clientWidth);
    this.height = this.chart.calculateHeight(this.host.nativeElement.clientHeight);
    this.id = this.helper.generateRandomString(5);
    this.debounce(() => this.render(), 200);
  }

  ngAfterViewInit(): void {
    this.erd.listenTo(this.host.nativeElement, () => {
      this.debounce(() => this.render(), 200);
    });
  }

  ngOnChanges(): void {
    this.debounce(() => this.render(), 200);
  }

  debounce(fn: () => void, delay: number): void {
    clearTimeout(this.debouncerTimeout);
    this.debouncerTimeout = setTimeout(fn, delay);
  }

  isSmall(): boolean {
    return this.host.nativeElement.offsetWidth < 550;
  }

  render(): void {
    if (this.chart !== undefined) {
      this.lines = this.chart?.update(
        this.data,
        this.host.nativeElement.clientWidth,
        this.host.nativeElement.clientHeight,
        this.margins,
        this.scale,
        this.period,
        this.isFilled
      );

      if (this.isSmall() && this.autosize) {
        this.xAxis = this.chart.xAxisSmall();
      } else {
        this.xAxis = this.chart.xAxis();
      }

      this.yAxis = this.chart.yAxis(this.height);
      this.width = this.chart.calculateWidth();
      this.height = this.chart.calculateHeight();

      this.tooltips = this.generateTooltips();
    }
  }

  generateTooltips(): any[] {
    const tooltips = [];
    const temp = {};
    const startDate = new Date(this.chart.xDomain()[0]).getTime();

    this.domains.emit(this.chart.xDomain());

    this.lines?.forEach((line: Line & { paths: string[]; fills: string[] }) => {
      if (line.data) {
        line.data.forEach((data: any) => {
          if (data.value || data.value === 0) {
            const date = this.chart.roundTime(new Date(data.label)).toString();
            const timestamp = new Date(date).valueOf();

            if (timestamp >= startDate && timestamp <= Date.now()) {
              if (date in temp) {
                temp[date].push({
                  series: line.series.translation,
                  value: this.round(data.value, 4),
                  color: line.series.color
                });
              } else {
                temp[date] = [
                  {
                    series: line.series.translation,
                    value: this.round(data.value, 4),
                    color: line.series.color
                  }
                ];
              }
            }
          }
        });
      }
    });

    let width = this.width;

    if (this.period === 9) {
      width = this.width / 8;
    }

    if (this.period === 10) {
      width = this.width / 10;
    }

    if (this.period === 1) {
      width = this.width / 96;
    }

    if (this.period === 7) {
      width = this.width / 168;
    }

    if (this.period === 30) {
      width = this.width / 30;
    }

    if (this.itemCount) {
      width = this.width / this.itemCount;
    }

    Object.keys(temp)
      .sort((a: any, b: any) => new Date(b).getTime() - new Date(a).getTime())
      .forEach((key: any) => {
        const left = this.chart.scaleValue('x', new Date(key)) - width / 2;
        const leftTransform = '0';
        const leftPosition = left < this.width / 2 ? width / 2 + 10 : 'auto';
        const rightPosition = left >= this.width / 2 ? width / 2 + 10 : 'auto';

        const tooltip = {
          key,
          date:
            moment(new Date(key)).format('ll') +
            (this.period !== 30
              ? ', ' + moment(new Date(key)).format(this.period === 10 || this.period === 9 ? 'LTS' : 'LT')
              : ''),
          data: temp[key],
          show: false,
          region: {
            'width.px': width,
            'left.px': left,
            'height.px': this.chart.calculateHeight() + 35
          },
          style: {
            'top.px': this.chart.calculateHeight() / 2,
            'left.px': leftPosition,
            'right.px': rightPosition,
            transform: `translate(${leftTransform}, -50%)`
          }
        };

        if (this.scale.symbol === 'MB') {
          tooltip.data = tooltip.data.map((data: any) => {
            data.formatted = this.helper.formatBytes(data.value, 'MB');
            return data;
          });
        }

        tooltips.push(tooltip);
      });

    return tooltips;
  }

  showTooltip(data: any, mode: boolean): void {
    data.show = mode;
    this.lines?.forEach((line: Line & { paths: string[]; fills: string[] }) => {
      line.dots.forEach((dot: Dot) => {
        if (new Date(dot.label).valueOf() === new Date(data.key).valueOf()) {
          dot.show = mode;
        }
      });
    });
  }

  round(value: number, decimalPlaces: number = 0): number {
    const multiplier = Math.pow(10, decimalPlaces);
    return Math.round(value * multiplier + Number.EPSILON) / multiplier;
  }

  ngOnDestroy(): void {
    this.erd.uninstall(this.host.nativeElement);
    clearTimeout(this.debouncerTimeout);
  }
}
