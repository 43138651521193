import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Pipe({
  name: 'accountId'
})
export class AccountIdPipe implements PipeTransform {
  constructor(private auth: AuthService) {}

  transform(value?: string): string {
    if (this.auth.isExtendedAccess()) {
      return '***********';
    }
    return value;
  }
}
