import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { selectCapabilities } from 'src/app/store/customer/capabilities.selector';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';

type TrafficModes = 'overview' | 'monitoring' | 'pieMonitoring';

@UntilDestroy()
@Component({
  templateUrl: './traffic.component.html',
  styleUrls: ['./traffic.component.scss']
})
export class TrafficComponent implements OnInit {
  mode: TrafficModes = 'overview';
  appQoeCapable: boolean = false;
  appPrioCapable: boolean = false;

  constructor(
    private plume: PlumeService,
    private router: Router,
    private route: ActivatedRoute,
    private mixpanel: MixpanelService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.mixpanel.storeEvent('TRAFFIC_CLASS_SCREEN');

    combineLatest([this.route.params, this.store.select(selectCapabilities)])
      .pipe(untilDestroyed(this))
      .subscribe(([params, capability]) => {
        if (params && capability) {
          this.appPrioCapable = capability?.AppPrioritization?.capable;
          this.appQoeCapable = capability?.AppQoE?.capable;

          if (params.mode) {
            if (params.mode === 'pieMonitoring' && !this.appQoeCapable) {
              this.handle('overview');
            } else if (params.mode !== 'pieMonitoring' && !this.appPrioCapable) {
              this.handle('pieMonitoring');
            } else {
              this.mode = params.mode;
            }
          }
        }
      });
  }

  handle(mode: TrafficModes): void {
    this.mode = mode;

    this.router.navigate(['customer', this.plume.customerid, 'location', this.plume.locationid, 'traffic', this.mode], {
      replaceUrl: true
    });
  }
}
