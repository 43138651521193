<ng-container *ngIf="data?.title && data?.color">
  <div class="colorBar" [style.background]="data.color"></div>
  <div class="details">
    <div class="title" [style.color]="titleColor ? data.color : ''">{{ data.title | translate }}</div>
    <div *ngIf="data.totalUsage">{{ 'charts.gridDetails.total' | translate }}: {{ data.totalUsage }}</div>
    <div *ngIf="data.percentage || data.percentage === 0">
      {{ 'charts.gridDetails.percentage' | translate }}: {{ data.percentage }}%
    </div>
    <div *ngIf="data.qoeScore">
      {{ 'charts.gridDetails.qoeScore' | translate }}:
      <span [style.color]="colorByScore(data.qoeScore)">{{ data.qoeScore | number : '1.0-1' }}</span>
    </div>
  </div>
</ng-container>
