<div class="content">
  <div class="strip col-wide">
    <div class="icon-container">
      <icon
        [path]="iconURL"
        class="column size-2"
        [class.node]="data.type === 'pod'"
        [class.device]="data.type === 'device'"
      ></icon>
    </div>
    <div class="column name">
      <div class="ellipsis">{{ type === 'node' ? data.name : (data.name | macAddr : true) }}</div>
      <div *ngIf="mode !== '5g'" class="ellipsis id">{{ type === 'node' ? data.id : (data.id | macAddr) }}</div>
    </div>

    <div class="togglers">
      <div class="column toggler" [class.show]="data.charts">
        <slidetoggler [options]="chartModes" (toggle)="toggleChartMode($event, data)"></slidetoggler>
      </div>
      <div class="rssiBandToggle" *ngIf="rssiChartAvailableBands.length > 1">
        <slidetoggler [options]="rssiChartAvailableBands" (toggle)="selectRssiChartBand($event)"></slidetoggler>
      </div>
    </div>

    <div class="col-chart">
      <div class="column chart" *ngIf="data.charts">
        <qoe15minchart
          titleLabel="qoe.qoeScoreHistory"
          [hasOfflines]="true"
          [data]="data.charts.qoe"
          [status]="data.status"
        ></qoe15minchart>
      </div>
      <div
        class="column no-data"
        *ngIf="!data.loading && !data.charts && data.connection !== 'qoe.ethernet' && data.status === 'connected'"
      >
        {{ 'qoe.noQoeData' | translate }}
      </div>
      <div
        class="column no-data"
        *ngIf="!data.loading && !data.charts && data.connection === 'qoe.ethernet' && data.status === 'connected'"
      >
        {{ data.connection | translate }}
      </div>
      <div class="column no-data" *ngIf="!data.loading && !data.charts && data.status !== 'connected'">
        {{ 'qoe.offline' | translate }}
      </div>
      <div class="column no-data loading" *ngIf="data.loading">
        {{ 'qoe.loading' | translate }}
      </div>
    </div>

    <icon
      name="icon-arrow-down"
      class="expander"
      [class.rotate180]="expanded"
      (click)="toggleExpand()"
      *ngIf="data.charts"
    ></icon>
    <div class="column connection-state" *ngIf="mode !== '5g'">
      <div
        class="circle {{ data.status }}"
        tooltip="{{ data.connectionStateMsg | translate : { connectionStateChangeAt: data.connectionStateChangeAt } }}"
      ></div>
    </div>
  </div>

  <div class="chart-narrow">
    <div class="column chart" *ngIf="data.charts">
      <qoe15minchart
        titleLabel="qoe.qoeScoreHistory"
        [hasOfflines]="true"
        [data]="data.charts.qoe"
        [status]="data.status"
      ></qoe15minchart>
    </div>
    <div
      class="column no-data"
      *ngIf="!data.loading && !data.charts && data.connection !== 'qoe.ethernet' && data.status === 'connected'"
    >
      {{ 'qoe.noQoeData' | translate }}
    </div>
    <div
      class="column no-data connection"
      *ngIf="!data.loading && !data.charts && data.connection === 'qoe.ethernet' && data.status === 'connected'"
    >
      <div class="title">{{ 'qoe.headers.connection' | translate }}</div>
      <div>{{ data.connection | translate }}</div>
    </div>
    <div class="column no-data" *ngIf="!data.loading && !data.charts && data.status !== 'connected'">
      {{ 'qoe.offline' | translate }}
    </div>
    <div class="column no-data loading" *ngIf="data.loading">
      {{ 'qoe.loading' | translate }}
    </div>
  </div>

  <div class="details" *ngIf="expanded">
    <div class="table">
      <ng-container *ngIf="ui === 'central'">
        <ng-container *ngIf="mode !== '5g'">
          <qoe15minchart
            titleLabel="qoe.rssiHistory"
            [hasOfflines]="true"
            [data]="data.charts?.rssi"
            [status]="data.status"
          ></qoe15minchart>
          <qoe15minchart
            titleLabel="qoe.weightedQoeScore"
            [hasOfflines]="true"
            [data]="data.charts?.weightedQoeScore"
            [status]="data.status"
          ></qoe15minchart>
          <qoe15minchart
            titleLabel="qoe.effPhyrateRx"
            [data]="data.charts?.effPhyrateRx"
            [status]="data.status"
          ></qoe15minchart>
          <qoe15minchart
            titleLabel="qoe.effPhyrateTx"
            [data]="data.charts?.effPhyrateTx"
            [status]="data.status"
          ></qoe15minchart>
          <qoe15minchart
            titleLabel="qoe.weightedPrrRx"
            [data]="data.charts?.weightedPrrRx"
            [status]="data.status"
          ></qoe15minchart>
          <qoe15minchart
            titleLabel="qoe.weightedPrrTx"
            [data]="data.charts?.weightedPrrTx"
            [status]="data.status"
          ></qoe15minchart>
          <qoe15minchart
            titleLabel="qoe.channelUtilization"
            [hasOfflines]="true"
            [data]="data.charts?.channelUtilization"
            [status]="data.status"
          ></qoe15minchart>
          <qoe15minchart
            titleLabel="qoe.busyRatio"
            [data]="data.charts?.busyRatio"
            [status]="data.status"
          ></qoe15minchart>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="ui === 'noc' && data.type === 'device'">
        <div class="graph">
          <div class="caption" (click)="hideGraph('dataConsumption')">
            {{ 'qoe.dataConsumption.title' | translate }}
            <icon name="icon-triangle-down" class="expander" [class.rotate180]="isShowChart('dataConsumption')"></icon>
          </div>

          <div class="history" *ngIf="isShowChart('dataConsumption')">
            <div class="chart-wrap">
              <nodechart
                [series]="dataConsumption.series"
                [data]="dataConsumption.graph"
                [scale]="{ range: [0, dataConsumption.maxValue], symbol: 'MB' }"
                [period]="data.chartMode === '24h' ? 1 : 7"
                [isFilled]="true"
                [autosize]="true"
              ></nodechart>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="ui === 'noc'">
        <div class="graph" *ngIf="rssiMode === 'dynamic'">
          <div class="caption" (click)="hideGraph('rssiDynamic')">
            {{ 'qoe.rssi.title' | translate }}
            <ng-container *ngIf="rssiChartAvailableBands.length > 1">({{ rssiChartSelectedBand }})</ng-container>
            <icon name="icon-triangle-down" class="expander" [class.rotate180]="isShowChart('rssiDynamic')"></icon>
          </div>
          <div class="history" *ngIf="isShowChart('rssiDynamic')">
            <div class="rssiSwitchWrapper">
              <slidetoggler [options]="rssiModeItems" (toggle)="setRssiMode($event)"></slidetoggler>
            </div>
            <div class="chart-wrap">
              <nodechart
                [series]="[]"
                [data]="nodeCharts.rssi && data.charts ? data.charts.line.rssi.graph : [{ data: [] }]"
                [scale]="{
                  range: [-100, nodeCharts.rssi && data.charts?.line.rssi.maxValue > -40 ? 0 : -40],
                  symbol: 'dBm'
                }"
                [period]="data.chartMode === '24h' ? 1 : 7"
                [isFilled]="false"
                [autosize]="true"
              ></nodechart>
            </div>
          </div>
        </div>

        <div class="graph" *ngIf="mode !== '5g' && rssiMode === 'static'">
          <div class="caption" (click)="hideGraph('rssiStatic')">
            {{ 'qoe.snr.title' | translate }}
            <ng-container *ngIf="rssiChartAvailableBands.length > 1">({{ rssiChartSelectedBand }})</ng-container>
            <icon name="icon-triangle-down" class="expander" [class.rotate180]="isShowChart('rssiStatic')"></icon>
          </div>
          <div class="history" *ngIf="isShowChart('rssiStatic')">
            <div class="rssiSwitchWrapper">
              <slidetoggler [options]="rssiModeItems" (toggle)="setRssiMode($event)"></slidetoggler>
            </div>
            <div class="chart-wrap">
              <nodechart
                [series]="[]"
                [data]="nodeCharts.snr ? data.charts.line.snr.graph : [{ data: [] }]"
                [scale]="{
                  range: [-100, nodeCharts.snr && data.charts?.line.snr.maxValue > -40 ? 0 : -40],
                  symbol: 'dBm'
                }"
                [period]="data.chartMode === '24h' ? 1 : 7"
                [isFilled]="false"
                [autosize]="true"
              ></nodechart>
            </div>
          </div>
        </div>

        <div class="graph" *ngIf="mode !== '5g'">
          <div class="caption" (click)="hideGraph('weightedQoeScore')">
            {{ 'qoe.weightedQoeScore' | translate }}
            <icon name="icon-triangle-down" class="expander" [class.rotate180]="isShowChart('weightedQoeScore')"></icon>
          </div>
          <div class="history" *ngIf="isShowChart('weightedQoeScore')">
            <div class="chart-wrap">
              <nodechart
                [series]="[]"
                [data]="
                  nodeCharts.weightedQoeScore && data.charts ? data.charts.line.weightedQoeScore.graph : [{ data: [] }]
                "
                [scale]="{ range: [0, 5], symbol: '' }"
                [period]="data.chartMode === '24h' ? 1 : 7"
                [isFilled]="true"
                [autosize]="true"
              ></nodechart>
            </div>
          </div>
        </div>

        <div class="graph" *ngIf="mode !== '5g'">
          <div class="caption" (click)="hideGraph('effPhyrateRx')">
            {{ 'qoe.effPhyrateRx' | translate }}
            <ng-container *ngIf="rssiChartAvailableBands.length > 1">({{ rssiChartSelectedBand }})</ng-container>
            <icon name="icon-triangle-down" class="expander" [class.rotate180]="isShowChart('effPhyrateRx')"></icon>
          </div>
          <div class="history" *ngIf="isShowChart('effPhyrateRx')">
            <div class="chart-wrap">
              <nodechart
                [series]="[]"
                [data]="nodeCharts.effPhyrateRx && data.charts ? data.charts.line.effPhyrateRx.graph : [{ data: [] }]"
                [scale]="{
                  range: [
                    0,
                    nodeCharts.effPhyrateRx && data.charts?.line.effPhyrateRx.maxValue
                      ? data.charts.line.effPhyrateRx.maxValue
                      : 1
                  ],
                  symbol: '%'
                }"
                [period]="data.chartMode === '24h' ? 1 : 7"
                [isFilled]="true"
                [autosize]="true"
              ></nodechart>
            </div>
          </div>
        </div>

        <div class="graph" *ngIf="mode !== '5g'">
          <div class="caption" (click)="hideGraph('effPhyrateTx')">
            {{ 'qoe.effPhyrateTx' | translate }}
            <ng-container *ngIf="rssiChartAvailableBands.length > 1">({{ rssiChartSelectedBand }})</ng-container>
            <icon name="icon-triangle-down" class="expander" [class.rotate180]="isShowChart('effPhyrateTx')"></icon>
          </div>
          <div class="history" *ngIf="isShowChart('effPhyrateTx')">
            <div class="chart-wrap">
              <nodechart
                [series]="[]"
                [data]="nodeCharts.effPhyrateTx && data.charts ? data.charts.line.effPhyrateTx.graph : [{ data: [] }]"
                [scale]="{
                  range: [
                    0,
                    nodeCharts.effPhyrateTx && data.charts?.line.effPhyrateTx.maxValue
                      ? data.charts.line.effPhyrateTx.maxValue
                      : 1
                  ],
                  symbol: '%'
                }"
                [period]="data.chartMode === '24h' ? 1 : 7"
                [isFilled]="true"
                [autosize]="true"
              ></nodechart>
            </div>
          </div>
        </div>

        <div class="graph" *ngIf="mode !== '5g'">
          <div class="caption" (click)="hideGraph('weightedPrrRx')">
            {{ 'qoe.weightedPrrRx' | translate }}
            <ng-container *ngIf="rssiChartAvailableBands.length > 1">({{ rssiChartSelectedBand }})</ng-container>
            <icon name="icon-triangle-down" class="expander" [class.rotate180]="isShowChart('weightedPrrRx')"></icon>
          </div>
          <div class="history" *ngIf="isShowChart('weightedPrrRx')">
            <div class="chart-wrap">
              <nodechart
                [series]="[]"
                [data]="nodeCharts.weightedPrrRx && data.charts ? data.charts.line.weightedPrrRx.graph : [{ data: [] }]"
                [scale]="{
                  range: [
                    0,
                    nodeCharts.weightedPrrRx && data.charts?.line.weightedPrrRx.maxValue
                      ? data.charts.line.weightedPrrRx.maxValue
                      : 1
                  ],
                  symbol: '%'
                }"
                [period]="data.chartMode === '24h' ? 1 : 7"
                [isFilled]="true"
                [autosize]="true"
              ></nodechart>
            </div>
          </div>
        </div>

        <div class="graph" *ngIf="mode !== '5g'">
          <div class="caption" (click)="hideGraph('weightedPrrTx')">
            {{ 'qoe.weightedPrrTx' | translate }}
            <ng-container *ngIf="rssiChartAvailableBands.length > 1">({{ rssiChartSelectedBand }})</ng-container>
            <icon name="icon-triangle-down" class="expander" [class.rotate180]="isShowChart('weightedPrrTx')"></icon>
          </div>
          <div class="history" *ngIf="isShowChart('weightedPrrTx')">
            <div class="chart-wrap">
              <nodechart
                [series]="[]"
                [data]="nodeCharts.weightedPrrTx && data.charts ? data.charts.line.weightedPrrTx.graph : [{ data: [] }]"
                [scale]="{
                  range: [
                    0,
                    nodeCharts.weightedPrrTx && data.charts?.line.weightedPrrTx.maxValue
                      ? data.charts.line.weightedPrrTx.maxValue
                      : 1
                  ],
                  symbol: '%'
                }"
                [period]="data.chartMode === '24h' ? 1 : 7"
                [isFilled]="true"
                [autosize]="true"
              ></nodechart>
            </div>
          </div>
        </div>

        <div class="graph" *ngIf="mode !== '5g'">
          <div class="caption" (click)="hideGraph('avgRxAirTime')">
            {{ 'qoe.avgRxAirTime' | translate }}
            <ng-container *ngIf="rssiChartAvailableBands.length > 1">({{ rssiChartSelectedBand }})</ng-container>
            <icon name="icon-triangle-down" class="expander" [class.rotate180]="isShowChart('avgRxAirTime')"></icon>
          </div>
          <div class="history" *ngIf="isShowChart('avgRxAirTime')">
            <div class="chart-wrap">
              <nodechart
                [series]="[]"
                [data]="nodeCharts.avgRxAirTime && data.charts ? data.charts.line.avgRxAirTime.graph : [{ data: [] }]"
                [scale]="{
                  range: [
                    0,
                    nodeCharts.avgRxAirTime && data.charts?.line.avgRxAirTime.maxValue
                      ? data.charts.line.avgRxAirTime.maxValue
                      : 1
                  ],
                  symbol: '%'
                }"
                [period]="data.chartMode === '24h' ? 1 : 7"
                [isFilled]="true"
                [autosize]="true"
              ></nodechart>
            </div>
          </div>
        </div>

        <div class="graph" *ngIf="mode !== '5g'">
          <div class="caption" (click)="hideGraph('avgTxAirTime')">
            {{ 'qoe.avgTxAirTime' | translate }}
            <ng-container *ngIf="rssiChartAvailableBands.length > 1">({{ rssiChartSelectedBand }})</ng-container>
            <icon name="icon-triangle-down" class="expander" [class.rotate180]="isShowChart('avgTxAirTime')"></icon>
          </div>
          <div class="history" *ngIf="isShowChart('avgTxAirTime')">
            <div class="chart-wrap">
              <nodechart
                [series]="[]"
                [data]="nodeCharts.avgTxAirTime && data.charts ? data.charts.line.avgTxAirTime.graph : [{ data: [] }]"
                [scale]="{
                  range: [
                    0,
                    nodeCharts.avgTxAirTime && data.charts?.line.avgTxAirTime.maxValue
                      ? data.charts.line.avgTxAirTime.maxValue
                      : 1
                  ],
                  symbol: '%'
                }"
                [period]="data.chartMode === '24h' ? 1 : 7"
                [isFilled]="true"
                [autosize]="true"
              ></nodechart>
            </div>
          </div>
        </div>

        <div class="graph" *ngIf="data.type === 'device'">
          <div class="caption" (click)="hideGraph('connectivityScore')">
            {{ 'qoe.connectivityScore' | translate }}
            <icon
              name="icon-triangle-down"
              class="expander"
              [class.rotate180]="isShowChart('connectivityScore')"
            ></icon>
          </div>
          <div class="history" *ngIf="isShowChart('connectivityScore')">
            <div class="chart-wrap">
              <nodechart
                [series]="[]"
                [data]="
                  nodeCharts.connectivityBasedScore && data.charts
                    ? data.charts.line.connectivityBasedScore.graph
                    : [{ data: [] }]
                "
                [scale]="{ range: [0, 5], symbol: '' }"
                [period]="data.chartMode === '24h' ? 1 : 7"
                [isFilled]="true"
                [autosize]="true"
              ></nodechart>
            </div>
          </div>
        </div>

        <div class="graph" *ngIf="mode !== '5g'">
          <div class="caption" (click)="hideGraph('channelUtilization')">
            {{ 'qoe.channelUtilization' | translate }}
            <ng-container *ngIf="rssiChartAvailableBands.length > 1">({{ rssiChartSelectedBand }})</ng-container>
            <icon
              name="icon-triangle-down"
              class="expander"
              [class.rotate180]="isShowChart('channelUtilization')"
            ></icon>
          </div>
          <div class="history" *ngIf="isShowChart('channelUtilization')">
            <div class="chart-wrap">
              <nodechart
                [series]="[]"
                [data]="
                  nodeCharts.channelUtilization && data.charts
                    ? data.charts.line.channelUtilization.graph
                    : [{ data: [] }]
                "
                [scale]="{
                  range: [
                    0,
                    nodeCharts.channelUtilization && data.charts?.line.channelUtilization.maxValue
                      ? data.charts.line.channelUtilization.maxValue
                      : 1
                  ],
                  symbol: '%'
                }"
                [period]="data.chartMode === '24h' ? 1 : 7"
                [isFilled]="true"
                [autosize]="true"
              ></nodechart>
            </div>
          </div>
        </div>

        <div class="graph" *ngIf="mode !== '5g'">
          <div class="caption" (click)="hideGraph('busy')">
            {{ 'qoe.busy' | translate }}
            <icon name="icon-triangle-down" class="expander" [class.rotate180]="isShowChart('busy')"></icon>
          </div>
          <div class="history" *ngIf="isShowChart('busy')">
            <div class="chart-wrap">
              <nodechart
                [series]="[]"
                [data]="nodeCharts.busyRatio && data.charts ? data.charts.line.busyRatio.graph : [{ data: [] }]"
                [scale]="{
                  range: [
                    0,
                    nodeCharts.busyRatio && data.charts?.line.busyRatio.maxValue
                      ? data.charts.line.busyRatio.maxValue
                      : 1
                  ],
                  symbol: '%'
                }"
                [period]="data.chartMode === '24h' ? 1 : 7"
                [isFilled]="true"
                [autosize]="true"
              ></nodechart>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="mode === '5g'">
        <div class="graph" *ngIf="ui === 'noc'">
          <div class="caption" (click)="hideGraph('weightedQoeScore')">
            {{ 'qoe.weightedQoeScore' | translate }}
            <icon name="icon-triangle-down" class="expander" [class.rotate180]="isShowChart('weightedQoeScore')"></icon>
          </div>
          <div class="history" *ngIf="isShowChart('weightedQoeScore')">
            <div class="chart-wrap">
              <nodechart
                [series]="[]"
                [data]="
                  nodeCharts.weightedQoeScore && data.charts ? data.charts.line.weightedQoeScore.graph : [{ data: [] }]
                "
                [scale]="{ range: [0, 5], symbol: '' }"
                [period]="data.chartMode === '24h' ? 1 : 7"
                [isFilled]="true"
                [autosize]="true"
              ></nodechart>
            </div>
          </div>
        </div>
        <qoe15minchart
          titleLabel="qoe.weightedQoeScore"
          [hasOfflines]="true"
          [data]="data.charts?.weightedQoeScore"
          [status]="data.status"
          *ngIf="ui === 'central'"
        ></qoe15minchart>
        <qoe15minchart
          titleLabel="qoe.signalBars"
          [hasOfflines]="true"
          [data]="data.charts?.signalBars"
          [status]="data.status"
          *ngIf="ui === 'central'"
        ></qoe15minchart>

        <div class="graph" *ngIf="ui === 'noc'">
          <div class="caption" (click)="hideGraph('signalBars')">
            {{ 'qoe.signalBars' | translate }}
            <ng-container *ngIf="rssiChartAvailableBands.length > 1">({{ rssiChartSelectedBand }})</ng-container>
            <icon name="icon-triangle-down" class="expander" [class.rotate180]="isShowChart('signalBars')"></icon>
          </div>
          <div class="history" *ngIf="isShowChart('signalBars')">
            <div class="chart-wrap">
              <nodechart
                [data]="data.charts ? data.charts?.line?.signalBars?.graph : [{ data: [] }]"
                [scale]="{ range: [0, 5], symbol: '' }"
                [period]="data.chartMode === '24h' ? 1 : 7"
                [isFilled]="true"
                [autosize]="true"
              ></nodechart>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="mode !== '5g'">
        <qoe15minchart
          titleLabel="qoe.online"
          [hasOfflines]="true"
          [data]="data.charts?.online"
          [status]="data.status"
        ></qoe15minchart>
        <qoe15minchart
          titleLabel="qoe.channel"
          [hasOfflines]="true"
          [data]="data.charts?.channel"
          [status]="data.status"
        ></qoe15minchart>
      </ng-container>

      <ng-container *ngIf="mode !== '5g' && ui === 'central' && powerCapable && powerPermission">
        <qoe15minchart titleLabel="qoe.lpmRatio" [data]="data.charts?.lpmRatio" [status]="data.status"></qoe15minchart>
      </ng-container>
      <div class="graph" *ngIf="mode !== '5g' && ui === 'noc' && powerCapable && powerPermission">
        <div class="caption" (click)="hideGraph('lpm')">
          {{ 'qoe.lpmRatio' | translate }}
          <ng-container *ngIf="rssiChartAvailableBands.length > 1">({{ rssiChartSelectedBand }})</ng-container>
          <icon name="icon-triangle-down" class="expander" [class.rotate180]="isShowChart('lpm')"></icon>
        </div>
        <div class="history" *ngIf="isShowChart('lpm')">
          <div class="chart-wrap">
            <nodechart
              [series]="[]"
              [data]="nodeCharts.lpmRatio && data.charts ? data.charts.line.lpmRatio.graph : [{ data: [] }]"
              [scale]="{ range: [0, 1], symbol: '' }"
              [period]="data.chartMode === '24h' ? 1 : 7"
              [isFilled]="true"
              [autosize]="true"
            ></nodechart>
          </div>
        </div>
      </div>
      <div class="graph" *ngIf="mode !== '5g' && data.charts?.averagePredictedWifiSpeed">
        <div class="caption" (click)="hideGraph('predictedThroughput')">
          {{ 'qoe.predictedThroughput' | translate }}
          <icon
            name="icon-triangle-down"
            class="expander"
            [class.rotate180]="isShowChart('predictedThroughput')"
          ></icon>
        </div>
        <div class="history chart-wrap" *ngIf="isShowChart('predictedThroughput')">
          <qoethroughputchart
            [data]="[data.charts?.averagePredictedWifiSpeed.ticks]"
            [period]="data.chartMode === '24h' ? 1 : 7"
          ></qoethroughputchart>
        </div>
      </div>

      <div class="graph" *ngIf="mode === '5g' && data.charts?.actualThroughput">
        <div class="caption" (click)="hideGraph('actualThroughput')">
          {{ 'qoe.actualThroughput' | translate }}
          <icon name="icon-triangle-down" class="expander" [class.rotate180]="isShowChart('actualThroughput')"></icon>
        </div>
        <div class="history chart-wrap" *ngIf="isShowChart('actualThroughput')">
          <qoethroughputchart
            [data]="[data.charts?.actualThroughput.ticks]"
            [period]="data.chartMode === '24h' ? 1 : 7"
          ></qoethroughputchart>
        </div>
      </div>
      <div class="graph" *ngIf="mode === '5g' && data.charts?.estimatedThroughput">
        <div class="caption" (click)="hideGraph('estimatedThroughput')">
          {{ 'qoe.estimatedThroughput' | translate }}
          <icon
            name="icon-triangle-down"
            class="expander"
            [class.rotate180]="isShowChart('estimatedThroughput')"
          ></icon>
        </div>
        <div class="history chart-wrap" *ngIf="isShowChart('estimatedThroughput')">
          <qoethroughputchart
            [data]="[data.charts?.estimatedThroughput.ticks]"
            [period]="data.chartMode === '24h' ? 1 : 7"
          ></qoethroughputchart>
        </div>
      </div>

      <div class="graph" *ngIf="data.mode === '5g' && data.charts?.speedTestLatency">
        <div class="caption" (click)="hideGraph('speedTestLatency')">
          {{ 'qoe.speedTestLatency' | translate }}
          <icon name="icon-triangle-down" class="expander" [class.rotate180]="isShowChart('speedTestLatency')"></icon>
        </div>
        <div class="history chart-wrap" *ngIf="isShowChart('speedTestLatency')">
          <qoethroughputchart
            [data]="[data.charts?.speedTestLatency.ticks]"
            [period]="data.chartMode === '24h' ? 1 : 7"
          ></qoethroughputchart>
        </div>
      </div>

      <!--NOTE:  This is an empty/invisible graph.  It keeps columns in line when all other graphs are closed-->
      <div class="graph">
        <div class="caption"></div>
        <div class="history chart-wrap"></div>
      </div>
    </div>
  </div>
</div>
