import { select } from '@ngrx/store';
import { Observable, pipe, UnaryFunction } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { AppState } from './reducers';

export interface StoreData<T, ERR_T> {
  data: T;
  lastValidData: T;
  state: 'initial' | 'loading' | 'loaded' | 'error' | 'loadedNext';
  error: ERR_T | undefined;
}

export function makeFilterDistinctPipeSelect<K>(
  filterFn: (state: AppState) => boolean,
  mapperFn: (state: AppState) => K
): UnaryFunction<Observable<AppState>, Observable<K>> {
  return pipe(
    select((state: AppState) => state),
    filter(filterFn),
    map(mapperFn),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
  );
}

export function setInitialState<T>(data: T): StoreData<T, string> {
  return {
    data,
    lastValidData: data,
    state: 'initial',
    error: undefined
  };
}

export function setLoadingState<T, ERR_T>(storeValue: StoreData<T, ERR_T>): StoreData<T, ERR_T> {
  return { ...storeValue, state: 'loading', error: undefined };
}

export function setLoadedState<T>(data: T): StoreData<T, string> {
  return { data, lastValidData: data, state: 'loaded', error: undefined };
}

export function setNextLoadedState<T>(data: T): StoreData<T, string> {
  return { data, lastValidData: data, state: 'loadedNext', error: undefined };
}

export function setErrorState<T, ERR_T>(storeValue: StoreData<T, ERR_T>, error: ERR_T, value?: T): StoreData<T, ERR_T> {
  return { ...storeValue, state: 'error', error, data: value !== undefined ? value : storeValue.lastValidData };
}

export function sendValue<T, ERR_T>(storeValue: StoreData<T, ERR_T>, data: T, optimistic = true): StoreData<T, ERR_T> {
  return { ...storeValue, state: 'loading', error: undefined, data: optimistic ? data : storeValue.data };
}
