import { createAction, props } from '@ngrx/store';
import {
  autoModes,
  IAppPrioritization,
  IAppPrioritizationMonitoring,
  IAppPrioritizationV2,
  IAppTime,
  ICapabilities,
  IConfigAndState,
  ICustomer,
  ILocation,
  ILocationLog,
  IMobilizeLog,
  IPrimarySecondaryNetworks,
  ISecondaryNetworks
} from 'src/app/lib/interfaces/interface';

export const customerChange = createAction(
  '[Customer] Customer change',
  props<{ customerid: string; locationid: string; fromBrowserHistory: boolean }>()
);

export const customerLoaded = createAction('[Customer] Customer loaded', props<{ customer: ICustomer }>());
export const customerLoadedError = createAction('[Customer] Customer loading error', props<{ error: any }>());

export const customerSetFromUtilities = createAction(
  '[Configuration Utilities] Customer set from email verified',
  props<{ customer: ICustomer }>()
);
export const customerReload = createAction('[Customer] Customer start of Reload', props<{ actionType: string }>());
export const locationReload = createAction('[Customer] Location start of Reload', props<{ actionType: string }>());

export const customerLocationLoaded = createAction(
  '[Customer] Customer Location loaded',
  props<{ location: ILocation }>()
);
export const customerLocationLoadedError = createAction(
  '[Customer] Customer Location loading error',
  props<{ error: any }>()
);

export const customerLocationListLoaded = createAction(
  '[Customer] Customer Location list loaded',
  props<{ locationList: ILocation[] }>()
);
export const customerLocationListLoadedError = createAction(
  '[Customer] Customer Location list loading error',
  props<{ error: any }>()
);

export const customerLocationConfigAndStateReload = createAction(
  '[Customer] Customer Location config and state start of Reload',
  props<{ actionType: string }>()
);
export const customerLocationConfigAndStateLoaded = createAction(
  '[Customer] Customer Location config and state loaded',
  props<{ configAndState: IConfigAndState }>()
);
export const customerLocationConfigAndStateLoadedError = createAction(
  '[Customer] Customer Location config and state loading error',
  props<{ error: any }>()
);

export const appTimeLoaded = createAction('[Customer] App time loaded', props<{ appTime: IAppTime }>());
export const appTimeLoadedError = createAction('[Customer] App time loaded error', props<{ error: any }>());

export const wifiPasswordUpdateLoaded = createAction('[Network settings] WiFi password update loaded');
export const wifiPasswordUpdateLoadedError = createAction('[Network settings] WiFi password update loading error');
export const customerCapabilitiesLoaded = createAction(
  '[Customer] Customer Capabilities loaded',
  props<{ capabilities: ICapabilities }>()
);
export const customerCapabilitiesLoadedError = createAction(
  '[Customer] CustomerCapabilities loading error',
  props<{ error: any }>()
);

export const customerClose = createAction('[Customer] Customer close');

export const samKnowsModeSet = createAction(
  '[Configuration][Agent Integrations] Sam knows mode set',
  props<{ mode: autoModes }>()
);
export const samKnowsModeSetFinished = createAction('[Customer effects] Sam knows mode set finished');
export const samKnowsModeSetError = createAction(
  '[Customer effects] Sam knows mode set error',
  props<{ error: any }>()
);

export const appQoeModeSet = createAction(
  '[Configuration][Agent Integrations] App qoe mode set',
  props<{ mode: autoModes }>()
);
export const appQoeModeSetFinished = createAction('[Customer effects] App qoe mode set finished');
export const appQoeModeSetError = createAction('[Customer effects] App qoe mode set error', props<{ error: any }>());

export const sipAlgModeSet = createAction(
  '[Configuration][Privacy and security] Sip alg mode set',
  props<{ mode: autoModes }>()
);
export const puncturingModeSet = createAction(
  '[Configuration][Puncturing] Puncturing mode set',
  props<{ mode: autoModes }>()
);

export const sipAlgModeSetFinished = createAction('[Customer effects] Sip alg mode set finished');
export const puncturingModeSetFinished = createAction('[Customer effects] Puncturing mode set finished');

export const sipAlgModeSetError = createAction('[Customer effects] Sip alg mode set error', props<{ error: any }>());
export const puncturingModeSetError = createAction(
  '[Customer effects] Puncturing mode set error',
  props<{ error: any }>()
);

export const updateDevicesList = createAction('[Devices] Update devices list');
export const updateNodesList = createAction('[Nodes] Update node list');
export const nodesChanged = createAction(
  '[Customer] Nodes changed based on Topology',
  props<{
    added: string[];
    removed: string[];
    connectionStateChanged: string[];
  }>()
);

export const setSSID = createAction('[Network Settings] Set SSID', props<{ ssid: string }>());

export const getPrimarySecondaryNetworks = createAction('[Network Settings] Get primary/secondary network');
export const setPrimarySecondaryNetworks = createAction(
  '[Network Settings] Set primary/secondary network',
  props<{ options: IPrimarySecondaryNetworks }>()
);
export const primarySecondaryNetworksLoaded = createAction(
  '[Network Settings] Primary/secondary network loaded',
  props<{ options: IPrimarySecondaryNetworks }>()
);
export const setPrimarySecondaryNetworksLoaded = createAction(
  '[Network Settings] Set primary/secondary network loaded'
);
export const setPrimarySecondaryNetworksLoadedError = createAction(
  '[Network Settings] Set primary/secondary network error'
);

export const getSecondaryNetworks = createAction('[Network Settings] Get secondary networks');
export const secondaryNetworksLoaded = createAction(
  '[Network Settings] Secondary networks loaded',
  props<{ options: ISecondaryNetworks }>()
);

export const getAppPrioritizationMonitoring = createAction(
  '[App Prioritization] Get app prioritization monitoring',
  props<{ monitoring: IAppPrioritizationMonitoring }>()
);
export const appPrioritizationMonitoringLoaded = createAction(
  '[App Prioritization] App prioritization monitoring loaded',
  props<{ monitoring: IAppPrioritizationMonitoring }>()
);
export const appPrioritizationMonitoringLoadedError = createAction(
  '[App Prioritization] App prioritization monitoring error'
);

export const getGranularAppPrioritizationMonitoring = createAction(
  '[App Prioritization] Get granular app prioritization monitoring',
  props<{ monitoring: IAppPrioritizationMonitoring }>()
);
export const granularAppPrioritizationMonitoringLoaded = createAction(
  '[App Prioritization] Granular app prioritization monitoring loaded',
  props<{ monitoring: IAppPrioritizationMonitoring }>()
);
export const granularAppPrioritizationMonitoringLoadedError = createAction(
  '[App Prioritization] Granular app prioritization monitoring error'
);

export const getAppPrioritization = createAction('[App Prioritization] Get app prioritization');
export const appPrioritizationLoaded = createAction(
  '[App Prioritization] App prioritization loaded',
  props<{ appPrioritization: IAppPrioritization }>()
);
export const appPrioritizationLoadedError = createAction('[App Prioritization] App prioritization error');
export const setAppPrioritization = createAction(
  '[App Prioritization] Patch app prioritization',
  props<{ appPrioritization: IAppPrioritization }>()
);
export const deleteAppPrioritizationCustomSettings = createAction(
  '[App Prioritization] Delete app prioritization custom settings',
  props<{ appPrioritization: IAppPrioritization }>()
);
export const setAppPrioritizationLoaded = createAction('[App Prioritization] Set app prioritization loaded');
export const setAppPrioritizationLoadedError = createAction('[App Prioritization] Set app prioritization error');
export const deleteAppPrioritizationCustomSettingsError = createAction(
  '[App Prioritization] Delete app prioritization custom settings error'
);
export const toggleAppPrioritization = createAction(
  '[App Prioritization] Toggle app prioritization enabled state',
  props<{ enabled?: boolean; mode?: 'auto' | 'enable' | 'disable' }>()
);
export const toggleAppPrioritizationLoadedError = createAction(
  '[App Prioritization] Toggle app prioritization enabled state error'
);

export const getAppPrioritizationV2 = createAction('[App Prioritization V2] Get app prioritization');
export const appPrioritizationV2Loaded = createAction(
  '[App Prioritization V2] App prioritization loaded',
  props<{ appPrioritizationV2: IAppPrioritizationV2 }>()
);
export const appPrioritizationV2LoadedError = createAction('[App Prioritization V2] App prioritization error');
export const setAppPrioritizationV2 = createAction(
  '[App Prioritization V2] Patch app prioritization',
  props<{ appPrioritizationV2: IAppPrioritizationV2 }>()
);

export const setAppPrioritizationV2Loaded = createAction('[App Prioritization V2] Set app prioritization loaded');
export const setAppPrioritizationV2LoadedError = createAction('[App Prioritization V2] Set app prioritization error');

export const toggleAppPrioritizationV2 = createAction(
  '[App Prioritization V2] Toggle app prioritization enabled state',
  props<{ mode?: 'auto' | 'enable' | 'disable' }>()
);
export const toggleAppPrioritizationV2LoadedError = createAction(
  '[App Prioritization V2] Toggle app prioritization enabled state error'
);

export const updateLogpullHistory = createAction('[Logpull History] Fetch new logpull history data');
export const logpullHistoryLoaded = createAction(
  '[Logpull History] Got new logpull history data',
  props<{ logpullHistory: ILocationLog[] }>()
);
export const logpullHistoryLoadedError = createAction(
  '[Logpull History] Failed to get new logpull history data',
  props<{ error: any }>()
);
export const updateMobilizeProblemsHistory = createAction(
  '[Mobilize Problems History] Fetch new mobilize problems history data'
);
export const mobilizeProblemsHistoryLoaded = createAction(
  '[Mobilize Problems History] Got new mobilize problems history data',
  props<{ mobilizeProblemsHistory: IMobilizeLog[] }>()
);
export const mobilizeProblemsHistoryLoadedError = createAction(
  '[Mobilize Problems History] Failed to get new mobilize problems history data',
  props<{ error: any }>()
);
