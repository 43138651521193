<div
  class="feedback-overlay"
  [class.open]="open"
  (click)="closeFeedback()"
  [@overlayAnimation]="open ? 'expanded' : 'collapsed'"
></div>
<div class="feedback" [@overlayAnimation]="open ? 'expanded' : 'collapsed'">
  <icon name="icon-close" class="size-0_6" (click)="closeFeedback()"></icon>
  <ng-container *ngIf="step === 'performance'">
    <div class="title">{{ 'header.feedback.performance' | translate }}</div>
    <div class="options">
      <icon class="size-2" name="icon-thumbsup" (click)="feedbackAction('yes')"></icon>
      <icon class="size-2 rotate180" name="icon-thumbsup" (click)="feedbackAction('no')"></icon>
    </div>
  </ng-container>
  <ng-container *ngIf="step === 'improve'">
    <div class="title">{{ 'header.feedback.improve' | translate }}</div>
    <textarea [formControl]="improve" rows="3"></textarea>
    <div class="buttons">
      <button class="super-primary" (click)="feedbackAction('submit')">
        {{ 'header.feedback.submit' | translate }}
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="step === 'thankYou'">
    <div class="title">{{ 'header.feedback.thankYou' | translate }}</div>
  </ng-container>
</div>
