import { Point } from './point';
import { Series } from './series';
import { Dot } from './dot';

export class Line {
  d?: string;
  fill?: string;
  dots?: Dot[];
  alarms?: Dot[];
  class: string;
  series: Series;
  axis: string;
  data: Point[];
  thresholdFill?: string[] | null[];
  allData?: { timestamp: number; value: number }[];

  constructor(series: Series, axis: string, data: Point[], allData?: { timestamp: number; value: number }[]) {
    this.class = 'line ' + series?.text;
    this.series = series;
    this.axis = axis;
    this.data = data;
    this.allData = allData?.sort((a, b) => a.timestamp - b.timestamp);
  }
}

export interface MultiLine {
  paths: string[];
  fills: string[];
  data: Point[];
  alarms: Dot[];
  dots?: Dot[];
  series: Series;
  class: string;
}
