<div class="row">
  <div class="col-xs-12">
    <box>
      <div [class.loading]="loading" header>
        <div class="nodeName">
          <nodename [node]="node"></nodename>
          <div class="alarms" *ngIf="node.connectionState == 'disconnected' || alarms.length > 0">
            <span class="txt">{{ 'speedtests.speednode.alarms' | translate }}:</span>
            <span class="num" *ngIf="node.connectionState == 'disconnected'">
              {{ 'speedtests.speednode.alarmsExtenderOffline' | translate }}
            </span>
            <ng-container *ngIf="node.connectionState == 'connected' && alarms.length > 0">
              <div class="num alerted" *ngFor="let alarm of alarms">
                {{ 'nodes.node.' + alarm | translate }}
              </div>
            </ng-container>
          </div>
        </div>
        <div class="alarms" *ngIf="node.connectionState == 'disconnected' || alarms.length > 0">
          <span class="txt">{{ 'speedtests.speednode.alarms' | translate }}:</span>
          <span class="num" *ngIf="node.connectionState == 'disconnected'">
            {{ 'speedtests.speednode.alarmsExtenderOffline' | translate }}
          </span>
          <div class="alarmsWrap" *ngIf="node.connectionState == 'connected' && alarms.length > 0">
            <div class="num alerted" *ngFor="let alarm of alarms">
              {{ 'nodes.node.' + alarm | translate }}
            </div>
          </div>
        </div>
        <div class="status">
          <div class="circle {{ node.connectionState }}"></div>
        </div>
      </div>
      <div id="speedtest-{{ node.id }}" container>
        <div class="progressbar progressbar--top" [class.hidden]="!loading"><div class="indeterminate"></div></div>
        <div class="content">
          <div class="controls">
            <button class="action small" (click)="toggleUploadData()">
              {{
                showUpload
                  ? ('speedtests.speednode.hideUpload' | translate)
                  : ('speedtests.speednode.showUpload' | translate)
              }}
            </button>
            <slidetoggler [options]="chartModes" (toggle)="setMode($event)"></slidetoggler>
          </div>
          <div class="stats">
            <div class="table-container">
              <div class="data-table">
                {{ 'speedtests.speednode.download' | translate }}:
                <div class="data-row">
                  <div class="data-cell txt">{{ 'speedtests.speednode.mostRecent' | translate }}:</div>
                  <div class="data-cell val">{{ stats.download.recent | number : '1.0-2' }} Mbps</div>
                </div>
                <div class="data-row">
                  <div class="data-cell txt">{{ 'speedtests.speednode.maxSpeed' | translate }}:</div>
                  <div class="data-cell val">{{ stats.download.max | number : '1.0-2' }} Mbps</div>
                </div>
                <div class="data-row">
                  <div class="data-cell txt">{{ 'speedtests.speednode.minSpeed' | translate }}:</div>
                  <div class="data-cell val">{{ stats.download.min | number : '1.0-2' }} Mbps</div>
                </div>
              </div>
              <div class="data-table">
                {{ 'speedtests.speednode.upload' | translate }}:
                <div class="data-row">
                  <div class="data-cell txt">{{ 'speedtests.speednode.mostRecent' | translate }}:</div>
                  <div class="data-cell val">{{ stats.upload.recent | number : '1.0-2' }} Mbps</div>
                </div>
                <div class="data-row">
                  <div class="data-cell txt">{{ 'speedtests.speednode.maxSpeed' | translate }}:</div>
                  <div class="data-cell val">{{ stats.upload.max | number : '1.0-2' }} Mbps</div>
                </div>
                <div class="data-row">
                  <div class="data-cell txt">{{ 'speedtests.speednode.minSpeed' | translate }}:</div>
                  <div class="data-cell val">{{ stats.upload.min | number : '1.0-2' }} Mbps</div>
                </div>
              </div>
            </div>
            <div class="maxBandwidthWarning" *ngIf="showMaximumSupportedBandwidth">
              <h3>
                <icon name="icon-info" class="size-0_8"></icon
                >{{ 'speedtests.speednode.maxBandwidthTitle' | translate }}
              </h3>
              <p>{{ 'speedtests.speednode.maxBandwidthWarning' | translate : { value: maximumSupportedBandwidth } }}</p>
            </div>
            <div class="buttonBox">
              <div
                class="tooltipBox"
                [tooltip]="'speedtests.speednode.speedTestDisableDueToTle' | translate"
                [hideToolTip]="(lteInUse$ | async) !== true && permissions?.uiFeatures.lte"
              >
                <button
                  class="action medium"
                  [class.loading]="loading"
                  [class.disabled]="
                    (speedTestDisabled$ | async) || ((lteInUse$ | async) && permissions?.uiFeatures.lte)
                  "
                  *ngIf="node.connectionState == 'connected' && !node.disableSpeedTest"
                  (click)="startTest()"
                >
                  <icon name="icon-gauge"></icon>
                  <span *ngIf="loading">{{ 'speedtests.speednode.testing' | translate }}</span>
                  <span *ngIf="!loading">{{ 'speedtests.speednode.testSpeed' | translate }}</span>
                </button>
              </div>
            </div>
          </div>
          <div class="chart" [class.hideUpload]="!showUpload">
            <speedtestchart [id]="node.id" [data]="bars" [xrange]="range"></speedtestchart>
          </div>
        </div>
      </div>
    </box>
  </div>
</div>
