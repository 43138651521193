import { IHelpPage } from '../help-items';

export const devices: IHelpPage = {
  helpItems: {
    general: {
      options: {
        scrollToTop: true,
        locationOnlineNeeded: true,
        devicesNeeded: true
      },
      steps: [
        {
          targetElement: '.dropdown.listContainer.ng-star-inserted',
          content: 'help.devices.general.filters',
          options: {
            action: {
              type: 'click',
              targetElement: '[headericon="icon-controls"] drop-down [cdkoverlayorigin]'
            },
            exitAction: {
              type: 'click',
              targetElement: '[headericon="icon-controls"] drop-down [cdkoverlayorigin]'
            }
          }
        },
        {
          targetElement: '.filter slidetoggler',
          content: 'help.devices.general.workPassFilters'
        },
        {
          targetElement: '.tooltip',
          content: 'help.devices.general.onlineState',
          options: {
            action: {
              type: 'function',
              function: async () => {
                const circle = document.querySelector('#device-connectionCircle .circle');
                if (!circle) return;
                circle.dispatchEvent(new MouseEvent('mouseenter', { bubbles: true }));
                await new Promise((r) => setTimeout(r, 100));
                setTimeout(() => {
                  const width = parseInt(document.querySelector<HTMLDivElement>('.spotlight').style.width, 10);
                  document.querySelector<HTMLDivElement>('.spotlight').style.width = `${width + 20}px`;
                  document.querySelector<HTMLDivElement>('.spotlightShadow').style.width = `${width + 20}px`;
                }, 100);
              }
            },
            exitAction: {
              type: 'function',
              function: async () => {
                document
                  .querySelector('#device-connectionCircle .circle')
                  .dispatchEvent(new MouseEvent('mouseleave', { bubbles: true }));
              }
            }
          }
        },
        {
          targetElement: '#device-actionsDropdown-content',
          content: 'help.devices.general.actions',
          options: {
            action: {
              type: 'click',
              targetElement: '#device-actionsDropdown [cdkoverlayorigin]'
            },
            exitAction: {
              type: 'click',
              targetElement: '#device-actionsDropdown [cdkoverlayorigin]'
            }
          }
        },
        {
          targetElement: '.device-history',
          content: 'help.devices.general.history',
          options: {
            expectMovement: true,
            scrollIntoView: true,
            action: {
              type: 'function',
              function: async () => {
                const expanded = document.querySelector<HTMLDivElement>('#device-history-collapsibleBox .header');
                if (!expanded) {
                  document.querySelector<HTMLDivElement>('#device-collapsibleBox .header').click();
                  await new Promise((r) => setTimeout(r, 100));
                }

                const historyExpanded = document.querySelector<HTMLDivElement>('.device-history');
                if (!historyExpanded) {
                  document.querySelector<HTMLDivElement>('#device-history-collapsibleBox .header').click();
                  await new Promise((r) => setTimeout(r, 100));
                }
              }
            },
            exitAction: {
              type: 'function',
              function: async () => {
                document.querySelector('#customer-view').scrollTop = 0;
                document.querySelector<HTMLDivElement>('#device-collapsibleBox .header').click();
                await new Promise((r) => setTimeout(r, 100));
              }
            }
          }
        }
      ]
    },
    thresholds: {
      options: {
        scrollToTop: true,
        locationOnlineNeeded: true,
        devicesNeeded: true,
        deviceOnlineNeeded: true
      },
      steps: [
        {
          targetElement: '#help-device-health',
          content: 'help.devices.thresholds.health',
          options: {
            expectMovement: true,
            scrollIntoView: true
          }
        },
        {
          targetElement: '#help-device-rssi',
          content: 'help.devices.thresholds.rssi',
          options: {
            action: {
              type: 'function',
              function: async () => {
                const expanded = document.querySelector<HTMLDivElement>('#device-history-collapsibleBox .header');
                if (!expanded) {
                  document.querySelector<HTMLDivElement>('#device-collapsibleBox.help-device-isOnline .header').click();
                  await new Promise((r) => setTimeout(r, 100));
                }
              }
            },
            exitAction: {
              type: 'function',
              function: async (action) => {
                if (action !== 'finish') return;
                document.querySelector('#customer-view').scrollTop = 0;
                document.querySelector<HTMLDivElement>('#device-collapsibleBox.help-device-isOnline .header').click();
                await new Promise((r) => setTimeout(r, 100));
              }
            }
          }
        },
        {
          targetElement: '#help-device-interference',
          content: 'help.devices.thresholds.interference',
          options: {
            exitAction: {
              type: 'function',
              function: async (action) => {
                if (action !== 'finish') return;
                document.querySelector('#customer-view').scrollTop = 0;
                document.querySelector<HTMLDivElement>('#device-collapsibleBox.help-device-isOnline .header').click();
                await new Promise((r) => setTimeout(r, 100));
              }
            }
          }
        }
      ]
    }
  }
};
