import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { selectBaseUrl, selectCustomerIdentification } from 'src/app/store/customer/customer.selectors';
import { DeepPartial, ISecurityPolicy, ISecurityPolicyEvent, ISecurityPolicyEventStats } from '../interfaces/interface';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SecurityPolicyService {
  constructor(private store: Store, private api: ApiService) {}

  securityToken$(): Observable<{
    id: string;
    scopes: string[];
    ttl: number;
    expireAt: string;
    created: string;
    userId: string;
  }> {
    return this.store.select(selectCustomerIdentification).pipe(
      take(1),
      switchMap((customer) => this.api.post(`/Customers/${customer.customerid}/createReadDnsAccessToken`, {}))
    );
  }

  realizedState$(): Observable<{
    ipThreatProvider: string;
    dpiContentFiltering: boolean;
    ipThreatEnable: boolean;
    contentFilters: string[];
    inlineDpi: boolean;
    intelligence: string;
    ipThreatIntelligence: string;
    wcHealthTelemetry: boolean;
    wcHeroTelemetry: boolean;
  }> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/securityPolicy/realizedState`))
    );
  }

  hourlyBlockedCounts$(): Observable<number[][]> {
    // 30 rows(days), 24columns (hours)
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/securityPolicy/hourlyBlockedCounts`))
    );
  }

  remoteConnections$(): Observable<{
    mode: 'auto' | 'enable' | 'highRiskOnly' | 'disable';
    modeRealized: 'enable' | 'highRiskOnly' | 'disable';
  }> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/securityPolicy/remoteConnections`))
    );
  }

  setRemoteConnections$(mode: 'auto' | 'enable' | 'highRiskOnly' | 'disable'): Observable<any> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/securityPolicy/remoteConnections`, { mode }))
    );
  }

  removeDomain$(mode: 'whitelist' | 'blacklist', domain: string, mac?: string): Observable<undefined> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.delete(`${baseUrl}${mac ? '/devices/' + mac : ''}/securityPolicy/websites/${mode}/${domain}`)
      )
    );
  }

  addDomain$(
    mode: 'whitelist' | 'blacklist',
    value: string,
    type: 'fqdn' | 'ipv4' | 'ipv6',
    mac?: string
  ): Observable<ISecurityPolicy> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.post(`${baseUrl}${mac ? '/devices/' + mac : ''}/securityPolicy/websites/${mode}`, { value, type })
      )
    );
  }

  triggerAnomaly$(mac: string, fqdn: string): Observable<{ reason?: string; result: string }> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.post(`${baseUrl}/devices/${mac}/securityPolicy/anomaly/experience`, { fqdn }))
    );
  }

  rollBackAnomaly$(mac: string): Observable<undefined> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.delete(`${baseUrl}/devices/${mac}/securityPolicy/anomaly/experience`))
    );
  }

  securityPolicy$(typeOrMac: 'unassigned' | 'all' | string, token: string): Observable<ISecurityPolicy> {
    const urlPart =
      typeOrMac === 'unassigned' ? '/groupOfUnassignedDevices' : typeOrMac === 'all' ? '' : `/devices/${typeOrMac}`;
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.raw(
          'get',
          `${this.api.apiURL()}${baseUrl}${urlPart}/securityPolicy`,
          {},
          { headers: { Authorization: token, 'Plume-Application-Name': 'PlumeCentral' } }
        )
      ),
      map((data) => (typeOrMac === 'unassigned' || typeOrMac === 'all' ? data : data.securityPolicy))
    );
  }

  editSecurityPolicy$(
    typeOrMac: 'unassigned' | 'all' | string,
    data: DeepPartial<ISecurityPolicy>
  ): Observable<ISecurityPolicy> {
    const urlPart =
      typeOrMac === 'unassigned' ? '/groupOfUnassignedDevices' : typeOrMac === 'all' ? '' : `/devices/${typeOrMac}`;
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}${urlPart}/securityPolicy`, data)),
      map((data) => (typeOrMac === 'unassigned' || typeOrMac === 'all' ? data : data.securityPolicy))
    );
  }

  events$(unassignedDevices: boolean, date: string, limit: number, token: string): Observable<ISecurityPolicyEvent[]> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.raw(
          'get',
          `${this.api.apiURL()}${baseUrl}${
            unassignedDevices ? '/groupOfUnassignedDevices' : ''
          }/securityPolicy/events?showAlsoIot=true&includes=IoTProtect.blacklistedAnomaly,IoTProtect.rolledbackAnomaly,IoTProtect.whitelistedAnomaly,adBlocking,kids,teenagers,workAppropriate,secureAndProtect,adultAndSensitive,SecureAndProtect.outboundIpBlocked,SecureAndProtect.inboundIpBlocked&limit=${limit}&direction=before&startTime=${date}`,
          {},
          { headers: { Authorization: token, 'Plume-Application-Name': 'PlumeCentral' } }
        )
      )
    );
  }

  eventStats$(
    macs: string[],
    protectionType: 'ohp' | 'ihp' | 'all',
    token: string
  ): Observable<ISecurityPolicyEventStats> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.raw(
          'post',
          `${this.api.apiURL()}${baseUrl}/securityPolicy/guard/eventStats`,
          {
            macs,
            timePeriod: 'last30Days',
            groupOfUnassignedDevices: false,
            protectionType
          },
          { headers: { Authorization: token, 'Plume-Application-Name': 'PlumeCentral' } }
        )
      )
    );
  }
}
