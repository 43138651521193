import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { SlideTogglerItems } from 'src/app/lib/interfaces/interface';
import { ILteSecondaryNetwork } from 'src/app/lib/interfaces/lte.interface';
import { LteService } from 'src/app/lib/services/lte.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { selectCapabilities } from 'src/app/store/customer/capabilities.selector';
import { lteConfigurationClosed, lteConfigurationOpened, lteReloadConfigAndState } from 'src/app/store/lte/lte.actions';
import {
  selectLteApn,
  selectLteForced,
  selectLteIccid,
  selectLteImei,
  selectLteInUse,
  selectLteLinkState,
  selectLtePersistance,
  selectLteSecondaryNetworks,
  selectLteServiceConfig,
  selectLteServiceGeoIp,
  selectLteServiceState,
  selectLteSignalStrength,
  selectLteSupportedObject
} from 'src/app/store/lte/lte.selectors';

@UntilDestroy()
@Component({
  selector: 'lte',
  templateUrl: './lte.component.html',
  styleUrls: ['./lte.component.scss']
})
export class LteComponent implements OnInit, OnChanges {
  permissions$ = this.plume.permissions;
  expand: boolean = false;
  capable$ = this.store.select(selectCapabilities).pipe(map((capabilities) => capabilities?.lte?.capable));
  imei$ = this.store.select(selectLteImei);
  iccid$ = this.store.select(selectLteIccid);
  supportedData$ = this.store.select(selectLteSupportedObject);
  signalStrength$ = this.store.select(selectLteSignalStrength);
  networkConfiguration$ = this.store.select(selectLteSecondaryNetworks).pipe(map(this.networkConfigurationMapper));
  linkState$ = this.store.select(selectLteLinkState);
  apn$ = this.store.select(selectLteApn);
  lteServiceState$ = this.store.select(selectLteServiceState);
  lteServiceConfig$ = this.store.select(selectLteServiceConfig);
  lteInUse$ = this.store.select(selectLteInUse);
  lteForced$ = this.store.select(selectLteForced);
  geoIp$ = this.store.select(selectLteServiceGeoIp);
  persistenceOptions$ = this.store.select(selectLtePersistance).pipe(
    distinctUntilChanged((prev, curr) => prev.config === curr.config && prev.state === curr.state), // HACK until save is not in store
    map(this.getPersistenceOptions)
  );
  lteEnabledOptions$ = combineLatest([this.lteServiceState$, this.lteServiceConfig$]).pipe(map(this.getLteEnabled));
  editApn = false;
  apnControl: UntypedFormControl = new UntypedFormControl('', Validators.maxLength(63));
  expandSecondNetworks = true;
  expandGeoIp = false;
  apnErrorText = '';
  persistenceOptions = [];
  lteCloudSupported = !!this.plume.getEnv()?.lteUrl;

  @Input()
  open: number = 0;

  @Output()
  toggle = new EventEmitter();

  @Output()
  filter = new EventEmitter();

  @Output()
  clearFilter = new EventEmitter<{ section: string }>();

  constructor(
    private lteService: LteService,
    private mixpanel: MixpanelService,
    private translate: TranslateService,
    private toast: ToastService,
    private store: Store,
    private plume: PlumeService
  ) {}

  ngOnInit(): void {
    this.registerFilter();
  }

  ngOnChanges(changes: any): void {
    this.expand = changes.open.currentValue;

    if (this.expand) {
      this.store.dispatch(lteConfigurationOpened());
    } else {
      this.store.dispatch(lteConfigurationClosed());
    }
  }

  toggleExpand(): void {
    this.toggle.emit(!this.expand);

    if (this.expand) {
      this.mixpanel.storeEvent('CONFIGURATION_LTE_SCREEN');
    }
  }

  registerFilter(): void {
    this.capable$.pipe(untilDestroyed(this)).subscribe((capable) => {
      this.clearFilter.emit({ section: 'lte' });

      if (capable) {
        this.translate
          .get('configurations.lte.iccid')
          .subscribe((translated: string) =>
            this.filter.emit({ section: 'lte', property: 'iccid', translation: translated })
          );
        this.translate
          .get('configurations.lte.imei')
          .subscribe((translated: string) =>
            this.filter.emit({ section: 'lte', property: 'imei', translation: translated })
          );
        this.translate
          .get('configurations.lte.linkState')
          .subscribe((translated: string) =>
            this.filter.emit({ section: 'lte', property: 'linkState', translation: translated })
          );
        this.translate
          .get('configurations.lte.signalStrength')
          .subscribe((translated: string) =>
            this.filter.emit({ section: 'lte', property: 'signalStrength', translation: translated })
          );
        this.translate
          .get('configurations.lte.apn')
          .subscribe((translated: string) =>
            this.filter.emit({ section: 'lte', property: 'apn', translation: translated })
          );
        this.translate
          .get('configurations.lte.enabled')
          .subscribe((translated: string) =>
            this.filter.emit({ section: 'lte', property: 'enabled', translation: translated })
          );
      }
    });
  }

  enableLte(enable: boolean): void {
    this.mixpanel.storeEvent('LTE_ENABLE', { enable });

    this.lteService.enable$(enable).subscribe(
      () => {
        setTimeout(() => {
          // wait until server set the force switch state
          this.store.dispatch(lteReloadConfigAndState());
        }, 3000);
      },
      () => {
        this.mixpanel.storeEvent('LTE_FORCE_SWITCH', { enable });
        this.toast.error('configurations.lte.changeFailure', 'configurations.lte.lteEnableFailure');
      }
    );
  }

  forceSwitch(use: boolean): void {
    this.lteService.forceSwitch$(use).subscribe(
      (data) => {
        this.mixpanel.storeEvent('LTE_FORCE_SWITCH_ACCEPTED', data);
        this.toast.success('configurations.lte.forceSwitchDone', 'configurations.lte.success');

        setTimeout(() => {
          // wait until server set the force switch state
          this.store.dispatch(lteReloadConfigAndState());
        }, 3000);
      },
      () => {
        this.mixpanel.storeEvent('LTE_FORCE_SWITCH', { use });
        this.toast.error('configurations.lte.changeFailure', 'configurations.lte.forceChangeError');
      }
    );
  }

  startApnEdit(apn: string): void {
    this.apnControl.setValue(apn);
    this.editApn = true;
  }

  cancelApnEdit(): void {
    this.editApn = false;
  }

  getPersistenceOptions(data: {
    config: 'auto' | 'enable' | 'disable';
    state: boolean;
  }): SlideTogglerItems<'auto' | 'enable' | 'disable'> {
    return [
      {
        value: 'auto',
        translation: 'auto',
        marked: false,
        selected: data.config === 'auto'
      },
      {
        value: 'enable',
        translation: 'enabled',
        marked: data.state,
        selected: data.config === 'enable'
      },
      {
        value: 'disable',
        translation: 'disabled',
        marked: !data.state,
        selected: data.config === 'disable'
      }
    ];
  }

  getLteEnabled([state, config]: [boolean, boolean]): SlideTogglerItems<boolean> {
    return [
      {
        value: true,
        translation: 'enabled',
        marked: state,
        selected: config
      },
      {
        value: false,
        translation: 'disabled',
        marked: !state,
        selected: !config
      }
    ];
  }

  persistenceEdit(value: 'enable' | 'disable' | 'auto'): void {
    this.editApn = false;

    this.lteService.putConfiguration$({ persist: value }).subscribe(
      () => {
        this.mixpanel.storeEvent('LTE_PERSISTENCE_CHANGE_ACCEPTED', { value });
        this.toast.success('configurations.lte.persistenceChangeDone', 'configurations.lte.success');

        setTimeout(() => {
          // wait until server set the force switch state
          this.store.dispatch(lteReloadConfigAndState());
        }, 3000);
      },
      (error) => {
        this.toast.error(error.error.message, error.error.error);
        this.mixpanel.storeEvent('LTE_ENABLE', { error });
      }
    );
  }

  confirmApnEdit(): void {
    if (this.apnControl.invalid) {
      return;
    }

    this.lteService.putConfiguration$({ apn: this.apnControl.value }).subscribe(
      () => {
        this.mixpanel.storeEvent('LTE_APN_CHANGE_ACCEPTED', { apn: this.apnControl.value });
        this.toast.success('configurations.lte.apnChangeDone', 'configurations.lte.success');
        this.editApn = false;

        setTimeout(() => {
          // wait until server set the force switch state
          this.store.dispatch(lteReloadConfigAndState());
        }, 3000);
      },
      (error) => {
        this.toast.error(error.error.message, error.error.error);
      }
    );
  }

  trackByNetwork(
    index: number,
    network: {
      networkId: string;
      enable: boolean;
      wan: boolean;
      toggler: {
        value: 'enabled' | 'disabled' | 'lanOnly';
        selected: boolean;
        translation: string;
      }[];
    }
  ): string {
    return network.networkId;
  }

  secondaryNetworkChangeState(
    networks: { networkId: string; enable: boolean; wan: boolean }[],
    networkId: string,
    newState: 'enabled' | 'disabled' | 'lanOnly'
  ): void {
    const updatedNetworks = networks.map((networkItem) => {
      if (networkItem.networkId === networkId) {
        switch (newState) {
          case 'enabled':
            return {
              networkId,
              enable: true,
              wan: true
            };
          case 'lanOnly':
            return {
              networkId,
              enable: true,
              wan: false
            };
          case 'disabled':
            return {
              networkId,
              enable: false,
              wan: false
            };
        }
      } else {
        return {
          networkId: networkItem.networkId,
          enable: networkItem.enable,
          wan: networkItem.wan
        };
      }
    });

    this.lteService.networkConfigurationSet$({ secondaryNetworks: updatedNetworks }).subscribe(() => {
      this.store.dispatch(lteReloadConfigAndState());
    });
  }

  networkConfigurationMapper(data: ILteSecondaryNetwork[]): {
    networkId: string;
    enable: boolean;
    wan: boolean;
    toggler: {
      value: 'enabled' | 'disabled' | 'lanOnly';
      selected: boolean;
      translation: string;
    }[];
  }[] {
    return data?.map((network) => ({
      ...network,
      toggler: [
        {
          value: 'enabled',
          selected: network.enable && network.wan,
          translation: 'enabled'
        },
        {
          value: 'lanOnly',
          selected: network.enable && !network.wan,
          translation: 'configurations.lte.lanOnly'
        },
        {
          value: 'disabled',
          selected: !network.enable,
          translation: 'disabled'
        }
      ]
    }));
  }

  forceSwitchDisabledTooltip(lteServiceStateDisabled: boolean, notAGateway: boolean, notARouterMode: boolean): string {
    if (notAGateway) {
      return 'configurations.lte.forceSwitchDisabledGateway';
    }
    if (notARouterMode) {
      return 'configurations.lte.forceSwitchDisabledRouterMode';
    }
    if (lteServiceStateDisabled) {
      return 'configurations.lte.forceSwitchDisabledServiceState';
    }
    return '';
  }
}
