import { Component, Input } from '@angular/core';
import { Line } from 'src/app/lib/d3/models/objects/line';

@Component({
  selector: '[lineVisual]',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.scss']
})
export class LineVisualComponent {
  @Input()
  lineVisual: Line;

  @Input()
  style: any = {};
}
