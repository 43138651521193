import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { AuthService } from 'src/app/lib/services/auth.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { previewClosed, previewOpened, publishOpened } from 'src/app/store/captive-portal/captive-portal.actions';
import {
  selectCaptivePortalConfig,
  selectCaptivePortalIsUprise,
  selectCaptivePortalPreviewMode,
  selectCaptivePortalPublishMode
} from 'src/app/store/captive-portal/captive-portal.selectors';

@Component({
  selector: 'captive-menu',
  templateUrl: './captive-menu.component.html',
  styleUrls: ['./captive-menu.component.scss']
})
export class CaptiveMenuComponent {
  config$ = this.store.select(selectCaptivePortalConfig);
  isUprise$ = this.store.select(selectCaptivePortalIsUprise);
  previewMode$ = this.store.select(selectCaptivePortalPreviewMode);
  publishing$ = this.store.select(selectCaptivePortalPublishMode).pipe(map((mode) => mode !== 'closed'));
  adblocker: boolean = false;
  unblock: string[] = [window.location.origin, this.auth.getEnvFromUrl().mywifi];

  constructor(private store: Store, private auth: AuthService, private toast: ToastService) {}

  openPreview(): void {
    this.validateLoginOptions((valid) => {
      if (valid) {
        this.detectAdblocker();
        this.store.dispatch(previewOpened());
      }
    });
  }

  closePreview(): void {
    this.store.dispatch(previewClosed());
  }

  publish(): void {
    this.validateLoginOptions((valid) => {
      if (valid) {
        this.store.dispatch(publishOpened());
      }
    });
  }

  validateLoginOptions(callback: (valid: boolean) => void): void {
    this.config$.pipe(take(1)).subscribe((config) => {
      const passcodeOption = config.loginOptions.options.filter((option) => option.name === 'Passcode');
      const specialCharRegex = /[&#"+]/;

      if (
        !passcodeOption.length ||
        (passcodeOption.length === 1 && passcodeOption[0].code.length && !specialCharRegex.test(passcodeOption[0].code))
      ) {
        callback(true);
      } else {
        callback(false);
        this.toast.warning('captiveportal.errorNoPasscodeMessage', 'captiveportal.errorNoPasscodeTitle');
      }
    });
  }

  detectAdblocker(): void {
    const fakeAd = document.createElement('div');
    fakeAd.className = 'textads banner-ads banner_ads ad-unit ad-zone ad-space adsbox';

    fakeAd.style.height = '1px';
    fakeAd.style.position = 'absolute';

    document.body.appendChild(fakeAd);

    if (fakeAd.offsetHeight) {
      this.adblocker = false;
    } else {
      this.adblocker = true;
    }
  }
}
