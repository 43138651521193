import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { clone, findIndex } from 'lodash';
import * as moment from 'moment';
import { PageScrollService } from 'ngx-page-scroll-core';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import {
  DeepReadonly,
  IActiveIssue,
  IDevice,
  IGuidedTroubleshootMessage,
  IGuidedTroubleshootSelectedOption,
  INode
} from 'src/app/lib/interfaces/interface';
import { AlarmedService } from 'src/app/lib/services/alarmed.service';
import { ApiService } from 'src/app/lib/services/api.service';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { DeviceIconRefService } from 'src/app/lib/services/deviceIconRef.service';
import { FirebaseService } from 'src/app/lib/services/firebase.service';
import { GuidedTroubleShootingPubnubService } from 'src/app/lib/services/guidedTroubleshooting.service';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { MessageService } from 'src/app/lib/services/message.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ModelRefService } from 'src/app/lib/services/modelref.service';
import { NeighborsService } from 'src/app/lib/services/neighbors.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { QoeService } from 'src/app/lib/services/qoe.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { selectPipeLocationOnChange } from 'src/app/store/customer/customer.selectors';
import { addMessage } from 'src/app/store/guidedTroubleshooting/guidedTroubleshooting.actions';
import { selectMessagesWithSelections } from 'src/app/store/guidedTroubleshooting/guidedTroublshooting.selector';
import {
  selectDevices,
  selectLocationAlarmReports,
  selectLocationBbe,
  selectLocationInternet,
  selectLocationQoE,
  selectLocationStability,
  selectNodes
} from 'src/app/store/polling/polling.selector';
import { NetworkperformanceComponent } from './network/networkperformance/networkperformance.component';

interface IHeathDevice extends IDevice {
  rssi: number;
  interference: number;
}

@Component({
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.scss']
})
export class HealthComponent implements OnInit, OnDestroy {
  helper: GeneralHelper = new GeneralHelper();
  subscriptions: Subscription[] = [];
  permissionSubscription: any;
  permissions: any;
  locationId: string;
  networkSection: any = null;
  qoeSection: any = null;
  alerts: any = {
    overallScore: null,
    networkstatus: {
      internet: null,
      stability: null,
      environment: null
    },
    networkperformance: {
      networkspeed: null,
      extenderspeed: null,
      deviation: null,
      coverage: null,
      qoe: {},
      connectivity: {},
      podHealthAlert: null, // device & pod QOE
      podEthernetAlert: null, // pod ethernet QoE
      deviceHealthAlert: null, // some devices have poor QOE
      deviceRSSIHistory: {} // device health alert
    },

    devices: [],
    extenders: [],
    raw: {}
  };

  thresholds: any = {
    networkSpeed: {
      bbe: 0.5, // Low Extender Broadband Efficiency
      standardDeviation: 1, // Inconsistent ISP Speed
      extenderSpeedRatio: 0.5, // Low Extender Speed for PODS
      health: 2, // of 5
      extenderTestRatio: 0.5
    },
    deviceAndPodQoe: {
      podHealth: 2,
      deviceHealth: 2
    }
  };
  messages$: Observable<IGuidedTroubleshootMessage[]> = this.store.select(selectMessagesWithSelections);
  selectedButton: string | null = null;
  appFacadeData: any = {};
  nodes: INode[] = null;
  devices: DeepReadonly<IDevice[]> = [];

  alarmCleared: boolean = false;
  alarmClearMessage: string = '';

  alarmsCheck: any = {};
  alarmsCheckComplete: boolean = false;
  alarmsCheckTimeout: any;
  show: any = {
    guidedTroubleshooting: false,
    guidedTroublshootingLanding: false
  };
  lastBBE: any = null;
  lastInternet: any = null;
  qoeResponse: any = null;
  bbePending: boolean = false;
  internetPending: boolean = false;
  showWANSaturation: boolean = false;
  fakeCount: number = 0;

  firstPoll: boolean = true;
  @ViewChild(NetworkperformanceComponent, { static: false }) networkperformanceComponent!: NetworkperformanceComponent;
  messagesSubscription: Subscription;
  networkperformance: NetworkperformanceComponent;
  deviceQoeTimer: number = null;
  isGuidedTroubleshootPubnubSubscribed: boolean = false;
  enableGuidedTroubleshootAccess: boolean = false;
  activeIssues: IActiveIssue[] = [];
  guidedTroubleshootMessages: string[] = [];
  onOpenGuidedTroubleshoot(value: boolean): void {
    this.show.guidedTroubleshooting = value;
    this.openGuidedTroubleshoot();
  }

  constructor(
    private plume: PlumeService,
    private troubleShoot: TroubleshootingService,
    private logging: LoggingService,
    private modelRef: ModelRefService,
    private guidedTroubleshoot: GuidedTroubleShootingPubnubService,
    private iconRef: DeviceIconRefService,
    private isAlarm: AlarmedService,
    private pagescroll: PageScrollService,
    private message: MessageService,
    private qoe: QoeService,
    private toast: ToastService,
    private mixpanel: MixpanelService,
    private neighbors: NeighborsService,
    private firebase: FirebaseService,
    private api: ApiService,
    private store: Store,
    private customerService: CustomerService
  ) {}

  ngOnInit(): void {
    this.mixpanel.storeEvent('HEALTH_SCREEN');
    const env = this.plume.getEnv();
    this.enableGuidedTroubleshootAccess =
      this.plume.getPermissions().guidedTroubleshootingAccess && Boolean(env.signalUrl);

    if (this.enableGuidedTroubleshootAccess) {
      this.customerService.getActiveIssues$().subscribe(
        (issues) => {
          this.activeIssues = issues;
          this.activeIssues.forEach((issue) => {
            if (issue.issueType === 'networkOffline') {
              this.mixpanel.storeEvent('GUIDED_TROUBLESHOOTING_START', { ISSUE: 'NETWORK_OFFLINE' });
              this.guidedTroubleshoot.start().subscribe({
                next: () => {
                  this.isGuidedTroubleshootPubnubSubscribed = true;
                },
                error: (error) => {
                  if (error instanceof Error) {
                    console.error('Error in Guided Troubleshoot PubNub subscription:', error);
                  }
                }
              });
            }
          });
        },
        () => {
          console.error('Error in getActiveIssues');
        }
      );
    }

    this.permissionSubscription = this.plume.permissions.subscribe((data: any) => {
      this.permissions = data;
    });

    this.showWANSaturation = this.plume.cloudVersionAbove1_88();

    this.init();
  }

  selectButton(selectedOption: IGuidedTroubleshootSelectedOption): void {
    this.selectedButton = selectedOption.selection;
    this.guidedTroubleshoot.sendSelectedButton(this.selectedButton);
    if (selectedOption.index !== undefined && selectedOption.index !== null) {
      this.store.dispatch(
        addMessage({
          message: selectedOption.message,
          selections: selectedOption.message.selections,
          selectedOption: selectedOption.selection,
          responseType: selectedOption.message.responseType
        })
      );
    }

    this.messages$.subscribe((data) => {
      data.forEach((message) => {
        if (message.responseType === 'feedback') {
          this.mixpanel.storeEvent('GUIDED_TROUBLESHOOTING_FEEDBACK_REQUEST');
        }
      });
    });
  }

  setAlarmsTimeout(): void {
    this.alarmsCheckTimeout = setTimeout(() => {
      if (!this.qoeResponse) {
        this.qoeResponse = {};
        this.mixpanel.storeEvent('HEALTH_SCREEN_EMPTY_QOE');
      }

      this.mixpanel.storeEvent('HEALTH_SCREEN_ALARMS_TIMEOUT');

      this.finalizeAlarms();
    }, 10000);
  }

  showClearedAlarm(msg: string): void {
    this.alarmClearMessage = msg;
    this.alarmCleared = true;
  }

  init(): void {
    const history = [];

    this.setAlarmsTimeout();

    for (let i = 1; i <= 96; i++) {
      history.push({
        time: moment()
          .subtract(i * 15, 'minutes')
          .format('h:mm a'),
        warning: (i > 20 && i < 30) || (i > 60 && i < 70) ? true : false,
        error: (i > 45 && i < 61) || (i > 69 && i < 100) ? true : false
      });
    }

    this.subscriptions.push(
      this.message.getMessage().subscribe((message: any) => {
        switch (message.source) {
          case 'closeNetworkSection': {
            this.networkSelector(null);
            break;
          }
        }
      })
    );

    this.subscriptions.push(
      this.store.select(selectLocationInternet).subscribe((response: any) => {
        if (response) {
          this.lastInternet = response;

          if (!this.alerts.networkstatus.internet) {
            this.alerts.networkstatus.internet = {
              network: null,
              extenders: []
            };
          }
          this.appFacadeData = response; // data used in wizard steps

          if (this.nodes) {
            this.internetConnection(response);
          } else {
            this.lastInternet = response;
            this.internetPending = true;
          }

          if (
            response.summary &&
            response.summary.onboardingStatus &&
            !['OnboardingComplete', 'PodsAdded'].includes(response.summary.onboardingStatus) &&
            !this.plume.getPermissions().uiFeatures.overrideOnboarded &&
            this.plume.getUI() !== 'noc'
          ) {
            const toastId = this.plume.getObject('onboardedToast', false);

            if (!this.toast.check(toastId)) {
              this.plume.saveObject(
                'onboardedToast',
                this.toast.warning('health.onboardingMessage', 'health.onboardingTitle', { disableTimeOut: true }),
                false
              );
            }
          }
        }
      })
    );

    this.subscriptions.push(
      this.store.select(selectLocationQoE).subscribe((response: any) => {
        if (response) {
          this.qoeResponse = response;
        }
      })
    );

    if (!this.plume.isFlexRole()) {
      this.subscriptions.push(
        this.store.select(selectLocationStability).subscribe((response: any) => {
          if (response) {
            this.stability(response);
          }
        })
      );

      this.subscriptions.push(
        this.store.select(selectLocationBbe).subscribe((response: any) => {
          if (response) {
            this.lastBBE = response;
            if (this.nodes) {
              this.bbe(response);
            } else {
              this.bbePending = true;
            }
          }
        })
      );

      this.subscriptions.push(
        this.store.select(selectLocationAlarmReports).subscribe((response: any) => {
          if (response) {
            this.coverageReport(JSON.parse(JSON.stringify(response)));
          }
        })
      );
    }

    this.subscriptions.push(
      this.store
        .select(selectDevices)
        .pipe(map((devices) => devices ?? []))
        .subscribe((devices: DeepReadonly<IDevice[]>) => {
          this.devices = devices.map((device) => ({ ...device, deviceIcon: this.iconRef.get(device.icon) + '.svg' }));
          if (!this.alerts.networkperformance.deviceHealthAlert) {
            this.alerts.networkperformance.deviceHealthAlert = false;
          }

          this.deviceQoe(devices);
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectNodes)
        .pipe(filter((nodes) => !!nodes))
        .subscribe((nodes) => {
          this.nodes = nodes.map((node: any) => {
            const nModel = this.modelRef.get(node.model);
            return {
              ...node,
              kind: {
                category: node.model || '',
                icon: nModel.icon || '',
                name: node.nickname || node.name || ''
              }
            };
          });

          if (!this.alerts.networkperformance.podHealthAlert) {
            this.alerts.networkperformance.podHealthAlert = false;
          }

          if (!this.alerts.networkperformance.podEthernetAlert) {
            this.alerts.networkperformance.podEthernetAlert = false;
          }

          this.podQoe(nodes);
          this.podEthernetQoe(nodes);
          this.environmentCongestion(nodes);

          if (nodes.length && this.firstPoll) {
            // STORE ONLY ONCE
            this.firstPoll = false;

            const now = moment.utc().valueOf();

            let newestConnectedExtender = moment.utc('1970-01-01').valueOf();
            let oldestConnectedExtender = now;
            let newestDisconnectedExtender = moment.utc('1970-01-01').valueOf();
            let oldestDisconnectedExtender = now;
            let locationOnline = false;
            let extenderOffline = false;

            nodes.forEach((node: any) => {
              if (node.connectionState === 'connected') {
                locationOnline = true;

                if (moment.utc(node.connectionStateChangeAt).valueOf() < oldestConnectedExtender) {
                  oldestConnectedExtender = moment.utc(node.connectionStateChangeAt).valueOf();
                }

                if (moment.utc(node.connectionStateChangeAt).valueOf() > newestConnectedExtender) {
                  newestConnectedExtender = moment.utc(node.connectionStateChangeAt).valueOf();
                }
              }

              if (node.connectionState === 'disconnected') {
                extenderOffline = true;

                if (moment.utc(node.connectionStateChangeAt).valueOf() < oldestDisconnectedExtender) {
                  oldestDisconnectedExtender = moment.utc(node.connectionStateChangeAt).valueOf();
                }

                if (
                  moment.utc(node.connectionStateChangeAt).valueOf() > newestDisconnectedExtender &&
                  now - moment.utc(node.connectionStateChangeAt).valueOf() > 10000
                ) {
                  newestDisconnectedExtender = moment.utc(node.connectionStateChangeAt).valueOf();
                }
              }
            });

            const durationSinceLastConnect = now - newestConnectedExtender;
            const durationSinceISPDown = now - oldestConnectedExtender;
            const durationSinceLastDisconnect = now - newestDisconnectedExtender;

            if (locationOnline) {
              if (extenderOffline) {
                this.mixpanel.storeEvent('HEALTH_UPTIME_SUMMARY', {
                  ISP_ONLINE_DURATION: Math.ceil(durationSinceISPDown / 60000),
                  EXTENDER_OFFLINE_DURATION: Math.ceil(durationSinceLastDisconnect / 60000)
                });
              } else {
                this.mixpanel.storeEvent('HEALTH_UPTIME_SUMMARY', {
                  ISP_ONLINE_DURATION: Math.ceil(durationSinceISPDown / 60000),
                  EXTENDER_ONLINE_DURATION: Math.ceil(durationSinceLastConnect / 60000)
                });
              }
            } else {
              this.mixpanel.storeEvent('HEALTH_UPTIME_SUMMARY', {
                ISP_OFFLINE_DURATION: Math.ceil(durationSinceLastDisconnect / 60000),
                EXTENDER_OFFLINE_DURATION: Math.ceil(durationSinceLastDisconnect / 60000)
              });
            }
          }

          if (this.bbePending) {
            this.bbePending = false;
            this.bbe(this.lastBBE);
          }

          if (this.internetPending) {
            this.internetPending = false;
            this.internetConnection(this.lastInternet);
          }
        })
    );

    this.environmentNeighborReports();
  }

  hideChatDialog(): void {
    this.show.guidedTroubleshooting = false;
    this.show.guidedTroubleshootingQoe = false;
  }

  reset(): void {
    this.networkSection = null;
    this.qoeSection = null;

    this.alerts = {
      overallScore: null,
      networkstatus: {
        internet: null,
        stability: null,
        environment: null
      },

      networkperformance: {
        networkspeed: null,
        extenderspeed: null,
        deviation: null,
        coverage: null,
        qoe: {},
        connectivity: {},
        podHealthAlert: null,
        podEthernetAlert: null,
        deviceHealthAlert: null,
        deviceRSSIHistory: {},
        speedAlarms: false
      },

      devices: [],
      extenders: [],
      raw: {}
    };

    this.appFacadeData = {};
    this.nodes = [];
    this.devices = [];

    this.alarmCleared = false;
    this.alarmClearMessage = '';

    this.alarmsCheck = {};
    this.alarmsCheckComplete = false;

    this.lastBBE = null;
    this.lastInternet = null;
    this.qoeResponse = null;
    this.bbePending = false;
    this.internetPending = false;

    this.logging.log('NEW LOCATION: ', this.alerts);

    this.firstPoll = true;
  }

  networkSelector(section: string): void {
    if (section === this.networkSection || section === null) {
      this.networkSection = null;
    } else {
      this.networkSection = section;
      if (
        section === 'connection' &&
        this.alerts.networkstatus.internet.network &&
        this.isGuidedTroubleshootPubnubSubscribed &&
        this.enableGuidedTroubleshootAccess
      ) {
        this.show.guidedTroublshootingLanding = true;
      } else {
        this.scrollTo('#section-' + section);
        this.mixpanel.storeEvent('HEALTH_TILE_SELECTED', { TILE: section });
      }
    }
  }

  goToDiagnostics(): void {
    this.show.guidedTroublshootingLanding = false;
    this.scrollTo('#section-' + this.networkSection);
    this.mixpanel.storeEvent('HEALTH_TILE_SELECTED', { TILE: this.networkSection });
  }

  openGuidedTroubleshoot(): void {
    this.guidedTroubleshoot.checkAndRestart();
    this.mixpanel.storeEvent('GUIDED_TROUBLESHOOTING_CHAT_WINDOW', { ISSUE: 'NETWORK_OFFLINE' });
    this.show.guidedTroubleshooting = true;
    this.show.guidedTroublshootingLanding = false;
  }
  scrollTo(selector: string): void {
    setTimeout(() => {
      const view = document.getElementById('customer-view');

      this.pagescroll.scroll({
        document: window.document,
        scrollTarget: selector,
        scrollViews: [view],
        scrollOffset: 10
      });
    }, 100);
  }

  qoeSelector(section: string): void {
    if (section === this.qoeSection) {
      this.qoeSection = null;
    } else {
      this.qoeSection = section;
    }
  }

  updateNetworkperformance(): void {
    setTimeout(() => {
      this.networkperformance.onResize();
    }, 10);
  }

  trackNetworkExtenders(index: number, extender: any): number {
    return extender.id;
  }

  internetConnection(response: any): void {
    const networkOffline = this.isAlarm.getNetworkOffline(response);

    this.alerts.networkstatus.internet = {
      network: null,
      extenders: []
    };

    if (networkOffline) {
      this.store
        .pipe(selectPipeLocationOnChange)
        .pipe(take(1))
        .subscribe((location) => {
          this.alerts.networkstatus.internet.network = {
            version: location.version,
            networkName: location.name || 'unnamed',
            firmwareVersion: location.firmwareUpgrade ? location.firmwareUpgrade.updatedAt : null,
            hasgateway: false,
            ssid: location.ssid,
            id: location.id,
            // connectionStateChangeAt: this.location.updatedAt, // TODO there is not a location Offline Since
            type: 'network',
            mode: 'network',
            kind: {
              category: (location.geoIp?.city || 'N/A') + ', ' + (location.geoIp?.country || 'N/A'),
              icon: this.modelRef.get(this.nodes[0]?.model).icon,
              name: location.name
            }
          };

          this.alerts.networkstatus.internet.network.hasgateway = false;

          this.nodes.forEach((node: any) => {
            if (this.modelRef.getGateways().indexOf(node.model) > -1) {
              this.alerts.networkstatus.internet.network.hasgateway = true;
            }
          });
        });
    } else {
      if (this.nodes.length) {
        this.nodes.forEach((node: any) => {
          const nodeModel = this.modelRef.get(node.model);
          const index = findIndex(this.alerts.networkstatus.internet.extenders, { id: node.id });

          if (node.connectionState === 'connected') {
            if (index !== -1) {
              this.logging.log('Node ' + node.id + ' status disconnect => connect', node);

              this.alerts.networkstatus.internet.extenders.splice(index, 1);
            }
          } else {
            if (index === -1) {
              this.logging.log('Node ' + node.id + ' status connect => disconnect', node);

              const workflow = node;

              workflow.kind = {
                category: node.model,
                icon: nodeModel.icon,
                name: node.nickname || node.defaultName
              };

              workflow.type = nodeModel.name;
              workflow.mode = 'node';

              if (nodeModel.manufacturer) {
                workflow.manufacturer = nodeModel.manufacturer;
              } else {
                workflow.manufacturer = null;
              }

              this.alerts.networkstatus.internet.extenders.push(workflow);
            }
          }
        });
      }
    }

    this.alerts.raw.internet = response;

    this.alarmsState('LOCATION_OFFLINE', this.alerts.networkstatus.internet.network ? true : false);
    this.alarmsState('WIFI_EXTENDER_OFFLINE', this.alerts.networkstatus.internet.extenders.length ? true : false);
  }

  calcHistory(mac: string): any {
    const now = new Date();

    this.logging.debug('<calcHistory> ', mac);
    this.logging.debug('           now=', now.valueOf());

    const history = [];

    for (let i = 1; i <= 96; i++) {
      history.push({
        time: moment()
          .subtract(i * 15, 'minutes')
          .format('h:mm a'),
        warning: (i > 20 && i < 30) || (i > 60 && i < 70) ? true : false,
        error: (i > 45 && i < 61) || (i > 69 && i < 100) ? true : false
      });
    }
    return history;
  }

  calculateHistory(rssi: any): any[] {
    const rssi2g = {};
    const rssi5g = {};

    rssi['2g']['perc25'].forEach((tick: any) => {
      if (tick.value !== null) {
        if (rssi2g[tick.timestamp] !== null) {
          rssi2g[tick.timestamp] = 0.33 * rssi2g[tick.timestamp] + 0.67 * tick.value;
        } else {
          rssi2g[tick.timestamp] = tick.value;
        }
      }
    });
    rssi['2g']['perc75'].forEach((tick: any) => {
      if (tick.value !== null) {
        if (rssi2g[tick.timestamp] !== null) {
          rssi2g[tick.timestamp] = 0.33 * tick.value + 0.67 * rssi2g[tick.timestamp];
        } else {
          rssi2g[tick.timestamp] = tick.value;
        }
      }
    });

    rssi['5g']['perc25'].forEach((tick: any) => {
      if (tick.value !== null) {
        if (rssi5g[tick.timestamp] !== null) {
          rssi5g[tick.timestamp] = 0.33 * rssi5g[tick.timestamp] + 0.67 * tick.value;
        } else {
          rssi5g[tick.timestamp] = tick.value;
        }
      }
    });
    rssi['5g']['perc75'].forEach((tick: any) => {
      if (tick.value !== null) {
        if (rssi5g[tick.timestamp] !== null) {
          rssi5g[tick.timestamp] = 0.33 * tick.value + 0.67 * rssi5g[tick.timestamp];
        } else {
          rssi5g[tick.timestamp] = tick.value;
        }
      }
    });

    const data = { ...rssi2g, ...rssi5g };

    const round = (interval: number, moment: any) => {
      const roundedMinutes = Math.floor(moment.minute() / interval) * interval;
      return moment.clone().minute(roundedMinutes).second(0);
    };

    const start = round(15, moment());

    const history = [
      {
        time: start.format('h:mm a'),
        warning: false,
        error: false
      }
    ];

    for (let i = 0; i < 96; i++) {
      start.subtract(15, 'minutes');

      const tick = {
        time: moment.utc(start).format('h:mm a'),
        warning: false,
        error: false
      };

      if (data[moment.utc(start).format('YYYY-MM-DD[T]HH:mm:ss.[000Z]')] !== null) {
        if (data[moment.utc(start).format('YYYY-MM-DD[T]HH:mm:ss.[000Z]')] > -65) {
          tick.warning = false;
          tick.error = false;
        }
        if (
          data[moment.utc(start).format('YYYY-MM-DD[T]HH:mm:ss.[000Z]')] <= -65 &&
          data[moment.utc(start).format('YYYY-MM-DD[T]HH:mm:ss.[000Z]')] >= -70
        ) {
          tick.warning = true;
          tick.error = false;
        }
        if (data[moment.utc(start).format('YYYY-MM-DD[T]HH:mm:ss.[000Z]')] < -70) {
          tick.warning = false;
          tick.error = true;
        }
      }

      history.push(tick);
    }

    return history;
  }

  createDeviceQoeAlert(devData: any): void {
    const mac = devData.mac;
    this.troubleShoot.checkRSSIHistory(mac).subscribe((rssi: any) => {
      const newAlert = clone(devData);
      newAlert.history = this.calculateHistory(rssi);

      if (newAlert && newAlert.kind && newAlert.kind.icon) {
        newAlert.icon = this.iconRef.get(newAlert.kind.icon) + '.svg';
      }

      this.logging.log('<createDeviceQoeAlert> ', newAlert);
      const index = this.alerts.devices.findIndex((device) => device.mac === newAlert.mac);
      if (index > -1) {
        this.alerts.devices[index] = newAlert;
      } else {
        this.alerts.devices.push(newAlert);
      }
    });
  }

  createPodQoeAlert(devData: any): void {
    const newAlert = clone(devData);

    newAlert.kind = {
      category: devData.model || '',
      icon: this.modelRef.get(devData.model).icon,
      name: devData.nickname || devData.name || ''
    };

    this.logging.log('<createPodQoeAlert> ', newAlert);

    this.alerts.extenders.push(newAlert);
  }

  deviceQoe(devices: DeepReadonly<IDevice[]>): void {
    if (!this.qoeResponse) {
      this.deviceQoeTimer = setTimeout(() => this.deviceQoe(devices), 100) as any as number;
    } else {
      clearTimeout(this.deviceQoeTimer);
      const alarmedDevices = [];

      this.alerts.raw.devices = devices?.map((device): IHeathDevice => {
        const newDevice: IHeathDevice = { ...(device as IDevice), rssi: null, interference: null };
        // TODO change to Device QoE alarms when available
        const isAlarmed = this.isAlarm.getDeviceQoe(newDevice);

        if (isAlarmed) {
          if (device.health && newDevice.health.details && device.health.details.channelGain) {
            newDevice.rssi = newDevice.health.details.channelGain + 20;
            newDevice.interference = newDevice.health.details.interference;
          } else {
            const deviceQoe = this.qoeResponse?.devices?.find((deviceQoe) => deviceQoe.mac === newDevice.mac) || null;

            if (deviceQoe) {
              const qoe = this.qoe.prepare(deviceQoe);
              newDevice.rssi = qoe.rssi;
              newDevice.interference = qoe.interference;
            } else {
              newDevice.rssi = null;
              newDevice.interference = null;
            }
          }

          alarmedDevices.push(newDevice);

          // if the alert is already set, then do not add the alert
          let found = false;

          this.alerts.devices.forEach((devAlert: any) => {
            if (devAlert.mac === newDevice.mac) {
              this.logging.info('ALERT SUPPRESSED: deviceQoe', devAlert);
              found = true;
            }
          });

          if (!found) {
            this.alerts.networkperformance.deviceHealthAlert = true;
            this.createDeviceQoeAlert(newDevice);
          }
        } else {
          // if (alert) is NOT set, and IS in alert list, then remove it

          for (let i = 0; i < this.alerts.devices; i++) {
            const devAlert = this.alerts.devices[i];
            if (devAlert.mac === newDevice.mac) {
              this.logging.info('ALERT CLEARED: deviceQoe', devAlert);
              this.alerts.networkperformance.deviceHealthAlert = false;
              this.alerts.devices.splice(i, 1);
            }
          }
        }
        return newDevice;
      });

      this.alerts.raw.alarmedDevices = alarmedDevices;

      this.alarmsState('DEVICE_QOE_ALERT', this.alerts.networkperformance.deviceHealthAlert);
    }
  }

  podQoe(nodes: any): void {
    if (!this.qoeResponse) {
      setTimeout(() => this.podQoe(nodes), 100);
    } else {
      if (nodes && nodes.length > 0) {
        nodes.map((node: any) => {
          node = { ...node };
          const isAlarmed = this.isAlarm.getPodQoe(node);

          if (isAlarmed) {
            if (node.health && node.health.details && node.health.details.channelGain) {
              node.rssi = node.health.details.channelGain + 20;
              node.interference = node.health.details.interference;
            } else {
              const nodeQoe = this.qoeResponse?.nodes?.find((nodeQoe: any) => nodeQoe.id === node.id) || null;

              if (nodeQoe) {
                const qoe = this.qoe.prepare(nodeQoe);
                node.rssi = qoe.rssi;
                node.interference = qoe.interference;
              } else {
                node.rssi = null;
                node.interference = null;
              }
            }

            let found = false;

            this.alerts.extenders.forEach((extAlert: any) => {
              if (extAlert.id === node.id) {
                this.logging.info('ALERT SUPPRESSED: podQOE', extAlert);
                found = true;
              }
            });

            if (!found) {
              this.alerts.networkperformance.podHealthAlert = true;
              this.createPodQoeAlert(node);
            }
          } else {
            for (let i = 0; i < this.alerts.extenders; i++) {
              const extAlert = this.alerts.extenders[i];

              if (extAlert.id === node.id) {
                this.logging.info('ALERT CLEARED: deviceQoe', extAlert);
                this.alerts.networkperformance.podHealthAlert = false;
                this.alerts.extenders.splice(i, 1);
              }
            }
          }

          return node;
        });
      }

      this.alerts.raw.nodes = nodes;

      this.alarmsState('POD_QOE_ALERT', this.alerts.networkperformance.podHealthAlert);
    }
  }

  podEthernetQoe(nodes: INode[]): void {
    this.alerts.raw.ethernet = [];

    if (nodes && nodes.length > 0) {
      nodes.map((node) => {
        const isAlarmed = this.isAlarm.getEthernetUplink(node);

        if (isAlarmed) {
          this.alerts.networkperformance.podEthernetAlert = true;

          this.alerts.raw.ethernet.push(isAlarmed);
        }
      });
    }

    this.alarmsState('POD_ETHERNET_ALERT', this.alerts.networkperformance.podEthernetAlert);
  }

  anyAlertsSpeedtest(): boolean {
    if (
      this.alerts.networkperformance.extenderspeed.length > 0 ||
      this.alerts.networkperformance.networkspeed.length > 0 ||
      (this.alerts.networkperformance.deviation && this.alerts.networkperformance.deviation.status === true)
    ) {
      return true;
    } else {
      return false;
    }
  }

  bbe(response: any): void {
    const msgNodes = response.nodes;
    const msgSummary = response.summary;

    if (!this.alerts.networkperformance.networkspeed) {
      this.alerts.networkperformance.networkspeed = [];
    }
    if (!this.alerts.networkperformance.extenderspeed) {
      this.alerts.networkperformance.extenderspeed = [];
    }
    if (!this.alerts.networkperformance.deviation) {
      this.alerts.networkperformance.deviation = {};
    }

    this.alerts.raw.bbe = response;

    // Low extender speed
    const broadbandDownloadSpeed = msgSummary.twentyFourHours.broadbandDownloadSpeed.average;

    msgNodes.forEach((mNode: any) => {
      const node = this.findNodeById(mNode.nodeId);

      if (mNode && mNode.broadbandEfficiency) {
        const lowextenderAlarmed = this.isAlarm.getLowExtenderSpeed(mNode, msgSummary, node);
        let found = false;

        if (lowextenderAlarmed) {
          this.alerts.networkperformance.networkspeed.forEach((alert: any) => {
            if (mNode.nodeId === alert.id) {
              found = true;
              this.logging.info('ALERT SUPPRESSED: networkSpeed', mNode);
            }
          });

          if (!found) {
            this.nodes.forEach((node: any) => {
              if (node.id === mNode.nodeId && node.connectionState === 'connected') {
                const model = this.modelRef.get(node.model);
                const newAlert = clone(node);
                newAlert.broadbandDownloadSpeed = broadbandDownloadSpeed;
                newAlert.wifiDownloadSpeed = mNode.wifiDownloadSpeed.average;
                newAlert.bbe = mNode;
                newAlert.firmwareVersion = node.firmwareVersion;
                newAlert.model = node.model;
                newAlert.kind = {
                  category: model.name,
                  icon: model.icon,
                  name: node.nickname || node.defaultName || 'unnamed'
                };

                this.alerts.networkperformance.networkspeed.push(newAlert);
                this.logging.log('<createBBEAlert>', newAlert);
              }
            });
          }
        } else {
          // remove alarm if NOT set
          if (this.alerts.networkperformance.networkspeed && this.alerts.networkperformance.networkspeed.length > 0) {
            for (let i = 0; i < this.alerts.networkperformance.networkspeed.length; i++) {
              //  this.logging.log(" checkclear ===> ",this.alerts.networkperformance.extenderspeed[i].id,mNode.nodeId);
              if (this.alerts.networkperformance.networkspeed[i].id === mNode.nodeId) {
                // clear alarm
                this.logging.log('<networkSpeed> clear', this.alerts.networkperformance.networkspeed[i]);
                this.alerts.networkperformance.networkspeed.splice(i, 1);
              }
            }
          }
        }
      }
    });

    // Inconsistent ISP Speed
    const gatewayIdList: string[] = this.nodes
      .filter((node: INode) => this.helper.isGateway(node.id, this.nodes))
      .map((gateway: INode) => gateway.id);
    const inconsistentSpeedAlarm = this.isAlarm.getInconsistentSpeedAlarm(msgSummary);

    if (inconsistentSpeedAlarm === true) {
      this.alerts.networkperformance.deviation = {
        nodes: msgNodes,
        status: true,
        deviation: msgSummary.twentyFourHours.broadbandDownloadSpeed.standardDeviation,
        threshold: this.thresholds.networkSpeed.standardDeviation,
        gatewayIdList
      };
    }

    // BBE ALARM

    msgNodes.forEach((mNode: any) => {
      const node = this.findNodeById(mNode.nodeId);
      if (!this.isAlarm.getLowExtenderSpeed(mNode, msgSummary, node)) {
        const alarmSet = this.isAlarm.getBbeAlarm(mNode, msgSummary, node);

        if (alarmSet) {
          let found = false;
          this.alerts.networkperformance.extenderspeed.forEach((alert: any) => {
            if (mNode.nodeId === alert.id) {
              found = true;
            }
          });

          if (!found) {
            this.nodes.forEach((node: any) => {
              if (node.id === mNode.nodeId) {
                const model = this.modelRef.get(node.model);
                const newAlert = clone(node);
                newAlert.alert = mNode;
                newAlert.bbe = mNode;
                newAlert.kind = {
                  category: model.name,
                  icon: model.icon,
                  name: node.nickname || node.defaultName || 'unnamed'
                };

                this.alerts.networkperformance.extenderspeed.push(newAlert);
              }
            });
          }
        } else {
          // remove alarm if NOT set
          if (this.alerts.networkperformance.extenderspeed && this.alerts.networkperformance.extenderspeed.length > 0) {
            for (let i = 0; i < this.alerts.networkperformance.extenderspeed.length; i++) {
              if (this.alerts.networkperformance.extenderspeed[i].id === mNode.nodeId) {
                // clear alarm
                this.logging.log('<extenderspeed> clear', this.alerts.networkperformance.extenderspeed[i]);
                this.alerts.networkperformance.extenderspeed.splice(i, 1);
              }
            }
          }
        }
      }
    });

    const thisSpeed = this.anyAlertsSpeedtest();

    // if there were speed alarms, and alarms are now clear
    if (thisSpeed === false && this.alerts.networkperformance.speedAlarms === true) {
      this.showClearedAlarm('health.alarmMessageNetworkSpeed');
    }

    this.alerts.networkperformance.speedAlarms = clone(thisSpeed);

    this.alarmsState('INCONSISTENT_ISP_SPEED', inconsistentSpeedAlarm);
    this.alarmsState('INCONSISTENT_ISP_SPEED_MULTIPLE_GATEWAYS', inconsistentSpeedAlarm && gatewayIdList.length > 1);
    this.alarmsState('LOW_EXTENDER_SPEED', this.alerts.networkperformance.networkspeed.length ? true : false);
    this.alarmsState(
      'LOW_EXTENDER_BROADBAND_EFFICIENCY',
      this.alerts.networkperformance.extenderspeed.length ? true : false
    );
  }

  stability(response: any): void {
    this.logging.log('<stability>', response);

    this.alerts.networkstatus.stability = {
      high: null,
      failed: null
    };

    this.alerts.raw.stability = response;

    const highOptimizations = this.isAlarm.getStability(response, 'high');
    const failedOptimizations = this.isAlarm.getStability(response, 'failed');

    if (highOptimizations) {
      this.alerts.networkstatus.stability.high = {
        icon: 'icon-topology',
        name: 'health.networkStatus.stabilityAlarm.highOptimizationsAlarmTitle',
        message: 'health.networkStatus.stabilityAlarm.highOptimizationsAlarmMessage',
        history: null
      };
    }

    if (failedOptimizations) {
      this.alerts.networkstatus.stability.failed = {
        icon: 'icon-topology',
        name: 'health.networkStatus.stabilityAlarm.failedOptimizationsAlarmTitle',
        message: 'health.networkStatus.stabilityAlarm.failedOptimizationsAlarmMessage',
        history
      };
    }

    this.alarmsState('HIGH_OPTIMIZATIONS', highOptimizations);
    this.alarmsState('FAILED_OPTIMIZATIONS', failedOptimizations);
  }

  environmentCongestion(nodes: any[]): void {
    this.logging.log('<environmentCongestion>', nodes);

    const requests = {};

    nodes.forEach((node: any) => {
      requests[node.id] = this.api
        .get(
          '/Customers/' +
            this.plume.customerid +
            '/locations/' +
            this.plume.locationid +
            '/nodes/' +
            node.id +
            '/utilizationMetrics?limit=60&granularity=minutes',
          'reports'
        )
        .pipe(catchError((error: any) => of(error)));
    });

    forkJoin(requests).subscribe((responses: any) => {
      const alarms = [];
      const congestion = [];

      for (const key of Object.keys(responses)) {
        if (responses[key] instanceof HttpErrorResponse) {
          break;
        }

        const alarmed = this.isAlarm.getHighEnvironmentCongestion(responses[key]);
        const node = { ...nodes.find((node: any) => node.id === key) };
        const model = this.modelRef.get(node.model);
        node.icon = model.icon;

        if (alarmed) {
          congestion.push({ node, data: alarmed, alarmed: true });
          alarms.push(alarmed);
        } else {
          congestion.push({ node, data: responses[key], alarmed: false });
        }
      }

      congestion.sort((a: any, b: any) => b.alarmed - a.alarmed);

      this.alerts.raw.congestion = congestion;
      this.alerts.raw.congestionNonEmpty = congestion.filter((item) => Object.keys(item.data).length > 1); // ignore statsDateRange key in item.data

      if (alarms.length) {
        if (this.alerts.networkstatus.environment?.highEnvironmentCongestion) {
          congestion.forEach((node: any) => {
            const oldNode = this.alerts.networkstatus.environment.highEnvironmentCongestion.find(
              (oldNode: any) => node.node.id === oldNode.node.id
            );

            if (oldNode) {
              if (oldNode.show) {
                node.show = true;
              }

              if (oldNode.chartSeries) {
                node.chartSeries = oldNode.chartSeries;
              }

              if (oldNode.chartData) {
                node.chartData = oldNode.chartData;
              }
            }
          });
        }

        this.logging.log('environmentCongestion - HIGH');
        this.alerts.networkstatus.environment = {
          ...this.alerts.networkstatus.environment,
          ...{ highEnvironmentCongestion: congestion }
        };
      } else {
        if (this.alerts.networkstatus.environment?.highEnvironmentCongestion) {
          const nodes = Object.keys(responses);
          let count = 0;

          nodes.forEach((id: string) => {
            if (Object.keys(responses[id]).length <= 1) {
              count++;
            }
          });

          if (nodes.length !== count) {
            this.alerts.networkstatus.environment = {
              ...this.alerts.networkstatus.environment,
              ...{ highEnvironmentCongestion: null }
            };
          }
        } else {
          this.alerts.networkstatus.environment = {
            ...this.alerts.networkstatus.environment,
            ...{ highEnvironmentCongestion: null }
          };
        }
      }

      this.alarmsState('HIGH_CONGESTION', alarms.length ? true : false);
    });
  }

  environmentNeighborReports(): void {
    const permissions = this.plume.getPermissions();

    if (permissions.uiFeatures.neighborsHealthCheck) {
      this.neighbors.getNeighbors((response: any) => {
        const { timestamp, networks, evilTwins, parallelNetworks, hasTwins, hasParallel } = response;
        this.alerts.networkstatus.environment = {
          ...this.alerts.networkstatus.environment,
          ...{ neighborReports: null }
        };

        if (hasTwins || hasParallel) {
          this.alerts.networkstatus.environment.neighborReports = {};
        }

        if (hasTwins) {
          this.logging.log('environmentNeighborReports - evilTwin');
          this.alerts.networkstatus.environment.neighborReports.neighbors = networks;
          this.alerts.networkstatus.environment.neighborReports.timestamp = timestamp;
          this.alerts.networkstatus.environment.neighborReports.evilTwins = evilTwins;
        }

        if (hasParallel) {
          this.logging.log('environmentNeighborReports - parallelNetwork');
          this.alerts.networkstatus.environment.neighborReports.neighbors = networks;
          this.alerts.networkstatus.environment.neighborReports.timestamp = timestamp;
          this.alerts.networkstatus.environment.neighborReports.parallelNetworks = parallelNetworks;
        }

        this.alerts.raw.twins = evilTwins;
        this.alerts.raw.parallel = parallelNetworks;

        this.alarmsState('EVIL_TWINS', hasTwins);
        this.alarmsState('PARALLEL_NETWORKS', hasParallel);
      });
    }
  }

  coverageReport(response: any): void {
    this.logging.log('<coverageReport> ', response);

    const isAlarmed = this.isAlarm.getCoverageReport(response);
    this.alerts.networkperformance.coverage = isAlarmed;
    this.alerts.raw.coverage = response;

    this.alarmsState('COVERAGE', isAlarmed);
  }

  alarmsState(alarm: string, state: boolean): void {
    if (this.alarmsCheckComplete || (alarm === 'LOCATION_OFFLINE' && state)) {
      this.calculateOverallScore();
    }

    if (!this.alarmsCheck[alarm]) {
      this.alarmsCheck[alarm] = state;
    }

    const allAlarms = Object.keys(this.alarmsCheck);

    if (!this.alarmsCheckComplete && allAlarms.length >= 14 && alarm !== 'INCONSISTENT_ISP_SPEED_MULTIPLE_GATEWAYS') {
      clearTimeout(this.alarmsCheckTimeout);
      this.alarmsCheckComplete = true;
      this.finalizeAlarms();
    }
  }

  finalizeAlarms(): void {
    const activeAlarms = {};

    if (this.alarmsCheck['LOCATION_OFFLINE']) {
      activeAlarms['LOCATION_OFFLINE'] = true;
    } else {
      Object.keys(this.alarmsCheck).forEach((alarm: any) => {
        if (this.alarmsCheck[alarm]) {
          activeAlarms[alarm] = true;
        }
      });
    }

    this.calculateOverallScore();
    this.mixpanel.storeEvent('INITIAL_LOCATION_SCORE', { OVERALL_SCORE: this.alerts.overallScore });

    if (Object.keys(activeAlarms).length) {
      this.mixpanel.storeEvent('HEALTH_ALARM_DISPLAYED', activeAlarms);
    }
  }

  calculateOverallScore(): void {
    let score = 0;

    if (!this.alerts.networkstatus.internet?.network) {
      // Node offline
      const nodesTotal = this.alerts.raw.nodes?.length || 0;
      const nodesOffline = this.alerts.networkstatus.internet?.extenders?.length || 0;
      const nodeOffline = nodesOffline
        ? this.firebase.snapshot.rules.health.nodeOffline -
          this.firebase.snapshot.rules.health.nodeOffline * Math.pow(1 - nodesOffline / (nodesTotal - 1), nodesOffline)
        : 0;

      // Pods in Qoe alarm
      const podsTotal = this.alerts.raw.nodes?.filter((node: any) => node.connectionState === 'connected').length || 0;
      const podsQoe = this.alerts.extenders.length;
      const podsQoeAlarm = podsQoe
        ? this.firebase.snapshot.rules.health.podsQoeAlarm -
          this.firebase.snapshot.rules.health.podsQoeAlarm * Math.pow(1 - podsQoe / podsTotal, podsQoe)
        : 0;

      // Pods in Ethernet alarm
      const podsEthernetAlarm = this.alerts.raw.ethernet?.length
        ? this.firebase.snapshot.rules.health.podsEthernetAlarm
          ? this.firebase.snapshot.rules.health.podsEthernetAlarm
          : 0
        : 0;

      // Devices in Qoe alarm
      const devicesTotal =
        this.alerts.raw.devices?.filter((device: any) => device.connectionState === 'connected').length || 0;
      const devicesQoe = this.alerts.raw.alarmedDevices?.length || 0;
      const devicesQoeAlarm = devicesQoe
        ? this.firebase.snapshot.rules.health.devicesQoeAlarm -
          this.firebase.snapshot.rules.health.devicesQoeAlarm *
            Math.pow(1 - Math.min(devicesQoe, 3) / Math.min(devicesTotal, 3), devicesQoe)
        : 0;

      // High optimizations
      const highOptimizations =
        this.alerts.raw.stability?.total > 8 ? this.firebase.snapshot.rules.health.highOptimizations : 0;

      // failed optimizations
      const failedOptimizations =
        this.alerts.raw.stability?.failed > 4 ? this.firebase.snapshot.rules.health.failedOptimizations : 0;

      // Coverage
      const coverage = this.alerts.networkperformance?.coverage ? this.firebase.snapshot.rules.health.coverage : 0;

      // Congestion (Thresholds and Simple)
      const anyNodeWithHighCongestionAlarm: boolean = this.alerts.raw.congestion?.length
        ? this.alerts.raw.congestion
            .map((node: any) => node.alarmed)
            .reduce((locationAlarmed: boolean, currentNodeAlarmed: boolean) => locationAlarmed || currentNodeAlarmed)
        : false;
      const nodeInterference = this.alerts.raw.congestion?.length
        ? this.alerts.raw.congestion
            .map(
              (node: any) =>
                Math.max(
                  parseFloat(node.data?.['2.4G']?.[0]?.avgutilization || 0),
                  parseFloat(node.data?.['5GL']?.[0]?.avgutilization || 0),
                  parseFloat(node.data?.['5GU']?.[0]?.avgutilization || 0),
                  parseFloat(node.data?.['5GL']?.[0]?.avgutilization || 0),
                  parseFloat(node.data?.['5G']?.[0]?.avgutilization || 0),
                  parseFloat(node.data?.['6G']?.[0]?.avgutilization || 0)
                ) / 100
            )
            .reduce((max: number, val: number) => (max > val ? max : val))
        : 0;
      const congestion =
        this.firebase.snapshot.rules.health.congestion *
        Math.max(nodeInterference || 0, anyNodeWithHighCongestionAlarm ? 1 : 0);

      // Location offline in 24h
      const lastChange = this.alerts.raw.internet?.summary?.gatewayConnectionStateChangeAt || null;
      const locationOffline24h =
        lastChange && moment(lastChange).valueOf() >= Date.now() - 24 * 60 * 60 * 1000
          ? this.firebase.snapshot.rules.health.locationOffline24h
          : 0;

      // Worst Devices QoE score
      const nodesOnline =
        this.alerts.raw.nodes?.filter(
          (node: any) => node.connectionState === 'connected' && node.backhaulType === 'wifi' && node.health
        ) || [];
      const nodesScore = nodesOnline.length
        ? nodesOnline
            .map((node: any) => node.health.score)
            .reduce((min: number, val: number) => (min < val ? min : val))
        : 0;
      const nodesQoeScore = nodesScore
        ? this.firebase.snapshot.rules.health.nodesQoeScore * ((4 - (nodesScore - 1)) / 4)
        : 0;

      // Worst Devices QoE score
      const devicesOnline =
        this.alerts.raw.devices?.filter(
          (device: any) => device.connectionState === 'connected' && device.medium === 'wifi' && device.health
        ) || [];
      const devicesScore = devicesOnline.length
        ? devicesOnline
            .map((device: any) => parseFloat(device.health.score))
            .reduce((min: number, val: number) => (min < val ? min : val))
        : 0;
      const devicesQoeScore = devicesScore
        ? this.firebase.snapshot.rules.health.devicesQoeScore * ((4 - (devicesScore - 1)) / 4)
        : 0;

      // Speed test alarms
      const speedAlarm =
        this.alerts.networkperformance.networkspeed?.length ||
        this.alerts.networkperformance.extenderspeed?.length ||
        this.alerts.networkperformance.deviation?.status
          ? this.firebase.snapshot.rules.health.speedAlarm
          : 0;

      // Twin
      const evilTwins = this.alerts.networkstatus.environment?.neighborReports?.evilTwins?.length
        ? this.firebase.snapshot.rules.health.evilTwins
        : 0;

      // Parallel network
      const parallelNetworks = this.alerts.networkstatus.environment?.neighborReports?.parallelNetworks?.length
        ? this.firebase.snapshot.rules.health.parallelNetworks
        : 0;

      const total =
        nodeOffline +
        podsQoeAlarm +
        podsEthernetAlarm +
        devicesQoeAlarm +
        highOptimizations +
        failedOptimizations +
        coverage +
        congestion +
        locationOffline24h +
        nodesQoeScore +
        devicesQoeScore +
        speedAlarm +
        evilTwins +
        parallelNetworks;

      const debugTotalStats = {
        NODE_OFFLINE: nodeOffline,
        PODS_QOE_ALARM: podsQoeAlarm,
        POD_ETHERNET_ALARM: podsEthernetAlarm,
        DEVICES_QOE_ALARM: devicesQoeAlarm,
        HIGH_OPTIMIZATIONS: highOptimizations,
        FAILED_OPTIMIZATIONS: failedOptimizations,
        COVERAGE: coverage,
        CONGESTION: congestion,
        LOCATION_OFFLINE_DAY: locationOffline24h,
        NODES_QOE_SCORE: nodesQoeScore,
        DEVICE_QOE_SCORE: devicesQoeScore,
        SPEED_ALARM: speedAlarm,
        EVIL_TWINS: evilTwins,
        PARALLEL_NETWORKS: parallelNetworks
      };

      if (total > 100) {
        this.mixpanel.storeEvent('INITIAL_LOCATION_SCORE_ERROR', debugTotalStats);
      }

      score = Math.trunc(Math.min(100, Math.max(0, 100 - total)));

      if (this.firebase.getDeployment() === 'development') {
        this.logging.log(`HEALTH OVERALL SCORE (100 - TOTAL) = ${score}; TOTAL = ${total}`, debugTotalStats);
        for (const [key, value] of Object.entries(debugTotalStats)) {
          this.logging.log(`HEALTH SCORE DEBUG, ${key}, ${value}`);
        }
        this.mixpanel.storeEvent('INITIAL_LOCATION_SCORE_DEBUG', debugTotalStats);
      }
    }

    this.alerts.overallScore = score;
  }

  findNodeById(id: string): any {
    if (!this.nodes || !(this.nodes.length > 0)) {
      this.logging.error('<findNodeById> Attempt to find ', id, ' prior to polling of nodes');
    } else {
      const index = findIndex(this.nodes, { id });
      if (index > -1) {
        return this.nodes[index];
      } else {
        this.logging.warn('Unable to find node ', id, ' in nodePool');
        return null;
      }
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this.alarmsCheckTimeout);

    if (this.subscriptions.length) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    if (this.permissionSubscription) {
      this.permissionSubscription.unsubscribe();
    }

    if (this.messagesSubscription) {
      this.messagesSubscription.unsubscribe();
    }

    if (this.guidedTroubleshoot) {
      this.guidedTroubleshoot.stop();
    }
  }
}
