<svg>
  <defs>
    <linearGradient id="linechart-gradient" x1="0" x2="0" y1="0" y2="1">
      <stop class="stop1" offset="0%" />
      <stop class="stop2" offset="100%" />
    </linearGradient>
    <filter id="linechart-shadow" x="-10%" y="-1%" width="120%" height="110%">
      <feGaussianBlur in="SourceAlpha" stdDeviation="1" result="blur" />
      <feOffset in="blur" dx="0" dy="1" result="offsetBlur" />
      <feFlood flood-color="#000000" flood-opacity="0.2" result="offsetColor" />
      <feComposite in="offsetColor" in2="offsetBlur" operator="in" result="offsetComposite" />
      <feBlend in="SourceGraphic" in2="offsetComposite" mode="normal" />
    </filter>
  </defs>
  <svg:g [attr.transform]="'translate(' + margins.left + ',' + margins.top + ')'">
    <svg:g *ngIf="options.xaxis">
      <svg:g [attr.class]="tick.class" [tickVisual]="tick" *ngFor="let tick of xAxis"></svg:g>
    </svg:g>
    <svg:rect
      *ngFor="let region of regions"
      class="region"
      [class.hover]="region.hover"
      [attr.x]="region.x"
      [attr.y]="region.y - 5"
      [attr.width]="region.width"
      [attr.height]="region.height + 10"
    ></svg:rect>
    <svg:g *ngIf="options.yaxisleft">
      <svg:g [attr.class]="tick.class" [tickVisual]="tick" *ngFor="let tick of yAxisLeft"></svg:g>
    </svg:g>
    <svg:g *ngIf="options.yaxisright">
      <svg:g [attr.class]="tick.class" [tickVisual]="tick" *ngFor="let tick of yAxisRight"></svg:g>
    </svg:g>
    <svg:g
      [attr.class]="line.class"
      [lineVisual]="line"
      [style]="{ stroke: line.series.color, 'stroke-width': 2 }"
      *ngFor="let line of lines"
    ></svg:g>
    <svg:g *ngFor="let line of lines">
      <svg:circle
        *ngFor="let dot of line.dots"
        [class.show]="dot.show"
        [attr.class]="dot.class"
        [attr.fill]="dot.series.color"
        [attr.r]="dot.show ? dot.r : dot.r / 2"
        [attr.cx]="dot.cx"
        [attr.cy]="dot.cy"
      ></svg:circle>
    </svg:g>
  </svg:g>
</svg>
<div class="tooltips" [style.left.px]="margins.left" (click)="onClick()">
  <div
    *ngFor="let tooltip of tooltips"
    class="region"
    [ngStyle]="tooltip.region"
    (mouseenter)="showTooltip(tooltip, true)"
    (mouseleave)="showTooltip(tooltip, false)"
  >
    <div class="info" [ngStyle]="tooltip.style" *ngIf="tooltip.show">
      <div class="date">{{ tooltip.date }}</div>
      <div class="data" *ngFor="let data of tooltip.data">
        <div class="circle" [style.backgroundColor]="data.color"></div>
        {{ data.series | translate }}: &nbsp;<b>{{ data.value }}</b> &nbsp;{{ scale.symbol }}
      </div>
    </div>
  </div>
</div>
