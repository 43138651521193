import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl } from '@angular/forms';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { Store } from '@ngrx/store';
import { selectCapabilities } from 'src/app/store/customer/capabilities.selector';
import { map, tap } from 'rxjs/operators';
import { NetworkConfigurationService } from 'src/app/lib/services/network-configuration.service';
import { IDns } from 'src/app/lib/interfaces/interface';

@Component({
  selector: 'dns',
  templateUrl: './dns.component.html',
  styleUrls: ['./dns.component.scss']
})
export class DnsComponent implements OnInit, OnChanges {
  expand: boolean = false;
  dataExpand: boolean = false;
  dns: Partial<IDns> = {};
  editPrimaryDns: boolean = false;
  primaryDns: UntypedFormControl = new UntypedFormControl('');
  editSecondaryDns: boolean = false;
  secondaryDns: UntypedFormControl = new UntypedFormControl('');
  dnsServersCapable$ = this.store.select(selectCapabilities).pipe(
    map((capabilities) => capabilities?.dnsServers?.capable),
    tap((capable) => this.registerFilter(capable))
  );
  capabilities$ = this.store.select(selectCapabilities);

  @Input()
  open: number = 0;

  @Output()
  toggle = new EventEmitter<boolean>();

  @Output()
  filter = new EventEmitter<{ section: 'dns'; property: string; translation: string }>();

  @Output()
  clearFilter = new EventEmitter<{ section: string }>();

  constructor(
    public plume: PlumeService,
    private mixpanel: MixpanelService,
    private toast: ToastService,
    private translate: TranslateService,
    private store: Store,
    private networkConfigurationService: NetworkConfigurationService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.expand = changes.open.currentValue;
  }

  init(): void {
    this.getDnsServers();
  }

  getDnsServers(): void {
    this.networkConfigurationService.dnsServers$().subscribe((response) => {
      if (response) {
        this.dns = response;
        this.initItems();
      }
    });
  }

  registerFilter(dnsServersCapable: boolean): void {
    this.clearFilter.emit({ section: 'dns' });

    if (dnsServersCapable) {
      this.translate
        .get('configurations.dns.primaryDns')
        .subscribe((translated: string) =>
          this.filter.emit({ section: 'dns', property: 'primaryDns', translation: translated })
        );

      this.translate
        .get('configurations.dns.secondaryDns')
        .subscribe((translated: string) =>
          this.filter.emit({ section: 'dns', property: 'secondaryDns', translation: translated })
        );

      this.translate
        .get('configurations.dns.dnsSource')
        .subscribe((translated: string) =>
          this.filter.emit({ section: 'dns', property: 'dnsSource', translation: translated })
        );

      this.translate
        .get('configurations.dns.dnsData')
        .subscribe((translated: string) =>
          this.filter.emit({ section: 'dns', property: 'dnsData', translation: translated })
        );
    }
  }

  initItems(): void {
    if (this.dns) {
      this.primaryDns.setValue(this.dns.primaryDns);
      this.secondaryDns.setValue(this.dns.secondaryDns);
    }
  }

  updateDnsServer(primarydns: string, secondarydns: string): void {
    this.networkConfigurationService
      .updateDnsServers$({
        primaryDns: primarydns,
        secondaryDns: secondarydns
      })
      .subscribe(
        (response) => {
          this.dns = response;
          setTimeout(() => this.getDnsServers(), 1000); // slow sync due to FRNT-1743
          this.mixpanel.storeEvent('CONFIGURATION_DNS_SET');
          this.initItems();
          this.toast.success('configurations.dns.dnsSet', 'header.success');
        },
        (error) => {
          this.mixpanel.storeEvent('CONFIGURATION_DNS_EDIT_ERROR');
          this.toast.error(error.error.error.message, 'header.failed');
          this.initItems();
        }
      );
  }

  toggleExpand(): void {
    this.toggle.emit(!this.expand);

    if (!this.expand) {
      this.mixpanel.storeEvent('CONFIGURATION_DNS_SERVERS_SCREEN');
      this.initItems();
    }
  }

  toggleDataExpand(): void {
    this.dataExpand = !this.dataExpand;
  }

  enablePrimaryDnsEdit(): void {
    this.editPrimaryDns = true;
  }

  confirmPrimaryDnsEdit(): void {
    this.editPrimaryDns = false;
    this.updateDnsServer(this.primaryDns.value, this.dns.secondaryDns);
  }

  cancelPrimaryDnsEdit(): void {
    this.editPrimaryDns = false;
    this.primaryDns.setValue(this.dns.primaryDns);
  }

  enableSecondaryDnsEdit(): void {
    this.editSecondaryDns = true;
  }

  confirmSecondaryDnsEdit(): void {
    this.editSecondaryDns = false;
    this.updateDnsServer(this.dns.primaryDns, this.secondaryDns.value);
  }

  cancelSecondaryDnsEdit(): void {
    this.editSecondaryDns = false;
    this.secondaryDns.setValue(this.dns.secondaryDns);
  }
}
