import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { ApiService } from 'src/app/lib/services/api.service';
import { AuthService } from 'src/app/lib/services/auth.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';

@Component({
  templateUrl: './supporttools.component.html',
  styleUrls: ['./supporttools.component.scss']
})
export class SupportToolsComponent implements OnInit {
  logPullFormControl: UntypedFormControl = new UntypedFormControl();
  loadingLogPull: boolean = false;
  helper: GeneralHelper = new GeneralHelper();

  constructor(
    private plume: PlumeService,
    private auth: AuthService,
    private api: ApiService,
    private mixpanel: MixpanelService,
    private toast: ToastService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (!this.plume.isStrictAdminRole()) {
      this.router.navigate(['/']);
    }
  }

  downloadLogPull(): void {
    if (this.logPullFormControl.value?.length) {
      this.mixpanel.storeEvent('LOGPULL_DOWNLOAD');

      this.loadingLogPull = true;

      this.api
        .raw('GET', this.logPullFormControl.value, {}, { headers: { ...this.auth.getHeaders(undefined, true) } })
        .subscribe(
          (response: any) => {
            const nodeLength = Object.keys(response.podFilenameMap).length;
            let requestLength = 0;

            for (const nodeId of Object.keys(response.podFilenameMap)) {
              const logUrl = response.podFilenameMap[nodeId];
              const headers = {
                responseType: 'blob',
                headers: {
                  Accept: 'application/octet-stream',
                  'Content-Type': 'application/octet-stream',
                  ...this.auth.getHeaders(undefined, true)
                }
              };

              this.api.raw('get', logUrl + '?' + this.auth.getParams(), {}, headers).subscribe(
                (response: any) => {
                  const binaryData = [];
                  binaryData.push(response);

                  const blob = new Blob(binaryData, { type: response.type });
                  const filename = logUrl.substring(logUrl.lastIndexOf('/') + 1);

                  this.helper.download(blob, filename);

                  requestLength++;

                  if (requestLength >= nodeLength) {
                    this.loadingLogPull = false;
                  }
                },
                () => {
                  this.toast.error(
                    'configurations.utilities.history.downloadErrorToastMessage',
                    'configurations.utilities.history.downloadErrorToastTitle',
                    { params: { nodeId } }
                  );

                  requestLength++;

                  if (requestLength >= nodeLength) {
                    this.loadingLogPull = false;
                  }
                }
              );
            }
          },
          () => {
            this.loadingLogPull = false;
            this.toast.error(
              'configurations.utilities.history.downloadErrorToastUrl',
              'configurations.utilities.history.downloadErrorToastTitle'
            );
          }
        );
    }
  }
}
