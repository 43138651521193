import { Component, Input, OnInit } from '@angular/core';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { ApiService } from 'src/app/lib/services/api.service';
import { AuthService } from 'src/app/lib/services/auth.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';

@Component({
  selector: 'logpulldetails',
  templateUrl: './logpulldetails.component.html',
  styleUrls: ['./logpulldetails.component.scss']
})
export class LogpullDetailsComponent implements OnInit {
  @Input()
  event: {
    createdAt: string;
    details: {
      eventAt: string;
      comment: string;
    };
    link?: string;
    copyLink?: string;
  } = null;

  helper = new GeneralHelper();
  permissionsSubscription: any;
  permissions: any;

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private mixpanel: MixpanelService,
    private plume: PlumeService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.permissionsSubscription = this.plume.permissions.subscribe((data: any) => {
      this.permissions = data;
    });
  }

  closeEvent(): void {
    this.event = null;
  }

  downloadLogpull(link: string): void {
    this.mixpanel.storeEvent('TIMELINES_LOGPULL_DOWNLOAD');

    this.api.get(link, 'awlan').subscribe(
      (response: any) => {
        for (const nodeId of Object.keys(response.podFilenameMap)) {
          const logUrl = response.podFilenameMap[nodeId];
          const headers = {
            responseType: 'blob',
            headers: {
              Accept: 'application/octet-stream',
              'Content-Type': 'application/octet-stream',
              ...this.auth.getHeaders(undefined, true)
            }
          };

          this.api.raw('get', logUrl + '?' + this.auth.getParams(), {}, headers).subscribe(
            (response: any) => {
              const binaryData = [];
              binaryData.push(response);

              const blob = new Blob(binaryData, { type: response.type });
              const filename = logUrl.substring(logUrl.lastIndexOf('/') + 1);

              this.helper.download(blob, filename);
            },
            () => {
              this.toast.error(
                'customerinfo.logpullHistory.downloadErrorToastMessage',
                'customerinfo.logpullHistory.downloadErrorToastTitle',
                { params: { nodeId } }
              );
            }
          );
        }
      },
      () => {
        this.toast.error(
          'customerinfo.logpullHistory.awlanErrorToastMessage',
          'customerinfo.logpullHistory.downloadErrorToastTitle'
        );
      }
    );
  }

  copyLogpullLink(copyLink: string): void {
    this.mixpanel.storeEvent('TIMELINES_LOGPULL_HISTORY_COPY');
    try {
      window.navigator.clipboard.writeText(copyLink);
      this.toast.success(
        'customerinfo.logpullHistory.copyUrlToastMessage',
        'customerinfo.logpullHistory.copyUrlToastTitle'
      );
    } catch (error) {}
  }
}
