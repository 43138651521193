import { Component, OnInit, OnChanges, Output, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectIPv6MapTE, selectPipeLocationOnChangeOptimistic } from 'src/app/store/customer/customer.selectors';
import { IPv6AddressingConfigSet, IPv6ModeSet, IPv6PartialStateSet } from 'src/app/store/ipv6/ipv6.actions';
import { map } from 'rxjs/operators';
import { DeepReadonly, ILocation, IMapConfig, IMapConfigState } from 'src/app/lib/interfaces/interface';
import { customerLocationConfigAndStateReload } from 'src/app/store/customer/customer.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {selectCapabilities} from 'src/app/store/customer/capabilities.selector';

@UntilDestroy()
@Component({
  selector: 'ipv6',
  templateUrl: './ipv6.component.html',
  styleUrls: ['./ipv6.component.scss']
})
export class Ipv6Component implements OnInit, OnChanges {
  expand: boolean = false;
  dataExpand: boolean = false;
  configDataExpand: boolean = false;
  stateDataExpand: boolean = false;
  location$ = this.store.pipe(selectPipeLocationOnChangeOptimistic);
  details: any = null;
  editPrimaryDns: boolean = false;
  primaryDns: UntypedFormControl = new UntypedFormControl('');
  secondaryDns: UntypedFormControl = new UntypedFormControl('');
  editSecondaryDns: boolean = false;
  wanConfig: IMapConfig = null;
  wanState: IMapConfigState = null;
  capabilities$ = this.store.select(selectCapabilities);

  @Input()
  open: number = 0;

  @Output()
  toggle = new EventEmitter();

  @Output()
  filter = new EventEmitter();

  @Output()
  clearFilter = new EventEmitter<{ section: string }>();

  modeItems$ = this.location$.pipe(
    map((location) => [
      { value: 'auto' as const, translation: 'auto', selected: location.ipv6.mode === 'auto' },
      {
        value: 'enable' as const,
        translation: 'enabled',
        marked: location.ipv6.enable,
        selected: location.ipv6.mode === 'enable'
      },
      {
        value: 'disable' as const,
        translation: 'disabled',
        marked: !location.ipv6.enable,
        selected: location.ipv6.mode === 'disable'
      }
    ])
  );

  addressingItems$ = this.location$.pipe(
    map((location) => [
      {
        value: 'auto' as const,
        translation: 'auto',
        selected: location.ipv6.addressingConfig === 'auto'
      },
      {
        value: 'slaac' as const,
        translation: 'configurations.ipv6.slaac',
        marked: location.ipv6.addressingRealized === 'slaac',
        selected: location.ipv6.addressingConfig === 'slaac'
      },
      {
        value: 'statelessDhcpv6' as const,
        translation: 'configurations.ipv6.statelessDhcpv6',
        marked: location.ipv6.addressingRealized === 'statelessDhcpv6',
        selected: location.ipv6.addressingConfig === 'statelessDhcpv6'
      },
      {
        value: 'statefulDhcpv6' as const,
        translation: 'configurations.ipv6.statefulldhcpv6',
        marked: location.ipv6.addressingRealized === 'statefulDhcpv6',
        selected: location.ipv6.addressingConfig === 'statefulDhcpv6'
      },
      {
        value: 'all' as const,
        translation: 'configurations.ipv6.all',
        marked: location.ipv6.addressingRealized === 'all',
        selected: location.ipv6.addressingConfig === 'all'
      }
    ])
  );

  ipv6DnsEnableItems$ = this.location$.pipe(
    map((location) => [
      {
        value: false,
        translation: 'configurations.ipv6.no',
        selected: !location.ipv6.enable || !location.ipv6.dns?.enabled
      },
      {
        value: true,
        translation: 'configurations.ipv6.yes',
        selected: location.ipv6.enable && location.ipv6.dns?.enabled
      }
    ])
  );

  IPv4overIPv6Items: any[] = [];

  constructor(
    public plume: PlumeService,
    private mixpanel: MixpanelService,
    private translate: TranslateService,
    private store: Store,
    private customer: CustomerService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.registerFilter();
    this.mapConfig();

    this.store
      .select(selectIPv6MapTE)
      .pipe(untilDestroyed(this))
      .subscribe((state) => {
        this.wanState = state;
        this.initMapConfigToggler();
      });
  }

  ngOnChanges(changes: any): void {
    this.expand = changes.open.currentValue;
  }

  registerFilter(): void {
    this.clearFilter.emit({ section: 'ipv6' });

    this.translate
      .get('configurations.ipv6.prefix')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'ipv6', property: 'prefix', translation: translated })
      );

    this.translate
      .get('configurations.ipv6.mode')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'ipv6', property: 'mode', translation: translated })
      );

    this.translate
      .get('configurations.ipv6.addressing')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'ipv6', property: 'addressing', translation: translated })
      );

    this.translate
      .get('configurations.ipv6.ipv6dns')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'ipv6', property: 'ipv6dns', translation: translated })
      );

    this.translate
      .get('configurations.ipv6.primarydns')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'ipv6', property: 'primarydns', translation: translated })
      );

    this.translate
      .get('configurations.ipv6.secondarydns')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'ipv6', property: 'secondarydns', translation: translated })
      );

    this.translate
      .get('configurations.ipv6.ipv6data')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'ipv6', property: 'ipv6data', translation: translated })
      );

    this.translate
      .get('configurations.ipv6.IPv4overIPv6')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'ipv6', property: 'IPv4overIPv6', translation: translated })
      );

    this.translate
      .get('configurations.ipv6.configData')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'ipv6', property: 'configData', translation: translated })
      );

    this.translate
      .get('configurations.ipv6.stateData')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'ipv6', property: 'stateData', translation: translated })
      );
  }

  setMode(mode: 'auto' | 'enable' | 'disable'): void {
    this.store.dispatch(IPv6ModeSet({ mode }));
  }

  setAddressing(addressingConfig: 'auto' | 'slaac' | 'statelessDhcpv6' | 'statefulDhcpv6' | 'all'): void {
    this.store.dispatch(IPv6AddressingConfigSet({ addressingConfig }));
  }

  setIPv4overIPv6(mode: 'AUTO' | 'ENABLE' | 'DISABLE'): void {
    this.customer.setMapConfig$({ mode }).subscribe(() => {
      this.toast.success('configurations.ipv6.toggleMapConfigMsg', 'configurations.ipv6.toggleMapConfigTitle');

      setTimeout(() => {
        this.store.dispatch(customerLocationConfigAndStateReload({ actionType: '[IPv6] MapConfig state' }));
        this.mapConfig();
      }, 5000);
    });
  }

  enableIpv6Dns(state: boolean, location: DeepReadonly<ILocation>): void {
    this.store.dispatch(
      IPv6PartialStateSet({
        partialState: {
          ...location.ipv6,
          enable: state,
          dns: {
            enabled: state,
            primary: this.editPrimaryDns ? this.primaryDns.value : location.ipv6?.dns?.primary,
            secondary: this.editSecondaryDns ? this.secondaryDns.value : location.ipv6?.dns?.secondary
          }
        }
      })
    );
  }

  mapConfig(): void {
    this.customer.getMapConfig$().subscribe((config) => {
      this.wanConfig = config;
      this.initMapConfigToggler();
    });
  }

  initMapConfigToggler(): void {
    this.IPv4overIPv6Items = [
      {
        value: 'AUTO',
        translation: 'auto',
        selected: this.wanConfig?.mode === 'AUTO'
      },
      {
        value: 'ENABLE',
        translation: 'configurations.ipv6.enable',
        marked: this.wanState?.enable,
        selected: this.wanConfig?.mode === 'ENABLE'
      },
      {
        value: 'DISABLE',
        translation: 'configurations.ipv6.disable',
        marked: !this.wanState?.enable,
        selected: this.wanConfig?.mode === 'DISABLE'
      }
    ];
  }

  toggleExpand(): void {
    this.toggle.emit(!this.expand);

    if (!this.expand) {
      this.mixpanel.storeEvent('CONFIGURATION_IPV6_SCREEN');
    }
  }

  enablePrimaryDnsEdit(location: DeepReadonly<ILocation>): void {
    if (location.ipv6.dns) {
      this.primaryDns.setValue(location.ipv6.dns.primary);
    }
    this.editPrimaryDns = true;
  }

  confirmPrimaryDnsEdit(location: DeepReadonly<ILocation>): void {
    this.enableIpv6Dns(location.ipv6.dns?.enabled, location);
    this.editPrimaryDns = false;
  }

  cancelPrimaryDnsEdit(location: DeepReadonly<ILocation>): void {
    this.primaryDns.setValue(location?.ipv6?.dns?.primary ?? '');
    this.editPrimaryDns = false;
  }

  enableSecondaryDnsEdit(location: DeepReadonly<ILocation>): void {
    if (location.ipv6.dns) {
      this.secondaryDns.setValue(location.ipv6.dns.secondary);
    }
    this.editSecondaryDns = true;
  }

  confirmSecondaryDnsEdit(location: DeepReadonly<ILocation>): void {
    this.enableIpv6Dns(location.ipv6.dns?.enabled, location);
    this.editSecondaryDns = false;
  }

  cancelSecondaryDnsEdit(location: DeepReadonly<ILocation>): void {
    this.secondaryDns.setValue(location?.ipv6?.dns?.secondary ?? '');
    this.editSecondaryDns = false;
  }
}
