import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'time' })
export class TimePipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      const minutes = parseInt(value, 10);
      const days = Math.floor(minutes / 1440);
      const remainingMinutesAfterDays = minutes % 1440;
      const hours = Math.floor(remainingMinutesAfterDays / 60);
      const remainingMinutes = remainingMinutesAfterDays % 60;

      let string = '';
      if (days > 0) {
        string += `${days} d `;
      }
      if (hours > 0) {
        string += `${hours} h `;
      }
      if (remainingMinutes > 0) {
        string += `${remainingMinutes} min`;
      }
      if (string === '') {
        string = '0 min';
      }
      return string.trim();
    }
  }
}
