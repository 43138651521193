import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { PlumeService } from '../services/plume.service';

@Pipe({
  name: 'customerName'
})
export class CustomerNamePipe implements PipeTransform {
  constructor(private plume: PlumeService, private auth: AuthService) {}

  transform(value?: string): string {
    if (this.plume.hidePersonalDetails() || this.auth.isExtendedAccess()) {
      return value
        ? value
            .split(' ')
            .map((value, index, array) => (array.length - 1 === index && array.length > 1 ? '*****' : value))
            .join(' ')
        : '*****';
    }
    return value;
  }
}
