import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { forkJoin, of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { INode } from 'src/app/lib/interfaces/interface';
import { ApiService } from 'src/app/lib/services/api.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { selectHelpHasOptimizerData } from 'src/app/store/help/help.selectors';
import { selectDevices, selectNodes } from 'src/app/store/polling/polling.selector';
import { HELP_ITEMS, HelpPage, IHelpPage, IHelpStep } from './help-items';

const scrollIntoViewPromise = async (node: HTMLElement, options?: ScrollIntoViewOptions) => {
  if (!node) return;
  node.scrollIntoView(options);

  return new Promise((resolve) => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        setTimeout(() => {
          resolve(true);
          intersectionObserver.unobserve(node);
        }, 100);
      }
    });
    intersectionObserver.observe(node);
  });
};

@Component({
  selector: 'help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  animations: [
    trigger('animation', [
      state(
        'collapsed',
        style({
          transform: 'scale(0)'
        })
      ),
      state(
        'expanded',
        style({
          transform: 'scale(1)'
        })
      ),
      transition('collapsed <=> expanded', [animate('200ms cubic-bezier(0.64, 0.57, 0.67, 1.53)')])
    ]),
    trigger('overlayAnimation', [
      state(
        'collapsed',
        style({
          opacity: 0,
          display: 'none'
        })
      ),
      state(
        'expanded',
        style({
          opacity: 1,
          display: 'initial'
        })
      ),
      transition('collapsed <=> expanded', [animate('200ms ease-in-out')])
    ])
  ]
})
export class HelpComponent implements OnInit, OnDestroy {
  @Output()
  openFeedbackModal = new EventEmitter<void>();

  subscriptions: Subscription[] = [];

  helpDisabled = false;

  startExpanded = false;
  expanded = false;
  inProgress = false;

  nodes: INode[] = [];
  path = '';

  locationOnline = false;
  locationHasNodes = false;
  locationHasDevices = false;
  locationHasCongestionData = false;
  locationHasOnlineDevice = false;
  locationHasOptimizerEvents = false;

  helpPage: IHelpPage = null;
  helpItemNames: string[] = [];
  helpItemsLength = 0;
  helpItemsVisible: {
    [helpItem: string]: { visible: boolean; reason?: string };
  } = {};
  currentHelpSteps: IHelpStep[] = null;
  currentStep: IHelpStep = null;
  currentStepIndex = 0;
  stepsLength = 0;
  ui: string;

  targetElement: HTMLElement = null;
  searchingForElement = false;

  spotlightStyle = { top: '0px', left: '0px', width: '0px', height: '0px' };
  dialogStyle = { top: '0px', left: '0px', transform: '' };

  @ViewChild('dialog') dialog: ElementRef<HTMLDivElement>;

  constructor(
    private router: Router,
    private store: Store,
    private mixpanel: MixpanelService,
    private plume: PlumeService,
    private api: ApiService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.path = this.getRoutePath();
        this.helpPage = HELP_ITEMS[this.path];
        this.helpItemNames = this.getOrderedHelpItemNames(this.helpPage);
        this.helpItemsLength = Object.keys(this.helpPage?.helpItems || {}).length;
        this.setHelpItemsVisible();
      }
    });
  }

  ngOnInit() {
    this.path = this.getRoutePath();
    this.helpPage = HELP_ITEMS[this.path];
    this.helpItemNames = this.getOrderedHelpItemNames(this.helpPage);
    this.helpItemsLength = Object.keys(this.helpPage?.helpItems || {}).length;
    this.setHelpItemsVisible(true);
    this.ui = this.plume.getUI();

    this.subscriptions.push(
      this.store.select(selectNodes).subscribe((nodes) => {
        this.locationOnline = false;
        this.locationHasNodes = false;
        if (nodes?.length) this.locationHasNodes = true;
        nodes?.forEach((node) => {
          if (node.connectionState === 'connected') this.locationOnline = true;
        });
        this.calculateNodeCongestionState(nodes);
        this.setHelpItemsVisible();
      }),
      this.store.select(selectDevices).subscribe((devices) => {
        this.locationHasDevices = false;
        if (devices?.length > 0) this.locationHasDevices = true;
        this.locationHasOnlineDevice = false;
        devices?.forEach((device) => {
          if (device.connectionState === 'connected' && device.health?.status !== 'calculating')
            this.locationHasOnlineDevice = true;
        });
        this.setHelpItemsVisible();
      }),
      this.store.select(selectHelpHasOptimizerData).subscribe((hasOptimizerEvents) => {
        this.locationHasOptimizerEvents = hasOptimizerEvents;
        this.setHelpItemsVisible();
      })
    );
  }

  getOrderedHelpItemNames(helpPage: IHelpPage): string[] {
    if (!helpPage) return [];
    if (!helpPage.options?.order) return Object.keys(helpPage.helpItems);
    return helpPage.options.order.map((key) => {
      if (!helpPage.helpItems[key]) return undefined;
      return key;
    });
  }

  calculateNodeCongestionState(nodes: INode[]) {
    if (!nodes?.length) {
      this.locationHasCongestionData = false;
      this.setHelpItemsVisible();
      return;
    }

    const requests = {};

    nodes.forEach((node) => {
      requests[node.id] = this.api
        .get(
          '/Customers/' +
            this.plume.customerid +
            '/locations/' +
            this.plume.locationid +
            '/nodes/' +
            node.id +
            '/utilizationMetrics?limit=60&granularity=minutes',
          'reports'
        )
        .pipe(catchError((error: any) => of(error)));
    });

    forkJoin(requests).subscribe((responses: any) => {
      const congestion = [];

      for (const key of Object.keys(responses)) {
        congestion.push(responses[key]);
      }

      this.locationHasCongestionData = congestion.filter((item) => Object.keys(item).length > 1).length > 0; // ignore statsDateRange key in item.data
      this.setHelpItemsVisible();
    });
  }

  @HostListener('window:plumeDisableHelp')
  disableHelp() {
    if (this.inProgress) return;
    this.helpDisabled = true;
  }

  @HostListener('window:plumeEnableHelp')
  enableHelp() {
    this.helpDisabled = false;
  }

  getRoutePath() {
    const currentUrl = window.location.pathname;
    if (/\/customer\/[a-f0-9]+\/location\/[a-f0-9]+\/topology/.test(currentUrl)) return HelpPage.Topology;
    if (/\/customer\/[a-f0-9]+\/location\/[a-f0-9]+\/devices/.test(currentUrl)) return HelpPage.Devices;
    if (/\/customer\/[a-f0-9]+\/location\/[a-f0-9]+\/nodes/.test(currentUrl)) return HelpPage.Nodes;
    if (/\/customer\/[a-f0-9]+\/location\/[a-f0-9]+\/health/.test(currentUrl)) return HelpPage.Health;
    if (/\/customer\/[a-f0-9]+\/location\/[a-f0-9]+\/timelines/.test(currentUrl)) return HelpPage.Timelines;
    if (/\/customer\/[a-f0-9]+\/location\/[a-f0-9]+\/qoe\/devices/.test(currentUrl)) return 'qoeDevices';
    return null;
  }

  setHelpItemsVisible(visible?: boolean) {
    if (!this.helpPage?.helpItems) return;
    Object.keys(this.helpPage?.helpItems || {}).forEach((key) => {
      this.helpItemsVisible[key] = this.shouldShowHelpItem(key, visible);
    });
  }

  shouldShowHelpItem(key: string, visible?: boolean): { visible: true } | { visible: false; reason: string } {
    if (visible || !this.helpPage?.helpItems[key].options)
      return {
        visible: true
      };
    if (this.helpPage?.helpItems[key].options.nodesNeeded && !this.locationHasNodes)
      return {
        visible: false,
        reason: 'help.hints.nodesNeeded'
      };
    if (this.helpPage?.helpItems[key].options.devicesNeeded && !this.locationHasDevices)
      return {
        visible: false,
        reason: 'help.hints.devicesNeeded'
      };
    if (this.helpPage?.helpItems[key].options.deviceOnlineNeeded && !this.locationHasOnlineDevice)
      return {
        visible: false,
        reason: 'help.hints.deviceOnlineNeeded'
      };
    if (this.helpPage?.helpItems[key].options.locationOnlineNeeded && !this.locationOnline)
      return {
        visible: false,
        reason: 'help.hints.locationOnlineNeeded'
      };
    if (this.helpPage?.helpItems[key].options.congestionDataNeeded && !this.locationHasCongestionData)
      return {
        visible: false,
        reason: 'help.hints.congestionDataNeeded'
      };
    if (this.helpPage?.helpItems[key].options.optimizerEventsNeeded && !this.locationHasOptimizerEvents)
      return {
        visible: false,
        reason: 'help.hints.optimizerEventsNeeded'
      };
    return {
      visible: true
    };
  }

  toggleHelp() {
    if (this.helpDisabled) return;
    this.expanded = !this.expanded;
    this.startExpanded = !this.startExpanded;
    if (!this.expanded) this.stopDemo();
    if (this.expanded) {
      this.mixpanel.storeEvent('HELP_ITEMS_DISPLAYED', { SCREEN: this.path });
    }
  }

  async startDemo(key: string = 'general') {
    this.startExpanded = false;
    this.mixpanel.storeEvent('HELP_INITIATED', { SCREEN: this.path, ITEM: key });

    // if scrollToTop is set, scroll to top
    if (this.helpPage?.helpItems[key]?.options?.scrollToTop) {
      document.querySelector('#customer-view').scrollTop = 0;
    }

    // If a demo with provided key doesn't exist stop the demo
    if (!this.helpPage?.helpItems[key]?.steps.length) {
      this.stopDemo();
      return;
    }

    // Wait 1s for UX
    await new Promise((r) => setTimeout(r, 1000));

    // Setup demo variables
    this.currentHelpSteps = this.helpPage?.helpItems[key].steps;
    this.stepsLength = this.helpPage?.helpItems[key]?.steps.length;
    this.currentStepIndex = 0;

    // Check if first target element exists on the page - else stop the demo
    this.currentStep = this.currentHelpSteps?.[this.currentStepIndex];
    if (
      this.currentStep?.options?.action?.type === 'click' &&
      !document.querySelector(this.currentStep.targetElement)
    ) {
      document.querySelector<HTMLElement>(this.currentStep.options.action.targetElement)?.click();
    }

    if (this.currentStep?.options?.action?.type === 'function') {
      await this.currentStep.options.action.function();
    }

    this.targetElement = await this.waitForElement(this.currentStep.targetElement);
    if (!this.targetElement) {
      this.stopDemo();
      return;
    }

    this.inProgress = true;
    this.highlightTargetElement();
  }

  consumeClick(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
  }

  async nextStep() {
    if (!this.currentHelpSteps?.length || this.currentStepIndex + 1 === this.currentHelpSteps.length) {
      this.stopDemo();
      return;
    }
    await this.performExitAction('next');
    this.currentStepIndex += 1;
    this.highlightTargetElement(false, 'next');
  }

  async previousStep() {
    await this.performExitAction('previous');
    if (this.currentStepIndex - 1 < 0) return;
    this.currentStepIndex -= 1;
    this.highlightTargetElement(false, 'previous');
  }

  async highlightTargetElement(expectingMove?: boolean, action?: 'next' | 'previous') {
    // Ensure we have valid current help steps and index
    if (!this.currentHelpSteps || this.currentStepIndex < 0 || this.currentStepIndex >= this.currentHelpSteps.length) {
      this.stopDemo();
      return;
    }

    this.currentStep = this.currentHelpSteps[this.currentStepIndex];

    if (this.currentStep.options?.onlyNoc && this.ui !== 'noc') {
      if (action === 'next') this.nextStep();
      if (action === 'previous') this.previousStep();
      return;
    }

    // If no target element selector is provided, stop the demo
    if (!this.currentStep?.targetElement) {
      this.stopDemo();
      return;
    }

    // Find the target element
    this.targetElement = document.querySelector<HTMLElement>(this.currentStep.targetElement);

    // Perform initial actions only if not expecting a move
    if (!expectingMove) {
      // Perform click action if the target element isn't already visible
      if (
        this.currentStep?.options?.action?.type === 'click' &&
        !document.querySelector(this.currentStep.targetElement)
      ) {
        document.querySelector<HTMLElement>(this.currentStep.options.action.targetElement)?.click();
        this.targetElement = await this.waitForElement(this.currentStep.targetElement);
      }

      // Perform function action if specified
      if (this.currentStep?.options?.action?.type === 'function') {
        await this.currentStep.options.action.function();
        this.targetElement = await this.waitForElement(this.currentStep.targetElement);
      }

      // Scroll into view if option is selected
      if (this.currentStep?.options?.scrollIntoView && this.targetElement)
        await scrollIntoViewPromise(this.targetElement, {
          behavior: 'smooth',
          block: 'center'
        });
    }

    // If no target element found, try to move to next step
    if (!this.targetElement) {
      if (!expectingMove && action === 'next') this.nextStep();
      if (!expectingMove && action === 'previous') this.previousStep();
      return;
    }

    // Calculate spotlight and dialog positioning
    const rect = this.targetElement.getBoundingClientRect();

    this.spotlightStyle = {
      top: `${rect.top - 10}px`,
      left: `${rect.left - 10}px`,
      width: `${rect.width + 20}px`,
      height: `${rect.height + 20}px`
    };

    this.dialogStyle = {
      top: '100%',
      left: '50%',
      transform: 'translateX(-50%)'
    };

    // Adjust dialog positioning based on available space
    if (this.dialog?.nativeElement) {
      const dialog = this.dialog.nativeElement;
      const dialogRect = dialog.getBoundingClientRect();
      const dialogRectWidth = 360;
      const dialogRectLeft = rect.left - Math.abs((dialogRectWidth - rect.width) / 2);

      if (rect.top + rect.height + dialogRect.height > window.innerHeight) {
        this.dialogStyle.top = `-${dialogRect.height + 32}px`;
      }

      if (dialogRectLeft + dialogRectWidth > window.innerWidth) {
        this.dialogStyle.left = '100%';
        this.dialogStyle.transform = 'translateX(-100%)';
      }

      if (dialogRectLeft <= 0) {
        this.dialogStyle.left = '1px';
        this.dialogStyle.transform = 'translateX(0)';
      }
    }

    // Perform highlighting in a loop if the target element is expected to move
    if (this.currentStep?.options?.expectMovement) {
      await new Promise((r) => setTimeout(r, 100));
      this.highlightTargetElement(true);
    }
  }

  async waitForElement(selector: string, maxRetries = 10, interval = 500): Promise<HTMLElement | null> {
    this.searchingForElement = true;
    for (let attempt = 0; attempt < maxRetries; attempt++) {
      const element = document.querySelector<HTMLElement>(selector);
      if (element) {
        this.searchingForElement = false;
        return element;
      }
      await new Promise((resolve) => setTimeout(resolve, interval));
    }
    this.searchingForElement = false;

    return null;
  }

  async performExitAction(action?: 'next' | 'previous' | 'finish') {
    if (
      this.currentStep?.options?.exitAction?.type === 'click' &&
      document.querySelector(this.currentStep.targetElement)
    ) {
      document.querySelector<HTMLElement>(this.currentStep.options.exitAction.targetElement)?.click();
    }

    if (this.currentStep?.options?.exitAction?.type === 'function') {
      await this.currentStep.options.exitAction.function(action);
    }
  }

  stopDemo() {
    this.performExitAction('finish');
    this.inProgress = false;
    this.currentHelpSteps = null;
    this.currentStep = null;
    this.currentStepIndex = 0;
    this.spotlightStyle = { top: '0px', left: '0px', width: '0px', height: '0px' };
    this.dialogStyle = { top: '0px', left: '0px', transform: '' };
    this.startExpanded = false;
    this.expanded = false;
  }

  ngOnDestroy(): void {
    this.stopDemo();
    if (this.subscriptions.length) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }
}
