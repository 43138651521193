import { Component, OnDestroy, OnInit, ɵbypassSanitizationTrustHtml } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subject } from 'rxjs';
import { filter, skip, take, takeUntil, tap } from 'rxjs/operators';
import {
  DeepReadonly,
  IAppPrioritizationMonitoring,
  IDevice,
  ILocation,
  ITrafficGranularity,
  ITrafficLegend
} from 'src/app/lib/interfaces/interface';
import { DataSetGroup } from 'src/app/lib/graph/graph.interface';
import {
  selectAppPrioritizationMonitoring,
  selectPipeLocationOnChange
} from 'src/app/store/customer/customer.selectors';
import { getAppPrioritizationMonitoring } from 'src/app/store/customer/customer.actions';
import { selectDevices } from 'src/app/store/polling/polling.selector';
import { IconService } from 'src/app/lib/services/icon.service';
import { TrafficService } from 'src/app/lib/services/traffic.service';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import * as moment from 'moment';

@Component({
  selector: 'traffic-monitoring',
  templateUrl: './trafficmonitoring.component.html',
  styleUrls: ['./trafficmonitoring.component.scss']
})
export class TrafficMonitoringComponent implements OnInit, OnDestroy {
  granularityItems: ITrafficGranularity[] = [];
  granularityItemsCurrent: ITrafficGranularity = null;
  modeItems: any[] = [];
  modeItemsCurrent: any = null;
  allMonitoringDevices: any[] = [];
  monitoringDevices: any[] = [];
  headers: any[] = [];
  location: DeepReadonly<ILocation>;
  filterNoDevices: boolean = false;
  sort: { icon: string; field: string } = {
    icon: 'icon-sort',
    field: 'devices'
  };
  destroy$: Subject<boolean> = new Subject<boolean>();
  helper: GeneralHelper = new GeneralHelper();
  devices: IDevice[] = null;
  monitoring: IAppPrioritizationMonitoring = null;
  searchText = '';

  constructor(
    private icons: IconService,
    private translate: TranslateService,
    private store: Store,
    private traffic: TrafficService
  ) {}

  ngOnInit(): void {
    this.initGranularity();
    this.initMode();

    this.store
      .pipe(
        selectPipeLocationOnChange,
        takeUntil(this.destroy$),
        tap((location) => {
          this.location = location;
        })
      )
      .subscribe();

    combineLatest([
      this.store.select(selectDevices).pipe(
        filter((devices) => !!devices),
        take(1)
      ),
      this.store.select(selectAppPrioritizationMonitoring).pipe(skip(1))
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([devices, monitoring]) => {
        this.headers = this.traffic.legend;
        this.devices = devices ? devices : null;
        this.monitoring = monitoring && monitoring.devices && monitoring.startTime ? monitoring : null;

        this.generateCharts();
      });
  }

  initGranularity(): void {
    this.granularityItems = [
      {
        value: '3h',
        granularity: '15 minutes',
        interval: 15 * 60 * 1000,
        miliseconds: 3 * 60 * 60 * 1000,
        translation: 'traffic.h3',
        count: 12,
        selected: false
      },
      {
        value: '24h',
        granularity: '1 hour',
        interval: 60 * 60 * 1000,
        miliseconds: 24 * 60 * 60 * 1000,
        translation: 'traffic.h24',
        count: 24,
        selected: false
      },
      {
        value: '30d',
        granularity: '1 day',
        interval: 24 * 60 * 60 * 1000,
        miliseconds: 30 * 24 * 60 * 60 * 1000,
        translation: 'traffic.d30',
        count: 30,
        selected: true
      }
    ];

    this.changeGranularity();
  }

  initMode(): void {
    this.modeItems = [
      { value: 'rxBytes', translation: 'traffic.download', selected: true },
      { value: 'txBytes', translation: 'traffic.upload', selected: false }
    ];

    this.modeItemsCurrent = this.modeItems[0];
  }

  changeGranularity(): void {
    this.granularityItemsCurrent = this.granularityItems.find((granularity) => granularity.selected);

    this.store.dispatch(
      getAppPrioritizationMonitoring({
        monitoring: {
          granularity: this.granularityItemsCurrent.granularity,
          startTime: new Date(Date.now() - this.granularityItemsCurrent.miliseconds).toISOString(),
          endTime: new Date().toISOString()
        }
      })
    );
  }

  changeMode(): void {
    this.modeItemsCurrent = this.modeItems.find((mode) => mode.selected);

    this.generateCharts();
  }

  search(query: string): void {
    this.filterNoDevices = false;

    if (query.length) {
      this.monitoringDevices = this.allMonitoringDevices.filter(
        (device: any) =>
          device.name.toLowerCase().includes(query.toLowerCase()) ||
          device.mac.toLowerCase().includes(query.toLowerCase())
      );

      if (!this.monitoringDevices.length) {
        this.filterNoDevices = true;
      }
    } else {
      this.monitoringDevices = [...this.allMonitoringDevices];
    }

    this.sortDevices();
  }

  sortDevices(field: string = null, toggleOrder: boolean = false): void {
    if (field) {
      if (this.sort.field === field && toggleOrder) {
        this.sort.icon = this.sort.icon === 'icon-sort-up' ? 'icon-sort' : 'icon-sort-up';
      } else {
        this.sort.icon = 'icon-sort';
        this.sort.field = field;
      }
    }

    this.monitoringDevices.sort((a: any, b: any) => {
      if (this.sort.icon === 'icon-sort') {
        if (this.sort.field === 'devices') {
          return a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1;
        } else {
          return (a.details[this.sort.field]?.bytes ?? 0) < (b.details[this.sort.field]?.bytes ?? 0) ? 1 : -1;
        }
      } else {
        if (this.sort.field === 'devices') {
          return a.name?.toLowerCase() < b.name?.toLowerCase() ? 1 : -1;
        } else {
          return (a.details[this.sort.field]?.bytes ?? 0) > (b.details[this.sort.field]?.bytes ?? 0) ? 1 : -1;
        }
      }
    });
  }

  convertBytes(bytes: number): string {
    const convert = this.helper.formatBytes(bytes, 'Bytes');
    return convert.value + ' ' + convert.unit;
  }

  generateCharts(): void {
    if (this.devices && this.monitoring) {
      this.allMonitoringDevices = this.traffic
        .generateTrafficMonitoring(
          this.monitoring,
          this.devices,
          this.modeItemsCurrent.value,
          this.granularityItemsCurrent.interval
        )
        .map((monitoring) => ({
          ...monitoring,
          icon: this.icons.getPath(monitoring.icon),
          iconV3: this.icons.getV3Path(monitoring.iconV3, 'small'),
          expanded: this.allMonitoringDevices.find((device) => device.mac.toUpperCase() === monitoring.mac)?.expanded,
          chart: this.prepareStackedGraph(monitoring.chartData)
        }));
    } else {
      this.allMonitoringDevices = [];
    }
    this.search(this.searchText);
    this.sortDevices(this.sort.field);
  }

  prepareStackedGraph(monitoring: ITrafficLegend[]): DataSetGroup[] {
    if (!monitoring || monitoring.length) {
      const dateArray = [];
      const nullArray = [];

      const unit =
        this.granularityItemsCurrent.value === '30d'
          ? 'day'
          : this.granularityItemsCurrent.value === '24h'
          ? 'hour'
          : 'minute';

      for (let i = 0; i < this.granularityItemsCurrent.count; i++) {
        const substract = unit === 'minute' ? moment().minutes() % 15 : 0;

        const date = moment()
          .utc()
          .startOf(unit)
          .subtract(substract, 'minutes')
          .subtract(i * this.granularityItemsCurrent.interval, 'milliseconds')
          .toISOString();

        dateArray.push(date);
        nullArray.push(null);
      }

      monitoring.forEach((trafficClass) => {
        const timestamps = [...dateArray.sort()];
        const bytes = [...nullArray];
        const total = [...nullArray];

        trafficClass.timestamps.forEach((timestamp, index) => {
          const found = timestamps.findIndex((t) => t === timestamp);

          if (found !== -1) {
            bytes[found] = trafficClass.bytes[index];
            total[found] = trafficClass.total[index];
          }
        });

        trafficClass.bytes = bytes;
        trafficClass.total = total;
        trafficClass.timestamps = timestamps;
      });

      const timestamps = monitoring[0].timestamps;
      const sortedMonitoring = monitoring.sort((a, b) => (a.order < b.order ? 1 : -1));

      return [
        {
          startFromZero: true,
          autoScale: false,
          dataSets: [
            {
              type: 'segmentedIntervalBar',
              color: '#FFC500',
              yStart: 0,
              yEnd: 100,
              xLabelStart: 1,
              xLabelStep: 1,
              yLabelStep: 20,
              yLabelStart: 0,
              xStepVisualization: 'marker',
              yStepVisualization: 'line',
              xTextPosition: 'forceStart',
              yTextPosition: 'start',
              shrunkYIntervalOnOverflow: true,
              xStart: 0,
              xEnd: this.granularityItemsCurrent.value === '30d' ? timestamps.length : timestamps.length + 1,
              xValText: (val) =>
                (this.granularityItemsCurrent.value === '30d' && timestamps[val - 1] && val % 4 === 1) ||
                (this.granularityItemsCurrent.value === '24h' && timestamps[val - 1] && val % 2 === 0) ||
                (this.granularityItemsCurrent.value === '3h' && timestamps[val - 1])
                  ? moment(timestamps[val - 1])
                      .locale(navigator.language)
                      .format(this.granularityItemsCurrent.value === '30d' ? 'L' : 'LT')
                  : '',
              yValText: (val) => (!val ? `${val}` : `${val}%`),
              toolTipHtml: (data: any) =>
                ɵbypassSanitizationTrustHtml(
                  `
                  <span>${moment(timestamps[data.xVal - 1])
                    .locale(navigator.language)
                    .format(this.granularityItemsCurrent.value === '30d' ? 'LL' : 'LLL')}</span><br>
                  <div style="display: flex; align-items: center;">
                    <div style="background: ${
                      data.yInterval[data.activeYIntervalIndex].color
                    }; width: 8px; height: 8px; border-radius: 50%"></div>
                    <div style="margin-left: 0.5rem; color: ${
                      data.yInterval[data.activeYIntervalIndex].color
                    };"> ${this.translate.instant(data.yInterval[data.activeYIntervalIndex].dataObject.label)}</div>
                    <div style="margin-left: 1.5rem">${data.yInterval[data.activeYIntervalIndex].dataObject.value}</div>
                  </div>
                  `
                ),
              data: timestamps.map((timestamp, index) => ({
                xVal: index + 1,
                yVal: 0,
                yInterval: sortedMonitoring
                  .reduce(
                    (acc, trafficClass) => {
                      const usage = this.helper.formatBytes(trafficClass.bytes[index], 'Bytes');

                      if (usage.value <= 0) {
                        return acc;
                      }

                      const percent =
                        trafficClass?.bytes[index] && trafficClass?.total[index]
                          ? (trafficClass.bytes[index] * 100) / trafficClass.total[index]
                          : 0;

                      return {
                        result: [
                          ...acc.result,
                          {
                            start: acc.start,
                            end: acc.start + percent,
                            color: trafficClass.color,
                            dataObject: {
                              value: usage.value + ' ' + usage.unit,
                              label: trafficClass.label
                            }
                          }
                        ],
                        start: acc.start + percent
                      };
                    },
                    { start: 0, result: [] } as { start: number; result: DataSetGroup[] }
                  )
                  .result.filter((trafficClass) => trafficClass) as {
                  start: number;
                  end: number;
                  color?: string;
                  dataObject?: any;
                }[]
              }))
            }
          ]
        }
      ];
    } else {
      return [];
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
