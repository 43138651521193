import { IHelpPage } from '../help-items';

export const timelines: IHelpPage = {
  helpItems: {
    optimizerHistory: {
      options: {
        scrollToTop: true,
        optimizerEventsNeeded: true
      },
      steps: [
        {
          targetElement: '#help-timelines-optimizerHeader',
          content: 'help.timelines.optimizerHistory.intro',
          options: {
            expectMovement: true,
            scrollIntoView: true
          }
        },
        {
          targetElement: '#help-timelines-optimizerFilter',
          content: 'help.timelines.optimizerHistory.filter',
          options: {
            action: {
              type: 'click',
              targetElement: '#help-timelines-optimizer collapsiblebox [header]'
            }
          }
        },
        {
          targetElement: '#help-timelines-optimizerLegend',
          content: 'help.timelines.optimizerHistory.legend',
          options: {
            expectMovement: true,
            scrollIntoView: true
          }
        },
        {
          targetElement: '#help-timelines-optimizerDetails',
          content: 'help.timelines.optimizerHistory.details',
          options: {
            expectMovement: true,
            scrollIntoView: true
          }
        },
        {
          targetElement: '#help-timelines-optimizerDetailsStats',
          content: 'help.timelines.optimizerHistory.stats'
        },
        {
          targetElement: '#help-timelines-optimizerDetailsTopology',
          content: 'help.timelines.optimizerHistory.topologyIntro'
        },
        {
          targetElement: '#help-timelines-optimizerDetailsTopologyGraph',
          content: 'help.timelines.optimizerHistory.topologyGraph',
          options: {
            action: {
              type: 'click',
              targetElement: '#help-timelines-optimizerDetailsTopology'
            },
            exitAction: {
              type: 'click',
              targetElement: '#help-timelines-optimizerDetailsTopologyModal .modal-bg'
            }
          }
        },
        {
          targetElement: '#help-timelines-optimizerDetailsOpsLog',
          content: 'help.timelines.optimizerHistory.opsLog'
        },
        {
          targetElement: '#help-timelines-optimizerDetailsCloseButton',
          content: 'help.timelines.optimizerHistory.closeButton'
        }
      ]
    },
    rebootTimeMachine: {
      steps: [
        {
          targetElement: '#help-timelines-rebootTimeMachineHeader',
          content: 'help.timelines.rebootTimeMachine.intro',
          options: {
            expectMovement: true,
            scrollIntoView: true
          }
        },
        {
          targetElement: '#help-timelines-rebootTimeMachineLegend',
          content: 'help.timelines.rebootTimeMachine.legend',
          options: {
            expectMovement: true,
            scrollIntoView: true,
            action: {
              type: 'click',
              targetElement: '#help-timelines-rebootTimeMachine collapsiblebox [header]'
            },
            exitAction: {
              type: 'click',
              targetElement: '#help-timelines-rebootTimeMachine collapsiblebox [header]'
            }
          }
        }
      ]
    }
  }
};
