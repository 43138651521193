<div class="row" [class.dim]="selected !== alarm?.id && selected !== null">
  <div class="col-xs-12">
    <box class="wider">
      <div header>
        <span>
          {{ 'health.networkPerformance.inconsistentSpeedAlarm.ispSpeedWarning' | translate }}
        </span>
      </div>

      <div container>
        <div class="data">
          <div class="column middle-xs">
            <icon [path]="gateWayIcon$ | async"></icon>
            <div class="name">
              {{ 'health.networkPerformance.inconsistentSpeedAlarm.locationIspSpeedInconsistent' | translate }}
            </div>
          </div>
          <div class="col-xs-6"><div class="col-sm charts"></div></div>
        </div>
      </div>
    </box>

    <div class="wrapper" [class.show]="troubleshoot" *ngIf="troubleshoot">
      <div *ngIf="stage === 'highlightMultipleGateways'">
        <gatewayCompareBubble [gatewayNodeSpeedData]="alarm.nodes"></gatewayCompareBubble>
      </div>
      <div *ngIf="stage === 'reboot'">
        <rebootbubble></rebootbubble>
        <optimizeBubble
          lastOptimize="optimized"
          *ngIf="rebootState === 'optimize' || rebootState === 'fail'"
        ></optimizeBubble>

        <div class="bubble" *ngIf="rebootState === 'success'">
          <div class="endmessage">
            {{ 'health.networkPerformance.inconsistentSpeedAlarm.messageMonitorAlarm' | translate }}
          </div>
          <div class="finishedButton row">
            <div class="col-xs-4">
              <div class="tsButton" (click)="endFlow()">
                {{ 'health.networkPerformance.extenderSpeedAlarm.finish' | translate }}
              </div>
            </div>
            <div class="col-xs-8"></div>
          </div>
        </div>
        <logbubble *ngIf="rebootState === 'fail'"></logbubble>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="!troubleshoot">
  <div class="col-xs-12">
    <div class="actions">
      <div class="button wide" (click)="startTroubleshooting()">
        {{ 'health.networkPerformance.inconsistentSpeedAlarm.beginTroubleshooting' | translate }}
      </div>
      <!-- <div class="button">Dismiss Alert</div> -->
    </div>
  </div>
</div>
