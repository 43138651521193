import { OnInit, Input, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { INode } from 'src/app/lib/interfaces/interface';
import { ModelRefService } from 'src/app/lib/services/modelref.service';
import { selectNodeIcon, selectLteInUse } from 'src/app/store/lte/lte.selectors';

@UntilDestroy()
@Component({
  selector: 'nodename',
  templateUrl: './nodename.component.html',
  styleUrls: ['./nodename.component.scss']
})
export class NodeNameComponent implements OnInit {
  @Input()
  node: INode & { isGateway?: boolean } = null;

  @Input()
  maxWidth: number = undefined;

  gateWayIcon$ = this.store.pipe(selectNodeIcon(''));
  lteInUse$ = this.store.select(selectLteInUse);

  constructor(private modelRef: ModelRefService, private store: Store) {}

  ngOnInit(): void {
    this.gateWayIcon$ = this.store.pipe(selectNodeIcon(this.node.id));
  }

  getIcon(model: string): string {
    return this.modelRef.get(model).icon;
  }
}
