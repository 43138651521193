import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl, Validators } from '@angular/forms';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { ApiService } from 'src/app/lib/services/api.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { ModalService } from 'src/app/lib/services/modal.service';
import { SecondaryNetworksService } from 'src/app/lib/services/secondary-networks.service';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode';
import { EmailPipe } from 'src/app/lib/pipes/email.pipe';

@Component({
  selector: 'createcustomer',
  templateUrl: './createcustomer.component.html',
  styleUrls: ['./createcustomer.component.scss']
})
export class CreateCustomerComponent implements OnChanges {
  @Input() open: boolean | number = false;
  @Output() newCustomer: EventEmitter<any> = new EventEmitter();

  helper: GeneralHelper = new GeneralHelper();
  user: any;
  role: string;
  groups: any[] = [];
  groupSelected: string = '';
  partnerIdSelected: string = '';
  customerName: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.minLength(4)]);
  customerEmail: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.email]);
  customerPassword: UntypedFormControl = new UntypedFormControl('', [Validators.minLength(8)]);
  customerAccountId: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.minLength(1)]);
  partnerIdSameAsGroupId: boolean = true;
  verifyAndPasswordReset: boolean = false;
  sendVerifyEmail: boolean = false;
  profileSelectedItem: string = 'auto';
  usePartnerAPI: boolean = false;
  html5QrCode: any = null;
  onboarding: any = {
    customer: null,
    location: null,
    flow: '',
    step: 0,
    addNode: {
      list: [],
      input: new UntypedFormControl(''),
      error: false,
      error403: false,
      error404: false,
      errorInput: false
    }
  };
  profileItems = [
    {
      value: 'auto',
      translation: 'newCustomer.profileItems.homePass',
      selected: true
    },
    {
      value: 'smallbusiness',
      translation: 'newCustomer.profileItems.workPass',
      selected: false
    }
  ];
  show: any = {
    landing: false,
    legacy: false,
    onboarding: false,
    qrcode: false
  };

  constructor(
    private router: Router,
    private plume: PlumeService,
    private api: ApiService,
    private mixpanel: MixpanelService,
    private toast: ToastService,
    private secondaryNetworks: SecondaryNetworksService,
    private modal: ModalService,
    private emailPipe: EmailPipe,
    private customerService: CustomerService
  ) {}

  ngOnChanges(changes: any): void {
    if (changes.open.currentValue > 0) {
      this.user = this.plume.getUser();
      this.role = 'centralDisabledUIFeatures' in this.user ? 'header.customRole' : this.plume.getRole();
      this.groups = this.plume
        .getGroups()
        .sort((a: any, b: any) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1));
      this.usePartnerAPI =
        this.plume.versionCompare(this.plume.getEnv().version, '1.91.0') &&
        (this.user.partnerId.length || this.user.accessibleLeafPartnerIds.length);

      const permissions = this.plume.getPermissions();

      if (this.groups.length) {
        this.selectGroup(this.groups[0]);
      }

      if (permissions.uiFeatures.createCustomerOnboarding) {
        if (this.user.accessibleLeafPartnerIds?.length) {
          this.selectPartnerId(this.user.accessibleLeafPartnerIds[0]);
        }

        if (this.role === 'admin') {
          this.show.landing = true;
        } else {
          if (this.usePartnerAPI) {
            this.show.landing = true;
          } else {
            this.show.legacy = true;
          }
        }
      } else {
        this.show.legacy = true;
      }

      this.mixpanel.storeEvent('CREATE_CUSTOMER_DIALOG');
    }
  }

  selectGroup(group: any): void {
    this.groups.forEach((g: any) => {
      if (g.id === group.id) {
        this.groupSelected = g.id;
      }
    });
  }

  selectPartnerId(partnerId: string): void {
    this.partnerIdSelected = partnerId;
  }

  getPartnerIdName(partnerId: string): string {
    let name = partnerId;

    if (this.groups?.length) {
      this.groups.forEach((group: any) => {
        if (group.id === partnerId) {
          name = group.name;
        }
      });
    }

    return name;
  }

  confirmNewCustomer(onboardingFlow: boolean = false): void {
    if (
      this.customerName.valid &&
      this.customerEmail.valid &&
      (this.customerAccountId.valid || this.role === 'admin')
    ) {
      if (this.role === 'admin') {
        this.adminCreateCustomer(onboardingFlow);
      } else {
        if (this.usePartnerAPI && onboardingFlow) {
          this.partnerCreateCustomer();
        } else {
          this.groupsCreateCustomer(onboardingFlow);
        }
      }
    } else {
      const notvalid = [];

      if (!this.customerName.valid) {
        notvalid.push('name');
        this.toast.error('header.pleaseConfirmFieldName', 'header.failed');
      } else if (!this.customerEmail.valid) {
        notvalid.push('email');
        this.toast.error('header.pleaseConfirmFieldEmail', 'header.failed');
      } else if (!this.customerPassword.valid && !onboardingFlow) {
        notvalid.push('password');
        this.toast.error('header.pleaseConfirmFieldPassword', 'header.failed');
      } else if (!this.customerAccountId.valid) {
        notvalid.push('accountId');
        this.toast.error('header.pleaseConfirmFieldAccountId', 'header.failed');
      } else {
        notvalid.push('unknown');
        this.toast.error('header.pleaseConfirmFields', 'header.failed');
      }

      this.mixpanel.storeEvent('CREATE_CUSTOMER_VALIDATION_FAILURE', {
        FAILED_AT: notvalid.join(', ')
      });
    }
  }

  adminCreateCustomer(onboardingFlow: boolean = false): void {
    const params = {
      name: this.customerName.value,
      email: this.customerEmail.value
    };

    if (this.customerPassword.value?.length) {
      params['password'] = this.customerPassword.value;
    }

    this.api.post('/Customers', params).subscribe(
      (customer: any) => {
        if (onboardingFlow) {
          this.mixpanel.storeEvent('CREATE_CUSTOMER_SUCCESS', {
            CUSTOMER_EMAIL: customer.email,
            NEW_CUSTOMER_PROFILE: this.profileSelectedItem,
            ONBOARDING_FLOW: true
          });

          this.toast.success('header.toastNewUser', 'header.success', {
            params: { name: this.helper.truncateString(customer.name, 25) }
          });

          this.api.get('/Customers/' + customer.id + '/locations').subscribe((locations: any) => {
            this.onboarding.customer = customer;
            this.onboarding.location = locations[0];

            this.newCustomerFlow('onboarding', 'addNode');
          });
        } else {
          this.successCreatingCustomer(customer);
        }
      },
      (error: any) => {
        this.toast.error(error.error.error.message, 'header.failed');
        this.mixpanel.storeEvent('CREATE_CUSTOMER_SERVER_FAILURE', {
          CUSTOMER_EMAIL: this.customerEmail.value,
          ERROR: error.error.error.message
        });
      }
    );
  }

  partnerCreateCustomer(): void {
    let partnerId = this.user.partnerId;

    if (this.user.accessibleLeafPartnerIds.length) {
      partnerId = this.partnerIdSelected;
    }

    this.api
      .post('/Customers/register', {
        name: this.customerName.value,
        email: this.customerEmail.value,
        accountId: this.customerAccountId.value,
        partnerId,
        profile: this.profileSelectedItem
      })
      .subscribe(
        (response: any) => {
          this.mixpanel.storeEvent('CREATE_CUSTOMER_SUCCESS', {
            CUSTOMER_EMAIL: response.email,
            NEW_CUSTOMER_ACCOUNT_ID: response.accountId,
            NEW_CUSTOMER_PARTNER_ID: partnerId,
            NEW_CUSTOMER_PROFILE: this.profileSelectedItem,
            ONBOARDING_FLOW: true
          });

          this.toast.success('header.toastNewUser', 'header.success', {
            params: { name: this.helper.truncateString(response.name, 25) }
          });

          this.api.get('/Customers/' + response.id + '/locations').subscribe((locations: any) => {
            this.onboarding.customer = response;
            this.onboarding.location = locations[0];

            this.newCustomerFlow('onboarding', 'addNode');
          });
        },
        (error: any) => {
          this.toast.error(error.error.error.message, 'header.failed');
          this.mixpanel.storeEvent('CREATE_CUSTOMER_SERVER_FAILURE', {
            CUSTOMER_EMAIL: this.customerEmail.value,
            ERROR: error.error.error.message
          });
        }
      );
  }

  groupsCreateCustomer(onboardingFlow: boolean = false): void {
    const profileToApply = this.profileSelectedItem;
    const params = {
      name: this.customerName.value,
      email: this.customerEmail.value,
      accountId: this.customerAccountId.value,
      groupIds: [this.groupSelected],
      partnerId: this.partnerIdSameAsGroupId ? this.groupSelected : '',
      profile: this.profileSelectedItem
    };

    if (this.customerPassword.value?.length) {
      params['password'] = this.customerPassword.value;
    }

    this.api.post('/Customers/registerWithGroups', params).subscribe(
      (response: any) => {
        if (onboardingFlow) {
          this.mixpanel.storeEvent('CREATE_CUSTOMER_SUCCESS', {
            CUSTOMER_EMAIL: response.email,
            VERIFY_AND_RESET: this.verifyAndPasswordReset,
            NEW_CUSTOMER_GROUP_ID: this.groupSelected,
            NEW_CUSTOMER_PARTNER_ID_SET: this.partnerIdSameAsGroupId,
            NEW_CUSTOMER_PROFILE: this.profileSelectedItem,
            ONBOARDING_FLOW: true
          });

          this.toast.success('header.toastNewUser', 'header.success', {
            params: { name: this.helper.truncateString(response.name, 25) }
          });

          this.api.get('/Customers/' + response.id + '/locations').subscribe((locations: any) => {
            this.onboarding.customer = response;
            this.onboarding.location = locations[0];

            this.newCustomerFlow('onboarding', 'addNode');
          });
        } else {
          // verify and send password reset
          if (this.verifyAndPasswordReset) {
            this.api.patch('/Customers/' + response.id, { emailVerified: true }).subscribe(() => {
              this.api
                .post('/Customers/reset', {
                  email: response.email,
                  notificationOptions: {
                    profile: profileToApply
                  }
                })
                .subscribe(() => {});
            });
          } else {
            if (this.sendVerifyEmail) {
              this.customerService
                .resendEmailVerification$({ email: response.email, profile: this.profileSelectedItem })
                .subscribe();
            }
          }
          this.successCreatingCustomer(
            response,
            this.groupSelected,
            this.partnerIdSameAsGroupId,
            this.profileSelectedItem
          );
        }
      },
      (error: any) => {
        this.toast.error(error.error.error.message, 'header.failed');
        this.mixpanel.storeEvent('CREATE_CUSTOMER_SERVER_FAILURE', {
          CUSTOMER_EMAIL: this.customerEmail.value,
          ERROR: error.error.error.message
        });
      }
    );
  }

  successCreatingCustomer(
    customer: any,
    groupId: string = null,
    partnerIdSet: boolean = false,
    profile: string = null
  ): void {
    this.mixpanel.storeEvent('CREATE_CUSTOMER_SUCCESS', {
      CUSTOMER_EMAIL: this.customerEmail.value,
      VERIFY_AND_RESET: this.verifyAndPasswordReset,
      NEW_CUSTOMER_GROUP_ID: groupId,
      NEW_CUSTOMER_PARTNER_ID_SET: partnerIdSet,
      NEW_CUSTOMER_PROFILE: profile,
      ONBOARDING_FLOW: false
    });

    this.hideCustomerDialog();

    this.api.get('/Customers/' + customer.id + '/locations').subscribe((locations: any) => {
      if (locations.length) {
        this.newCustomer.emit({ customer, location: locations[0] });

        setTimeout(() => {
          this.toast.success('header.toastNewUser', 'header.success', {
            params: { name: this.helper.truncateString(customer.name, 25) }
          });
        }, 100);
      }
    });
  }

  addNode(): void {
    const nodeid = this.onboarding.addNode.nodeid.value || null;

    this.onboarding.addNode.error = false;
    this.onboarding.addNode.error403 = false;
    this.onboarding.addNode.error404 = false;
    this.onboarding.addNode.errorInput = false;

    if (nodeid) {
      this.onboarding.addNode.loading = true;

      this.api
        .post(
          '/Customers/' + this.onboarding.customer.id + '/locations/' + this.onboarding.location.id + '/nodes',
          {
            serialNumber: nodeid
          },
          'api',
          { observe: 'response' }
        )
        .subscribe(
          (response: any) => {
            if (response.status === 204) {
              this.mixpanel.storeEvent('ONBOARDING_ADD_NODE_SAMENODE', { NODE_ID: nodeid });
              this.toast.error('toast.node.errorNodeAddAlreadyExistsMessage', 'toast.node.errorNodeAddTitle', {
                params: {
                  serial: nodeid
                }
              });

              this.onboarding.addNode.error403 = true;
            } else {
              this.mixpanel.storeEvent('ONBOARDING_ADD_NODE_SUCCESS', { NODE_ID: nodeid });
              this.toast.success('toast.node.successNodeAddMessage', 'toast.node.successNodeAddTitle', {
                params: {
                  serial: nodeid
                }
              });

              this.newCustomerFlow('onboarding', 'addNode');
            }

            this.onboarding.addNode.loading = false;
          },
          (error: any) => {
            this.mixpanel.storeEvent('ONBOARDING_ADD_NODE_ERROR', {
              NODE_ID: nodeid,
              ERROR: error.error.error.message
            });

            this.toast.error('toast.node.errorNodeAddMessage', 'toast.node.errorNodeAddTitle', {
              params: {
                error: error.error.error.message
              }
            });

            if (error.status === 403) {
              this.onboarding.addNode.error403 = true;
            } else if (error.status === 404) {
              this.onboarding.addNode.error404 = true;
            } else {
              this.onboarding.addNode.error = error.error.error.message;
            }

            this.onboarding.addNode.loading = false;
          }
        );
    } else {
      this.onboarding.addNode.errorInput = true;
    }
  }

  clearNode(): void {
    this.onboarding.addNode.nodeid.reset();
    this.onboarding.addNode.error = false;
    this.onboarding.addNode.error403 = false;
    this.onboarding.addNode.error404 = false;
    this.onboarding.addNode.errorInput = false;
  }

  reset(mode: string = null): void {
    if (!mode) {
      this.onboarding = {
        customer: null,
        location: null,
        flow: '',
        step: 0,
        addNode: {},
        networkSettings: {}
      };
    }

    if (!mode || mode === 'addNode') {
      this.onboarding.addNode = {
        list: [],
        nodeid: new UntypedFormControl(''),
        error: false,
        error403: false,
        error404: false,
        errorInput: false,
        loading: false
      };
    }

    if (!mode || mode === 'networkSettings') {
      this.onboarding.networkSettings = {
        currentSsid: null,
        ssid: null,
        password: null,
        ssidEdit: false,
        passwordEdit: false,
        errorSsid: false,
        errorPassword: false,
        encryptionMode: 'psk2'
      };

      if (this.onboarding.location?.profile === 'smallbusiness') {
        this.onboarding.networkSettings.employee = {
          currentSsid: null,
          ssid: null,
          password: null,
          ssidEdit: false,
          passwordEdit: false,
          errorSsid: false,
          errorPassword: false,
          encryptionMode: 'psk2'
        };
      }
    }

    if (!mode || mode === 'passwordReset') {
      this.onboarding.passwordReset = {
        verifyAndPasswordReset: true,
        sendVerifyEmail: false
      };
    }
  }

  cancelSsid(): void {
    this.onboarding.networkSettings.ssid = new UntypedFormControl(this.onboarding.networkSettings.currentSsid || '');
    this.onboarding.networkSettings.ssidEdit = false;
  }

  cancelPassword(): void {
    this.onboarding.networkSettings.password = new UntypedFormControl('');
    this.onboarding.networkSettings.passwordEdit = false;

    if (this.onboarding.networkSettings.currentSsid === null) {
      this.onboarding.networkSettings.ssidEdit = true;
      this.onboarding.networkSettings.password = null;
    }
  }

  cancelEmployeeSsid(): void {
    this.onboarding.networkSettings.employee.ssid = new UntypedFormControl(
      this.onboarding.networkSettings.employee.currentSsid || ''
    );
    this.onboarding.networkSettings.employee.ssidEdit = false;
  }

  cancelEmployeePassword(): void {
    this.onboarding.networkSettings.employee.password = new UntypedFormControl('');
    this.onboarding.networkSettings.employee.passwordEdit = false;

    if (this.onboarding.networkSettings.employee.currentSsid === null) {
      this.onboarding.networkSettings.employee.ssidEdit = true;
      this.onboarding.networkSettings.employee.password = null;
    }
  }

  hideCustomerDialog(): void {
    this.show.landing = false;
    this.show.legacy = false;
    this.show.onboarding = false;
    this.show.qrcode = false;
    this.customerName.reset();
    this.customerEmail.reset();
    this.customerPassword.reset();
    this.customerAccountId.reset();
    this.profileItems[0].selected = true;
    this.profileItems[1].selected = false;
    this.profileSelectedItem = 'auto';
    this.reset();
  }

  newCustomerFlow(mode: string, flow: string = null): void {
    this.show.landing = false;
    this.show.qrcode = false;
    this.show.legacy = mode === 'legacy' ? true : false;
    this.show.onboarding = mode === 'onboarding' ? true : false;

    if (
      this.onboarding.flow === 'networkSettings' &&
      ((this.onboarding.networkSettings.currentSsid !== null && this.onboarding.networkSettings.ssidEdit) ||
        this.onboarding.networkSettings.passwordEdit ||
        (this.onboarding.networkSettings.employee?.currentSsid !== null &&
          this.onboarding.networkSettings.employee?.ssidEdit) ||
        this.onboarding.networkSettings.employee?.passwordEdit)
    ) {
      this.toast.warning('header.finishEditMessage', 'header.finishEditTitle');
      return;
    }

    if (mode === 'onboarding') {
      this.onboarding.flow = flow;

      switch (flow) {
        case 'createCustomer':
          this.onboarding.step = 0;
          break;
        case 'addNode':
          this.onboarding.step = 1;
          this.reset('addNode');
          this.getNodes();
          break;
        case 'networkSettings':
          if (this.onboarding.addNode?.list?.length) {
            this.onboarding.step = 2;
            this.reset('networkSettings');
            this.getNetworkSettings();

            if (this.onboarding.location?.profile === 'smallbusiness') {
              this.getEmployeeNetworkSettings();
            }
          } else {
            this.onboarding.flow = 'addNode';
            this.modal
              .showDialog('newCustomer.addNode.noNodesWarningMsg', 'newCustomer.addNode.noNodesWarningTitle', {
                buttons: [
                  { style: 'tertiary light', value: 'newCustomer.abort' },
                  { style: 'super-primary', value: 'newCustomer.continue' }
                ]
              })
              .subscribe((response) => {
                if (response.item.value === 'newCustomer.abort') {
                  this.hideCustomerDialog();
                }
              });
          }
          break;
        case 'passwordReset':
          if (this.checkOnboarding(false)) {
            this.onboarding.step = 3;
            this.reset('passwordReset');
          } else {
            this.onboarding.flow = 'networkSettings';
            this.modal
              .showDialog(
                'newCustomer.networkSettings.noSsidWarningMsg',
                'newCustomer.networkSettings.noSsidWarningTitle',
                {
                  buttons: [
                    { style: 'tertiary light', value: 'newCustomer.abort' },
                    { style: 'super-primary', value: 'newCustomer.continue' }
                  ]
                }
              )
              .subscribe((response) => {
                if (response.item.value === 'newCustomer.abort') {
                  this.hideCustomerDialog();
                }
              });
          }
          break;
        default:
          this.reset();
      }
    }
  }

  onboardingCheckpoint(): void {
    this.api
      .post(
        '/Customers/' +
          this.onboarding.customer.id +
          '/locations/' +
          this.onboarding.location.id +
          '/onboardingCheckpoint',
        { checkpoint: 'OnboardingComplete' }
      )
      .subscribe(() => {
        this.toast.success('toast.technician.successOnboardingMessage', 'toast.technician.successOnboardingTitle');
        this.router.navigate(['customer', this.onboarding.customer.id, 'location', this.onboarding.location.id]);
        this.hideCustomerDialog();
      });
  }

  checkOnboarding(showToast: boolean = true): boolean {
    if (this.onboarding.location.profile === 'smallbusiness') {
      if (showToast && !this.onboarding.networkSettings.currentSsid?.length) {
        this.toast.warning(
          'toast.technician.cannotCompleteOnboardingMessage',
          'toast.technician.cannotCompleteOnboardingTitle'
        );
      }

      if (showToast && !this.onboarding.networkSettings.employee?.currentSsid?.length) {
        this.toast.warning(
          'toast.technician.cannotCompleteOnboardingVSBMessage',
          'toast.technician.cannotCompleteOnboardingVSBTitle'
        );
      }

      if (
        this.onboarding.networkSettings.currentSsid?.length &&
        this.onboarding.networkSettings.employee?.currentSsid?.length
      ) {
        return true;
      }
    } else {
      if (showToast && !this.onboarding.networkSettings.currentSsid?.length) {
        this.toast.warning(
          'toast.technician.cannotCompleteOnboardingMessage',
          'toast.technician.cannotCompleteOnboardingTitle'
        );
      }

      if (this.onboarding.networkSettings.currentSsid?.length) {
        return true;
      }
    }

    return false;
  }

  finalizeCustomer(): void {
    if (this.checkOnboarding()) {
      if (this.onboarding.passwordReset.verifyAndPasswordReset) {
        this.api.patch('/Customers/' + this.onboarding.customer.id, { emailVerified: true }).subscribe(() => {
          this.mixpanel.storeEvent('CHANGE_EMAIL_VERIFIED', { IS_VERIFIED: true });
          this.toast.success('toast.customerinfo.verifiedMsg', 'toast.customerinfo.verifiedTitle');
          this.api
            .post('/Customers/reset', {
              email: this.onboarding.customer.email,
              notificationOptions: {
                profile: this.onboarding.location.profile
              }
            })
            .subscribe(() => {
              this.mixpanel.storeEvent('SEND_PASSWORD_RESET', { EMAIL: this.onboarding.customer.email });
              this.toast.success('toast.customerinfo.passwordMsg', 'toast.customerinfo.passwordTitle', {
                params: { email: this.emailPipe.transform(this.onboarding.customer.email) }
              });
              this.onboardingCheckpoint();
            });
        });
      } else {
        if (this.onboarding.passwordReset.sendVerifyEmail) {
          this.customerService
            .resendEmailVerification$({
              email: this.onboarding.customer.email,
              profile: this.onboarding.location.profile
            })
            .subscribe((email) => {
              this.mixpanel.storeEvent('SEND_EMAIL_VERIFICATION', { EMAIL: email });
              this.toast.success('toast.customerinfo.verificationMsg', 'toast.customerinfo.verificationTitle', {
                params: { email }
              });
              this.onboardingCheckpoint();
            });
        } else {
          this.onboardingCheckpoint();
        }
      }
    }
  }

  onboardingToggleVerifyAndPassword(): void {
    this.onboarding.passwordReset.verifyAndPasswordReset = !this.onboarding.passwordReset.verifyAndPasswordReset;

    if (this.onboarding.passwordReset.verifyAndPasswordReset) {
      this.onboarding.passwordReset.sendVerifyEmail = false;
    }
  }

  getNodes(): void {
    this.api
      .get('/Customers/' + this.onboarding.customer.id + '/locations/' + this.onboarding.location.id + '/nodes')
      .subscribe((response: any) => {
        this.onboarding.addNode.list = response.nodes;
      });
  }

  getNetworkSettings(): void {
    this.api
      .get('/Customers/' + this.onboarding.customer.id + '/locations/' + this.onboarding.location.id + '/wifiNetwork')
      .subscribe(
        (response: any) => {
          this.onboarding.networkSettings.currentSsid = response.wifiNetwork.ssid;
          this.onboarding.networkSettings.ssid = new UntypedFormControl(response.wifiNetwork.ssid);
          this.onboarding.networkSettings.ssidEdit = false;
          this.onboarding.networkSettings.password = new UntypedFormControl('');
          this.onboarding.networkSettings.passwordEdit = false;
        },
        (error: any) => {
          if (error.status === 404) {
            this.onboarding.networkSettings.currentSsid = null;
            this.onboarding.networkSettings.ssid = new UntypedFormControl('');
            this.onboarding.networkSettings.ssidEdit = true;
            this.onboarding.networkSettings.password = null;
            this.onboarding.networkSettings.passwordEdit = false;
          }
        }
      );
  }

  getEmployeeNetworkSettings(): void {
    this.secondaryNetworks
      .getFronthaul$({ customerId: this.onboarding.customer.id, locationId: this.onboarding.location.id })
      .subscribe(
        (response) => {
          if (response.length) {
            this.onboarding.networkSettings.employee.currentSsid = response[0].ssid;
            this.onboarding.networkSettings.employee.ssid = new UntypedFormControl(response[0].ssid);
            this.onboarding.networkSettings.employee.ssidEdit = false;
            this.onboarding.networkSettings.employee.password = new UntypedFormControl('');
            this.onboarding.networkSettings.employee.passwordEdit = false;
          } else {
            this.onboarding.networkSettings.employee.currentSsid = null;
            this.onboarding.networkSettings.employee.ssid = new UntypedFormControl('');
            this.onboarding.networkSettings.employee.ssidEdit = true;
            this.onboarding.networkSettings.employee.password = null;
            this.onboarding.networkSettings.employee.passwordEdit = false;
          }
        },
        (error: any) => {
          if (error.status === 404) {
            this.onboarding.networkSettings.employee.currentSsid = null;
            this.onboarding.networkSettings.employee.ssid = new UntypedFormControl('');
            this.onboarding.networkSettings.employee.ssidEdit = true;
            this.onboarding.networkSettings.employee.password = null;
            this.onboarding.networkSettings.employee.passwordEdit = false;
          }
        }
      );
  }

  setWifiNetwork(mode: string): void {
    if (mode === 'ssid') {
      this.onboarding.networkSettings.errorSsid = false;

      if (this.validateSsid()) {
        if (!this.onboarding.networkSettings.currentSsid) {
          this.mixpanel.storeEvent('NETWORK_EDIT_FIRST_SSID_SUCCESS', {
            NEW_SSID: this.onboarding.networkSettings.ssid.value
          });

          this.onboarding.networkSettings.ssidEdit = false;
          this.onboarding.networkSettings.password = new UntypedFormControl('');
          this.onboarding.networkSettings.passwordEdit = true;
        } else {
          this.api
            .patch(
              '/Customers/' +
                this.onboarding.customer.id +
                '/locations/' +
                this.onboarding.location.id +
                '/wifiNetwork',
              {
                ssid: this.onboarding.networkSettings.ssid.value
              }
            )
            .subscribe(
              () => {
                this.mixpanel.storeEvent('NETWORK_EDIT_SSID_SUCCESS', {
                  OLD_SSID: this.onboarding.networkSettings.currentSsid,
                  NEW_SSID: this.onboarding.networkSettings.ssid.value
                });

                this.getNetworkSettings();
                this.toast.success('toast.technician.successSsidMessage', 'toast.technician.successSsidTitle');
              },
              (error: any) => {
                this.mixpanel.storeEvent('NETWORK_EDIT_SSID_ERROR', {
                  OLD_SSID: this.onboarding.networkSettings.currentSsid,
                  NEW_SSID: this.onboarding.networkSettings.ssid.value,
                  ERROR: error.error.error.message
                });

                this.getNetworkSettings();
                this.toast.error('toast.technician.errorSsidMessage', 'toast.technician.errorSsidTitle', {
                  disableTimeOut: true,
                  params: {
                    error: error.error.error.message
                  }
                });
              }
            );
        }
      } else {
        this.onboarding.networkSettings.errorSsid = true;
      }
    }

    if (mode === 'password') {
      this.onboarding.networkSettings.errorPassword = false;

      if (this.validatePassword()) {
        const data = {
          ssid: this.onboarding.networkSettings.ssid.value,
          encryptionKey: this.onboarding.networkSettings.password.value,
          wpaMode: this.onboarding.networkSettings.encryptionMode
        };

        this.api
          .put(
            '/Customers/' + this.onboarding.customer.id + '/locations/' + this.onboarding.location.id + '/wifiNetwork',
            data
          )
          .subscribe(
            () => {
              this.mixpanel.storeEvent('NETWORK_EDIT_PASSWORD_SUCCESS');

              this.getNetworkSettings();

              this.toast.success('toast.technician.successPasswordMessage', 'toast.technician.successPasswordTitle');
            },
            (error: any) => {
              this.mixpanel.storeEvent('NETWORK_EDIT_PASSWORD_ERROR', { ERROR: error.error.error.message });

              this.onboarding.networkSettings.password = new UntypedFormControl('');
              this.onboarding.networkSettings.passwordEdit = false;

              this.toast.error('toast.technician.errorPasswordMessage', 'toast.technician.errorPasswordTitle', {
                disableTimeOut: true,
                params: {
                  error: error.error.error.message
                }
              });
            }
          );
      } else {
        this.onboarding.networkSettings.errorPassword = true;
      }
    }
  }

  setEmployeeNetwork(mode: string): void {
    if (mode === 'ssid') {
      this.onboarding.networkSettings.employee.errorSsid = false;

      if (this.validateEmployeeSsid()) {
        if (!this.onboarding.networkSettings.employee.currentSsid) {
          this.mixpanel.storeEvent('NETWORK_EDIT_EMPLOYEE_SSID_SUCCESS', {
            SSID: this.onboarding.networkSettings.employee.ssid.value
          });

          this.onboarding.networkSettings.employee.ssidEdit = false;
          this.onboarding.networkSettings.employee.password = new UntypedFormControl('');
          this.onboarding.networkSettings.employee.passwordEdit = true;
        } else {
          this.secondaryNetworks
            .editFronthaul$(
              'employee',
              {
                ssid: this.onboarding.networkSettings.employee.ssid.value
              },
              { customerId: this.onboarding.customer.id, locationId: this.onboarding.location.id }
            )
            .subscribe(
              () => {
                this.mixpanel.storeEvent('NETWORK_EDIT_EMPLOYEE_SSID_SUCCESS', {
                  OLD_SSID: this.onboarding.networkSettings.employee.currentSsid,
                  NEW_SSID: this.onboarding.networkSettings.employee.ssid.value
                });

                this.getEmployeeNetworkSettings();
                this.toast.success('toast.technician.successSsidMessage', 'toast.technician.successSsidTitle');
              },
              (error: any) => {
                this.mixpanel.storeEvent('NETWORK_EDIT_EMPLOYEE_SSID_ERROR', {
                  OLD_SSID: this.onboarding.networkSettings.employee.currentSsid,
                  NEW_SSID: this.onboarding.networkSettings.employee.ssid.value,
                  ERROR: error.error.error.message
                });

                this.getEmployeeNetworkSettings();
                this.toast.error('toast.technician.errorSsidMessage', 'toast.technician.errorSsidTitle', {
                  disableTimeOut: true,
                  params: {
                    error: error.error.error.message
                  }
                });
              }
            );
        }
      } else {
        this.onboarding.networkSettings.employee.errorSsid = true;
      }
    }

    if (mode === 'password') {
      this.onboarding.networkSettings.employee.errorPassword = false;

      if (this.validateEmployeePassword()) {
        if (!this.onboarding.networkSettings.employee.currentSsid) {
          const data = {
            ssid: this.onboarding.networkSettings.employee.ssid.value,
            encryptionKey: this.onboarding.networkSettings.employee.password.value,
            enable: true,
            networkId: 'employee',
            purgatory: false,
            wpaMode: this.onboarding.networkSettings.encryptionMode
          };

          this.secondaryNetworks
            .addFronthaul$(data, { customerId: this.onboarding.customer.id, locationId: this.onboarding.location.id })
            .subscribe(
              () => {
                this.mixpanel.storeEvent('NETWORK_EDIT_EMPLOYEE_PASSWORD_SUCCESS');

                this.getEmployeeNetworkSettings();

                this.toast.success('toast.technician.successPasswordMessage', 'toast.technician.successPasswordTitle');
              },
              (error: any) => {
                this.mixpanel.storeEvent('NETWORK_EDIT_EMPLOYEE_PASSWORD_ERROR', { ERROR: error.error.error.message });

                this.onboarding.networkSettings.employee.password = new UntypedFormControl('');
                this.onboarding.networkSettings.employee.passwordEdit = false;

                this.toast.error('toast.technician.errorPasswordMessage', 'toast.technician.errorPasswordTitle', {
                  disableTimeOut: true,
                  params: {
                    error: error.error.error.message
                  }
                });
              }
            );
        } else {
          this.secondaryNetworks
            .editFronthaul$(
              'employee',
              {
                ssid: this.onboarding.networkSettings.employee.password.value
              },
              { customerId: this.onboarding.customer.id, locationId: this.onboarding.location.id }
            )
            .subscribe(
              () => {
                this.mixpanel.storeEvent('NETWORK_EDIT_EMPLOYEE_PASSWORD_SUCCESS');

                this.getEmployeeNetworkSettings();
                this.toast.success('toast.technician.successPasswordMessage', 'toast.technician.successPasswordTitle');
              },
              (error: any) => {
                this.mixpanel.storeEvent('NETWORK_EDIT_EMPLOYEE_PASSWORD_ERROR', {
                  ERROR: error.error.error.message
                });

                this.getEmployeeNetworkSettings();
                this.toast.error('toast.technician.errorPasswordMessage', 'toast.technician.errorPasswordTitle', {
                  disableTimeOut: true,
                  params: {
                    error: error.error.error.message
                  }
                });
              }
            );
        }
      } else {
        this.onboarding.networkSettings.employee.errorPassword = true;
      }
    }
  }

  showQrcodeScanner(): void {
    this.show.qrcode = true;

    setTimeout(() => {
      this.html5QrCode = new Html5Qrcode('qrcode-scanner', {
        formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE, Html5QrcodeSupportedFormats.DATA_MATRIX],
        verbose: false
      });

      const config = { fps: 10, qrbox: { width: 300, height: 300 } };
      const callback = (decodedText: string) => {
        this.onboarding.addNode.nodeid.setValue(decodedText);

        this.html5QrCode
          .stop()
          .then(() => {
            this.show.qrcode = false;
          })
          .catch(() => {
            this.show.qrcode = false;
          });
      };

      this.html5QrCode.start({ facingMode: 'environment' }, config, callback).catch(() => {
        this.toast.error('newCustomer.addNode.cameraErrorMsg', 'newCustomer.addNode.cameraErrorTitle');
        this.show.qrcode = false;
      });
    }, 10);
  }

  closeQrCode(): void {
    this.html5QrCode.stop();
    this.html5QrCode = null;
    this.show.qrcode = false;
  }

  setEmailVerified(callback: any = null): void {
    this.api.patch('/Customers/' + this.onboarding.customer.id, { emailVerified: true }).subscribe(() => {
      this.mixpanel.storeEvent('CHANGE_EMAIL_VERIFIED', { IS_VERIFIED: true });
      this.toast.success('toast.customerinfo.verifiedMsg', 'toast.customerinfo.verifiedTitle');
      if (callback) {
        callback();
      }
    });
  }

  validateSsid(): boolean {
    const str = this.onboarding.networkSettings?.ssid?.value;
    if (str) {
      const len = this.getUTF8Length(str);
      return len > 0 && len < 33;
    }
    return false;
  }

  validatePassword(): boolean {
    const str = this.onboarding.networkSettings?.password?.value;
    if (str) {
      const len = this.getUTF8Length(str);
      return len > 7 && len < 64;
    }
    return false;
  }

  validateEmployeeSsid(): boolean {
    const str = this.onboarding.networkSettings?.employee?.ssid?.value;
    if (str) {
      const len = this.getUTF8Length(str);
      return len > 0 && len < 33;
    }
    return false;
  }

  validateEmployeePassword(): boolean {
    const str = this.onboarding.networkSettings?.employee?.password?.value;
    if (str) {
      const len = this.getUTF8Length(str);
      return len > 7 && len < 64;
    }
    return false;
  }

  getUTF8Length(str: string): number {
    let utf8length = 0;
    for (let n = 0; n < str.length; n++) {
      const c = str.charCodeAt(n);
      if (c < 128) {
        utf8length++;
      } else if (c > 127 && c < 2048) {
        utf8length = utf8length + 2;
      } else {
        utf8length = utf8length + 3;
      }
    }
    return utf8length;
  }
}
