<div class="togglers">
  <slidetoggler [options]="granularityItems" (toggle)="changeGranularity()"></slidetoggler>
  <slidetoggler [options]="modeItems" (toggle)="changeMode()"></slidetoggler>
</div>
<div class="filters">
  <div class="search-input">
    <icon name="icon-search"></icon>
    <input
      type="text"
      placeholder="{{ 'traffic.searchDevice' | translate }}"
      [(ngModel)]="searchText"
      (ngModelChange)="search($event)"
    />
  </div>
  <drop-down-icon headerIcon="icon-sort" tooltip="{{ 'devices.sort' | translate }}">
    <li class="select" (click)="sortDevices('devices', true)">
      <icon class="sortType" name="icon-sort-alphabet"></icon>
      {{ 'traffic.devices' | translate }}
      <icon class="sortIcon" [name]="sort.icon" [class.selected]="sort.field === 'devices'"></icon>
    </li>
    <li class="select" (click)="sortDevices(header.id, true)" *ngFor="let header of headers">
      <div class="circle sortType" [ngStyle]="{ backgroundColor: header.color }"></div>
      {{ header.label | translate }}
      <icon class="sortIcon" [name]="sort.icon" [class.selected]="sort.field === header.id"></icon>
    </li>
  </drop-down-icon>
</div>

<div class="headers">
  <div class="title" (click)="sortDevices('devices', true)">
    {{ 'traffic.devices' | translate }}
    <icon [name]="sort.icon" *ngIf="sort.field === 'devices'"></icon>
  </div>
  <div class="group">
    <div class="header" (click)="sortDevices(header.id, true)" *ngFor="let header of headers">
      <span [ngStyle]="{ color: header.color }">{{ header.label | translate }}</span>
      <icon [name]="sort.icon" *ngIf="sort.field === header.id"></icon>
    </div>
  </div>
  <div class="spacer"></div>
</div>
<ng-container *ngIf="!filterNoDevices">
  <collapsiblebox [showHeader]="false" *ngFor="let device of monitoringDevices">
    <div container>
      <div class="header large">
        <div class="data">
          <div class="icon">
            <icon *ngIf="!device.iconV3" [path]="device.icon" class="size-2"></icon>

            <div *ngIf="device.iconV3" class="icon-container">
              <icon [path]="device.iconV3" class="size-2"> </icon>
            </div>
          </div>

          <div class="details">
            <div class="name">{{ device.name }}</div>
            <div class="mac">{{ 'traffic.mac' | translate }}: {{ device.mac }}</div>
            <div class="totalUsage">
              {{ 'traffic.totalUsage' | translate }}: <span>{{ device.totalUsage }}%</span>
            </div>
          </div>
        </div>
        <div class="deviceDetails">
          <div class="detail" *ngFor="let header of headers" [ngStyle]="{ color: header.color }">
            <div>{{ device?.details[header.id]?.bytes ? convertBytes(device.details[header.id].bytes) : '- -' }}</div>
          </div>
        </div>
        <div class="controls">
          <icon
            name="icon-arrow-down"
            [class.expanded]="device.expanded"
            (click)="device.expanded = !device.expanded"
          ></icon>
          <div class="status" [class.connected]="device.connectionState === 'connected'"></div>
        </div>
      </div>
      <div class="header medium">
        <div class="deviceDetails">
          <div class="detail" *ngFor="let header of headers" [ngStyle]="{ color: header.color }">
            <div class="header">{{ header.label | translate }}</div>
            <div>{{ device?.details[header.id]?.bytes ? convertBytes(device.details[header.id].bytes) : '- -' }}</div>
          </div>
        </div>
      </div>
      <div class="header small">
        <div class="deviceDetails">
          <div class="detail" *ngFor="let header of headers" [ngStyle]="{ color: header.color }">
            <div class="header">{{ header.label | translate }}</div>
            <div>{{ device?.details[header.id]?.bytes ? convertBytes(device.details[header.id].bytes) : '- -' }}</div>
          </div>
        </div>
      </div>
      <div class="chart" *ngIf="device.expanded">
        <h3>{{ 'traffic.dataUsage' | translate }}</h3>
        <flm-graph
          [dataSet]="device.chart"
          [borders]="{ top: 10, right: 20, bottom: 45, left: 40 }"
          *ngIf="device.chart.length"
        ></flm-graph>
        <div class="noData" *ngIf="!device.chart.length">
          <span>{{ 'traffic.noDataAvailable' | translate }}</span>
        </div>
        <div class="legend">
          <div class="label" [ngStyle]="{ color: legend.color }" *ngFor="let legend of headers">
            <div class="pill" [ngStyle]="{ background: legend.color }"></div>
            {{ legend.label | translate }}
          </div>
        </div>
      </div>
    </div>
  </collapsiblebox>
</ng-container>
<div class="no-devices" *ngIf="filterNoDevices">{{ 'traffic.noDevicesFound' | translate }}</div>
