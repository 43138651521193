import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, iif, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { selectCustomerIdentification } from 'src/app/store/customer/customer.selectors';
import {
  IIspOutage,
  IIspOutageMonthData,
  IIspOutageYearData,
  ILteConfiguration,
  ILteCurrentState,
  ILteDataUsage,
  ILteHwInfo,
  ILteNetworkConfiguration,
  ILteSignalStrength,
  ILteSupported,
  IMetricsBeData,
  IMetricsNodeData,
  IQoeLiveMetrics
} from '../interfaces/lte.interface';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { PlumeService } from './plume.service';

@Injectable({
  providedIn: 'root'
})
export class LteService {
  fdqn = this.plume.getEnv()?.lteUrl;

  constructor(private api: ApiService, private auth: AuthService, private plume: PlumeService, private store: Store) {}

  get baseUrl$(): Observable<string> {
    if (!this.fdqn) {
      return throwError('no LTE FDQN set - LTE not supported');
    }

    return this.store.select(selectCustomerIdentification).pipe(
      // on non existing location (and null) returns 401 instead of 404
      switchMap((customer) => iif(() => !!customer.locationid, of(customer), throwError('LTE - invalid location'))),
      map((customer) => `${this.fdqn}/lteservice/locations/${customer.locationid}`),
      take(1)
    );
  }

  fullSupported$(): Observable<ILteSupported> {
    if (this.auth.isExtendedAccess()) return of(null);
    return this.baseUrl$.pipe(
      switchMap((baseUrl) =>
        this.api.raw(
          'get',
          `${baseUrl}/supported`,
          {},
          {
            headers: {
              ...this.auth.getHeaders(undefined, true)
            }
          }
        )
      )
    );
  }

  supported$(): Observable<boolean> {
    if (!this.fdqn) {
      return of(false);
    }
    if (this.auth.isExtendedAccess()) return of(null);
    return this.fullSupported$().pipe(
      map((capable) => !!capable?.isHardwareCapable),
      catchError(() => of(false))
    );
  }

  forceSwitch$(use: boolean): Observable<any> {
    const body = use ? {} : { durationSeconds: 0 };
    return this.baseUrl$.pipe(
      switchMap((baseUrl) =>
        this.api.raw('post', `${baseUrl}/forceSwitchover`, body, {
          headers: {
            ...this.auth.getHeaders(undefined, true)
          }
        })
      )
    );
  }

  enable$(state: boolean): Observable<any> {
    const action = state ? 'enable' : 'disable';
    return this.baseUrl$.pipe(
      switchMap((baseUrl) =>
        this.api.raw(
          'post',
          `${baseUrl}/${action}`,
          {},
          {
            headers: {
              ...this.auth.getHeaders(undefined, true)
            }
          }
        )
      )
    );
  }

  hwInfo$(): Observable<ILteHwInfo> {
    return this.baseUrl$.pipe(
      switchMap((baseUrl) =>
        this.api.raw(
          'get',
          `${baseUrl}/hardwareInfo`,
          {},
          {
            headers: {
              ...this.auth.getHeaders(undefined, true)
            }
          }
        )
      )
    );
  }

  currentState$(): Observable<ILteCurrentState> {
    return this.baseUrl$.pipe(
      switchMap((baseUrl) =>
        this.api.raw(
          'get',
          `${baseUrl}/currentState`,
          {},
          {
            headers: {
              ...this.auth.getHeaders(undefined, true)
            }
          }
        )
      )
    );
  }

  signalStrength$(): Observable<ILteSignalStrength> {
    return this.baseUrl$.pipe(
      switchMap((baseUrl) =>
        this.api.raw(
          'get',
          `${baseUrl}/signalStrength`,
          {},
          {
            headers: {
              ...this.auth.getHeaders(undefined, true)
            }
          }
        )
      )
    );
  }

  putConfiguration$(value: Partial<ILteConfiguration>): Observable<any> {
    return combineLatest([this.baseUrl$, this.getConfiguration$()]).pipe(
      switchMap(([baseUrl, config]) =>
        this.api.raw(
          'put',
          `${baseUrl}/configuration`,
          { ...config, ...value },
          {
            headers: {
              ...this.auth.getHeaders(undefined, true)
            }
          }
        )
      )
    );
  }

  getConfiguration$(): Observable<ILteConfiguration> {
    return this.baseUrl$.pipe(
      switchMap((baseUrl) =>
        this.api.raw(
          'get',
          `${baseUrl}/configuration`,
          {},
          {
            headers: {
              ...this.auth.getHeaders(undefined, true)
            }
          }
        )
      )
    );
  }

  speedTest$(
    granularity: 'days' | 'months' = 'days',
    limit: number = 1
  ): Observable<{
    uploadSpeeds: { timestamp: string; value: number }[];
    downloadSpeeds: { timestamp: string; value: number }[];
    statsDateRange: { start: string; end: string };
  }> {
    return this.baseUrl$.pipe(
      switchMap((baseUrl) =>
        this.api.raw(
          'get',
          `${baseUrl}/speedTests?granularity=${granularity}&limit=${limit}`,
          {},
          {
            headers: {
              ...this.auth.getHeaders(undefined, true)
            }
          }
        )
      )
    );
  }

  startSpeedTest$(nodeId?: string, serverId?: string): Observable<unknown> {
    return this.baseUrl$.pipe(
      switchMap((baseUrl) =>
        this.api.raw(
          'put',
          `${baseUrl}/speedTest`,
          { nodeId, serverId },
          {
            headers: {
              ...this.auth.getHeaders(undefined, true)
            }
          }
        )
      )
    );
  }

  networkConfiguration$(): Observable<ILteNetworkConfiguration> {
    return this.baseUrl$.pipe(
      switchMap((baseUrl) =>
        this.api.raw(
          'get',
          `${baseUrl}/networkConfiguration`,
          {},
          {
            headers: {
              ...this.auth.getHeaders(undefined, true)
            }
          }
        )
      )
    );
  }

  networkConfigurationSet$(network: ILteNetworkConfiguration): Observable<any> {
    return this.baseUrl$.pipe(
      switchMap((baseUrl) =>
        this.api.raw('put', `${baseUrl}/networkConfiguration `, network, {
          headers: {
            ...this.auth.getHeaders(undefined, true)
          }
        })
      )
    );
  }
  ispOutage$(
    startDate: string,
    endDate: string,
    type: 'yearly',
    offsetMinutesfromUTC?: number
  ): Observable<IIspOutage<IIspOutageYearData[]>>;
  ispOutage$(
    startDate: string,
    endDate: string,
    type: 'monthly',
    offsetMinutesfromUTC?: number
  ): Observable<IIspOutage<IIspOutageMonthData[]>>;
  ispOutage$(
    startDate: string,
    endDate: string,
    type: 'yearly' | 'monthly',
    offsetMinutesfromUTC?: number
  ): Observable<IIspOutage<IIspOutageYearData[]> | IIspOutage<IIspOutageMonthData[]>> {
    const params = {
      startDate,
      endDate,
      type
    };

    if (offsetMinutesfromUTC) {
      params['offsetMinutesfromUTC'] = offsetMinutesfromUTC;
    }

    return this.baseUrl$.pipe(
      switchMap((baseUrl) =>
        this.api.raw(
          'get',
          `${baseUrl}/ispOutageInfo`,
          {},
          {
            headers: {
              ...this.auth.getHeaders(undefined, true)
            },
            params
          }
        )
      )
    );
  }

  dataUsage$(
    startDate: string,
    endDate: string,
    type: 'yearly' | 'monthly',
    offsetMinutesfromUTC?: number
  ): Observable<ILteDataUsage> {
    const params = {
      startDate,
      endDate,
      type
    };

    if (offsetMinutesfromUTC) {
      params['offsetMinutesfromUTC'] = offsetMinutesfromUTC;
    }

    return this.baseUrl$.pipe(
      switchMap((baseUrl) =>
        this.api.raw(
          'get',
          `${baseUrl}/dataUsage`,
          {},
          {
            headers: {
              ...this.auth.getHeaders(undefined, true)
            },
            params
          }
        )
      )
    );
  }

  qoe$(limitDays = 1): Observable<IMetricsNodeData | null> {
    return this.baseUrl$.pipe(
      switchMap((baseUrl) =>
        this.api.raw(
          'get',
          `${baseUrl}/metrics?granularity=days&limitDays=${limitDays}`,
          {},
          {
            headers: {
              ...this.auth.getHeaders(undefined, true)
            }
          }
        )
      ),
      map((data: IMetricsBeData) => {
        const nodes = Object.keys(data.data ?? {});
        if (nodes.length === 0) {
          return null;
        }
        const firstNode = data.data[nodes[0]];
        return firstNode;
      })
    );
  }

  qoeLive$(superLive: boolean): Observable<IQoeLiveMetrics[]> {
    return this.baseUrl$.pipe(
      switchMap((baseUrl) =>
        this.api.raw(
          'get',
          `${baseUrl}/liveMetrics${superLive ? '?superLiveMode=true' : ''}`,
          {},
          {
            headers: {
              ...this.auth.getHeaders(undefined, true)
            }
          }
        )
      )
    );
  }
}
