import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastService } from 'src/app/lib/services/toast.service';
import { UntypedFormControl } from '@angular/forms';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { Store } from '@ngrx/store';
import {
  selectCustomer,
  selectPartnerIdLoading,
  selectPipeLocationOnChangeOptimistic
} from 'src/app/store/customer/customer.selectors';
import { map, take } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { partnerIdSet } from 'src/app/store/customer-info/customer-info.actions';
import { GroupService } from 'src/app/lib/services/group.service';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { ICustomerGroup } from 'src/app/lib/interfaces/interface';
import { IFilteredGroup } from 'src/app/lib/interfaces/groups.interface';

@Component({
  selector: 'customergroups',
  templateUrl: './customergroups.component.html',
  styleUrls: ['./customergroups.component.scss']
})
export class CustomerGroupsComponent implements OnInit {
  editPartnerId: boolean = false;
  partnerIdControl: UntypedFormControl = new UntypedFormControl();
  loadingGroups: boolean = false;
  groups: ICustomerGroup[] = [];
  allGroups: { text: string; value: string; object: IFilteredGroup }[] = [];
  partnerIdUpdateLoading$ = this.store.select(selectPartnerIdLoading);
  groupLimitSetting: number = 500;
  selected: string = '';
  offset: number = 0;
  location$ = this.store.pipe(selectPipeLocationOnChangeOptimistic);
  partnerId$ = this.getObservePartnerId$();

  @Output()
  groupsChanged = new EventEmitter<ICustomerGroup[]>();

  constructor(
    private mixpanel: MixpanelService,
    private toast: ToastService,
    public plume: PlumeService,
    private store: Store,
    private groupService: GroupService,
    private customerService: CustomerService
  ) {}

  ngOnInit(): void {
    this.getCustomerGroups();
  }

  toggleSetPartnerId(): void {
    this.editPartnerId = !this.editPartnerId;
    this.partnerId$.pipe(take(1)).subscribe((partnerId) => this.partnerIdControl.setValue(partnerId));
  }

  updatePartnerId(): void {
    this.editPartnerId = !this.editPartnerId;
    const partnerId = this.partnerIdControl.value ? this.partnerIdControl.value : null;

    this.store.dispatch(partnerIdSet({ partnerId }));
  }

  searchGroups(value: { text: string; value: string; object: IFilteredGroup }, filterText: string): boolean {
    return (
      value.object.name.toLowerCase().includes(filterText.toLowerCase()) ||
      value.object.id.toLowerCase().includes(filterText.toLowerCase())
    );
  }

  removeGroup(groupId: string): void {
    this.groupService.removeCustomerRelation$(groupId).subscribe(() => {
      this.getCustomerGroups(false);
      this.mixpanel.storeEvent('CUSTOMER_DELETE_GROUP');
      this.toast.success('customerGroups.removeGroupToastMessage', 'customerGroups.removeGroupToastTitle');
    });
  }

  addGroup(): void {
    if (this.selected) {
      this.groupService.addCustomerRelation$(this.selected).subscribe(
        () => {
          this.selected = '';
          this.getCustomerGroups(false);

          this.mixpanel.storeEvent('CUSTOMER_ADD_GROUP');
          this.toast.success('customerGroups.addGroupToastMessage', 'customerGroups.addGroupToastTitle');
        },
        (error) => {
          this.mixpanel.storeEvent('CUSTOMER_ADD_GROUP_ERROR');
          this.toast.error(error.error.error.message, error.error.error.name);
        }
      );
    }
  }

  selectGroup(id: string): void {
    this.selected = id;
  }

  getCustomerGroups(showLoading: boolean = true): void {
    this.loadingGroups = showLoading;
    this.offset = 0;
    this.customerService.getGroups$().subscribe((response) => {
      if (response) {
        this.groups = response;
        this.groupsChanged.emit(this.groups);
        this.loadingGroups = false;
      }
    });

    if (this.plume.isStrictAdminRole()) {
      this.selected = '';
      this.allGroups = [];
      this.getGroups();
    }

    this.mixpanel.storeEvent('CUSTOMER_GROUPS_SCREEN');
  }

  getGroups(): void {
    this.groupService
      .getGroups$({ order: 'name ASC', limit: this.groupLimitSetting, offset: this.offset })
      .subscribe((response) => {
        if (response) {
          this.allGroups = [
            ...this.allGroups,
            ...response.map((group) => ({ text: group.name, value: group.id, object: group }))
          ];

          if (response.length === this.groupLimitSetting) {
            this.offset = this.offset + this.groupLimitSetting;
            this.getGroups();
          }
        }
      });
  }

  private getObservePartnerId$(): Observable<string> {
    return combineLatest([this.store.select(selectCustomer), this.location$, this.partnerIdUpdateLoading$]).pipe(
      map(([customer, location, updateLoading]) => updateLoading ? this.partnerIdControl.value : location.partnerId || customer.partnerId)
    );
  }
}
