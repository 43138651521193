import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Line } from 'src/app/lib/d3/models/objects/line';
import { Point } from 'src/app/lib/d3/models/objects/point';
import { Series } from 'src/app/lib/d3/models/objects/series';
import { IDeviceOrNodeLiveModeStream, IDeviceOrNodeLiveModeStreamV2 } from 'src/app/lib/interfaces/interface';
import { IconService } from 'src/app/lib/services/icon.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { QoeService } from 'src/app/lib/services/qoe.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';

@UntilDestroy()
@Component({
  selector: 'qoesuperlive',
  templateUrl: './qoesuperlive.component.html',
  styleUrls: ['./qoesuperlive.component.scss']
})
export class QoesuperliveComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  data: any = {};

  @Input()
  period: number = 5;

  @Input()
  enabled: boolean = false;

  loopSubscription: any;
  showLiveCharts: boolean = false;
  showCurrentQoe: boolean = false;
  expanded: boolean = false;
  iconURL: string = '';
  loopLiveData: boolean = false;
  permissions: any = null;
  chartSelector: any = {
    list: ['snr', 'qoeScore', 'channelUtilization', 'prr', 'potentialThroughput', 'currentUsage', 'phyRate'],
    selected: 'snr'
  };

  liveCharts = null;
  chartAvailableBands: { value: string; translation: string; selected: boolean }[] = [];
  chartSelectedBand: string = 'none';

  chartColors = [
    'rgb(151, 57, 153)',
    'rgb(153, 204, 51)',
    'rgb(247, 130, 0)',
    'rgb(153, 204, 51)',
    'rgb(255, 102, 178)',
    'rgb(0, 76, 153)',
    'rgb(102, 51, 102)',
    'rgb(89, 199, 194)',
    'rgb(204, 153, 102)',
    'rgb(0, 156, 223)',
    'rgb(94, 189, 62)',
    'rgb(102, 102, 0)',
    'rgb(153, 0, 0)'
  ];

  chartNames = [
    'usageBasedScore',
    'needBasedScore',
    'snr',
    'dynamicRssi',
    'channelUtilization',
    'interference',
    'rxPrr',
    'txPrr',
    'rxPhyRate',
    'txPhyRate',
    'potentialThroughput',
    'currentUsage',
    'isLpm'
  ];

  constructor(
    private qoe: QoeService,
    private troubleshooting: TroubleshootingService,
    private mixpanel: MixpanelService,
    private icons: IconService,
    private toast: ToastService,
    private plume: PlumeService
  ) {}

  ngOnInit(): void {
    this.plume.permissions.pipe(untilDestroyed(this)).subscribe((data: any) => {
      this.permissions = data;
      clearTimeout(this.loopSubscription);
      this.getLiveData();
    });
  }

  ngOnChanges(changes: any): void {
    this.getIconPath();
    let updateData: boolean = false;

    if (changes.enabled) {
      this.loopLiveData = this.enabled;

      if (this.enabled) {
        updateData = true;
      }
    }

    if (changes.period) {
      updateData = true;
    }

    if (this.data.liveData) {
      this.data.liveCharts = this.prepareCharts(
        this.qoe.lineCharts(
          JSON.parse(JSON.stringify(this.data.liveData)),
          this.data.demo ? this.data.demo.current.values : {}
        )
      );
    }

    if (changes.data) {
      if (
        changes.data.previousValue &&
        changes.data.previousValue.id &&
        changes.data.previousValue.id !== changes.data.currentValue.id
      ) {
        this.data.liveData = {};
        this.showLiveCharts = false;
        this.expanded = false;
        updateData = true;
      }
    }

    if (updateData) {
      clearTimeout(this.loopSubscription);
      this.getLiveData();
    }

    this.getIconPath();
  }

  selectChart(chart: string): void {
    this.chartSelector.selected = chart;
  }

  getIconPath(): void {
    if (this.data.type === 'pod') {
      this.iconURL = this.data.icon;
    } else {
      this.iconURL = this.icons.getPath(this.data.iconV2);
    }
  }

  toggleExpand(): void {
    if (!this.expanded) {
      this.expanded = true;
      this.showLiveCharts = true;
    } else {
      this.expanded = false;
      this.showLiveCharts = false;
    }
  }

  getLiveData(): void {
    if (!this.permissions) return;

    let observable = null;
    let version = 'v1';

    if (this.plume.cloudVersionAbove1_142() && this.permissions?.uiFeatures?.qoeV2) {
      observable =
        this.data.type === 'pod'
          ? this.qoe.nodeSuperLiveModeStreamV2$(this.data.id)
          : this.qoe.deviceSuperLiveModeStreamV2$(this.data.id);
      version = 'v2';
    } else {
      observable =
        this.data.type === 'pod'
          ? this.qoe.nodeSuperLiveModeStream$(this.data.id)
          : this.qoe.deviceSuperLiveModeStream$(this.data.id);
      version = 'v1';
    }

    observable.pipe(catchError((error: any) => of(error))).subscribe((response: any) => {
      if (version === 'v1') this.calculateCharts(response);
      if (version === 'v2') this.calculateChartsV2(response);

      this.loopSubscription = setTimeout(() => {
        if (this.loopLiveData) {
          this.getLiveData();
        }
      }, this.period * 1000);
    });
  }

  calculateCharts(response: IDeviceOrNodeLiveModeStream) {
    this.data.liveData = JSON.parse(JSON.stringify(response));
    this.data.qoe.congestion = this.data.liveData.channelUtilization[0]
      ? this.data.liveData.channelUtilization[0].value
      : this.data.qoe.congestion;
    this.data.liveCharts = this.prepareCharts(
      this.qoe.lineCharts(response, this.data.demo ? this.data.demo.current.values : {})
    );
    if (this.data.liveData.snr[0]?.value) {
      this.data.qoe.rssi = this.data.liveData.snr[0].value - 95;
    }

    if (this.data.status === 'connected') {
      this.showLiveCharts = true;
    }
  }

  calculateChartsV2(response: IDeviceOrNodeLiveModeStreamV2) {
    const connectionBasedCharts = ['needBasedScore', 'usageBasedScore', 'potentialThroughput', 'currentUsage'];

    const liveCharts = {};

    const hasMlo = response.data.some((dataPoint) => dataPoint.connection.isMlo);

    response.data.forEach((dataPoint, i) => {
      const timestamp = dataPoint.timestamp;

      this.chartNames.forEach((chartName) => {
        // If difference between this and next data point is one minute, insert a null value to make a gap in the chart (was done on the backend for previous version)
        if (i < response.data.length - 1) {
          const nextTimestamp = response.data[i + 1].timestamp;
          if (nextTimestamp - timestamp > 60 * 1000) {
            let freqBands = ['joint'];
            if (!dataPoint.connection.isMlo) {
              freqBands = hasMlo ? [`${dataPoint.connection.freqBand}`] : ['joint'];
            } else {
              freqBands = dataPoint.links.map((link) => (hasMlo ? `${link.freqBand}` : 'joint'));
            }

            for (const freqBand of freqBands) {
              if (!liveCharts[freqBand]) liveCharts[freqBand] = {};
              if (!liveCharts[freqBand][chartName])
                liveCharts[freqBand][chartName] = {
                  points: [],
                  allPoints: []
                };

              const newTimestamp = timestamp + 60 * 1000;
              if (Date.now() - newTimestamp < 15 * 60 * 1000) {
                liveCharts[freqBand][chartName].points.push(new Point(new Date(newTimestamp).toString(), null));
              }
              liveCharts[freqBand][chartName].allPoints.push({ timestamp: newTimestamp, value: null });
            }
          }
        }

        if (!dataPoint.connection.isMlo) {
          const freqBand = hasMlo ? dataPoint.connection.freqBand : 'joint';
          if (freqBand === null) return;

          if (!liveCharts[freqBand]) liveCharts[freqBand] = {};
          if (!liveCharts[freqBand][chartName])
            liveCharts[freqBand][chartName] = {
              points: [],
              allPoints: []
            };

          let value =
            dataPoint.connection[chartName] !== null ? parseFloat(`${dataPoint.connection[chartName]}`) : null;
          if (chartName === 'snr') {
            value = value ? parseFloat(`${value}`) - 95 : null;
          }

          if (chartName === 'dynamicRssi') {
            value = dataPoint.connection['snr']
              ? this.data.rawQoe.noiseFloor
                ? parseFloat(`${dataPoint.connection['snr']}`) + parseFloat(this.data.rawQoe.noiseFloor)
                : parseFloat(`${dataPoint.connection['snr']}`) - 95
              : null;
          }

          if (Date.now() - timestamp < 15 * 60 * 1000) {
            liveCharts[freqBand][chartName].points.push(new Point(new Date(timestamp).toString(), value));
          }
          liveCharts[freqBand][chartName].allPoints.push({ timestamp, value });
        } else {
          const links = dataPoint.links;

          links.forEach((link) => {
            const freqBand = hasMlo ? link.freqBand : 'joint';

            if (!liveCharts[freqBand]) liveCharts[freqBand] = {};
            if (!liveCharts[freqBand][chartName])
              liveCharts[freqBand][chartName] = {
                points: [],
                allPoints: []
              };

            let value = link[chartName] !== null ? parseFloat(`${link[chartName]}`) : null;
            if (connectionBasedCharts.includes(chartName)) {
              value =
                dataPoint.connection[chartName] !== null ? parseFloat(`${dataPoint.connection[chartName]}`) : null;
            }
            if (chartName === 'snr') {
              value = value ? parseFloat(`${value}`) - 95 : null;
            }

            if (chartName === 'dynamicRssi') {
              value = dataPoint.connection['snr']
                ? this.data.rawQoe.noiseFloor
                  ? parseFloat(`${dataPoint.connection['snr']}`) + parseFloat(this.data.rawQoe.noiseFloor)
                  : parseFloat(`${dataPoint.connection['snr']}`) - 95
                : null;
            }

            if (Date.now() - timestamp < 15 * 60 * 1000) {
              liveCharts[freqBand][chartName].points.push(new Point(new Date(timestamp).toString(), value));
            }
            liveCharts[freqBand][chartName].allPoints.push({ timestamp, value });
          });
        }
      });
    });

    if (Object.keys(liveCharts).length === 0) {
      this.showLiveCharts = true;
      return;
    }

    this.liveCharts = liveCharts;

    const bands = Object.keys(liveCharts).sort((a, b) => {
      const priority = { '2.4G': 1, '5G': 2, '5GL': 3, '5GU': 4, '6G': 5 };
      const priorityA = priority[a] || 999;
      const priorityB = priority[b] || 999;
      return priorityA - priorityB;
    });

    if (!bands.includes(this.chartSelectedBand)) this.chartSelectedBand = bands[0];
    this.chartAvailableBands = bands
      .filter((band) => band !== 'joint')
      .map((band) => ({
        value: band,
        translation: band,
        selected: this.chartSelectedBand === band
      }));

    this.data.liveCharts = {};
    this.chartNames.forEach((chartName, index) => {
      this.data.liveCharts[chartName] = new Line(
        new Series(this.chartColors[index], chartName, 'qoe.charts.' + chartName),
        'left',
        liveCharts[this.chartSelectedBand][chartName].points,
        liveCharts[this.chartSelectedBand][chartName].allPoints
      );
    });

    this.data.liveCharts = this.prepareCharts(this.data.liveCharts);
    Object.keys(this.data.liveCharts).forEach((chartName) => {
      this.data.liveCharts[chartName].data.reverse();
    });

    this.showLiveCharts = true;
  }

  prepareCharts(charts: any): any {
    Object.keys(charts).forEach((key: string) => {
      if (charts[key].data && charts[key].data.length) {
        charts[key].data = charts[key].data.filter(
          (point: any) =>
            new Date(point.label).getTime() >= new Date(charts[key].data[0].label).getTime() - 10 * 60 * 1000
        );
      }
    });

    return charts;
  }

  selectChartBand(band: string): void {
    this.chartSelectedBand = band;
    this.chartAvailableBands = this.chartAvailableBands.map((item) => {
      item.selected = item.value === band;
      return item;
    });

    this.data.liveCharts = {};
    this.chartNames.forEach((chartName, index) => {
      this.data.liveCharts[chartName] = new Line(
        new Series(this.chartColors[index], chartName, 'qoe.charts.' + chartName),
        'left',
        this.liveCharts[band][chartName].points,
        this.liveCharts[band][chartName].allPoints
      );
    });
    this.data.liveCharts = this.prepareCharts(this.data.liveCharts);
    Object.keys(this.data.liveCharts).forEach((chartName) => {
      this.data.liveCharts[chartName].data.reverse();
    });
  }

  resolveAction(action: string): void {
    switch (action) {
      case 'qoe.recommendations.actions.rebootNode':
        if (this.data.type === 'pod') {
          if ((this.plume.cloudVersionAbove1_119() || !this.plume.isStrictSupportRole()) && !this.plume.isFlexRole()) {
            this.troubleshooting.resetNode(this.data.id).subscribe();
            this.mixpanel.storeEvent('QOE_RECOMMENDATION_REBOOT_NODE', { NODE_ID: this.data.id });
          } else {
            this.toast.error('qoe.recommendations.roleMsg', 'qoe.recommendations.roleTitle');
          }
        }
        break;
      case 'qoe.recommendations.actions.resetDevice':
        if (this.data.type === 'device') {
          this.troubleshooting.resetDevice(this.data.id, this.data.parentNodeId, this.data.freqBand).subscribe();
          this.mixpanel.storeEvent('QOE_RECOMMENDATION_RESET_DEVICE', {
            DEVICE_MAC: this.data.id,
            PARENT_ID: this.data.parentNodeId,
            FREQ_BAND: this.data.freqBand
          });
        }
        break;
    }
  }

  ngOnDestroy(): void {
    if (this.loopSubscription) {
      clearTimeout(this.loopSubscription);
    }

    this.loopLiveData = false;
  }
}
