<div class="charts">
  <collapsiblebox [showHeader]="false">
    <div container>
      <h3>{{ 'traffic.usageTrend' | translate }}</h3>
      <div class="line-chart" *ngIf="usageTrendChartDataset.length">
        <linechart
          [period]="granularityItemsCurrent.value"
          [series]="usageTrendChartSeries"
          [scale]="{ symbol: 'min' }"
          [data]="usageTrendChartDataset"
          (regionClick)="selectTrendTimestamp($event)"
        >
        </linechart>
      </div>
      <div class="noData overview" *ngIf="!usageTrendChartDataset.length">
        <span>{{ 'traffic.noDataAvailable' | translate }}</span>
      </div>
      <div class="legend overview">
        <div class="label" [ngStyle]="{ color: legend.color }" *ngFor="let legend of legend">
          <div class="pill" [ngStyle]="{ background: legend.color }"></div>
          {{ legend.label | translate }}
        </div>
      </div>
    </div>
  </collapsiblebox>

  <div class="usageTrendDetails" *ngIf="selectedUsageTrendTimestamp">
    <div class="title">
      {{ 'traffic.top5devicesTrend' | translate }}<icon name="icon-close" (click)="selectTrendTimestamp(null)"></icon>
    </div>
    <div class="subtitle">{{ selectedUsageTrendTimestamp | date : 'DD MMM YYYY' }}</div>
    <div class="devices" *ngIf="top5DevicesTrend?.[selectedUsageTrendTimestamp]">
      <div class="device" *ngFor="let device of top5DevicesTrend[selectedUsageTrendTimestamp]">
        <div class="deviceName">
          <div class="circle">
            <div class="icon-container">
              <icon [path]="device.iconURL" class="device-icon"></icon>
            </div>
          </div>
          <div class="data">
            <div class="nickname">
              <ng-container *ngIf="device.nickname || device.name">{{
                device.nickname || device.name | macAddr : true
              }}</ng-container>
              <ng-container *ngIf="!device.nickname && !device.name">{{
                'health.networkInformation.topologyHistory.unknown' | translate
              }}</ng-container>
            </div>
            <div class="sn">{{ device.mac | macAddr }}</div>
          </div>
        </div>

        <div class="details">
          {{ device.onlineMinutes | time }}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="togglers">
  <slidetoggler [options]="granularityItems" (toggle)="changeGranularity()"></slidetoggler>
  <slidetoggler [options]="modeItems" (toggle)="changeMode()"></slidetoggler>
</div>
<div class="charts">
  <collapsiblebox [showHeader]="false">
    <div container>
      <h3>{{ 'traffic.onlineOverview' | translate }}</h3>
      <div class="stacked-chart" *ngIf="onlineMinutesChartDataset.length">
        <stacked-bar
          [selectedItemIndex]="selectedOnlineOverviewIndex"
          (selectedItemIndexChange)="selectedOnlineOverviewIndex = $event"
          [data]="onlineMinutesChartDataset"
        >
        </stacked-bar>
        <grid-charts-details [data]="onlineMinutesChartDataset[selectedOnlineOverviewIndex]"></grid-charts-details>
      </div>
      <div class="noData overview" *ngIf="!onlineMinutesChartDataset.length">
        <span>{{ 'traffic.noDataAvailable' | translate }}</span>
      </div>
      <div class="legend overview">
        <div class="label" [ngStyle]="{ color: legend.color }" *ngFor="let legend of legend">
          <div class="pill" [ngStyle]="{ background: legend.color }"></div>
          {{ legend.label | translate }}
        </div>
      </div>
    </div>
  </collapsiblebox>
  <collapsiblebox [showHeader]="false">
    <div container>
      <h3>{{ 'traffic.dataUsageOverview' | translate }}</h3>
      <div class="stacked-chart" *ngIf="dataUsageChartDataset.length">
        <stacked-bar
          [selectedItemIndex]="selectedDataOverviewIndex"
          (selectedItemIndexChange)="selectedDataOverviewIndex = $event"
          [data]="dataUsageChartDataset"
        >
        </stacked-bar>
        <grid-charts-details [data]="dataUsageChartDataset[selectedDataOverviewIndex]"></grid-charts-details>
      </div>
      <div class="noData overview" *ngIf="!dataUsageChartDataset.length">
        <span>{{ 'traffic.noDataAvailable' | translate }}</span>
      </div>
      <div class="legend overview">
        <div class="label" [ngStyle]="{ color: legend.color }" *ngFor="let legend of legend">
          <div class="pill" [ngStyle]="{ background: legend.color }"></div>
          {{ legend.label | translate }}
        </div>
      </div>
    </div>
  </collapsiblebox>
</div>
<div class="charts">
  <collapsiblebox [showHeader]="false">
    <div container>
      <h3>{{ 'traffic.top5devices' | translate }}</h3>
      <flm-graph
        [dataSet]="graphDataSet"
        [borders]="{ top: 10, right: 20, bottom: 50, left: 40 }"
        *ngIf="graphDataSet.length"
      ></flm-graph>
      <div class="noData" *ngIf="!graphDataSet.length">
        <span>{{ 'traffic.noDataAvailable' | translate }}</span>
      </div>
      <div class="legend topDevices">
        <div class="label" [ngStyle]="{ color: legend.color }" *ngFor="let legend of legend">
          <div class="pill" [ngStyle]="{ background: legend.color }"></div>
          {{ legend.label | translate }}
        </div>
      </div>
    </div>
  </collapsiblebox>
  <collapsiblebox [showHeader]="false">
    <div container>
      <h3>{{ 'traffic.avgQoeTrafficClass' | translate }}</h3>
      <flm-graph
        [dataSet]="avgDataSet"
        [borders]="{ top: 10, right: 20, bottom: 50, left: 40 }"
        *ngIf="avgDataSet.length"
      ></flm-graph>
      <div class="noData" *ngIf="!avgDataSet.length">
        <span>{{ 'traffic.noDataAvailable' | translate }}</span>
      </div>
      <div class="legend avgQoe">
        <div class="label" [ngStyle]="{ color: legend.color }" *ngFor="let legend of avgLegend">
          <div class="pill" [ngStyle]="{ background: legend.color }"></div>
          {{ legend.label | translate }}
        </div>
      </div>
    </div>
  </collapsiblebox>
</div>
<div class="charts">
  <collapsiblebox [collapsible]="true" toggleSide="right" *ngIf="appPrioritization || appPrioritizationV2">
    <div header>
      {{ 'traffic.prioritization' | translate }}
      <ng-container *ngIf="!APIv1108">
        <div class="toggler skip-toggle" (click)="togglePrioritization()">
          <div class="state" [class.on]="prioritization.enabled">
            {{ prioritization.state | translate }}
          </div>
          <div class="checkbox">
            <label for="toggle" class="toggle" [class.checked]="prioritization.enabled"></label>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="APIv1108 && !APIv1122">
        <slidetoggler
          [options]="prioModeItems"
          [confirmChange]="confirmPrioritizationChange"
          [editable]="!isSomeSupport"
          (toggle)="togglePrioritization($event)"
        ></slidetoggler>
      </ng-container>
      <ng-container *ngIf="APIv1122">
        <slidetoggler
          [options]="prioModeItems"
          [confirmChange]="confirmPrioritizationChange"
          [editable]="!isSomeSupport"
          (toggle)="togglePrioritizationV2($event)"
        ></slidetoggler>
      </ng-container>
    </div>
    <div container>
      <ng-container *ngIf="APIv1122">
        <div class="priority">
          <div class="key">
            {{ 'traffic.boostPriority' | translate }}
            <icon name="icon-info" class="infoicon" tooltip="{{ 'traffic.boostTooltip' | translate }}"></icon>
          </div>
          <div class="value">{{ 'traffic.' + boost.current | translate }}</div>
          <icon name="icon-edit" class="editicon" (click)="editBoost = !editBoost" *ngIf="!isSomeSupport"></icon>
        </div>
        <div class="priority" *ngIf="editBoost">
          <div class="key">&nbsp;</div>
          <div class="value">
            <drop-down
              class="medium"
              forceState="active"
              [disabled]="!prioritization.enabled || isSomeSupport || disablePrioritySetting"
            >
              {{ 'traffic.' + categories.current | translate }}
              <ng-container dropdown>
                <li *ngFor="let category of categories.list" (click)="selectCategoryBoost(category)">
                  {{ category.translation | translate }}
                </li>
              </ng-container>
            </drop-down>
          </div>
        </div>
        <div class="priority" *ngIf="boost.currentExpiry">
          <div class="key">
            {{ 'traffic.expiresAt' | translate }}
          </div>
          <div class="value">{{ formatDate(boost.currentExpiry) | translate }}</div>
        </div>
        <div class="priority">
          <div class="key">
            {{ 'traffic.homeSecurity' | translate }}
          </div>
          <div class="value">
            <ng-container *ngIf="appPrioritizationV2.homeSecurity?.enable">
              <div class="circle green"></div>
              <div>{{ 'enabled' | translate }}</div>
            </ng-container>
            <ng-container *ngIf="!appPrioritizationV2.homeSecurity?.enable">
              <div class="circle red"></div>
              <div>{{ 'disabled' | translate }}</div>
            </ng-container>
          </div>
        </div>
        <div class="priority">
          <div class="key">
            {{ 'traffic.featureInitialized' | translate }}
          </div>
          <div class="value">{{ formatDate(appPrioritizationV2.ftuxEngagedAt) | translate }}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="templates.current && !APIv1122">
        <h4>{{ 'traffic.setPriorities' | translate }}</h4>
        <div class="controls">
          <drop-down
            class="medium"
            forceState="active"
            [disabled]="!prioritization.enabled || isSomeSupport || disablePrioritySetting"
          >
            {{ 'traffic.' + templates.current | translate }}
            <ng-container dropdown>
              <li *ngFor="let template of templates.list" (click)="selectTemplate(template)">
                {{ template.translation | translate }}
              </li>
            </ng-container>
          </drop-down>
        </div>
        <div class="customPriorities" *ngIf="templates.current === 'custom'">
          <div class="priority" *ngFor="let priority of priorities">
            <div class="label">
              {{ priority.name | translate }}
            </div>
            <slidetoggler
              [options]="priority.items"
              [editable]="prioritization.enabled && !priority.disabled && !isSomeSupport && !disablePrioritySetting"
              (toggle)="changePriority(priority, $event)"
            ></slidetoggler>
          </div>
        </div>
      </ng-container>
    </div>
  </collapsiblebox>
</div>
