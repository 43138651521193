<div class="headers">
  <div class="header space">
    <div class="value">&nbsp;</div>
  </div>
  <div class="header name">
    <div class="value">{{ 'qoe.headers.name' | translate }}</div>
  </div>
  <div class="header connection">
    <div class="value">{{ 'qoe.headers.connection' | translate }}</div>
  </div>
  <div class="header busy">
    <div class="value">{{ 'qoe.headers.busy' | translate }}</div>
  </div>
  <div class="header qoe">
    <div class="value">{{ 'qoe.headers.qoe' | translate }}</div>
  </div>
  <div class="header rssi">
    <div class="value">{{ 'qoe.headers.rssi' | translate }}</div>
  </div>
  <div class="header effPhyRate">
    <div class="value">{{ 'qoe.headers.effPhyRate' | translate }}</div>
  </div>
  <div class="header congestion">
    <div class="value">{{ 'qoe.headers.congestion' | translate }}</div>
  </div>
  <div class="header potentialThroughput">
    <div class="value">{{ 'qoe.headers.predThroughput' | translate }}</div>
  </div>
</div>
<div class="content">
  <div class="strip">
    <icon *ngIf="data.type === 'pod'" [path]="iconURL" class="column size-2" [class.node]="data.type === 'pod'"></icon>

    <div *ngIf="data.type === 'device'" class="icon-container">
      <icon [path]="iconURL" class="size-2"> </icon>
    </div>
    <div class="column name">
      <div class="ellipsis">{{ data.name | macAddr : true }}</div>
    </div>
    <div class="column connection">
      <div *ngIf="data.status === 'connected' && !data.qoe?.rawQoe?.mlo">{{ data.connection | translate }}</div>
      <div *ngIf="data.status === 'connected' && data.qoe?.rawQoe?.mlo">{{ 'mlo' | translate }}</div>
    </div>
    <div class="column busy" *ngIf="data.connection != 'qoe.ethernet' && data.status == 'connected' && data.rawQoe">
      <div class="wrapper" *ngIf="data.qoe.busy">
        <div class="point low"></div>
        <div class="point medium"></div>
        <div class="point high"></div>
      </div>
    </div>
    <div
      class="column qoe"
      *ngIf="data.connection != 'qoe.ethernet' && data.status == 'connected' && data.rawQoe"
      tooltip="{{ data.qoe.qoe }}"
    >
      <health-dots [rssiOrQoe]="data"></health-dots>
    </div>
    <div
      class="column rssi colors"
      [ngClass]="data.qoe.qoe > 4 ? 'good' : data.qoe.qoe < 2 ? 'poor' : data.qoe.qoe < 3 ? 'warn' : 'fair'"
      *ngIf="data.connection != 'qoe.ethernet' && data.status == 'connected' && data.rawQoe"
    >
      {{ data.qoe.rssi }} dBm
    </div>
    <div
      class="column phy-rate colors"
      [ngClass]="data.qoe.qoe > 4 ? 'good' : data.qoe.qoe < 2 ? 'poor' : data.qoe.qoe < 3 ? 'warn' : 'fair'"
      *ngIf="data.connection != 'qoe.ethernet' && data.status == 'connected' && data.rawQoe"
    >
      <div class="wrapper" *ngIf="data.qoe.phyRateEff && data.qoe.phyRateEff <= 100">{{ data.qoe.phyRateEff }} %</div>
    </div>
    <div
      class="column congestion colors"
      [ngClass]="data.qoe.qoe > 4 ? 'good' : data.qoe.qoe < 2 ? 'poor' : data.qoe.qoe < 3 ? 'warn' : 'fair'"
      *ngIf="data.connection != 'qoe.ethernet' && data.status == 'connected' && data.rawQoe"
    >
      {{ data.qoe.congestion }} %
    </div>
    <div
      class="column potentialThroughput"
      *ngIf="data.connection != 'qoe.ethernet' && data.status == 'connected' && data.rawQoe"
    >
      {{ data.qoe.potentialThroughput }} Mbps
    </div>
    <div
      class="column no-data"
      *ngIf="(!data.rawQoe || data.connection == 'qoe.ethernet') && data.status == 'connected'"
    >
      {{ 'qoe.noQoeData' | translate }}
    </div>
    <div class="column no-data" *ngIf="data.status != 'connected'">
      {{ 'qoe.offline' | translate }}
    </div>
    <icon
      name="icon-arrow-down"
      class="expander"
      [class.rotate180]="expanded"
      (click)="toggleExpand()"
      *ngIf="data.status !== 'connected'"
    ></icon>
  </div>
  <div class="details" *ngIf="enabled && (data.status == 'connected' || showLiveCharts)">
    <div class="controllers">
      <span class="title"> {{ 'qoe.selectGraph' | translate }} </span>
      <drop-down class="selector medium">
        {{ 'qoe.charts.' + chartSelector.selected | translate }}
        <ng-container dropdown>
          <li (click)="selectChart(chart)" *ngFor="let chart of chartSelector.list">
            {{ 'qoe.charts.' + chart | translate }}
          </li>
        </ng-container>
      </drop-down>
      <div class="bandToggle" *ngIf="chartAvailableBands.length > 1">
        <slidetoggler [options]="chartAvailableBands" (toggle)="selectChartBand($event)"></slidetoggler>
      </div>
    </div>
    <ng-container *ngIf="chartSelector.selected === 'qoeScore'">
      <qoelivechart
        [series]="[data.liveCharts?.needBasedScore?.series, data.liveCharts?.usageBasedScore?.series]"
        [data]="[data.liveCharts?.needBasedScore, data.liveCharts?.usageBasedScore]"
        [scale]="{ range: [0, 5], symbol: '', thresholdValue: 2, above: false }"
      ></qoelivechart>
    </ng-container>
    <ng-container *ngIf="chartSelector.selected === 'snr'">
      <qoelivechart
        [series]="[data.liveCharts?.snr?.series]"
        [data]="[data.liveCharts?.snr]"
        [scale]="{ range: [-100, 0], symbol: 'dBm', thresholdValue: -73, above: false }"
      ></qoelivechart>
    </ng-container>
    <ng-container *ngIf="chartSelector.selected === 'channelUtilization'">
      <qoelivechart
        [series]="[data.liveCharts?.channelUtilization?.series, data.liveCharts?.interference?.series]"
        [data]="[data.liveCharts?.channelUtilization, data.liveCharts?.interference]"
        [scale]="{ range: [0, 100], symbol: '%', thresholdValue: 75, above: true }"
      ></qoelivechart>
    </ng-container>
    <ng-container *ngIf="chartSelector.selected === 'prr'">
      <qoelivechart
        [series]="[data.liveCharts?.rxPrr?.series, data.liveCharts?.txPrr?.series]"
        [data]="[data.liveCharts?.rxPrr, data.liveCharts?.txPrr]"
        [scale]="{ range: [0, 100], symbol: '%', thresholdValue: 75, above: true }"
      ></qoelivechart>
    </ng-container>
    <ng-container *ngIf="chartSelector.selected === 'potentialThroughput'">
      <qoelivechart
        [series]="[data.liveCharts?.potentialThroughput?.series]"
        [data]="[data.liveCharts?.potentialThroughput]"
        [scale]="{ range: null, symbol: 'Mbps' }"
      ></qoelivechart>
    </ng-container>
    <ng-container *ngIf="chartSelector.selected === 'currentUsage'">
      <qoelivechart
        [series]="[data.liveCharts?.currentUsage?.series]"
        [data]="[data.liveCharts?.currentUsage]"
        [scale]="{ range: null, symbol: 'Mbps' }"
      ></qoelivechart>
    </ng-container>
    <ng-container *ngIf="chartSelector.selected === 'phyRate'">
      <qoelivechart
        [series]="[data.liveCharts?.rxPhyRate?.series, data.liveCharts?.txPhyRate?.series]"
        [data]="[data.liveCharts?.rxPhyRate, data.liveCharts?.txPhyRate]"
        [scale]="{ range: null, symbol: 'Mbps' }"
      ></qoelivechart>
    </ng-container>
  </div>
  <div class="recommendation" *ngFor="let recommendation of data.recommendations">
    <icon [path]="recommendation.icon" class="size-1_4"></icon>
    <div class="description">
      {{ recommendation.description | translate }}
    </div>
    <div class="actions" *ngIf="recommendation.actions.length">
      <div class="action" *ngFor="let action of recommendation.actions" (click)="resolveAction(action)">
        {{ action | translate }}
      </div>
    </div>
  </div>
</div>
