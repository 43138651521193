<div class="bubble arrow yes">
  <div class="troubleShootTitle">
    {{ 'health.bubbles.gatewayCompareBubble.inconsistentResultsHeading' | translate }}
  </div>

  <div class="data">
    <div class="col-xs-8">
      <div class="grid header">
        <div class="nodeIcon"></div>
        <div class="infoDeviceName infoHeading">
          {{ 'health.bubbles.gatewayCompareBubble.nodeName' | translate }}
        </div>
        <div class="infoSpeedValue infoHeading">
          {{ 'health.bubbles.gatewayCompareBubble.averageSpeedtest' | translate }}
        </div>
      </div>
      <div *ngFor="let node of filteredNodes" class="grid">
        <div class="nodeIcon">
          <icon [path]="getIcon(node.model)" class="size-1_4"></icon>
        </div>
        <div class="infoDeviceName">
          {{ node?.name }}
        </div>
        <div class="infoSpeedValue">{{ node?.averageSpeed | number : '1.0-2' }} Mbps</div>
      </div>
    </div>
  </div>

  <div class="row optButtonRow">
    <div class="buttons">
      <div class="col-xs-4 optButtonCol">
        <div class="tsButton" (click)="sendMessageNextStep('speedtestRedirect')">
          <span class="testButton">{{ 'health.bubbles.gatewayCompareBubble.openSpeedTest' | translate }}</span>
        </div>
      </div>
      <div class="col-xs-4 optButtonCol">
        <div class="tsButton" (click)="sendMessageNextStep('rebootGateway')">
          <span class="testButton">{{ 'health.bubbles.gatewayCompareBubble.gotoNextStage' | translate }}</span>
        </div>
      </div>
    </div>
    <div class="col-xs-8">{{ 'health.bubbles.gatewayCompareBubble.message' | translate }}</div>
  </div>
</div>
