import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/lib/services/auth.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';

@Component({
  selector: 'extended-access',
  templateUrl: './extended-access.component.html',
  styleUrls: ['./extended-access.component.scss']
})
export class ExtendedAccessComponent implements OnInit {
  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private plume: PlumeService,
    private toast: ToastService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const location = this.route.snapshot.paramMap.get('location');
    const customer = this.route.snapshot.paramMap.get('customer');

    if (!customer) {
      this.toast.error('toast.extendedAccess.urlIncorrect', 'toast.extendedAccess.errorTitle');
      return;
    }

    if (!location) {
      this.toast.error('toast.extendedAccess.urlIncorrect', 'toast.extendedAccess.errorTitle');
      return;
    }

    const env = this.plume.getEnv();

    this.auth.mintExtendedAccessToken$(env.partnerPortalUrl, location).subscribe(
      (token) => {
        this.auth.setExtendedAccessToken(token.temporaryToken, false);
        this.router.navigate(['customer', customer, 'location', location]);
      },
      () => {
        this.toast.error('toast.extendedAccess.errorMessage', 'toast.extendedAccess.errorTitle');
        this.router.navigate(['/']);
      }
    );
  }
}
