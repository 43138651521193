import { animate, query, stagger, state, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, take, tap } from 'rxjs/operators';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { CustomerNamePipe } from 'src/app/lib/pipes/customer-name.pipe';
import { AuthService } from 'src/app/lib/services/auth.service';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ModalService } from 'src/app/lib/services/modal.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { SystemService } from 'src/app/lib/services/system.service';
import { ThemeService } from 'src/app/lib/services/theme.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { ToolbarService } from 'src/app/lib/services/toolbar.service';
import {
  selectCustomer,
  selectIsUpriseLocation,
  selectLocationList,
  selectPipeLocationOnChange
} from 'src/app/store/customer/customer.selectors';
import { customerSelected, locationChangedMobile } from 'src/app/store/header/header.actions';
import { environments } from 'src/environments/shared/environments';
import { languages } from 'src/environments/shared/languages';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('toggle', [
      state('closed', style({ height: 0, opacity: 0, transform: 'translateY(-1rem)' })),
      state('open', style({ height: '*', opacity: 1, transform: 'translateY(0)' })),
      transition(
        'closed => open',
        animate('0.5s 100ms ease-in-out', style({ height: '*', opacity: 1, transform: 'translateY(0)' }))
      ),
      transition(
        'open => closed',
        animate('0.2s 100ms ease-in-out', style({ height: 0, opacity: 0, transform: 'translateY(-1rem)' }))
      )
    ]),

    trigger('listStagger', [
      transition('* => *', [
        query(
          ':enter',
          [
            style({ opacity: 0, transform: 'translateY(-0.5rem)' }),
            stagger('100ms', animate('0.5s', style({ opacity: 1, transform: 'translateY(0)' })))
          ],
          { optional: true }
        ),
        query(
          ':leave',
          [
            style({ opacity: 1, transform: 'translateY(0)' }),
            stagger('20ms', animate('0.2s', style({ opacity: 0, transform: 'translateY(-0.5rem)' })))
          ],
          { optional: true }
        )
      ])
    ])
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input()
  placeholder: string = 'header.search';

  @Input()
  enableDeleteCustomer: boolean = false;

  @Output()
  openFeedbackModal = new EventEmitter<void>();

  @ViewChildren('searchElements')
  searchElements: QueryList<ElementRef>;

  @ViewChild('versionContent')
  versionContentRef: ElementRef;

  helper: GeneralHelper = new GeneralHelper();
  logoURL: string = '';
  isMobile: boolean = false;
  updateSubscription: any;
  routerSubscription: any;
  querySubscription: any;
  permissionsSubscription: any;
  globalInventorySubscription: any;
  permissions: any;
  user: any = {};
  role: string = '';
  customers: any = [];
  moreResults: number = 0;
  maxGroupResultsReached: boolean = false;
  searchSubscription: any;
  searchTimeout: any;
  searchResultsTimeout: boolean = false;
  searchResultsNone: boolean = false;
  searchResultsIssue: boolean = false;
  state: string = 'closed';
  searchSettingsState: string = 'closed';

  searchSettings: {
    exactMatch: boolean;
    secondaryCloud: boolean;
  } = { exactMatch: false, secondaryCloud: null };

  updatedSettingsCount: number = 0;
  query: UntypedFormControl = new UntypedFormControl();
  authenticated: boolean = true;
  showResults: boolean = false;
  loading: boolean = false;
  menu: boolean = false;
  dropdown: boolean = false;
  themeItems: any[] = [];
  searchModeItems: any[] = [];
  mode: any = {
    placeholder: 'header.search',
    id: false,
    type: 'user',
    idSearchType: 'ACCOUNT_ID'
  };

  ui: string = '';
  version: string = '';
  reportsVersion: string = '';
  cloudMatrix: any = {};
  showCreateCustomer: number = 0;
  showGlobalInventory: boolean = false;
  showReleaseNotes: boolean = false;
  releaseNotes: any[] = [];
  id: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.minLength(4)]);
  model: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.minLength(1)]);
  packId: UntypedFormControl = new UntypedFormControl();
  partnerId: UntypedFormControl = new UntypedFormControl();
  radio6G = this.plume.cloudVersionAbove1_86() ? new UntypedFormControl() : null;
  radio5G: UntypedFormControl = new UntypedFormControl();
  radio24G: UntypedFormControl = new UntypedFormControl();
  radio5GU: UntypedFormControl = new UntypedFormControl();
  radio5GL: UntypedFormControl = new UntypedFormControl();
  eth0: UntypedFormControl = new UntypedFormControl();
  eth1: UntypedFormControl = new UntypedFormControl();
  podID: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.minLength(1)]);
  packName: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.minLength(1)]);
  residentialGateway: boolean = false;
  claimKeyRequired: boolean = false;
  subscriptionRequired: boolean = false;
  showUserSettings: boolean = false;
  inactivityTimerItems: any[] = [];
  globalInventoryActive: string = 'globalInventory';
  properties: any[] = [];

  locationList$ = this.store.select(selectLocationList);
  location$ = this.store.pipe(selectPipeLocationOnChange);
  isFlexAdmin: boolean = false;
  isUprisePropertyManager: boolean = false;
  isUprise$ = this.store.select(selectIsUpriseLocation);
  isExtendedAccess: boolean = false;
  showVersion: boolean = false;

  userModal: any = {
    show: false,
    user: {},
    data: {},
    locations: []
  };

  selector: any = {
    current: '',
    list: []
  };

  langSelector: any = {
    current: '',
    list: []
  };

  constructor(
    public plume: PlumeService,
    private auth: AuthService,
    private translate: TranslateService,
    private toast: ToastService,
    private router: Router,
    private modal: ModalService,
    private mixpanel: MixpanelService,
    private logging: LoggingService,
    private theme: ThemeService,
    private toolbar: ToolbarService,
    private store: Store,
    private systemService: SystemService,
    private customerNamePipe: CustomerNamePipe,
    private customerService: CustomerService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (window.innerWidth > 1024) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }

    this.initLogo();
  }

  ngOnInit(): void {
    this.ui = this.plume.getUI();
    this.version = this.plume.getVersion();
    this.mode.placeholder = this.placeholder;

    this.onResize();
    this.initUserSettings();
    this.update();
  }

  update(): void {
    this.init();
    this.updateSubscription = this.store
      .pipe(
        selectPipeLocationOnChange,
        filter((data) => !!data)
      )
      .subscribe(() => {
        this.init();
      });
  }

  init(): void {
    this.authenticated = this.auth.isAuthenticated();

    if (this.authenticated) {
      this.user = this.plume.getUser();
      this.role =
        'centralDisabledUIFeatures' in this.user
          ? 'header.customRole'
          : this.auth.isExtendedAccess()
          ? 'header.extendedAccess'
          : this.plume.getRole();
      this.isFlexAdmin = this.plume.isFlexRole();
      this.isUprisePropertyManager = this.plume.isUpriseProperyManager();
      this.isExtendedAccess = this.auth.isExtendedAccess();
      const env = this.plume.getEnv();
      const def = environments.find((e: any) => e.id === env.id);
      const search = def && def.defaultSearch ? def.defaultSearch : 'user';
      this.query.reset();
      this.changeMode(search);

      this.searchModeItems = [
        { value: 'user', translation: 'header.user', selected: search === 'user' },
        { value: 'id', translation: 'header.id', selected: search === 'id' },
        { value: 'node', translation: 'header.node', selected: search === 'node' }
      ];

      this.changeTheme();
      this.checkReleaseNotes();
      this.getReportsVersion();

      if (!this.routerSubscription) {
        this.routerSubscription = this.router.events.subscribe((data: any) => {
          if (data instanceof NavigationEnd) {
            this.checkReleaseNotes();
          }
        });
      }

      if (!this.permissionsSubscription) {
        this.permissionsSubscription = this.plume.permissions.subscribe((data: any) => {
          this.permissions = data;
          this.searchSettings.exactMatch = this.permissions.search.exactMatch || this.searchSettings.exactMatch;

          if (data.search.secondaryCloud && this.plume.getSecondaryCloud()) {
            this.searchSettings.secondaryCloud = data.search.secondaryCloud;
          } else {
            this.searchSettings.secondaryCloud = null;
          }

          const searchSettings = sessionStorage.getItem('searchSettings');
          if (searchSettings) {
            this.searchSettings = JSON.parse(searchSettings);
          }

          this.updateSelectedSettings();
        });
      }

      if (!this.globalInventorySubscription) {
        this.globalInventorySubscription = this.modal.showGlobalInventory.subscribe((show) => {
          this.showGlobalInventory = show;
        });
      }

      if (!this.querySubscription) {
        this.querySubscription = this.query.valueChanges
          .pipe(debounceTime(600), distinctUntilChanged())
          .subscribe((query: any) => {
            if (this.isUprisePropertyManager) {
              this.propertyFilter(query.trim());
            } else {
              if (query) {
                const trimmedQuery = query
                  .trim()
                  .replace(/\?/g, '')
                  .replace(/\//g, '')
                  .replace(/\\/g, '')
                  .replace(/#/g, '%23');
                this.search(trimmedQuery);
              } else {
                this.customers = [];
                this.loading = false;
                this.state = 'closed';
              }
            }
          });
      }
    }
  }

  initLogo(): void {
    this.logoURL = 'assets/logo/' + this.plume.getVendor() + '_logo' + (this.isMobile ? '_mobile' : '') + '.svg';
  }

  search(query: string): void {
    if (query && query.length >= 4) {
      this.customers = [];
      this.moreResults = 0;
      this.maxGroupResultsReached = false;
      this.searchResultsNone = false;
      this.searchResultsIssue = false;
      this.searchResultsTimeout = false;
      this.loading = true;
      this.searchSettingsState = 'closed';

      this.searchTimeout = setTimeout(() => {
        if (this.searchSubscription) {
          this.searchSubscription.unsubscribe();
          this.loading = false;
          this.state = 'open';

          if (!this.searchResultsIssue && !this.searchResultsNone) {
            this.searchResultsTimeout = true;
          }

          this.mixpanel.storeEvent('SEARCH_FAILED', { ERROR: 'Timeout' });
        }
      }, 55000);

      this.searchSubscription = this.customerService
        .search$(query, this.mode.type, this.searchSettings.exactMatch, this.searchSettings.secondaryCloud)
        .subscribe(
          (result) => {
            this.customers = result.results;
            this.maxGroupResultsReached = this.customers.length >= 10;
            this.mode.idSearchType = result.searchType;
            this.mixpanel.storeEvent('SEARCH', {
              SEARCH_TYPE: result.searchType,
              KEYWORD: query,
              RESULT_COUNT: this.customers.length,
              EXACT_MATCH: this.searchSettings.exactMatch
            });

            this.loading = false;
            this.state = 'open';
            this.searchResultsNone = this.customers.length ? false : true;
          },
          () => {
            this.loading = false;
            this.state = 'open';

            if (this.mode.type !== 'id' && this.mode.type !== 'node') {
              this.searchResultsIssue = true;
            } else {
              this.searchResultsNone = true;
            }

            this.mixpanel.storeEvent('SEARCH_FAILED', { ERROR: 'Network' });
          },
          () => {
            clearTimeout(this.searchTimeout);
          }
        );
    } else {
      this.customers = [];
      this.loading = false;
      this.state = 'closed';
    }
  }

  selectResult(event: any): void {
    if (event.mode === 'move') {
      this.customers.forEach((customer: any, index: number) => {
        if (index === event.index) {
          customer.selected = true;
        } else {
          customer.selected = false;
        }
      });

      if (this.searchElements) {
        const element = this.searchElements.toArray()[event.index];

        if (element) {
          const position = element.nativeElement;
          const parent = element.nativeElement.offsetParent;

          if (position.offsetTop + position.clientHeight > parent.scrollTop + parent.clientHeight) {
            parent.scrollTo(0, position.offsetTop + position.clientHeight - parent.clientHeight);
          }

          if (position.offsetTop < parent.scrollTop) {
            parent.scrollTo(0, position.offsetTop);
          }
        }
      }
    }

    if (event.mode === 'select') {
      this.selectCustomer(this.customers[event.index]);
    }
  }

  changeLocation(location: any): void {
    this.store.dispatch(locationChangedMobile({ newLocation: location.id }));
    this.menuToggle('close');
    this.logging.debug('<changeLocation>');
    this.mixpanel.storeEvent('CHANGE_LOCATION');
  }

  changeTheme(theme: string = null): void {
    if (theme) {
      this.theme.setActiveTheme(theme);
      this.plume.setTheme(theme);
      this.mixpanel.storeEvent('CHANGE_THEME', { THEME_SELECTED: theme });
    }

    this.themeItems = [
      { value: 'light', translation: 'header.light', selected: 'light' === this.plume.getTheme() },
      { value: 'dark', translation: 'header.dark', selected: 'dark' === this.plume.getTheme() }
    ];
  }

  checkReleaseNotes(): void {
    if (
      this.router.url === '/' ||
      this.router.url.indexOf('customer') > -1 ||
      this.router.url.indexOf('netops') > -1 ||
      this.router.url.indexOf('explorer') > -1
    ) {
      const releaseNotesVersion = this.plume.getObject('rn', false, true);

      this.releaseNotes = this.translate.instant('header.releaseNotes.' + this.ui + '.notes');

      if (!this.plume.versionCompare(releaseNotesVersion, this.version)) {
        this.showReleaseNotes = true;
      }
    }
  }

  closeReleaseNotes(): void {
    this.plume.saveObject('rn', this.version, false, true);
    this.showReleaseNotes = false;
  }

  goto(page: string): void {
    this.router.navigate([page]);
  }

  propertyFilter(query: string): void {
    if (query?.length) {
      const lowerCaseQuery = query.toLowerCase();
      this.customers = this.properties.filter((property: any) => {
        if (property.property.toLowerCase().includes(lowerCaseQuery)) {
          return true;
        }

        if (property.network.toLowerCase().includes(lowerCaseQuery)) {
          return true;
        }

        if (property.customerId.toLowerCase().includes(lowerCaseQuery)) {
          return true;
        }

        if (property.locationId.toLowerCase().includes(lowerCaseQuery)) {
          return true;
        }
      });
    } else {
      this.customers = [...this.properties];
    }
  }

  changeMode(mode: string): void {
    this.searchResultsIssue = false;
    this.searchResultsNone = false;
    this.searchResultsTimeout = false;

    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }

    if (this.isUprisePropertyManager) {
      this.mode.placeholder = 'header.upriseFilter';
      this.mode.idSearchType = 'PROPERTY';
      this.mode.type = 'uprise';
      this.customerService.getUprisePropertyLocationList$().subscribe((response: any) => {
        this.properties = response.items.map((item: any) => ({
          property: item.property.name,
          network: item.propertyNetwork.name,
          customerId: item.customerId,
          locationId: item.locationId
        }));

        this.propertyFilter('');
      });

      return;
    }

    if (this.isFlexAdmin) {
      this.mode.placeholder = 'header.flexSearch';
      this.mode.idSearchType = 'EMPLOYEE_ID';
      this.mode.type = 'flex';
      return;
    }

    switch (mode) {
      case 'user':
        this.mode.placeholder = this.placeholder;
        break;
      case 'id':
        this.mode.placeholder =
          this.permissions && this.permissions.search.idFallback && !this.isMobile
            ? 'header.searchAllIDs'
            : 'header.searchAccounts';
        break;
      case 'node':
        this.mode.placeholder = 'header.searchNodes';
        break;
    }

    this.mode.idSearchType = 'ACCOUNT_ID';
    this.mode.type = mode;

    const trimmedQuery = this.query.value
      ? this.query.value.trim().replace(/\?/g, '').replace(/\//g, '').replace(/\\/g, '').replace(/#/g, '')
      : '';

    this.search(trimmedQuery);
  }

  openCustomerInSecondaryCloud(customerId: string, locationId: string): void {
    this.mixpanel.storeEvent('OPEN_CUSTOMER_IN_SECONDARY_CLOUD', { CUSTOMER_ID: customerId });
    const secondaryCloudId = this.plume.getSecondaryCloud().id;
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    parts[parts.length - 4] = secondaryCloudId;
    const secondaryCloudHostname = parts.join('.');
    window.open('https://' + secondaryCloudHostname + '/customer/' + customerId + '/location/' + locationId, '_blank');
  }

  selectCustomer(customer: any): void {
    this.loading = true;
    this.toolbar.dock();

    if (customer._id) {
      customer.id = customer._id;
    }

    if (customer.customer && customer.customer.id) {
      customer.locationId = customer.id;
      customer.id = customer.customer.id;
    }

    if (this.auth.isExtendedAccess()) {
      this.setCustomer({ id: customer.customerId }, { id: customer.locationId });
      return;
    }

    if (this.mode.type === 'uprise') {
      this.setCustomer({ id: customer.customerId }, { id: customer.locationId });
      return;
    }

    if (this.mode.type === 'flex') {
      const env = this.plume.getEnv();

      this.auth.getAccessToken(env.flexUrl, customer.locationId).subscribe((response: any) => {
        this.setCustomer({ id: response.userId }, { id: response.locationId });
      });

      return;
    }

    if (
      this.mode.type === 'node' ||
      ['GLOBAL_LOCATION_ID', 'GROUPS_LOCATION_ID', 'PARTNERS_LOCATION_ID', 'SERVICE_ID'].includes(
        this.mode.idSearchType
      )
    ) {
      if (customer.deployment && this.plume.env.cloudId && customer.deployment !== this.plume.env.cloudId) {
        this.toast.warning('header.deploymentIncorrectMessage', 'header.deploymentIncorrect');
        this.mixpanel.storeEvent('SEARCH_DEPLOYMENT_DIFFERENT', {
          CLOUDID: this.plume.env.cloudId,
          DEPLOYMENT: customer.deployment
        });
        this.loading = false;
        return;
      }

      if (!customer.customerId) {
        customer.customerId = customer.id;
      }
      const { customerId, locationId } = customer;

      if (customer.cloud) {
        this.openCustomerInSecondaryCloud(customerId, locationId);
        this.loading = false;
        return;
      }

      combineLatest([
        this.customerService.getCustomer(customerId),
        this.customerService.getLocation(customerId, locationId)
      ]).subscribe(([customer, location]) => {
        this.setCustomer(customer, location);
      });
    } else {
      if (customer.cloud) {
        this.customerService.getLocationListFromSecondaryCloud$(customer.id).subscribe((locations) => {
          if (locations.length) {
            this.openCustomerInSecondaryCloud(customer.id, locations[0].id);
          } else {
            this.toast.warning('header.noLocationCustomerMsg', 'header.noLocationCustomerTitle');
          }
        });
        this.query.reset();
        this.loading = true;
        return;
      }

      this.customerService.getLocationList$(customer.id).subscribe((locations) => {
        if (locations.length) {
          this.setCustomer(customer, locations[0], locations);
        } else {
          this.setCustomer(customer, false);
        }
      });
    }
  }

  setCustomer(customer: any, location: any, locationList?: any): void {
    this.query.reset();
    this.loading = false;

    this.menuToggle('close');

    if (location) {
      this.store.dispatch(customerSelected({ newCustomer: customer.id, newLocation: location.id, locationList }));
      this.mixpanel.storeEvent('CHANGE_CUSTOMER', { ID: customer.id });
    } else {
      this.toast.warning('header.noLocationCustomerMsg', 'header.noLocationCustomerTitle');
      this.toggleUserModal(customer);
    }
  }

  toggleUserModal(customer: any = null): void {
    if (customer) {
      this.customerService.getCustomer(customer.id).subscribe((response: any) => {
        this.userModal = {
          show: true,
          user: response,
          data: {
            name: new UntypedFormControl(response.name, [Validators.required, Validators.minLength(4)]),
            email: new UntypedFormControl(response.email, [Validators.required, Validators.email]),
            accountId: new UntypedFormControl(response.accountId, [Validators.required]),
            emailVerified: [
              { value: true, translation: 'header.userModal.emailIsVerified', selected: response.emailVerified },
              { value: false, translation: 'header.userModal.emailIsNotVerified', selected: !response.emailVerified }
            ]
          }
        };

        this.customerService.entitledAccess$(response.id).subscribe((response) => {
          this.userModal.locations = response;
        });
      });
    } else {
      this.userModal = {
        show: false,
        user: {},
        data: {},
        locations: []
      };
    }
  }

  updateUserValue(property: string, mode: string, value: any): void {
    if (mode === 'set') {
      if (['name', 'email', 'accountId'].includes(property) && !this.userModal.data[property].valid) {
        this.toast.error('header.userModal.inputErrorMsg', 'header.userModal.inputErrorTitle');
      } else {
        const oldValue = this.userModal.user[property];
        const newValue = value;

        this.userModal.user[property] = value;

        this.customerService
          .setCustomerProperties$(this.userModal.user.id, {
            [property]: value
          })
          .subscribe(
            () => {
              this.toast.success('header.userModal.propertyUpdateMsg', 'header.userModal.propertyUpdateTitle');

              this.mixpanel.storeEvent('EDIT_LOCATIONLESS_USER_PROPERTY_SUCCESS', {
                PROPERTY: property,
                OLD_VALUE: oldValue,
                NEW_VALUE: newValue
              });
            },
            (error: any) => {
              this.toast.error('header.userModal.errorMsg', 'header.userModal.errorTitle', {
                disableTimeOut: true,
                params: {
                  error: error.error.error.message
                }
              });

              this.mixpanel.storeEvent('EDIT_LOCATIONLESS_USER_PROPERTY_ERROR', {
                PROPERTY: property,
                OLD_VALUE: oldValue,
                NEW_VALUE: newValue,
                ERROR: error.error.error.message
              });
            }
          );
      }
    }

    if (mode === 'close') {
      this.userModal.data[property].value = this.userModal.user[property];
    }

    if (mode === 'edit') {
      this.userModal.data[property].edit = value;
    }
  }

  confirmDeleteUser(): void {
    if (this.permissions?.uiFeatures.deleteCustomer) {
      this.modal
        .showDialog('header.deleteCustomerMsg', 'header.deleteCustomerTitle', {
          params: { name: this.helper.truncateString(this.userModal.user.name, 25) },
          buttons: [
            { style: 'tertiary light', value: 'header.cancel' },
            { style: 'super-primary', value: 'header.confirm' }
          ]
        })
        .pipe(
          tap((response) => {
            if (response.item?.value === 'header.confirm') {
              const customer = this.userModal.user;
              this.toggleUserModal();
              this.customerService.deleteCustomer$(customer.id).subscribe(() => {
                this.mixpanel.storeEvent('DELETE_LOCATIONLESS_USER_SUCCESS', { USER_EMAIL: customer.email });
                this.toast.success('header.deleteToastMsg', 'header.deleteToastTitle', {
                  params: {
                    name: this.helper.truncateString(this.customerNamePipe.transform(customer.name), 25)
                  }
                });
              });
            }
          })
        )
        .subscribe();
    }
  }

  navigateUserLocation(customerid: string, locationid: string): void {
    this.router.navigate(['customer', customerid, 'location', locationid]);
  }

  settingsIconClick(e: MouseEvent): void {
    e.stopPropagation();
    this.toggleSearchSettings();
  }

  toggleSearchSettings(open?: boolean): void {
    if (open !== undefined) {
      this.searchSettingsState = open ? 'open' : 'closed';
    } else {
      this.searchSettingsState = this.searchSettingsState === 'open' ? 'closed' : 'open';
    }

    if (this.searchSettingsState === 'open') this.state = 'closed';
  }

  updateSelectedSettings(): void {
    const count = Object.keys(this.searchSettings).reduce((count, setting) => {
      if (!this.searchSettings[setting]) return count;
      return count + 1;
    }, 0);
    this.updatedSettingsCount = count;

    sessionStorage.setItem('searchSettings', JSON.stringify(this.searchSettings));
  }

  toggleSearchResults(action: boolean): void {
    this.state = this.customers.length && action ? 'open' : 'closed';
  }

  toggleSearchSetting(setting: string): void {
    this.searchSettings[setting] = !this.searchSettings[setting];
    const trimmedQuery = this.query.value ? this.query.value.trim().replace(/[\?\/\\#]/g, '') : '';
    this.search(trimmedQuery);
    this.updateSelectedSettings();

    if (setting === 'exactMatch') {
      this.mixpanel.storeEvent('SEARCH_EXACT_MATCH_TOGGLE', {
        EXACT_MATCH: this.searchSettings.exactMatch
      });
    }

    if (setting === 'secondaryCloud') {
      this.mixpanel.storeEvent('SEARCH_SECONDARY_CLOUD_TOGGLE', {
        SECONDARY_CLOUD: this.searchSettings.secondaryCloud
      });
    }
  }

  menuToggle(mode: string): void {
    if (mode === 'open') {
      this.menu = true;
      document.body.style.position = 'fixed';
      document.body.style.top = '0';
      document.body.style.left = '0';
      document.body.style.right = '0';
      document.body.style.bottom = '0';
    } else {
      this.menu = false;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.left = '';
      document.body.style.right = '';
      document.body.style.bottom = '';
    }
  }

  dropdownToggle(): void {
    this.dropdown = !this.dropdown;
  }

  globalInventory(): void {
    this.showGlobalInventory = true;
    this.mixpanel.storeEvent('GLOBAL_INVENTORY_DIALOG');
  }

  renamePackID(): void {
    if (this.podID.valid && this.packName.valid) {
      this.systemService.renamePackID$(this.podID.value, this.packName.value).subscribe(
        () => {
          this.podID.setValue('');
          this.packName.setValue('');
          this.mixpanel.storeEvent('CONFIGURATION_RENAME_PACKID');
          this.toast.success('header.globalInventoryDialog.renameSuccess', 'header.success');
        },
        (error: any) => {
          this.mixpanel.storeEvent('CONFIGURATION_RENAME_PACKID_ERROR');
          this.toast.error(error.error.error.message, 'header.failed');
        }
      );
    } else {
      this.toast.error('header.pleaseConfirmFields', 'header.failed');
      this.mixpanel.storeEvent('CONFIGURATION_RENAME_PACKID_VALIDATION_FAILURE');
    }
  }

  confirmNewNode(): void {
    if (!this.id.valid) {
      this.toast.error('header.toastNodeValidationFailure', 'header.failed');
    }

    if (!this.model.valid) {
      this.toast.error('header.toastModelValidationFailure', 'header.failed');
    }

    if (!this.id.valid || !this.model.valid) {
      return;
    }

    const data = {
      ...(this.id.value && { id: this.id.value }),
      ...(this.model.value && { model: this.model.value }),
      ...(this.packId.value && { packId: this.packId.value }),
      ...(this.partnerId.value && { partnerId: this.partnerId.value }),
      ...(this.radio6G?.value && { radioMac60: this.radio6G.value }),
      ...(this.radio5G.value && { radioMac50: this.radio5G.value }),
      ...(this.radio24G.value && { radioMac24: this.radio24G.value }),
      ...(this.radio5GU.value && { radioMac50U: this.radio5GU.value }),
      ...(this.radio5GL.value && { radioMac50L: this.radio5GL.value }),
      ...(this.eth0.value && { ethernetMac: this.eth0.value }),
      ...(this.eth1.value && { ethernet1Mac: this.eth1.value }),
      residentialGateway: this.residentialGateway,
      claimKeyRequired: this.claimKeyRequired,
      subscriptionRequired: this.subscriptionRequired
    };

    this.systemService.globalInventoryAddNode$(data).subscribe(
      () => {
        this.successCreatingNode(this.id.value);
      },
      (error: any) => {
        this.mixpanel.storeEvent('GLOBAL_INVENTORY_NEW_NODE_FAILURE');
        this.toast.error('header.toastNewNodeFailure', 'header.failed', {
          params: {
            error: error.error.error.message
          }
        });
      }
    );
  }

  successCreatingNode(id: string): void {
    this.mixpanel.storeEvent('GLOBAL_INVENTORY_NEW_NODE_SUCCESS', { ID: id });
    this.clearDownCreatingNodeForm();
    this.toast.success('header.toastNewNode', 'header.success', { params: { id } });
  }

  clearDownCreatingNodeForm(): void {
    this.showGlobalInventory = false;
    this.id.reset();
    this.model.reset();
    this.packId.reset();
    this.partnerId.reset();
    this.radio6G?.reset();
    this.radio5G.reset();
    this.radio24G.reset();
    this.radio5GU.reset();
    this.radio5GL.reset();
    this.eth0.reset();
    this.eth1.reset();
    this.podID.reset();
    this.packName.reset();
    this.residentialGateway = false;
    this.claimKeyRequired = false;
    this.subscriptionRequired = false;
  }

  deleteCustomer(): void {
    this.isUprise$
      .pipe(
        take(1),
        filter((isUprise) => !isUprise),
        switchMap(() => this.store.select(selectCustomer)),
        take(1),
        switchMap((customer) => {
          this.mixpanel.storeEvent('DELETE_CUSTOMER_DIALOG', { CUSTOMER_EMAIL: customer.email });

          return this.modal
            .showDialog('header.deleteCustomerMsg', 'header.deleteCustomerTitle', {
              params: { name: this.helper.truncateString(this.customerNamePipe.transform(customer.name), 25) },
              buttons: [
                { style: 'tertiary light', value: 'header.cancel' },
                { style: 'super-primary', value: 'header.confirm' }
              ]
            })
            .pipe(
              tap((response) => {
                if (response.item?.value === 'header.confirm') {
                  this.router.navigate(['/']);
                  this.customerService.deleteCustomer$(customer.id).subscribe(() => {
                    this.mixpanel.storeEvent('DELETE_CUSTOMER_SUCCESS', { CUSTOMER_EMAIL: customer.email });
                    this.toast.success('header.deleteToastMsg', 'header.deleteToastTitle', {
                      params: { name: this.helper.truncateString(this.customerNamePipe.transform(customer.name), 25) }
                    });
                  });
                }
              })
            );
        })
      )
      .subscribe();
  }

  home(): void {
    this.router.navigate(['/']);
  }

  initUserSettings(): void {
    const inactivityTimer = localStorage.getItem('inactivityTimer') || null;

    this.inactivityTimerItems = [
      {
        value: 'enabled',
        translation: 'header.userSettings.enabled',
        selected: !inactivityTimer || 'enabled' === inactivityTimer
      },
      {
        value: 'disabled',
        translation: 'header.userSettings.disabled',
        selected: 'disabled' === inactivityTimer
      }
    ];

    const selectedLang = localStorage.getItem('lang') || null;
    const browserDefault = { id: 'default', translation: 'browserDefault' };
    const languagesList = [browserDefault, ...languages];

    if (selectedLang) {
      this.langSelector.current = languages.find((lang: any) => lang.id === this.translate.currentLang);
    } else {
      this.langSelector.current = browserDefault;
    }

    this.langSelector.list = languagesList.filter((lang: any) => lang.id !== this.langSelector.current.id);

    const searchSettings = sessionStorage.getItem('searchSettings');
    if (searchSettings) {
      this.searchSettings = JSON.parse(searchSettings);
    }
  }

  changeInactivityTimer(action: string): void {
    localStorage.setItem('inactivityTimer', action);
  }

  changeLang(lang: any): void {
    const browserDefault = { id: 'default', translation: 'browserDefault' };
    const languagesList = [browserDefault, ...languages];

    this.langSelector.current = lang;
    this.langSelector.list = languagesList.filter((lang: any) => lang.id !== this.langSelector.current.id);

    if (lang.id === 'default') {
      localStorage.removeItem('lang');

      let selectedLang = this.translate.getBrowserCultureLang();
      let language = languages.find((lang: any) => lang.id === selectedLang)?.id || null;

      if (!language) {
        if (selectedLang.indexOf('-') > -1) {
          selectedLang = selectedLang.split('-')[0];

          language = languages.find((lang: any) => lang.id === selectedLang)?.id || null;

          if (!language) {
            language = 'en';
          }
        } else {
          language = 'en';
        }
      }

      this.translate.use(language);
    } else {
      localStorage.setItem('lang', lang.id);
      this.translate.use(lang.id);
    }
  }

  copyMatrix(): any {
    try {
      window.navigator.clipboard.writeText(this.versionContentRef.nativeElement.innerText);
      this.toast.success('header.versionModal.copyConfirm', 'header.success');
    } catch (error) {}
  }

  getReportsVersion(): any {
    this.systemService.reportVersion$().subscribe(
      (response: any) => {
        this.reportsVersion = response.apiVersion ? response.apiVersion : 'unknown';
        this.contructCloudMatrix();
      },
      () => {
        this.reportsVersion = 'unknown';
        this.contructCloudMatrix();
      }
    );
  }

  contructCloudMatrix(): void {
    this.cloudMatrix = [
      {
        api: 'Frontline',
        version: (this.ui === 'noc' ? 'Frontline Tier 2 & 3' : 'Frontline Tier 1') + ' v' + this.version
      },
      {
        api: 'Customer API',
        version: this.plume.getEnv().version
      },
      { api: 'Reports API', version: this.reportsVersion }
    ];

    this.getCloudMatrix();
  }

  fixVersion(ver: any): any {
    if (typeof ver.version === 'object') {
      return {};
    }
    return { api: this.helper.truncateString(ver.api, 24), version: ver.version };
  }

  getCloudMatrix(): void {
    if (!this.isUprisePropertyManager) {
      this.systemService.cloudMatrix$().subscribe(
        (response: any) => {
          this.cloudMatrix = [
            ...this.cloudMatrix,
            { api: '-', version: '' },
            ...Object.entries(response).map(([api, version]) => this.fixVersion({ api, version }))
          ];
        },
        () => {
          this.mixpanel.storeEvent('CLOUD_MATRIX_REQUEST_FAILURE');
        }
      );
    }
  }

  gotoReleaseNotespage(): void {
    this.mixpanel.storeEvent('OPEN_RELEASE_NOTES_EXTERNAL_LINK');
    window.open('https://partnersupport.plume.com/s/article/Plume-Frontline-Release-Notes', '_blank');
  }

  checkPlumeUser(): boolean {
    return this.user?.email.indexOf('@plume.com') >= 0 ? true : false;
  }

  logout(): void {
    this.auth.logout();
  }

  ngOnDestroy(): void {
    this.menu = false;

    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.left = '';
    document.body.style.right = '';
    document.body.style.bottom = '';

    if (this.permissionsSubscription) {
      this.permissionsSubscription.unsubscribe();
    }

    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }

    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }

    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }

    if (this.globalInventorySubscription) {
      this.globalInventorySubscription.unsubscribe();
    }
  }
}
