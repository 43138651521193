import { createReducer, on } from '@ngrx/store';
import { helpHasOptimizerData } from './help.actions';

export interface HelpState {
  hasOptimizerData: boolean;
}

export const initialState: HelpState = {
  hasOptimizerData: false
};

export const helpReducer = createReducer(
  initialState,
  on(helpHasOptimizerData, (state, action) => ({ ...state, hasOptimizerData: action.hasOptimizerData }))
);
