import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { ICaptivePortalLoginOptions } from 'src/app/lib/interfaces/captive-portal';
import { ModalService } from 'src/app/lib/services/modal.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import {
  loginOptionSetCode,
  loginOptionEnabledChanged,
  toggleMarketingConsent
} from 'src/app/store/captive-portal/captive-portal.actions';
import {
  selectCaptivePortalConfig,
  selectCaptivePortalLoginOptions,
  selectCaptivePortalPublishMode
} from 'src/app/store/captive-portal/captive-portal.selectors';

@UntilDestroy()
@Component({
  selector: 'captive-options',
  templateUrl: './captive-options.component.html',
  styleUrls: ['./captive-options.component.scss']
})
export class CaptiveOptionsComponent implements OnInit {
  config$ = this.store.select(selectCaptivePortalConfig);
  options$ = combineLatest([this.config$, this.store.select(selectCaptivePortalLoginOptions)]).pipe(
    map(([config, loginOptions]) =>
      loginOptions
        .map((option) => {
          const foundOpt = config?.loginOptions?.options.find((opt) => opt.name === option.data.name);

          return {
            ...option,
            data: foundOpt ?? option.data,
            enabled: !!foundOpt
          };
        })
        .filter((option) => {
          if (option.icon === 'fa-facebook' && !this.hadFacebookEnabled) {
            const facebook = config?.loginOptions?.options.find((option) => option.name === 'Facebook') || null;

            return facebook ? true : false;
          } else {
            if (option.data.name === 'SMS' && !this.plume.cloudVersionAbove1_124()) {
              return false;
            } else {
              return true;
            }
          }
        })
        .sort((a, b) => a.order - b.order)
    )
  );

  order: string[] = [];
  settings: ICaptivePortalLoginOptions & { enabled: boolean } = null;
  settingsInput: UntypedFormControl = new UntypedFormControl();
  passcode: string = '';
  hadFacebookEnabled: boolean = false;

  @ViewChild('passcodeInput') passcodeInput: ElementRef;

  constructor(
    private store: Store,
    private toast: ToastService,
    private modal: ModalService,
    private plume: PlumeService
  ) {}

  ngOnInit(): void {
    this.options$
      .pipe(
        take(1),
        tap((options) => {
          const enabledEditableOpt = options.find((option) => option.enabled && option.editable) || null;
          const facebook = options.find((option) => option.icon === 'fa-facebook') || null;

          this.hadFacebookEnabled = facebook ? true : false;
          this.passcode = enabledEditableOpt ? enabledEditableOpt.data.code : '';
          this.settingsInput.setValue(this.passcode);
          this.settingsInput.disable();

          this.order = options.filter((option) => option.enabled).map((option) => option.data.name);
        })
      )
      .subscribe();

    this.store
      .select(selectCaptivePortalPublishMode)
      .pipe(untilDestroyed(this))
      .subscribe((mode) => {
        if (mode === 'success') {
          this.hadFacebookEnabled = false;
        }
      });
  }

  toggleOption(option: ICaptivePortalLoginOptions & { enabled: boolean }): void {
    if (option.enabled) {
      if (option.data.name === 'Facebook') {
        this.modal
          .showDialog('captiveportal.facebookOptionMessage', 'captiveportal.facebookOptionTitle', {
            buttons: [
              { style: 'tertiary light', value: 'captiveportal.cancel' },
              { style: 'super-primary', value: 'captiveportal.ok' }
            ]
          })
          .subscribe((button) => {
            if (button.item.style === 'super-primary') {
              this.order = this.order.filter((name) => name !== option.data.name);

              if (this.order.length) {
                this.store.dispatch(loginOptionEnabledChanged({ option }));
              } else {
                this.order.push(option.data.name);
                this.toast.warning('captiveportal.noAuthSelectedMessage', 'captiveportal.noAuthSelectedTitle');
              }
            }
          });

        return;
      }

      this.order = this.order.filter((name) => name !== option.data.name);

      if (option.data.name === 'Passcode') {
        this.passcode = '';
        this.edit(null);
      }
    } else {
      this.order.push(option.data.name);
    }

    if (this.order.length) {
      this.store.dispatch(loginOptionEnabledChanged({ option }));
    } else {
      this.order.push(option.data.name);
      this.toast.warning('captiveportal.noAuthSelectedMessage', 'captiveportal.noAuthSelectedTitle');
    }
  }

  toggleConsent(): void {
    this.store.dispatch(toggleMarketingConsent());
  }

  edit(option: ICaptivePortalLoginOptions & { enabled: boolean }): void {
    this.settingsInput.setValue(this.passcode);

    if (!option) {
      this.settings = null;
      this.settingsInput.disable();
    } else {
      this.settingsInput.enable();
      this.passcodeInput.nativeElement.focus();
      this.settings = option;
    }
  }

  changeSettings(): void {
    if (this.settings) {
      this.passcode = this.settingsInput.value;
      this.store.dispatch(loginOptionSetCode({ code: this.settingsInput.value, name: this.settings.data.name }));
      this.edit(null);
    }
  }
}
