<div class="row">
  <div class="col-xs-12 indent">
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <box class="center">
          <div header>{{ 'speedtests.speedcontrols.titleISP' | translate }}</div>
          <div container>
            <speedresultschart
              class="download"
              title="{{ 'speedtests.speedcontrols.chartDownload' | translate }}"
              [data]="dataDownload"
            ></speedresultschart>
            <speedresultschart
              class="upload"
              title="{{ 'speedtests.speedcontrols.chartUpload' | translate }}"
              [data]="dataUpload"
            ></speedresultschart>
          </div>
        </box>
      </div>
      <div class="col-xs-12 col-md-6">
        <box class="center" *ngIf="permissions?.uiFeatures.speedTestsConfiguration">
          <div header>{{ 'speedtests.speedcontrols.titleControls' | translate }}</div>
          <div container>
            <div>
              <div class="toggler" (click)="plume.isSomeAdminRole() && change('isp')">
                <div class="checkbox">
                  <label
                    for="enableSpeedtest"
                    [class.disabled]="!plume.isSomeAdminRole()"
                    class="toggle"
                    [class.checked]="enableISP"
                  ></label>
                </div>
                <span [class.disabled]="!enableISP">{{ 'speedtests.speedcontrols.enableISP' | translate }}</span>
              </div>
            </div>
            <div>
              <div class="toggler" (click)="plume.isSomeAdminRole() && change('all')">
                <div class="checkbox">
                  <label
                    for="enableSpeedtestAll"
                    [class.disabled]="!plume.isSomeAdminRole()"
                    class="toggle"
                    [class.checked]="enableAll"
                  ></label>
                </div>
                <span [class.disabled]="!enableAll">{{ 'speedtests.speedcontrols.enableAll' | translate }}</span>
              </div>
            </div>
          </div>
        </box>
        <box class="center" *ngIf="permissions?.uiFeatures.lte && (lteSupported$ | async)">
          <div header>
            {{ 'speedtests.speedcontrols.lte7days' | translate }}
            <icon
              class="icon-info"
              name="icon-info"
              [tooltip]="'speedtests.speedcontrols.LTEWarning' | translate"
            ></icon>
          </div>
          <div container id="speedtest-lteSpeedTestChart">
            <div class="controls">
              <button class="action small showLte" (click)="showLteUpload = !showLteUpload">
                {{
                  showLteUpload
                    ? ('speedtests.speednode.hideUpload' | translate)
                    : ('speedtests.speednode.showUpload' | translate)
                }}
              </button>
            </div>
            <speedtestchart
              [class.hideUpload]="!showLteUpload"
              [id]="'lteSpeedTestChart'"
              [data]="lteBars"
              [xrange]="lteRange"
            ></speedtestchart>
            <button
              class="action medium"
              [disabled]="(lteInUse$ | async) !== true || !permissions?.uiFeatures.speedTestsConfiguration"
              (click)="startLteSpeedTest()"
            >
              <icon name="icon-gauge"></icon>
              <span>{{ 'speedtests.speednode.testSpeed' | translate }}</span>
            </button>
          </div>
        </box>
        <box class="center" *ngIf="!permissions?.uiFeatures.lte || !(lteSupported$ | async)">
          <div header>
            {{ 'speedtests.nodeSummary.title' | translate }}
            <icon class="icon-info" name="icon-info" [tooltip]="'speedtests.nodeSummary.tooltip' | translate"></icon>
          </div>
          <div container>
            <speedtestnodesummary></speedtestnodesummary>
          </div>
        </box>
      </div>
    </div>
  </div>
</div>
