import { Injectable } from '@angular/core';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, iif, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import {
  selectBaseUrl,
  selectCustomerIdentification,
  selectPipeLocationOnChange,
  selectSecondaryNetworks
} from 'src/app/store/customer/customer.selectors';
import {
  IClientSteeringTriggers,
  IDevice,
  IDeviceAlarm,
  IDeviceBandSteeringHistory,
  IDeviceBandwidth,
  IDeviceClientSteeringHistory,
  IDeviceTOS,
  IDeviceTypeDetail,
  IMacStitching
} from '../interfaces/interface';
import { ApiService } from './api.service';
import { PlumeService } from './plume.service';

@Injectable({ providedIn: 'root' })
export class DeviceService {
  constructor(
    private api: ApiService,
    private store: Store,
    private plume: PlumeService,
    private translate: TranslateService
  ) {}

  rename$(mac: string, nickname: string): Observable<any> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.patch(`${baseUrl}/devices/${mac}`, { nickname }))
    );
  }

  getMacStitching$(mac: string): Observable<IMacStitching[]> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/devices/${mac}/stitchHistory`, 'reports'))
    );
  }

  getTOS$(mac: string): Observable<IDeviceTOS[]> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.get(`${baseUrl}/devices/${mac}/tos`, 'api'))
    );
  }

  resetTOS$(mac: string): Observable<string> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.post(`${baseUrl}/devices/${mac}/tos/reset`, {}, 'api'))
    );
  }

  restartSniffing$(mac: string): Observable<undefined> {
    if (this.plume.cloudVersionAbove1_100()) {
      return this.store.select(selectBaseUrl()).pipe(
        take(1),
        switchMap((baseUrl) => this.api.post(`${baseUrl}/devices/${mac}/resniff`, {}, 'api'))
      );
    } else {
      return this.api.put(`/locations/${this.plume.locationid}/device/${mac}/resniff`, {}, 'awlan');
    }
  }

  getAll$(): Observable<IDevice[]> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.get(
          `${baseUrl}/devices?include=bandwidth&allSSIDs=true&showAlsoIot=true&showPartnerComponent=true`,
          'api',
          {
            headers: { Accept: 'application/json, application/vnd.plumedesign.v2+json' }
          }
        )
      ),
      map((response) =>
        response.devices.map((item) => {
          const itemNew = { ...item };
          delete itemNew.createdAt;
          return itemNew;
        })
      )
    );
  }

  coverageAlarms$(): Observable<string[]> {
    return this.store
      .select(selectBaseUrl())
      .pipe(
        take(1),
        switchMap((baseUrl) => this.api.get(`${baseUrl}/nodeDevice/alarms?granularity=days&limit=2`, 'reports'))
      )
      .pipe(map((resp) => resp.alarmIds));
  }

  setForceSteer$(mac: string): Observable<unknown> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.put(`${baseUrl}/devices/${mac}/forcedSteer`, {
          expiresAt: new Date(Date.now() + 15 * 60 * 1000).toISOString()
        })
      )
    );
  }

  cancelForceSteer$(mac: string): Observable<unknown> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.delete(`${baseUrl}/devices/${mac}/forcedSteer`))
    );
  }

  steeringTimeMachine$(mac: string, start: string, end: string): Observable<IClientSteeringTriggers[]> {
    return this.deviceRoleAwareUrl$(mac).pipe(
      switchMap((deviceUrl) =>
        this.api.get(
          `${deviceUrl}/clientSteeringTriggers?order=desc&limit=1000&startAt=${start}&endAt=${end}`,
          this.plume.isFlexRole() ? 'api' : 'reports'
        )
      )
    );
  }

  setAppTime$(mac: string, mode: boolean): Observable<{ enable: boolean; updatedAt: string }> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.patch(`${baseUrl}/devices/${mac}/appTime`, {
          enable: mode
        })
      )
    );
  }

  setClientSteering$(
    mac: string,
    mode: 'auto' | 'off'
  ): Observable<{ auto: boolean; enable: boolean; updatedAt: string }> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.patch(`${baseUrl}/devices/${mac}/clientSteering`, {
          auto: mode === 'auto'
        })
      )
    );
  }

  coverage15minAlarm$(mac: string): Observable<IDeviceAlarm> {
    return this.deviceRoleAwareUrl$(mac).pipe(
      switchMap((deviceUrl) =>
        this.api.get(`${deviceUrl}/alarms?granularity=hours&limit=24`, this.plume.isFlexRole() ? 'api' : 'reports')
      )
    );
  }

  device$(mac: string, features: ('chartsData' | 'bandwidthData')[] = []): Observable<IDevice> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.get(`${baseUrl}/devices/${mac}?${features.map((feature) => 'include=' + feature).join('&')}`)
      )
    );
  }

  delete$(mac: string): Observable<undefined> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.delete(`${baseUrl}/devices/${mac}`))
    );
  }

  bandwidth$(mac: string, limit: number): Observable<IDeviceBandwidth> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api.get(`${baseUrl}/devices/${mac}/bandwidth?granularity=hours&limit=${limit}`, 'reports')
      )
    );
  }

  updateSchedule$(
    mac: string,
    scheduleIid: string,
    schedule: { schedules: { daysOfWeek: string; times: { start: string; end: string }[] } }
  ): Observable<IDevice['freeze']> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) => this.api.put(`${baseUrl}/devices/${mac}/freeze/${scheduleIid}`, schedule))
    );
  }

  toggleFreeze$(mac: string, freeze: { id: string; enable: true }): Observable<undefined>;
  toggleFreeze$(mac: string, freeze: { id: string; enable: false }): Observable<IDevice['freeze']>;
  toggleFreeze$(mac: string, freeze: { id: string; enable: boolean }): Observable<IDevice['freeze'] | undefined> {
    if (freeze.enable) {
      return this.store.select(selectBaseUrl()).pipe(
        take(1),
        switchMap((baseUrl) => this.api.delete(`${baseUrl}/devices/${mac}/freeze/${freeze.id}`))
      );
    } else {
      return this.store.pipe(selectPipeLocationOnChange).pipe(
        take(1),
        map((location) => location.isUtilizingSharedLocationFreezeSchedules),
        // eslint-disable-next-line
        concatLatestFrom(() => this.store.select(selectBaseUrl())),
        switchMap(([sharedLocation, baseUrl]) =>
          iif(
            () => sharedLocation,
            this.api.post(`${baseUrl}/devices/${mac}/freeze/${freeze.id}`, {}),
            this.api.put(`${baseUrl}/devices/${mac}/freeze/${freeze.id}`, {
              deleteAllExceptSuspend: freeze.id === 'suspend' ? false : true
            })
          )
        )
      );
    }
  }

  freezeAutoExpire$(mac: string, duration: number): Observable<IDevice['freeze']> {
    if (duration) {
      return this.store.select(selectBaseUrl()).pipe(
        take(1),
        switchMap((baseUrl) =>
          this.api.put(`${baseUrl}/devices/${mac}/freeze/autoExpire`, {
            expiresAt: new Date(Date.now() + duration).toISOString()
          })
        )
      );
    } else {
      return this.store.select(selectBaseUrl()).pipe(
        take(1),
        switchMap((baseUrl) => this.api.delete(`${baseUrl}/devices/${mac}/freeze/autoExpire`)),
        switchMap(() => this.device$(mac)),
        map((device) => device.freeze)
      );
    }
  }

  suspend$(mac: string, suspend: boolean): Observable<IDevice['freeze']> {
    if (suspend) {
      return this.store.select(selectBaseUrl()).pipe(
        take(1),
        switchMap((baseUrl) =>
          this.api.put(`${baseUrl}/devices/${mac}/freeze/suspend`, {
            deleteAllExceptSuspend: false,
            enable: true
          })
        )
      );
    } else {
      return this.store.select(selectBaseUrl()).pipe(
        take(1),
        switchMap((baseUrl) => this.api.delete(`${baseUrl}/devices/${mac}/freeze/suspend`)),
        switchMap(() => this.device$(mac)),
        map((device) => device.freeze)
      );
    }
  }

  freezePerson$(personId: string, enable: false, templateId: string): Observable<undefined>;
  freezePerson$(personId: string, enable: true, templateId: string): Observable<IDevice['freeze']>;
  freezePerson$(personId: string, enable: boolean, templateId: string): Observable<IDevice['freeze'] | undefined>;
  freezePerson$(personId: string, enable: boolean, templateId: string): Observable<IDevice['freeze'] | undefined> {
    if (enable) {
      return this.store.select(selectBaseUrl()).pipe(
        take(1),
        switchMap((baseUrl) =>
          this.api.put(`${baseUrl}/persons/${personId}/freeze/${templateId}`, {
            deleteAllExceptSuspend: templateId === 'suspend' ? false : true
          })
        )
      );
    } else {
      return this.store.select(selectBaseUrl()).pipe(
        take(1),
        switchMap((baseUrl) => this.api.delete(`${baseUrl}/persons/${personId}/freeze/${templateId}`))
      );
    }
  }

  setDevicePriority$(mac: string, priorityType: string): Observable<IDevice['qos'] | undefined> {
    if (priorityType === 'auto') {
      return this.store.select(selectBaseUrl()).pipe(
        take(1),
        switchMap((baseUrl) => this.api.delete(`${baseUrl}/devices/${mac}/qos/prioritization`))
      );
    } else {
      return this.store.select(selectBaseUrl()).pipe(
        take(1),
        switchMap((baseUrl) => this.api.post(`${baseUrl}/devices/${mac}/qos`, { prioritization: priorityType }))
      );
    }
  }

  restartClassify$(mac: string): Observable<null> {
    return this.store.select(selectCustomerIdentification).pipe(
      take(1),
      switchMap(({ locationid }) => this.api.put(`/locations/${locationid}/device/${mac}/reclassify`, {}, 'awlan'))
    );
  }

  bandSteeringHistory$(mac: string): Observable<IDeviceBandSteeringHistory> {
    return this.deviceRoleAwareUrl$(mac).pipe(
      switchMap((deviceUrl) =>
        this.api.get(
          `${deviceUrl}/bandSteeringStats?granularity=hours&limit=175`,
          this.plume.isFlexRole() ? 'api' : 'reports'
        )
      )
    );
  }

  clientSteeringHistory$(mac: string): Observable<IDeviceClientSteeringHistory> {
    return this.deviceRoleAwareUrl$(mac).pipe(
      switchMap((deviceUrl) =>
        this.api.get(
          `${deviceUrl}/clientSteeringStats?granularity=hours&limit=175`,
          this.plume.isFlexRole() ? 'api' : 'reports'
        )
      )
    );
  }

  typeDetail$(mac: string): Observable<IDeviceTypeDetail> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      switchMap((baseUrl) =>
        this.api
          .get(`${baseUrl}/devices/${mac}/deviceTypeDetails`, 'reports')
          .pipe(catchError(() => this.api.get('/DeviceTypes/' + mac, 'awlan')))
      )
    );
  }

  private deviceRoleAwareUrl$(mac: string): Observable<string> {
    return this.store.select(selectBaseUrl()).pipe(
      take(1),
      map((baseUrl) => (this.plume.isFlexRole() ? `${baseUrl}/flex/devices/${mac}` : `${baseUrl}/devices/${mac}`))
    );
  }

  getDeviceSsid$(networkId: string, vapType: string): Observable<string> {
    return combineLatest([
      this.store.select(selectSecondaryNetworks).pipe(take(1)),
      this.store.pipe(selectPipeLocationOnChange).pipe(take(1))
    ]).pipe(
      map(([data, location]) => {
        switch (networkId) {
          case 'primary':
            return vapType === 'fronthaul' ? data.employee.ssid : location.ssid;
          case 'guest':
            return data.guest.ssid;
          case 'employee':
            return data.employee.ssid;
          case 'secondary':
            return data.secondary.ssid;
          case 'work':
            return this.translate.instant('health.networkInformation.workVpn') || 'WorkVPN';
          default:
            return location.ssid;
        }
      }),
      catchError(() => of(''))
    );
  }
}
