import { IHelpPage } from '../help-items';

export const health: IHelpPage = {
  options: {
    order: [
      'score',
      'internetConnection',
      'environment',
      'qoe',
      'coverage',
      'networkSpeed',
      'stability',
      'locationHistory',
      'wan',
      'nodeCongestion'
    ]
  },
  helpItems: {
    internetConnection: {
      steps: [
        {
          targetElement: '#help-health-internetConnection',
          content: 'help.health.internetConnection.circle',
          options: {
            scrollIntoView: true,
            expectMovement: true
          }
        }
      ]
    },
    environment: {
      steps: [
        {
          targetElement: '#help-health-environment',
          content: 'help.health.environment.intro',
          options: {
            scrollIntoView: true,
            expectMovement: true
          }
        },
        {
          targetElement: '#help-health-environment',
          content: 'help.health.environment.evilTwin'
        },
        {
          targetElement: '#help-health-environment',
          content: 'help.health.environment.parallelNetworks'
        },
        {
          targetElement: '#help-health-environment',
          content: 'help.health.environment.highCongestion'
        }
      ]
    },
    stability: {
      steps: [
        {
          targetElement: '#help-health-stability',
          content: 'help.health.stability.intro',
          options: {
            scrollIntoView: true,
            expectMovement: true
          }
        },
        {
          targetElement: '#help-health-stability',
          content: 'help.health.stability.highOptimizations'
        },
        {
          targetElement: '#help-health-stability',
          content: 'help.health.stability.failedOptimizations'
        }
      ]
    },
    networkSpeed: {
      steps: [
        {
          targetElement: '#help-health-networkSpeed',
          content: 'help.health.networkSpeed.intro',
          options: {
            scrollIntoView: true,
            expectMovement: true
          }
        },
        {
          targetElement: '#help-health-networkSpeed',
          content: 'help.health.networkSpeed.lowSpeed'
        },
        {
          targetElement: '#help-health-networkSpeed',
          content: 'help.health.networkSpeed.inconsistentSpeed'
        },
        {
          targetElement: '#help-health-networkSpeed',
          content: 'help.health.networkSpeed.broadbandEfficiency'
        }
      ]
    },
    coverage: {
      steps: [
        {
          targetElement: '#help-health-coverage',
          content: 'help.health.coverage.coverageProblem',
          options: {
            scrollIntoView: true,
            expectMovement: true
          }
        },
        {
          targetElement: '#help-health-coverage',
          content: 'help.health.coverage.chronicCoverageProblem'
        }
      ]
    },
    qoe: {
      steps: [
        {
          targetElement: '#help-health-podQoe',
          content: 'help.health.qoe.podQoeIntro',
          options: {
            scrollIntoView: true,
            expectMovement: true
          }
        },
        {
          targetElement: '#help-health-podQoe',
          content: 'help.health.qoe.extendersPoorQoe'
        },
        {
          targetElement: '#help-health-podQoe',
          content: 'help.health.qoe.extendersPoorEthernet'
        },
        {
          targetElement: '#help-health-deviceQoe',
          content: 'help.health.qoe.someDevicesPoorQoe',
          options: {
            scrollIntoView: true,
            expectMovement: true
          }
        }
      ]
    },
    score: {
      steps: [
        {
          targetElement: '#help-health-score',
          content: 'help.health.score.intro',
          options: {
            scrollIntoView: true,
            expectMovement: true
          }
        }
      ]
    },
    locationHistory: {
      steps: [
        {
          targetElement: '#help-health-locationHistoryChart',
          content: 'help.health.locationHistory.chart',
          options: {
            expectMovement: true,
            scrollIntoView: true,
            action: {
              type: 'click',
              targetElement: '#help-health-locationHistoryBox .header'
            }
          }
        },
        {
          targetElement: '.reportButton',
          content: 'help.health.locationHistory.recentOutageEventsButton'
        },
        {
          targetElement: '.modal-content',
          content: 'help.health.locationHistory.recentOutageEvents',
          options: {
            action: {
              type: 'click',
              targetElement: '.reportButton'
            },
            exitAction: {
              type: 'click',
              targetElement: '.modal-bg'
            }
          }
        }
      ]
    },
    wan: {
      steps: [
        {
          targetElement: '#help-health-wanChart',
          content: 'help.health.wan.chart',
          options: {
            scrollIntoView: true,
            expectMovement: true,
            action: {
              type: 'click',
              targetElement: '#help-health-wanBox .header'
            }
          }
        }
      ]
    },
    nodeCongestion: {
      options: {
        congestionDataNeeded: true
      },
      steps: [
        {
          targetElement: '#help-health-nodeCongestionItem',
          content: 'help.health.nodeCongestion.table',
          options: {
            expectMovement: true,
            scrollIntoView: true,
            action: {
              type: 'click',
              targetElement: '#help-health-nodeCongestionBox .header'
            }
          }
        },
        {
          targetElement: '#help-health-nodeCongestionChart',
          content: 'help.health.nodeCongestion.chart',
          options: {
            expectMovement: true,
            scrollIntoView: true,
            action: {
              type: 'click',
              targetElement: '#help-health-nodeCongestionItem .header'
            },
            exitAction: {
              type: 'click',
              targetElement: '#help-health-nodeCongestionItem .header'
            }
          }
        }
      ]
    }
  }
};
