<modal mode="compact" (overlayAction)="closeModal()">
  <ng-container title>{{ 'lanLatency.label' | translate }}</ng-container>
  <ng-container content>
    <div class="lanLatencyData">
      <div class="header" [class.twoColumns]="lanLatencyData?.hideUpstream">
        <div class="trafficClass">
          {{ 'lanLatency.trafficClass.label' | translate }}
        </div>
        <div class="up" *ngIf="!lanLatencyData?.hideUpstream">{{ 'lanLatency.up' | translate }}</div>
        <div class="down">{{ 'lanLatency.down' | translate }}</div>
      </div>
      <div
        class="data"
        [class.twoColumns]="lanLatencyData?.hideUpstream"
        *ngFor="let lanLatency of lanLatencyData?.data"
      >
        <div class="trafficClass">
          {{ 'lanLatency.trafficClass.' + lanLatency.class | translate }}
        </div>
        <div class="up" *ngIf="!lanLatencyData?.hideUpstream">
          <ng-container *ngIf="lanLatency.up === undefined"> - </ng-container>
          <ng-container *ngIf="lanLatency.up !== undefined">
            {{ 'lanLatency.milliseconds' | translate : { value: lanLatency.up } }}
          </ng-container>
        </div>
        <div class="down">
          <ng-container *ngIf="lanLatency.down === undefined"> - </ng-container>
          <ng-container *ngIf="lanLatency.down !== undefined">
            {{ 'lanLatency.milliseconds' | translate : { value: lanLatency.down } }}
          </ng-container>
        </div>
      </div>
    </div>

    <div class="lanLatencyHints">
      <div class="lanLatencyHint" *ngIf="node">
        {{
          'lanLatency.measurement'
            | translate
              : {
                  parentNode: lanLatencyData.parentNodeId || '-',
                  node: lanLatencyData.nodeId || '-'
                }
        }}
      </div>
      <div class="lanLatencyHint" *ngIf="device">
        {{ 'lanLatency.gatewayHint' | translate }}
      </div>
      <div class="lanLatencyHint">
        {{ 'lanLatency.dataDelayHint' | translate }}
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="closeModal()">
      {{ 'health.networkInformation.modal.close' | translate }}
    </button>
  </ng-container>
</modal>
