<spacer>
  <h1>
    {{ 'charts.tree.title' | translate }} <span>{{ 'charts.tree.beta' | translate }}</span>
  </h1>
</spacer>

<div class="filters">
  <slidetoggler
    *ngIf="showRenderModeToggle"
    [options]="renderModeItems"
    (toggle)="setRenderModeItem($event)"
  ></slidetoggler>
  <drop-down class="selector medium">
    {{ renderStyle.translation | translate }}
    <ng-container dropdown>
      <ng-container *ngFor="let renderStyleItem of renderStyleItems">
        <li (click)="setRenderStyle(renderStyleItem)">
          {{ renderStyleItem.translation | translate }}
        </li>
      </ng-container>
    </ng-container>
  </drop-down>
</div>

<svg
  #chart
  id="realtime-topology"
  [attr.width]="width"
  [attr.height]="(chart.clientWidth / width) * height"
  [attr.viewBox]="viewbox"
>
  <svg:g class="links">
    <svg:linearGradient
      gradientUnits="userSpaceOnUse"
      [attr.id]="link.id"
      [attr.x1]="link.x1"
      [attr.y1]="link.y1"
      [attr.x2]="link.x2"
      [attr.y2]="link.y2"
      *ngFor="let link of links; trackBy: track"
    >
      <svg:stop offset="5%" stop-color="#85BEFF" />
      <svg:stop offset="95%" stop-color="#6269FF" />
    </svg:linearGradient>
    <svg:path
      class="link"
      [class.active]="link.active"
      [class.dim]="selectedLinks.length && !selectedLinks.includes(link.id)"
      [attr.stroke]="link.active ? 'url(#' + link.id + ')' : '#333333'"
      [attr.stroke-width]="link.strokeWidth"
      [attr.d]="link.d"
      *ngFor="let link of links; trackBy: track"
      (mousemove)="showLanLatencyTooltip($event, link)"
      (mouseleave)="hideLanLatencyTooltip()"
    ></svg:path>
  </svg:g>
  <svg:g class="nodes">
    <svg:g
      class="node"
      [class.isp]="node.data.type === 'isp'"
      [class.device]="node.data.type === 'device'"
      [class.active]="node.data.traffic"
      [attr.transform]="'translate(' + node.y + ',' + node.x + ')'"
      *ngFor="let node of nodes; trackBy: track"
    >
      <svg:g>
        <svg:circle
          fill="var(--background-default)"
          [ngClass]="node.data.health"
          [attr.r]="node.width / 5"
          *ngIf="node.data.type === 'node' || node.data.type === 'isp' || node.data.type === 'access'"
        ></svg:circle>
        <svg:g
          [inline]="true"
          [width]="node.width / 2.5"
          [height]="node.width / 2.5"
          [icon]="'/assets/icons/icon-aura.svg'"
          [attr.transform]="'translate(-' + node.width / 5 + ',-' + node.width / 5 + ')'"
          *ngIf="node.data.traffic"
        ></svg:g>
        <svg:g
          fill="var(--text-color)"
          [inline]="true"
          [width]="node.width / 5"
          [height]="node.width / 5"
          [icon]="getIcon(node.data.icon)"
          [attr.transform]="'translate(-' + node.width / 10 + ',-' + node.width / 10 + ')'"
          *ngIf="node.data.type === 'node' || node.data.type === 'isp' || node.data.type === 'access'"
        ></svg:g>
        <svg:g
          fill="var(--text-color)"
          [inline]="true"
          [width]="node.width / 3"
          [height]="node.width / 3"
          [icon]="'/assets/iconsV2/' + node.data.icon"
          [attr.transform]="'translate(-' + node.width / 6 + ',-' + node.width / 6 + ')'"
          *ngIf="node.data.type === 'device'"
        ></svg:g>
        <svg:g class="gateway" transform="translate(15,-30)" *ngIf="node.data.gateway">
          <svg:circle fill="var(--background-default)" transform="translate(7.5,7.5)" [attr.r]="10"></svg:circle>
          <svg:g [inline]="true" [width]="15" [height]="15" [icon]="'/assets/icons/icon-globe-online.svg'"></svg:g>
        </svg:g>
        <svg:g class="label" [attr.transform]="'translate(0,45)'">
          <svg:text>{{ node.data.name }}</svg:text>
        </svg:g>
        <svg:g
          class="traffic"
          [attr.transform]="'translate(-20,62)'"
          *ngIf="renderMode === 'usage' && node.data.traffic && node.data.usage"
        >
          <svg:g
            [inline]="true"
            [width]="12"
            [height]="12"
            [icon]="'/assets/icons/icon-usage.svg'"
            [attr.transform]="'translate(-15, -10)'"
          ></svg:g>
          <svg:text>{{ node.data.usage }}</svg:text>
        </svg:g>

        <svg:g
          class="traffic"
          [attr.transform]="'translate(-20,62)'"
          *ngIf="renderMode === 'lanLatency' && node.data.lanLatency"
        >
          <svg:text
            *ngIf="node.data.lanLatencyDisplayValue?.down"
            tooltip="{{
              'charts.tree.tooltipHint'
                | nestedTranslate : { value: 'lanLatency.trafficClass.' + node.data.lanLatencyDisplayValue?.class }
            }}"
          >
            {{ 'lanLatency.milliseconds' | translate : { value: node.data.lanLatencyDisplayValue?.down } }}
          </svg:text>
        </svg:g>
      </svg:g>
      <svg:g
        class="expander"
        pointer-events="all"
        [attr.transform]="'translate(' + node.width / 2 + ',0)'"
        (click)="expand(node.id)"
        *ngIf="node.expandable"
      >
        <svg:circle r="18"></svg:circle>
        <svg:g
          pointer-events="none"
          fill="var(--text-color)"
          [inline]="true"
          [width]="10"
          [height]="10"
          [icon]="node.children ? '/assets/icons/icon-remove.svg' : '/assets/icons/icon-add.svg'"
          [attr.transform]="'translate(-5,-5)'"
        ></svg:g>
      </svg:g>
    </svg:g>
  </svg:g>
</svg>

<div
  [ngStyle]="lanLatencyTooltipPosition"
  class="tooltip"
  [class.twoColumns]="selectedNode.data.hideUpstream"
  *ngIf="renderMode === 'lanLatency' && lanLatencyTooltip && selectedNode?.data?.lanLatency?.length > 0"
>
  <div class="tooltipData" [class.twoColumns]="selectedNode.data.hideUpstream">
    <span class="header">
      {{ 'lanLatency.trafficClass.label' | translate }}
    </span>
    <span class="header" *ngIf="!selectedNode.data.hideUpstream">{{ 'lanLatency.up' | translate }}</span>
    <span class="header">{{ 'lanLatency.down' | translate }}</span>
  </div>
  <div class="tooltipData" [class.twoColumns]="selectedNode.data.hideUpstream">
    <ng-container *ngFor="let lanLatency of selectedNode.data.lanLatency">
      <span class="left">{{ 'lanLatency.trafficClass.' + lanLatency.class | translate }}</span>
      <ng-container *ngIf="lanLatency.up === undefined && !selectedNode.data.hideUpstream"
        ><span> - </span></ng-container
      >
      <ng-container *ngIf="lanLatency.up !== undefined && !selectedNode.data.hideUpstream">
        <span>{{ 'lanLatency.milliseconds' | translate : { value: lanLatency.up } }}</span>
      </ng-container>
      <ng-container *ngIf="lanLatency.down === undefined"><span> - </span></ng-container>
      <ng-container *ngIf="lanLatency.down !== undefined">
        <span>{{ 'lanLatency.milliseconds' | translate : { value: lanLatency.down } }}</span>
      </ng-container>
    </ng-container>
  </div>
</div>
