import { devices } from './help-items/devices.help';
import { health } from './help-items/health.help';
import { nodes } from './help-items/nodes.help';
import { timelines } from './help-items/timelines.help';
import { topology } from './help-items/topology.help';

export interface IHelpStep {
  targetElement: string;
  content: string;
  options?: {
    expectMovement?: boolean;
    scrollIntoView?: boolean;
    onlyNoc?: boolean;
    action?:
      | {
          type: 'click';
          targetElement: string;
        }
      | {
          type: 'function';
          function: () => Promise<void>;
        };
    exitAction?:
      | {
          type: 'click';
          targetElement: string;
        }
      | {
          type: 'function';
          function: (action?: 'next' | 'previous' | 'finish') => Promise<void>;
        };
  };
}

export interface IHelpItems {
  [helpItem: string]: { steps: IHelpStep[]; options?: IHelpOptions };
}

export interface IHelpPage {
  options?: { order?: string[] };
  helpItems: IHelpItems;
}

export interface IHelpOptions {
  locationOnlineNeeded?: boolean;
  congestionDataNeeded?: boolean;
  nodesNeeded?: boolean;
  devicesNeeded?: boolean;
  deviceOnlineNeeded?: boolean;
  optimizerEventsNeeded?: boolean;
  scrollToTop?: boolean;
}

// pageKey - is the last part of the URL and dictates which help items to show
// demoKey - dictates which demo to start (e.g. general, realtimeTopology, etc.)
//
// pageKey: {
//   demoKey: [
//     {
//        targetElement: query selector string ('#id' or '.class' or ':something .complex ')
//        content: string to display in the step explanation box
//        options: {
//          expectMovement: perform highlighting over and over if the element is animated and moves on screen
//          action: { type: 'click': targetElement: query string } - perform a click before checking for step's targetElement
//          exitAction: same as action but performed when switching to next or previous step (can be click like above or function for more complex exit actions)
//        }
//     }
//   ], ...
// }

export enum HelpPage {
  Health = 'health',
  Topology = 'topology',
  Devices = 'devices',
  Nodes = 'nodes',
  Timelines = 'timelines'
}

export const HELP_ITEMS: {
  [page in HelpPage]?: IHelpPage;
} = {
  health,
  topology,
  devices,
  nodes,
  timelines
};
