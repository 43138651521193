import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import dayjs from 'dayjs';
import { IDevice, IFocus } from 'src/app/lib/interfaces/interface';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { ToastService } from 'src/app/lib/services/toast.service';

@Component({
  selector: 'focus-schedule-modal',
  templateUrl: './focus-schedule-modal.component.html',
  styleUrls: ['./focus-schedule-modal.component.scss']
})
export class FocusScheduleModalComponent {
  @Input()
  device: IDevice = null;

  @Input()
  focusSchedule: {
    id?: string;
    name?: UntypedFormControl;
    enabled?: UntypedFormControl;
    schedules?: {
      startTime: UntypedFormControl;
      endTime: UntypedFormControl;
      selectedDays: number[];
    }[];
    mode?: 'add' | 'update';
  } = {};

  @Output()
  closeFocusScheduleModal = new EventEmitter<void>();

  @Output()
  updateFocuses = new EventEmitter<void>();

  focusRequestPending = false;

  constructor(private toast: ToastService, private customerService: CustomerService) {}

  addFocusSchedule(): void {
    this.focusRequestPending = true;

    if (!this.validateFocusSchedules()) {
      this.focusRequestPending = false;
      return;
    }

    const params: IFocus = {
      name: this.focusSchedule.name.value,
      macs: [this.device.mac],
      schedule: {
        schedules: this.focusSchedule.schedules.map((schedule) => {
          if (schedule.endTime.value === '00:00' || schedule.endTime.value === '') {
            schedule.endTime.setValue(null);
          }

          return {
            startTime: schedule.startTime.value,
            endTime: schedule.endTime.value,
            daysOfWeek: schedule.selectedDays
          };
        })
      }
    };

    this.customerService.postFocuses$(params).subscribe(
      () => {
        this.toast.success('devices.device.focusAdded', 'devices.device.success');
        this.updateFocuses.emit();
        this.closeFocusScheduleModal.emit();
        this.focusRequestPending = false;
      },
      (error: any) => {
        this.focusRequestPending = false;
        this.toast.error(error.error.error.message, error.error.error.name);
      }
    );
  }

  updateFocusSchedule(): void {
    this.focusRequestPending = true;
    if (!this.validateFocusSchedules()) {
      this.focusRequestPending = false;
      return;
    }

    const updatedFocus: IFocus = {
      id: this.focusSchedule.id,
      name: this.focusSchedule.name.value,
      schedule: {
        schedules: this.focusSchedule.schedules.map((schedule: any) => {
          if (schedule.endTime.value === '00:00' || schedule.endTime.value === '') {
            schedule.endTime.setValue(null);
          }

          return {
            startTime: schedule.startTime.value,
            endTime: schedule.endTime.value,
            daysOfWeek: schedule.selectedDays
          };
        })
      }
    };
    this.customerService.patchFocuses$(updatedFocus).subscribe(
      () => {
        this.toast.success('devices.device.focusUpdated', 'devices.device.success');
        this.updateFocuses.emit();
        this.closeFocusScheduleModal.emit();
        this.focusRequestPending = false;
      },
      (error: any) => {
        this.toast.error(error.error.error.message, error.error.error.name);
        this.focusRequestPending = false;
      }
    );
  }

  validateFocusSchedules(): boolean {
    let valid = true;

    if (!this.focusSchedule.name.value) {
      this.toast.warning('devices.device.nameIsRequired', 'devices.device.error');
      valid = false;
    }
    this.focusSchedule.schedules.forEach((schedule: any) => {
      const regex = '^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$';
      const validStartTime = new RegExp(regex).test(schedule.startTime.value);
      const validEndTime = new RegExp(regex).test(schedule.endTime.value);

      if (!(validStartTime && validEndTime)) {
        this.toast.warning('devices.device.timeFormatMessage', 'devices.device.timeFormatTitle');
        valid = false;
      }

      if (schedule.startTime.value === schedule.endTime.value) {
        this.toast.warning('devices.device.sameTimeMessage', 'devices.device.sameTimeTitle');
        valid = false;
      }

      if (!schedule.selectedDays.length) {
        this.toast.warning('devices.device.selectedDaysMessage', 'devices.device.selectedDaysTitle');
        valid = false;
      }
    });
    return valid;
  }

  processDay(day: number): string {
    return dayjs().day(day).format('ddd');
  }

  handleDayClick(day: number, i: number) {
    const index = this.focusSchedule.schedules[i].selectedDays.indexOf(day);

    if (index >= 0) {
      this.focusSchedule.schedules[i].selectedDays.splice(index, 1);
    } else {
      this.focusSchedule.schedules[i].selectedDays.push(day);
    }
  }

  closeModal(): void {
    this.closeFocusScheduleModal.emit();
  }
}
