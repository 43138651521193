import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Bar } from 'src/app/lib/d3/models/objects/bar';
import { AlarmedService } from 'src/app/lib/services/alarmed.service';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ModelRefService } from 'src/app/lib/services/modelref.service';
import { NodeService } from 'src/app/lib/services/nodes.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { selectCapabilities } from 'src/app/store/customer/capabilities.selector';
import { selectPowerManagementState } from 'src/app/store/customer/customer.selectors';
import { selectLteInUse, selectNodeIcon } from 'src/app/store/lte/lte.selectors';
import { selectLocationBbe, selectLocationOptimization } from 'src/app/store/polling/polling.selector';
import { generateBars } from './speedtestnode-bar-generator';

@UntilDestroy()
@Component({
  selector: 'speedtestnode',
  templateUrl: './speedtestnode.component.html',
  styleUrls: ['./speedtestnode.component.scss']
})
export class SpeedtestNodeComponent implements OnInit {
  @Input()
  node: any = {};

  bars: Bar[] = [];
  range: any = { start: new Date(), end: new Date() };

  stats = {
    upload: {
      recent: 0,
      max: 0,
      min: 0
    },
    download: {
      recent: 0,
      max: 0,
      min: 0
    }
  };
  showMaximumSupportedBandwidth: boolean = false;
  maximumSupportedBandwidth: number = null;

  alarms: string[] = [];

  loading: boolean = false;

  timeoutInterval: any;
  timeout: number = 300000;
  interval: number = 6000;

  chartModes: any[] = [];
  chartMode: number = 1;

  showUpload: boolean = true;

  speedTestDisabled$ = this.store
    .select(selectCapabilities)
    .pipe(
      map((capabilities) =>
        this.plume.cloudVersionAbove1_88()
          ? this.isDisabled(capabilities?.ispSpeedTest?.capable, capabilities?.nodeIspSpeedTest?.capable)
          : this.isDisabled(capabilities?.ispSpeedTest?.capable, capabilities?.nodeIspSpeedTest?.capable) ||
            this.plume.isStrictSupportRole()
      )
    );
  gateWayIcon$ = this.store.pipe(selectNodeIcon(''));
  lteInUse$ = this.store.select(selectLteInUse);

  permissions: any;
  inLowPower: boolean = false;

  optimizationState = 'none';

  constructor(
    private plume: PlumeService,
    private modelRef: ModelRefService,
    private logging: LoggingService,
    private toast: ToastService,
    private mixpanel: MixpanelService,
    private nodeService: NodeService,
    private store: Store,
    private isAlarm: AlarmedService
  ) {}

  ngOnInit(): void {
    this.stats.upload.recent = this.node.speedTest ? this.node.speedTest.upload : 0;
    this.stats.download.recent = this.node.speedTest ? this.node.speedTest.download : 0;

    this.setMode(1);
    this.calculateAlarms();

    this.gateWayIcon$ = this.store.pipe(selectNodeIcon(this.node.id));

    this.plume.permissions.pipe(untilDestroyed(this)).subscribe((data: any) => {
      this.permissions = data;
    });

    this.store
      .select(selectPowerManagementState)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.inLowPower =
          data.state?.status === 'PM_STATUS_ACTIVE' ||
          data.state?.status === 'PM_STATUS_ACTIVATING' ||
          data.state?.status === 'PM_STATUS_DEACTIVATING';
      });

    this.store
      .select(selectLocationBbe)
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        if (response) {
          this.calculateBroadbandEfficiencyAlarms(response);
        }
      });

    this.store
      .select(selectLocationOptimization)
      .pipe(untilDestroyed(this))
      .subscribe((response: any) => {
        if (response) {
          this.optimizationState = response.state;
        }
      });
  }

  calculateAlarms() {
    const nodeAlarms: string[] = [];

    const lowQoeHealth = this.isAlarm.getPodQoe(this.node);
    if (lowQoeHealth) {
      nodeAlarms.push('alarmsLowQoeHealth');
    }

    const lowEthernet = this.isAlarm.getEthernetUplink(this.node);
    if (lowEthernet) {
      nodeAlarms.push('alarmsLowEthernet');
    }

    this.alarms = nodeAlarms;
  }

  calculateBroadbandEfficiencyAlarms(response: any) {
    const msgNodes = response.nodes;
    const msgSummary = response.summary;

    const msgNode = msgNodes.find((node: any) => node.nodeId === this.node.id);
    if (!msgNode?.broadbandEfficiency) return;

    const extenderSpeedAlarm = this.isAlarm.getLowExtenderSpeed(msgNode, msgSummary, this.node);
    if (extenderSpeedAlarm && !this.alarms.includes('alarmsExtenderSpeed')) {
      this.alarms.push('alarmsExtenderSpeed');
    } else {
      this.alarms.filter((alarm) => alarm !== 'alarmsExtenderSpeed');
    }

    const broadbandEfficiencyAlarm = this.isAlarm.getBbeAlarm(msgNode, msgSummary, this.node);
    if (broadbandEfficiencyAlarm && !this.alarms.includes('alarmsBroadBandEfficiency') && !extenderSpeedAlarm) {
      this.alarms.push('alarmsBroadBandEfficiency');
    } else {
      this.alarms.filter((alarm) => alarm !== 'alarmsBroadBandEfficiency');
    }
  }

  getIcon(model: string): string {
    return this.modelRef.get(model).icon;
  }

  isDisabled(ispSpeedTestCapable: boolean, nodeIspSpeedTestCapable: boolean): boolean {
    if (!this.permissions?.uiFeatures?.speedTestsConfiguration) {
      return true;
    } else {
      if (this.node.residentialGateway === true) {
        return !ispSpeedTestCapable;
      } else if (this.node.backhaulType === 'ethernet' && (ispSpeedTestCapable || nodeIspSpeedTestCapable)) {
        return false;
      } else if (nodeIspSpeedTestCapable) {
        return false;
      } else {
        return true;
      }
    }
  }

  setMode(mode: number): void {
    this.chartMode = mode;

    this.chartModes = [
      { value: 1, translation: '24h', selected: this.chartMode === 1 ? true : false },
      { value: 7, translation: '7d', selected: this.chartMode === 7 ? true : false },
      { value: 30, translation: '30d', selected: this.chartMode === 30 ? true : false }
    ];

    this.speedtestData();
  }

  toggleUploadData(): void {
    this.showUpload = !this.showUpload;
  }

  speedtestData(testStart?: any): void {
    this.nodeService.speedTestResults$(this.node.id, 'days', this.chartMode).subscribe((response: any) => {
      this.showMaximumSupportedBandwidth = false;
      this.maximumSupportedBandwidth = response?.maximumSupportedBandwidth || null;

      if (!response.downloadSpeeds.length) {
        if (+new Date() - testStart > this.timeout) {
          this.logging.error('Speed test timeout with no response!');
          this.loading = false;
          clearTimeout(this.timeoutInterval);
          this.toast.error('toast.speedTestNode.errorMessage', 'toast.speedTestNode.errorTitle', {
            disableTimeOut: true
          });
        } else {
          this.stats = {
            upload: { ...this.stats.upload, min: 0, max: 0 },
            download: { ...this.stats.download, min: 0, max: 0 }
          };
          this.bars = [];
        }
        return;
      }

      const highestDownloadSpeed = response.downloadSpeeds.reduce((prev, current) =>
        prev.value > current ? prev.value : current
      );
      const highestUploadSpeed = response.uploadSpeeds.reduce((prev, current) =>
        prev.value > current.value ? prev : current
      );

      // If any of the displayed nodes speedtest_result is more than 90%
      // of maximumSupportedBandwidth then we show the warning message
      if (this.maximumSupportedBandwidth) {
        if (highestDownloadSpeed.value >= 0.9 * this.maximumSupportedBandwidth) {
          this.showMaximumSupportedBandwidth = true;
        }
        if (highestUploadSpeed.value >= 0.9 * this.maximumSupportedBandwidth) {
          this.showMaximumSupportedBandwidth = true;
        }
      }

      const testTime = +new Date(response.downloadSpeeds[0].timestamp);
      const setOnload = !testStart;

      if (setOnload || (testTime && testTime - testStart > 0)) {
        this.loading = false;
        clearTimeout(this.timeoutInterval);

        response.statsDateRange.mode = this.chartMode;
        this.range = response.statsDateRange;
        const downData = generateBars(response.downloadSpeeds, 'download', this.range);
        const upData = generateBars(response.uploadSpeeds, 'upload', this.range);
        this.stats.download = downData.stats;
        this.stats.upload = upData.stats;
        this.bars = [...downData.bars, ...upData.bars];
      } else if (+new Date() - testStart > this.timeout) {
        this.loading = false;
        clearTimeout(this.timeoutInterval);
        this.logging.error('Speed test timeout with no response!');
        this.toast.error('toast.speedTestNode.errorMessage', 'toast.speedTestNode.errorTitle', {
          disableTimeOut: true
        });
      }
    });
  }

  startTest(): void {
    if (this.optimizationState === 'initiated' || this.optimizationState === 'inProgress') {
      this.toast.warning(
        'toast.speedTestOptimizationInProgress.message',
        'toast.speedTestOptimizationInProgress.title',
        {
          disableTimeOut: true
        }
      );
      return;
    }

    if (this.inLowPower === true && !this.node.isGateway) {
      this.toast.warning('toast.speedTestLowPower.message', 'toast.speedTestLowPower.title', {
        disableTimeOut: true
      });
    }

    if (!this.loading) {
      this.loading = true;
      this.mixpanel.storeEvent('SPEEDTEST_TEST_SPEED', { NODE_ID: this.node.id });
      clearTimeout(this.timeoutInterval);
      const testStart = +new Date();

      this.nodeService.triggerSpeedTest$(this.node.id, 'OOKLA').subscribe(() => {
        this.timeoutInterval = setInterval(() => {
          this.speedtestData(testStart);
        }, this.interval);
      });
    }
  }
}
