import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl } from '@angular/forms';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { ModalService } from 'src/app/lib/services/modal.service';
import { Store } from '@ngrx/store';
import { selectPipeLocationOnChange } from 'src/app/store/customer/customer.selectors';
import { map } from 'rxjs/operators';
import { NetworkAccessService } from 'src/app/lib/services/network-access.service';
import { IManager, INetworkAccessNetworks } from 'src/app/lib/interfaces/interface';

@Component({
  selector: 'smallbusiness',
  templateUrl: './smallbusiness.component.html',
  styleUrls: ['./smallbusiness.component.scss']
})
export class SmallbusinessComponent implements OnInit, OnChanges, OnDestroy {
  locationSubscription: any;
  expand: boolean = false;
  details: any = null;
  managers: IManager[] = [];
  networks: INetworkAccessNetworks[] = [];
  managerNameFormControl: UntypedFormControl = new UntypedFormControl();
  managerEmailFormControl: UntypedFormControl = new UntypedFormControl();
  isSmallBusiness$ = this.store
    .pipe(selectPipeLocationOnChange)
    .pipe(map((location) => location.profile === 'smallbusiness'));

  @Input()
  open: number = 0;

  @Output()
  toggle = new EventEmitter();

  @Output()
  filter = new EventEmitter();

  @Output()
  clearFilter = new EventEmitter<{ section: string }>();

  constructor(
    public plume: PlumeService,
    private mixpanel: MixpanelService,
    private toast: ToastService,
    private modal: ModalService,
    private translate: TranslateService,
    private store: Store,
    private networkAccessService: NetworkAccessService
  ) {}

  ngOnInit(): void {
    this.locationSubscription = this.store.pipe(selectPipeLocationOnChange).subscribe((location) => {
      this.registerFilter(location.profile === 'smallbusiness');

      if (location.profile !== 'auto') {
        this.getNetworks();
        this.getManagers();
      }
    });
  }

  ngOnChanges(changes: any): void {
    this.expand = changes.open.currentValue;
  }

  registerFilter(isSmallBusiness: boolean): void {
    this.clearFilter.emit({ section: 'smallbusiness' });

    if (!isSmallBusiness) {
      return;
    }

    this.translate
      .get('configurations.smallbusiness.update')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'smallbusiness', property: 'update', translation: translated })
      );

    this.translate
      .get('configurations.smallbusiness.add')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'smallbusiness', property: 'add', translation: translated })
      );
    this.translate
      .get('configurations.smallbusiness.purgatory')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'smallbusiness', property: 'networks', translation: translated })
      );
    this.translate
      .get('configurations.smallbusiness.networkApprovalSettings')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'smallbusiness', property: 'networks', translation: translated })
      );
  }

  getManagers(): void {
    this.networkAccessService.managers$().subscribe((response) => {
      this.managers = response;
    });
  }

  getNetworks(): void {
    this.networkAccessService.networks$().subscribe((response) => {
      this.networks = response;
    });
  }

  confirmDeleteManager(id: string): void {
    this.modal
      .showDialog(
        'configurations.smallbusiness.popup.wantDeleteMsg',
        'configurations.smallbusiness.popup.wantDeleteTitle',
        {
          buttons: [
            { style: 'tertiary light', value: 'configurations.smallbusiness.popup.cancel' },
            { style: 'super-primary', value: 'configurations.smallbusiness.popup.delete' }
          ]
        }
      )
      .subscribe((response: any) => {
        if (response.item?.value === 'configurations.smallbusiness.popup.delete') {
          this.deleteManager(id);
        }
      });
  }

  deleteManager(id: string): void {
    this.networkAccessService.deleteManager$(id).subscribe(
      () => {
        this.getManagers();
        this.mixpanel.storeEvent('MANAGER_DELETE_SUCCESS', { MANAGER_ID: id });
      },
      () => {
        this.mixpanel.storeEvent('MANAGER_DELETE_FAILURE', { MANAGER_ID: id });
        this.toast.warning(
          'configurations.smallbusiness.toast.cannotDeleteMsg',
          'configurations.smallbusiness.toast.cannotDeleteTitle'
        );
      }
    );
  }

  resendInvite(id: string): void {
    this.networkAccessService.managerResendInvite$(id).subscribe(
      () => {
        this.mixpanel.storeEvent('MANAGER_RESEND_INVITE_SUCCESS', { MANAGER_ID: id });
        this.toast.success(
          'configurations.smallbusiness.toast.successResendInviteMsg',
          'configurations.smallbusiness.toast.successResendInviteTitle'
        );
      },
      () => {
        this.mixpanel.storeEvent('MANAGER_RESEND_INVITE_FAILURE', { MANAGER_ID: id });
        this.toast.warning(
          'configurations.smallbusiness.toast.cannotResendInviteMsg',
          'configurations.smallbusiness.toast.cannotResendInviteTitle'
        );
      }
    );
  }

  togglePurgatory(networkId: string, status: boolean): void {
    this.networkAccessService
      .patchNetwork$(networkId, {
        purgatory: !status
      })
      .subscribe(
        () => {
          this.getNetworks();
          this.mixpanel.storeEvent('PURGATORY_EDIT_SUCCESS', { NETWORK_ID: networkId });
        },
        () => {
          this.mixpanel.storeEvent('PURGATORY_EDIT_FAILURE', { NETWORK_ID: networkId });
          this.toast.warning(
            'configurations.smallbusiness.toast.failureMsg',
            'configurations.smallbusiness.toast.failureTitle'
          );
        }
      );
  }

  addManager(): void {
    if (!this.managerNameFormControl.value) {
      this.toast.error(
        'configurations.smallbusiness.toast.missingName',
        'configurations.smallbusiness.toast.cannotAddTitle'
      );
    }
    if (!this.managerEmailFormControl.value) {
      this.toast.error(
        'configurations.smallbusiness.toast.missingEmail',
        'configurations.smallbusiness.toast.cannotAddTitle'
      );
    }
    if (!this.managerNameFormControl.value || !this.managerEmailFormControl.value) {
      return;
    }
    this.networkAccessService
      .addManager$(this.managerNameFormControl.value, this.managerEmailFormControl.value)
      .subscribe(
        () => {
          this.getManagers();

          this.managerNameFormControl.setValue('');
          this.managerEmailFormControl.setValue('');

          this.mixpanel.storeEvent('MANAGER_ADD_SUCCESS', {
            NAME: this.managerNameFormControl.value,
            EMAIL: this.managerEmailFormControl.value,
            ACCESSTYPE: 'admin'
          });
        },
        (error: any) => {
          const message =
            error && error.error && error.error.error && error.error.error.message ? error.error.error.message : '';

          this.mixpanel.storeEvent('MANAGER_ADD_FAILURE', {
            NAME: this.managerNameFormControl.value,
            EMAIL: this.managerEmailFormControl.value,
            MESSAGE: message,
            ACCESSTYPE: 'admin'
          });

          this.toast.warning(
            message ? message : 'configurations.smallbusiness.toast.cannotAddMsg',
            'configurations.smallbusiness.toast.cannotAddTitle'
          );
        }
      );
  }

  toggleExpand(): void {
    this.toggle.emit(!this.expand);

    if (!this.expand) {
      this.mixpanel.storeEvent('CONFIGURATION_SMALL_BUSINESS_SCREEN');
    }
  }

  ngOnDestroy(): void {
    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
    }
  }
}
