import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IConfigAndStateWan, IWanConfiguration } from 'src/app/lib/interfaces/interface';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { customerLocationConfigAndStateReload } from 'src/app/store/customer/customer.actions';
import { selectConfigAndState } from 'src/app/store/customer/customer.selectors';

@Component({
  selector: 'wan-configuration',
  templateUrl: './wan-configuration.component.html',
  styleUrls: ['./wan-configuration.component.scss']
})
export class WanConfigurationComponent implements OnInit {
  edit = false;

  wan: IConfigAndStateWan = null;

  pppoe = {
    enabled: false,
    username: new UntypedFormControl(''),
    password: new UntypedFormControl('')
  };

  staticIp = {
    enabled: false,
    ip: new UntypedFormControl(''),
    gateway: new UntypedFormControl(''),
    primaryDns: new UntypedFormControl(''),
    secondaryDns: new UntypedFormControl(''),
    subnet: new UntypedFormControl('')
  };

  vlan = {
    enabled: false,
    vlanId: new UntypedFormControl('')
  };

  numRegex = /^[0-9]*$/;
  ipRegex =
    /^(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])$/;

  constructor(
    public plume: PlumeService,
    private customerService: CustomerService,
    private toast: ToastService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.store.select(selectConfigAndState).subscribe((configAndState) => {
      const masterGateway = configAndState.state.nodeState.find((node) => node.isMasterGateway);

      if (masterGateway?.wan?.length) {
        this.wan = masterGateway.wan[0];
        this.pppoe.enabled = !!this.wan?.pppoe;
        this.staticIp.enabled = !!this.wan?.staticIp;
        this.vlan.enabled = !!this.wan?.vlan;
      }
    });
  }

  setEditState(): void {
    this.pppoe.enabled = !!this.wan?.pppoe;
    this.pppoe.username.setValue(this.wan?.pppoe?.username || '');
    this.pppoe.password.setValue(this.wan?.pppoe?.password || '');

    this.staticIp.enabled = !!this.wan?.staticIp;
    this.staticIp.ip.setValue(this.wan?.staticIp?.ip || '');
    this.staticIp.gateway.setValue(this.wan?.staticIp?.gateway || '');
    this.staticIp.primaryDns.setValue(this.wan?.staticIp?.primaryDns || '');
    this.staticIp.secondaryDns.setValue(this.wan?.staticIp?.secondaryDns?.value || '');
    this.staticIp.subnet.setValue(this.wan?.staticIp?.subnet || '');

    this.vlan.enabled = !!this.wan?.vlan;
    this.vlan.vlanId.setValue(this.wan?.vlan?.vlanId || '');

    this.edit = true;
  }

  cancelEditState(): void {
    this.edit = false;

    this.pppoe.enabled = !!this.wan?.pppoe;
    this.pppoe.username.reset();
    this.pppoe.password.reset();

    this.staticIp.enabled = !!this.wan?.staticIp;
    this.staticIp.ip.reset();
    this.staticIp.gateway.reset();
    this.staticIp.primaryDns.reset();
    this.staticIp.secondaryDns.reset();
    this.staticIp.subnet.reset();

    this.vlan.enabled = !!this.wan?.vlan;
    this.vlan.vlanId.reset();
  }

  saveEditState(): void {
    this.customerService.setWanConfiguration$(this.cleanWanData()).subscribe(
      () => {
        this.store.dispatch(customerLocationConfigAndStateReload({ actionType: '[WAN Config] Update configAndState' }));
        this.edit = false;
        this.toast.success('configurations.wan.configuration.saveSuccess', 'header.success');
      },
      () => {
        this.toast.error('configurations.wan.configuration.saveError', 'header.failed');
      }
    );
  }

  cleanWanData(): IWanConfiguration {
    const wan = {
      pppoe: { enabled: false },
      staticIPv4: { enabled: false },
      uplink: { enabled: false }
    };

    if (this.pppoe.enabled) {
      wan.pppoe['enabled'] = true;
      wan.pppoe['username'] = this.pppoe.username.value;
      wan.pppoe['password'] = this.pppoe.password.value;
    }

    if (this.staticIp.enabled) {
      wan.staticIPv4['enabled'] = true;
      wan.staticIPv4['ip'] = this.staticIp.ip.value;
      wan.staticIPv4['gateway'] = this.staticIp.gateway.value;
      wan.staticIPv4['primaryDns'] = this.staticIp.primaryDns.value;
      wan.staticIPv4['secondaryDns'] = this.staticIp.secondaryDns.value;
      wan.staticIPv4['subnet'] = this.staticIp.subnet.value;
    }

    if (this.vlan.enabled) {
      wan.uplink['enabled'] = true;
      wan.uplink['vlan'] = this.vlan.vlanId.value;
      wan.uplink['hardwarePort'] = 'any';
      wan.uplink['qos'] = '0';
    }

    return wan;
  }

  toggleSection(section: 'pppoe' | 'staticIp' | 'vlan'): void {
    this[section].enabled = !this[section].enabled;

    if (this[section].enabled) {
      switch (section) {
        case 'pppoe':
          this.pppoe.username.setValue(this.wan?.pppoe?.username || '');
          this.pppoe.password.setValue(this.wan?.pppoe?.password || '');
          break;
        case 'staticIp':
          this.staticIp.ip.setValue(this.wan?.staticIp?.ip || '');
          this.staticIp.gateway.setValue(this.wan?.staticIp?.gateway || '');
          this.staticIp.primaryDns.setValue(this.wan?.staticIp?.primaryDns || '');
          this.staticIp.secondaryDns.setValue(this.wan?.staticIp?.secondaryDns?.value || '');
          this.staticIp.subnet.setValue(this.wan?.staticIp?.subnet || '');
          break;
        case 'vlan':
          this.vlan.vlanId.setValue(this.wan?.vlan?.vlanId || '');
      }
    }
  }
}
