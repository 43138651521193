import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'nestedTranslate' })
export class NestedTranslatePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(key: string, params: any = {}) {
    const translatedParams = {};
    for (const [param, value] of Object.entries(params)) {
      if (typeof value === 'string') {
        translatedParams[param] = this.translate.instant(value);
      } else {
        translatedParams[param] = value;
      }
    }

    return this.translate.instant(key, translatedParams);
  }
}
