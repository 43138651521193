import { scaleLinear, scaleTime, extent, NumberValue } from 'd3';
import { Line } from '../objects/line';
import { Tick } from '../objects/tick';
import { Point } from '../objects/point';
import moment from 'moment';
import { Dot } from '../objects/dot';

export class LineChart {
  private data: any[];
  private lines: Line[];
  private width: number;
  private height: number;
  private xScale: any;
  private yScaleLeft: any;
  private yScaleRight: any;
  private period: '3h' | '24h' | '7d' | '30d' = '30d';
  private margins = {
    top: 20,
    right: 20,
    bottom: 20,
    left: 20
  };

  constructor() {}

  calculateWidth(width: number = null): number {
    const calculated = (width ? width : this.width) - this.margins.left - this.margins.right;
    return calculated > 0 ? calculated : 0;
  }

  calculateHeight(height: number = null): number {
    const calculated = (height ? height : this.height) - this.margins.top - this.margins.bottom;
    return calculated > 0 ? calculated : 0;
  }

  prepareScales(): void {
    this.xScale = scaleTime().range([0, this.calculateWidth()]);
    this.yScaleLeft = scaleLinear().range([this.calculateHeight(), 0]);
    this.yScaleRight = scaleLinear().range([this.calculateHeight(), 0]);
  }

  roundTime(subtract: number, moment: moment.Moment) {
    const roundedMinutes = Math.floor(moment.minute() / 15) * 15;
    return [
      moment.clone().minute(roundedMinutes).second(0).subtract(subtract),
      moment.clone().minute(roundedMinutes).second(0)
    ];
  }

  roundDate(date: Date): Date {
    const ms = 60 * 1000;
    const round = Math.floor(date.getTime() / ms) * ms;

    return new Date(round);
  }

  scaleValue(axis: string, value: Date | NumberValue) {
    if (axis === 'x') {
      return this.xScale(value);
    } else {
      return this.yScaleLeft(value);
    }
  }

  prepareDomains(): void {
    const xdomains = [];
    const yleftdomains = [];
    const yrightdomains = [];

    this.data.forEach((line: any) => {
      xdomains.push(...line.data.map((d: any) => new Date(d.label)));

      if (line.axis === 'left') {
        yleftdomains.push(...line.data.map((d: any) => d.value));
      }
      if (line.axis === 'right') {
        yrightdomains.push(...line.data.map((d: any) => d.value));
      }
    });

    if (this.period === '3h') {
      this.xScale.domain(this.roundTime(3 * 60 * 60 * 1000, moment()));
    } else if (this.period === '24h') {
      this.xScale.domain(this.roundTime(24 * 60 * 60 * 1000, moment()));
    } else if (this.period === '7d') {
      this.xScale.domain(this.roundTime(7 * 24 * 60 * 60 * 1000, moment().add(1, 'hours')));
    } else if (this.period === '30d') {
      this.xScale.domain([moment().clone().startOf('day').subtract(30, 'days'), moment().clone().startOf('day')]);
    } else {
      this.xScale.domain(extent(xdomains));
    }

    this.yScaleLeft.domain(extent([0, ...yleftdomains, 1])).nice();
    this.yScaleRight.domain(extent([0, ...yrightdomains])).nice();
  }

  calculateLines(): void {
    let offset = [0];

    if (this.data?.length === 2) offset = [-1, 1];
    if (this.data?.length === 3) offset = [-3, 0, 3];

    this.lines = this.data.map((line: Line) => {
      let resetLine = false;
      let command = 'M';
      let x = 0;
      let y = 0;

      line.d = '';
      line.dots = [];

      line.data.forEach((point: Point, index: number) => {
        offset[index] = offset[index] || 0;

        if (point.value !== null) {
          if (index && !resetLine) {
            command = 'L';
          } else {
            command = 'M';
            resetLine = false;
          }

          x = this.xScale(new Date(point.label));

          if (line.axis === 'left') {
            y = this.yScaleLeft(point.value);
          } else {
            y = this.yScaleRight(point.value);
          }

          if (x > 0 && x < this.width) {
            line.dots.push(new Dot(line.series, 3, x, y + offset[index], false, point.label));
          }

          line.d += command + x + ' ' + y + ' ';
        } else {
          resetLine = true;
        }
      });

      return line;
    });
  }

  timeExtractor(time: string): string {
    if (this.period === '3h') {
      return new Date(time).toLocaleTimeString(moment.locale(), {
        hour: '2-digit',
        minute: '2-digit'
      });
    }

    if (this.period === '24h') {
      return new Date(time).toLocaleTimeString(moment.locale(), {
        hour: '2-digit',
        minute: '2-digit'
      });
    }

    if (this.period === '7d') {
      return new Date(time).toLocaleString(moment.locale(), {
        day: '2-digit',
        month: 'short'
      });
    }

    if (this.period === '30d') {
      return new Date(time).toLocaleString(moment.locale(), {
        day: '2-digit',
        month: 'short'
      });
    }
  }

  xAxis() {
    return this.xScale
      .ticks(12)
      .map(
        (tick) =>
          new Tick(
            'xaxis',
            this.timeExtractor(tick),
            'translate(' + this.xScale(tick) + ', ' + (this.calculateHeight() + 20) + ')',
            { x: 0, dy: 0.32, textAnchor: 'middle' }
          )
      );
  }

  yAxisLeft() {
    return this.yScaleLeft.ticks(5).map(
      (tick) =>
        new Tick(
          'yaxis left',
          tick + ' min',
          'translate(0, ' + this.yScaleLeft(tick) + ')',
          { x: -20 - this.margins.left / 2, dy: 0.32 },
          true,
          {
            x2: this.calculateWidth()
          }
        )
    );
  }

  yAxisRight(): any[] {
    return this.yScaleRight
      .ticks(5)
      .map(
        (tick: any) =>
          new Tick(
            'yaxis right',
            this.converter(tick),
            'translate(' + this.calculateWidth() + ', ' + this.yScaleRight(tick) + ')',
            { x: 20 + this.margins.right / 2, dy: 0.32 },
            false
          )
      );
  }

  xDomain() {
    return this.xScale.domain();
  }

  update(data: any[], width?: number, height?: number, margins?: any, period?: '3h' | '24h' | '7d' | '30d'): Line[] {
    this.data = data;

    if (width) {
      this.width = width;
    }
    if (height) {
      this.height = height;
    }
    if (margins) {
      this.margins = margins;
    }
    if (period) {
      this.period = period;
    }

    this.prepareScales();
    this.prepareDomains();

    this.calculateLines();

    return this.lines;
  }

  converter(value: number): string {
    const units = ['', 'k', 'M', 'B'];

    const tier = Math.log10(value) / 3 || 0;

    if (tier === 0) {
      return value.toString();
    }

    const prefix = units[tier];
    const scale = Math.pow(10, tier * 3);

    const scaled = value / scale;

    return parseFloat(scaled.toFixed(1)) + prefix;
  }
}
