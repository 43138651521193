import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { ModalService } from 'src/app/lib/services/modal.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { SecondaryNetworksService } from 'src/app/lib/services/secondary-networks.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { selectCapabilityWpa3 } from 'src/app/store/customer/capabilities.selector';
import {
  selectIsHomePass,
  selectIsProperty,
  selectIsUpriseLocation,
  selectIsWorkPass,
  selectPipeLocationOnChange
} from 'src/app/store/customer/customer.selectors';
import { ISecondaryNetworkFronthaul } from '../../../lib/interfaces/interface';
import { CustomerService } from '../../../lib/services/customer.service';

@UntilDestroy()
@Component({
  selector: 'secondarynetworks',
  templateUrl: './secondarynetworks.component.html',
  styleUrls: ['./secondarynetworks.component.scss']
})
export class SecondaryNetworksComponent implements OnInit {
  loadingEmployee: boolean = true;
  loadingGuest: boolean = true;
  smallbusiness: any = {
    show: false,
    mode: 'employees',
    employees: [],
    guests: []
  };

  employeeSsidFormControl: UntypedFormControl = new UntypedFormControl();
  employeePasswordFormControl: UntypedFormControl = new UntypedFormControl();

  guestEnabledItems: any[] = [];
  guestSsidFormControl: UntypedFormControl = new UntypedFormControl();
  editingRateLimit: boolean = false;
  guestRateLimitFormControl: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9/.]*')]);
  guestLimitItems: any[] = [];
  showLimitBW: boolean = false;
  limitBWSetting: any = {};
  supportTechnicianCanEdit = this.plume.cloudVersionAbove1_94() && this.plume.isGroupSupportTechnician();
  encryptionItems: any[] = [];
  isUprise$ = this.store.select(selectIsUpriseLocation);
  isHomePass$ = this.store.select(selectIsHomePass);
  isWorkPass$ = this.store.select(selectIsWorkPass);
  isProperty$ = this.store.select(selectIsProperty);
  helper: GeneralHelper = new GeneralHelper();
  IOT: ISecondaryNetworkFronthaul[] = [];
  numNetworks: number = 0;
  isIoT: boolean = false;
  isWPA3: boolean = false;
  allowSecondaryNetworkFronthaulWpaModeOpenEnabled: boolean = false;
  wpa3capable$ = this.store.select(selectCapabilityWpa3);

  constructor(
    public plume: PlumeService,
    private secondaryNetworks: SecondaryNetworksService,
    private toast: ToastService,
    private modal: ModalService,
    private mixpanel: MixpanelService,
    private router: Router,
    private store: Store,
    private customerService: CustomerService
  ) {}

  ngOnInit(): void {
    this.guestEnabledItems = [
      { value: true, translation: 'health.networkInformation.modal.yes', selected: true },
      { value: false, translation: 'health.networkInformation.modal.no', selected: false }
    ];
    this.encryptionItems = [
      { value: 'psk2', translation: 'health.networkInformation.wpa2', selected: true },
      { value: 'sae-mixed', translation: 'health.networkInformation.wpa3transition', selected: false }
    ];

    this.store
      .pipe(selectPipeLocationOnChange)
      .pipe(untilDestroyed(this))
      .subscribe((location) => {
        this.isIoT = location.uprise === true && location.upriseConfig?.iotConfig?.iotNetwork === true;
      });
    this.getCustomerSupportConfig();
  }

  change(): void {
    this.plume.toggler.next();
  }

  getCustomerSupportConfig(): any {
    this.wpa3capable$.subscribe((capable) => {
      this.isWPA3 = capable;

      if (this.plume.cloudVersionAbove1_128()) {
        this.customerService.getCustomerSupportConfigurations$().subscribe(
          (customerSupportConfigurationsResponse) => {
            this.allowSecondaryNetworkFronthaulWpaModeOpenEnabled =
              customerSupportConfigurationsResponse?.customerFeatureEnablement
                ?.allowSecondaryNetworkFronthaulWpaModeOpenEnabled === true;
            this.getSmallbusinessWiFi();
          },
          (error) => {
            console.log('customerSupportConfigurations failed to respond correctly', error);
            this.getSmallbusinessWiFi();
          }
        );
      } else {
        this.getSmallbusinessWiFi();
      }
    });
  }

  getWpaItems(wpaMode: string): any {
    const wpaTogglerItems = [];

    if (this.allowSecondaryNetworkFronthaulWpaModeOpenEnabled || this.isWPA3) {
      wpaTogglerItems.push({
        value: 'psk2',
        translation: 'health.networkInformation.wpa2',
        selected: wpaMode === 'psk2'
      });
      if (this.isWPA3) {
        wpaTogglerItems.push({
          value: 'sae-mixed',
          translation: 'health.networkInformation.wpa3transition',
          selected: wpaMode === 'sae-mixed'
        });
      }
      if (this.allowSecondaryNetworkFronthaulWpaModeOpenEnabled) {
        wpaTogglerItems.push({
          value: 'open',
          translation: 'health.networkInformation.open',
          selected: wpaMode === 'open'
        });
      }
    }
    return wpaTogglerItems;
  }

  toggleShowLimitBW(show: boolean, guestNetwork: any = null): void {
    this.showLimitBW = show;

    if (show) {
      this.limitBWSetting = guestNetwork;

      if (!guestNetwork.bandwidthLimit) {
        this.limitBWSetting.bandwidthLimit = { enabled: false, type: 'percentage', upload: 20.0, download: 20.0 };
      }

      this.guestRateLimitFormControl.setValue(this.limitBWSetting.bandwidthLimit.download);
      this.editingRateLimit = false;

      this.guestLimitItems = [
        {
          value: 'off',
          translation: 'health.networkInformation.modal.off',
          selected: !this.limitBWSetting.bandwidthLimit.enabled
        },
        {
          value: 'percentage',
          translation: 'health.networkInformation.modal.percentageBW',
          selected:
            this.limitBWSetting.bandwidthLimit.enabled && this.limitBWSetting.bandwidthLimit.type === 'percentage'
        }
      ];
    } else {
      this.getSmallbusinessWiFi();
    }
  }

  updateLimitBWType(value: string): void {
    switch (value) {
      case 'off':
        this.limitBWSetting.bandwidthLimit.enabled = false;
        break;
      case 'percentage':
        this.limitBWSetting.bandwidthLimit.enabled = true;
        this.limitBWSetting.bandwidthLimit.type = value;
        this.forceRateLimitsToMaxPercent();
        break;
    }
  }

  validatePercentage(value: number, typePercentage: boolean): boolean {
    if (typePercentage) {
      return value >= 0 && value <= 100 ? true : false;
    } else {
      return true;
    }
  }

  forceRateLimitsToMaxPercent(): void {
    if (
      !this.validatePercentage(
        parseFloat(this.guestRateLimitFormControl.value),
        this.limitBWSetting.bandwidthLimit.type === 'percentage'
      )
    ) {
      this.limitBWSetting.bandwidthLimit.download = 100;
      this.limitBWSetting.bandwidthLimit.upload = 100;
      this.guestRateLimitFormControl.setValue(100);
    }
  }

  updateRateLimit(): void {
    if (
      this.guestRateLimitFormControl.valid &&
      this.validatePercentage(
        parseFloat(this.guestRateLimitFormControl.value),
        this.limitBWSetting.bandwidthLimit.type === 'percentage'
      )
    ) {
      this.limitBWSetting.bandwidthLimit.upload = parseFloat(this.guestRateLimitFormControl.value);
      this.limitBWSetting.bandwidthLimit.download = parseFloat(this.guestRateLimitFormControl.value);
      this.editingRateLimit = false;
    } else {
      this.toast.error(
        'health.networkInformation.toast.limitBWFailureNumberFormat',
        'health.networkInformation.toast.limitBWFailureTitle'
      );
    }
  }

  enableRateLimits(): void {
    this.editingRateLimit = true;
  }

  cancelRateLimits(): void {
    this.guestRateLimitFormControl.setValue(this.limitBWSetting.bandwidthLimit.download);
    this.editingRateLimit = false;
  }

  storeLimitBW(): void {
    this.secondaryNetworks
      .editCaptivePortalNetwork$(this.limitBWSetting.id, { bandwidthLimit: this.limitBWSetting.bandwidthLimit })
      .subscribe(
        () => {
          this.showLimitBW = false;
          this.guestRateLimitFormControl.reset();
          this.mixpanel.storeEvent('HEALTH_EDIT_SMALLBUSINESS_BW_UPDATE_SUCCESS');
          this.getSmallbusinessWiFi();
        },
        (error: any) => {
          this.mixpanel.storeEvent('HEALTH_EDIT_SMALLBUSINESS_BW_UPDATE_FAILURE');
          this.toast.error(error.error.error.message, error.error.error.name);
        }
      );
  }

  getSmallbusinessWiFi(): void {
    this.numNetworks = 0;
    this.secondaryNetworks.getFronthaul$().subscribe(
      (response) => {
        const responseSB = response.filter((net) => net.networkId !== 'iot');
        const responseIOT = response.filter((net) => net.networkId === 'iot');
        const networks = [];
        this.IOT = responseIOT;
        if (responseIOT.length > 0) {
          this.numNetworks++;
        }
        if (responseSB.length > 0) {
          this.numNetworks++;
        }
        responseSB.forEach((config: any) => {
          config.editing = {
            ssid: false,
            password: false
          };
          config.ssidInput = new UntypedFormControl(config.ssid);
          config.password = new UntypedFormControl('');
          config.wpaItems = this.getWpaItems(config.wpaMode);
          config.id = config.networkId;
          networks.push(config);
        });

        this.smallbusiness.employees = networks;
        this.loadingEmployee = false;
      },
      () => {
        this.smallbusiness.employees = [];
        this.loadingEmployee = false;
      }
    );

    this.secondaryNetworks.getCaptivePortalNetwork$().subscribe(
      (response) => {
        const networks = [];

        if (response.length > 0) {
          this.numNetworks++;
        }
        response.forEach((config: any) => {
          config.editing = false;
          config.ssidInput = new UntypedFormControl(config.ssid);
          config.enabledItems = [
            { value: true, translation: 'health.networkInformation.modal.yes', selected: config.enable },
            { value: false, translation: 'health.networkInformation.modal.no', selected: !config.enable }
          ];
          config.id = config.networkId;
          config.limitEnabled =
            config.bandwidthLimit && config.bandwidthLimit.enabled
              ? 'health.networkInformation.modal.on'
              : 'health.networkInformation.modal.off';
          networks.push(config);
        });

        this.smallbusiness.guests = networks;
        this.loadingGuest = false;
      },
      () => {
        this.smallbusiness.guests = [];
        this.loadingGuest = false;
      }
    );
  }

  cancelEdit(mode: string, key: any): void {
    if (mode === 'employee') {
      key.editing = { ssid: false, password: false };
      key.ssidInput.value = key.ssid;
    }

    if (mode === 'guest') {
      key.editing = false;
      key.ssidInput.value = key.ssid;
    }
  }

  addEmployeeKey(): void {
    let valid = true;

    if (this.smallbusiness.employees.length > 0) {
      valid = false;
      this.toast.warning('health.networkInformation.toast.onlyOneMsg', 'health.networkInformation.toast.onlyOneTitle');
    }

    if (!this.employeeSsidFormControl.value || this.employeeSsidFormControl.value.length < 1) {
      valid = false;
      this.toast.warning('health.networkInformation.toast.ssidMsg', 'health.networkInformation.toast.ssidTitle');
    }

    if (!this.employeePasswordFormControl.value || this.employeePasswordFormControl.value.length < 8) {
      valid = false;
      this.toast.warning(
        'health.networkInformation.toast.passwordMsg',
        'health.networkInformation.toast.passwordTitle'
      );
    }

    if (valid) {
      this.addEmployee();
    }
  }

  addEmployee(): void {
    this.loadingEmployee = true;
    const wpaMode = this.encryptionItems.find((option: any) => option.selected === true);

    this.secondaryNetworks
      .addFronthaul$({
        networkId: 'employee',
        enable: true,
        ssid: this.employeeSsidFormControl.value,
        encryptionKey: this.employeePasswordFormControl.value,
        purgatory: false,
        wpaMode: wpaMode.value
      })
      .subscribe(
        () => {
          this.mixpanel.storeEvent('HEALTH_EDIT_SMALLBUSINESS_ADD_KEY_SUCCESS', {
            ID: 'employee',
            TYPE: 'EMPLOYEE'
          });

          this.employeeSsidFormControl.reset();
          this.employeePasswordFormControl.reset();

          this.encryptionItems = [
            { value: 'psk2', translation: 'health.networkInformation.wpa2', selected: true },
            { value: 'sae-mixed', translation: 'health.networkInformation.wpa3transition', selected: false }
          ];
          this.getSmallbusinessWiFi();
        },
        (error: any) => {
          this.loadingEmployee = false;
          this.toast.error(error.error.error.message, error.error.error.name);
          this.mixpanel.storeEvent('HEALTH_EDIT_SMALLBUSINESS_ADD_KEY_FAILURE', {
            TYPE: 'EMPLOYEE',
            ERROR: error.error.error.message
          });
        }
      );
  }

  editEmployee(key: any): void {
    key.error = false;

    let field = '';
    let value = '';

    if (key.editing.ssid) {
      if (key.ssidInput.value && key.ssidInput.value.length > 0) {
        field = 'ssid';
        value = key.ssidInput.value;
      } else {
        key.error = true;

        this.mixpanel.storeEvent('HEALTH_EDIT_SMALLBUSINESS_EDIT_KEY_FAILURE', {
          ID: key.id,
          TYPE: 'EMPLOYEE',
          FIELD: 'SSID',
          ERROR: 'SSID EMPTY'
        });
        this.toast.warning('health.networkInformation.toast.ssidMsg', 'health.networkInformation.toast.ssidTitle');

        return;
      }
    }

    if (key.editing.password) {
      if (key.password.value && key.password.value.length > 7) {
        field = 'encryptionKey';
        value = key.password.value;
      } else {
        key.error = true;

        this.mixpanel.storeEvent('HEALTH_EDIT_SMALLBUSINESS_EDIT_KEY_FAILURE', {
          ID: key.id,
          TYPE: 'EMPLOYEE',
          FIELD: 'PASSWORD',
          ERROR: 'PASSWORD TOO SHORT'
        });
        this.toast.warning(
          'health.networkInformation.toast.passwordMsg',
          'health.networkInformation.toast.passwordTitle'
        );

        return;
      }
    }

    if (key.editing.ssid || key.editing.password) {
      this.loadingEmployee = true;

      this.secondaryNetworks.editFronthaul$(key.id, { [field]: value }).subscribe(
        () => {
          this.getSmallbusinessWiFi();

          if (key.editing.ssid) {
            key.ssid = value;

            this.mixpanel.storeEvent('HEALTH_EDIT_SMALLBUSINESS_EDIT_KEY_SUCCESS', {
              ID: key.id,
              TYPE: 'EMPLOYEE',
              FIELD: 'SSID'
            });
            this.toast.success(
              'health.networkInformation.toast.ssidSuccessMsg',
              'health.networkInformation.toast.ssidSuccessTitle'
            );
          }

          if (key.editing.password) {
            this.mixpanel.storeEvent('HEALTH_EDIT_SMALLBUSINESS_EDIT_KEY_SUCCESS', {
              ID: key.id,
              TYPE: 'EMPLOYEE',
              FIELD: 'PASSWORD'
            });
            this.toast.success(
              'health.networkInformation.toast.passwordSuccessMsg',
              'health.networkInformation.toast.passwordSuccessTitle'
            );
          }

          key.editing = {
            ssid: false,
            password: false
          };

          this.loadingEmployee = false;
        },
        (error: any) => {
          this.toast.error(error.error.error.message, error.error.error.name);
          this.mixpanel.storeEvent('HEALTH_EDIT_SMALLBUSINESS_EDIT_KEY_FAILURE', {
            TYPE: 'EMPLOYEE',
            ERROR: error.error.error.message
          });
          this.loadingEmployee = false;
        }
      );
    }
  }

  confirmDeleteEmployee(key: any): void {
    this.modal
      .showDialog(
        'health.networkInformation.popup.wantDeleteEmployeeMsg',
        'health.networkInformation.popup.wantDeleteTitle',
        {
          buttons: [
            { style: 'tertiary light', value: 'health.networkInformation.popup.cancel' },
            { style: 'super-primary', value: 'health.networkInformation.popup.deleteEmployee' }
          ]
        }
      )
      .subscribe((response: any) => {
        if (response.item?.value === 'health.networkInformation.popup.deleteEmployee') {
          this.deleteEmployee(key.id);
        }
      });
  }

  deleteEmployee(id: string): void {
    this.loadingEmployee = true;

    this.secondaryNetworks.deleteFronthaul$(id).subscribe(
      () => {
        this.mixpanel.storeEvent('HEALTH_EDIT_SMALLBUSINESS_DELETE_KEY_SUCCESS', { ID: id, TYPE: 'EMPLOYEE' });
        this.getSmallbusinessWiFi();
      },
      () => {
        this.loadingEmployee = false;
      }
    );
  }

  changeWpaMode(key: any, mode: string): void {
    this.loadingEmployee = true;
    this.secondaryNetworks.editFronthaul$(key.id, { wpaMode: mode }).subscribe(
      (response) => {
        key.wpaMode = response.wpaMode;

        this.mixpanel.storeEvent('HEALTH_EDIT_SMALLBUSINESS_EDIT_WPA_SUCCESS', {
          ID: key.id,
          TYPE: 'EMPLOYEE',
          FIELD: 'WPA_MODE'
        });
        this.toast.success(
          'health.networkInformation.toast.wpaSuccessMsg',
          'health.networkInformation.toast.wpaSuccessTitle'
        );

        this.loadingEmployee = false;
      },
      (error: any) => {
        this.toast.error(error.error.error.message, error.error.error.name);
        this.mixpanel.storeEvent('HEALTH_EDIT_SMALLBUSINESS_EDIT_WPA_FAILURE', {
          TYPE: 'EMPLOYEE',
          ERROR: error.error.error.message
        });
        this.loadingEmployee = false;
      }
    );
  }

  addGuestKey(): void {
    let valid = true;

    if (this.smallbusiness.guests.length > 0) {
      valid = false;
      this.toast.warning('health.networkInformation.toast.onlyOneMsg', 'health.networkInformation.toast.onlyOneTitle');
    }

    if (!this.guestSsidFormControl.value || this.guestSsidFormControl.value.length < 1) {
      valid = false;
      this.toast.warning('health.networkInformation.toast.ssidMsg', 'health.networkInformation.toast.ssidTitle');
    }

    if (valid) {
      this.addGuest();
    }
  }

  addGuest(): void {
    this.loadingGuest = true;
    const enabled = this.guestEnabledItems.find((option: any) => option.selected === true);

    this.secondaryNetworks.addCaptivePortalNetwork$(this.guestSsidFormControl.value, enabled.value).subscribe(
      () => {
        this.mixpanel.storeEvent('HEALTH_EDIT_SMALLBUSINESS_ADD_KEY_SUCCESS', {
          ID: 'guest',
          TYPE: 'GUEST'
        });

        this.guestSsidFormControl.reset();

        this.guestEnabledItems = [
          { value: true, translation: 'health.networkInformation.modal.yes', selected: true },
          { value: false, translation: 'health.networkInformation.modal.no', selected: false }
        ];

        this.getSmallbusinessWiFi();
      },
      (error: any) => {
        this.loadingGuest = false;
        this.toast.error(error.error.error.message, error.error.error.name);
        this.mixpanel.storeEvent('HEALTH_EDIT_SMALLBUSINESS_ADD_KEY_FAILURE', {
          TYPE: 'GUEST',
          ERROR: error.error.error.message
        });
      }
    );
  }

  editGuest(key: any): void {
    key.error = false;

    if (key.ssidInput.value && key.ssidInput.value.length > 0) {
      this.loadingGuest = true;

      this.secondaryNetworks.editCaptivePortalNetwork$(key.id, { ssid: key.ssidInput.value }).subscribe(
        () => {
          this.getSmallbusinessWiFi();

          key.ssid = key.ssidInput.value;

          this.mixpanel.storeEvent('HEALTH_EDIT_SMALLBUSINESS_EDIT_KEY_SUCCESS', {
            ID: key.id,
            TYPE: 'GUEST',
            FIELD: 'SSID'
          });
          this.toast.success(
            'health.networkInformation.toast.ssidSuccessMsg',
            'health.networkInformation.toast.ssidSuccessTitle'
          );

          key.editing = false;

          this.loadingGuest = false;
        },
        (error: any) => {
          this.toast.error(error.error.error.message, error.error.error.name);
          this.mixpanel.storeEvent('HEALTH_EDIT_SMALLBUSINESS_EDIT_KEY_FAILURE', {
            TYPE: 'GUEST',
            ERROR: error.error.error.message
          });
          this.loadingGuest = false;
        }
      );
    } else {
      key.error = true;

      this.mixpanel.storeEvent('HEALTH_EDIT_SMALLBUSINESS_EDIT_KEY_FAILURE', {
        ID: key.id,
        TYPE: 'GUEST',
        FIELD: 'SSID',
        ERROR: 'SSID EMPTY'
      });
      this.toast.warning('health.networkInformation.toast.ssidMsg', 'health.networkInformation.toast.ssidTitle');
    }
  }

  confirmDeleteGuest(key: any): void {
    this.modal
      .showDialog(
        'health.networkInformation.popup.wantDeleteGuestMsg',
        'health.networkInformation.popup.wantDeleteTitle',
        {
          buttons: [
            { style: 'tertiary light', value: 'health.networkInformation.popup.cancel' },
            { style: 'super-primary', value: 'health.networkInformation.popup.deleteGuest' }
          ]
        }
      )
      .subscribe((response: any) => {
        if (response.item?.value === 'health.networkInformation.popup.deleteGuest') {
          this.deleteGuest(key.id);
        }
      });
  }

  deleteGuest(id: string): void {
    this.loadingGuest = true;

    this.secondaryNetworks.deleteCaptivePortalNetwork$(id).subscribe(
      () => {
        this.mixpanel.storeEvent('HEALTH_EDIT_SMALLBUSINESS_DELETE_KEY_SUCCESS', {
          ID: id,
          TYPE: 'GUEST'
        });

        this.getSmallbusinessWiFi();
      },
      () => {
        this.loadingGuest = false;
      }
    );
  }

  changeEnabled(id: string, event: any): void {
    this.loadingGuest = true;
    this.secondaryNetworks.editCaptivePortalNetwork$(id, { enable: event }).subscribe(
      () => {
        this.mixpanel.storeEvent('HEALTH_EDIT_SMALLBUSINESS_EDIT_WPA_SUCCESS', {
          ID: id,
          TYPE: 'GUEST',
          FIELD: 'ENABLE'
        });
        this.toast.success(
          'health.networkInformation.toast.enabledSuccessMsg',
          'health.networkInformation.toast.enabledSuccessTitle'
        );
        this.loadingGuest = false;
      },
      (error: any) => {
        this.toast.error(error.error.error.message, error.error.error.name);
        this.mixpanel.storeEvent('HEALTH_EDIT_SMALLBUSINESS_EDIT_WPA_FAILURE', {
          TYPE: 'EMPLOYEE',
          ERROR: error.error.error.message
        });
        this.loadingGuest = false;
      }
    );
  }

  openWorkpass(guest: any): void {
    this.isUprise$.pipe(take(1)).subscribe((isUprise) => {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          'captiveportal',
          'customer',
          this.plume.customerid,
          'location',
          this.plume.locationid,
          'network',
          guest.networkId,
          isUprise ? 'uprise' : 'workpass'
        ])
      );

      window.open(url, '_blank');

      this.mixpanel.storeEvent('OPEN_CAPTIVE_PORTAL', {
        URL: url,
        TYPE: 'WORKPASS'
      });
    });
  }

  getSessionTime(seconds: number): { days?: number; hours?: number } {
    const days = this.getDays(seconds);
    const hours = this.getHours(seconds);

    if (days > 0 && hours > 0) {
      return {
        days,
        hours
      };
    }

    if (days > 0) {
      return {
        days
      };
    }

    if (hours > 0) {
      return {
        hours
      };
    }

    return null;
  }

  getDays(seconds: number): number {
    return Math.floor(seconds / 86400);
  }

  getHours(seconds: number): number {
    return Math.floor(seconds / 3600) - this.getDays(seconds) * 24;
  }
}
