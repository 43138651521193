import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { INode } from 'src/app/lib/interfaces/interface';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { MessageService } from 'src/app/lib/services/message.service';
import { ModelRefService } from 'src/app/lib/services/modelref.service';
import { selectNodes } from 'src/app/store/polling/polling.selector';

type gatewayNodeSpeed = {
  id: string;
  model: string;
  name: string;
  averageSpeed: number;
  standardDeviation: number;
};
@Component({
  selector: 'gatewayCompareBubble',
  templateUrl: './gatewayCompareBubble.component.html',
  styleUrls: ['./gatewayCompareBubble.component.scss', '../bubbles.scss']
})
export class GatewayCompareBubbleComponent implements OnInit, OnDestroy {
  @Input()
  gatewayNodeSpeedData: any;

  helper: GeneralHelper = new GeneralHelper();
  nodes: INode[] = [];
  filteredNodes: gatewayNodeSpeed[] = [];
  nodeSubscription: any;

  constructor(
    private message: MessageService,
    private logging: LoggingService,
    private store: Store,
    private modelRef: ModelRefService
  ) {}

  ngOnInit(): void {
    this.nodeSubscription = this.store.select(selectNodes).subscribe((nodes) => {
      if (nodes) {
        this.nodes = nodes;
        this.prepare();
      }
    });
  }

  getIcon(model: string): string {
    return this.modelRef.get(model).icon;
  }

  prepare(): void {
    this.filteredNodes = this.nodes
      .filter((node: INode) => this.helper.isGateway(node.id, this.nodes))
      .map((node: INode) => ({
        id: node.id,
        model: node.model,
        name: node?.nickname || node?.name || node?.defaultName,
        averageSpeed: this.gatewayNodeSpeedData.find((gw: any) => gw.nodeId === node.id).broadbandDownloadSpeed.average,
        standardDeviation: this.gatewayNodeSpeedData.find((gw: any) => gw.nodeId === node.id).broadbandDownloadSpeed
          .standardDeviation
      }));
  }

  sendMessageNextStep(nextStep: string): void {
    this.logging.log('sendMessageNextStep complete: ', nextStep);
    this.message.sendMessage({ source: 'gatewayCompare', nextStep });
  }

  ngOnDestroy(): void {
    if (this.nodeSubscription) {
      this.nodeSubscription.unsubscribe();
    }
  }
}
