import { extent, NumberValue, ScaleLinear, scaleLinear, ScaleTime, scaleTime } from 'd3';
import * as moment from 'moment';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { Dot } from '../objects/dot';
import { Line } from '../objects/line';
import { Point } from '../objects/point';
import { Tick } from '../objects/tick';

export type NodeChartMargins = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
};

export type NodeChartScale = { range: [number, number]; symbol: string };

export class NodeChart {
  private data: Line[];
  private lines: (Line & { paths: string[]; fills: string[] })[];
  private width: number;
  private height: number;
  private xScale: ScaleTime<number, number>;
  private yScale: ScaleLinear<number, number>;
  private scale: NodeChartScale = null;
  private period: number;
  private isFilled: boolean;
  private helper: GeneralHelper = new GeneralHelper();
  private margins: NodeChartMargins = {
    top: 20,
    right: 20,
    bottom: 20,
    left: 20
  };

  constructor() {}

  calculateWidth(width: number = null): number {
    const calculated = (width ? width : this.width) - this.margins.left - this.margins.right;
    return calculated > 0 ? calculated : 0;
  }

  calculateHeight(height: number = null): number {
    const calculated = (height ? height : this.height) - this.margins.top - this.margins.bottom;
    return calculated > 0 ? calculated : 0;
  }

  prepareScales(): void {
    this.xScale = scaleTime().range([0, this.calculateWidth()]);
    this.yScale = scaleLinear().range([this.calculateHeight(), 0]);
  }

  prepareDomains(): void {
    const xDomains = [];
    const yDomains = [];

    this.data?.forEach((line) => {
      if (line?.data) {
        xDomains.push(...line.data.map((d) => new Date(d.label)));
      }
      if (line?.data) {
        yDomains.push(...line.data.map((d) => d.value));
      }
    });

    const round = (subtract: number, moment: moment.Moment) => {
      const roundedMinutes = Math.floor(moment.minute() / 15) * 15;
      return [
        moment.clone().minute(roundedMinutes).second(0).subtract(subtract),
        moment.clone().minute(roundedMinutes).second(0)
      ];
    };

    if (this.period === 9) {
      this.xScale.domain([moment().clone().subtract(9, 'minutes'), moment().clone()]);
    }

    if (this.period === 10) {
      this.xScale.domain([moment().clone().subtract(11, 'minutes'), moment().clone().subtract(1, 'minutes')]);
    }

    if (this.period === 1) {
      this.xScale.domain(round(24 * 60 * 60 * 1000 - 15 * 60 * 1000, moment().add(15, 'minutes')));
    }

    if (this.period === 7) {
      this.xScale.domain(round(7 * 24 * 60 * 60 * 1000, moment().add(1, 'hours')));
    }

    if (this.period === 30) {
      this.xScale.domain([moment().clone().startOf('day').subtract(30, 'days'), moment().clone().startOf('day')]);
    }

    if (this.scale && this.scale.range) {
      this.yScale.domain(this.scale.range);
    } else {
      const max = extent([0, ...yDomains]);
      this.yScale.domain(max[1] > 0 ? max : [0, 1]);
    }
  }

  calculateLines(): void {
    let offset = [0];

    if (this.data?.length === 2) offset = [-1, 1];
    if (this.data?.length === 3) offset = [-3, 0, 3];

    this.lines = this.data?.map((line: Line & { paths: string[]; fills: string[] }, i) => {
      let buffer = '';

      line.alarms = [];
      line.dots = [];
      line.paths = [];
      line.fills = [];

      let firstX = 0;
      let lastX = 0;

      if (line.data) {
        line.data.forEach((point: Point) => {
          offset[i] = offset[i] || 0;

          if (point.value !== null) {
            const x = this.xScale(new Date(point.label)) || 0;
            const y = this.yScale(point.value) || 0;

            if (x > 0 && x < this.width) {
              line.dots.push(new Dot(line.series, 3, x, y + offset[i], false, point.label));
            }

            if (buffer.length) {
              lastX = x;
              buffer += 'L' + x + ' ' + (y + offset[i] + ' ');
            } else {
              firstX = x;
              lastX = x;
              buffer = 'M' + x + ' ' + (y + offset[i] + ' ');
            }
          } else {
            if (buffer.length) {
              line.paths.push(buffer);

              if (this.isFilled) {
                line.fills.push(
                  buffer +
                    'L' +
                    lastX +
                    ' ' +
                    this.calculateHeight() +
                    ' L' +
                    firstX +
                    ' ' +
                    this.calculateHeight() +
                    ' Z'
                );
              }
            }
            buffer = '';
            firstX = 0;
            lastX = 0;
          }
        });
      }

      if (buffer.length) {
        line.paths.push(buffer);

        if (this.isFilled) {
          line.fills.push(
            buffer + 'L' + lastX + ' ' + this.calculateHeight() + ' L' + firstX + ' ' + this.calculateHeight() + ' Z'
          );
        }
      }

      return line;
    });
  }

  uniqueArrayOfDates(array: Date[]) {
    const dates = {};

    return array.filter((date: Date) => {
      date.setHours(0, 0, 0, 0);

      if (!dates[date.valueOf()]) {
        dates[date.valueOf()] = true;
        return date;
      }
    });
  }

  xDomain() {
    return this.xScale.domain();
  }

  yDomain() {
    return this.yScale.domain();
  }

  xAxis() {
    return this.xScale
      .ticks(7)
      .map(
        (tick) =>
          new Tick(
            'xaxis',
            this.timeExtractor(tick),
            'translate(' + this.xScale(tick) + ', ' + (this.calculateHeight() + 20) + ')',
            { x: 0, dy: 0.32 },
            true,
            { y1: -20, y2: -this.calculateHeight() - 30 }
          )
      );
  }

  xAxisSmall() {
    return this.xScale
      .ticks(3)
      .map(
        (tick) =>
          new Tick(
            'xaxis',
            this.timeExtractor(tick),
            'translate(' + this.xScale(tick) + ', ' + (this.calculateHeight() + 20) + ')',
            { x: 0, dy: 0.32 },
            true,
            { y1: -20, y2: -this.calculateHeight() - 30 }
          )
      );
  }

  yAxis(height: number) {
    let symbol = 'Mbps';

    if (this.scale) symbol = this.scale.symbol;

    return this.yScale.ticks(height < 150 ? 3 : 5).map((tick) => {
      const data =
        this.scale.symbol === 'MB' ? this.helper.formatBytes(tick, symbol, 2, 1000) : { value: tick, unit: symbol };

      return new Tick(
        'yaxis',
        data.value + ' ' + data.unit,
        'translate(0, ' + this.yScale(tick) + ')',
        { dx: -5 },
        true,
        {
          x2: this.calculateWidth()
        }
      );
    });
  }

  update(
    data: Line[],
    width?: number,
    height?: number,
    margins?: NodeChartMargins,
    scale?: NodeChartScale,
    period?: number,
    isFilled?: boolean
  ): (Line & { paths: string[]; fills: string[] })[] {
    this.data = data;

    if (width) {
      this.width = width;
    }
    if (height) {
      this.height = height;
    }
    if (margins) {
      this.margins = margins;
    }
    if (scale) {
      this.scale = scale;
    }
    if (period) {
      this.period = period;
    }
    if (isFilled) {
      this.isFilled = isFilled;
    }

    this.prepareScales();
    this.prepareDomains();

    this.calculateLines();

    return this.lines;
  }

  timeExtractor(time: Date): string {
    if (this.period === 10 || this.period === 9) {
      return new Date(time).toLocaleTimeString(moment.locale(), {
        hour: '2-digit',
        minute: '2-digit'
      });
    }

    if (this.period === 1) {
      return new Date(time).toLocaleTimeString(moment.locale(), {
        hour: '2-digit',
        minute: '2-digit'
      });
    }

    if (this.period === 7) {
      return new Date(time).toLocaleString(moment.locale(), {
        day: '2-digit',
        month: 'short'
      });
    }

    if (this.period === 30) {
      return new Date(time).toLocaleString(moment.locale(), {
        day: '2-digit',
        month: 'short'
      });
    }
  }

  roundTime(time: Date): Date {
    if (this.period === 10 || this.period === 9) {
      return time;
    } else {
      const ms = 60 * 1000;
      const round = Math.floor(time.getTime() / ms) * ms;

      return new Date(round);
    }
  }

  scaleValue(axis: string, value: Date | NumberValue) {
    if (axis === 'x') {
      return this.xScale(value);
    } else {
      return this.yScale(value);
    }
  }
}
