<div class="container-fluid">
  <ng-container *ngIf="onboarded == 'uncomplete'">
    <spacer>{{ 'completeOnboarding' | translate }}</spacer>
  </ng-container>
  <ng-container *ngIf="onboarded == 'complete'">
    <ng-container *ngIf="(hasSecurity$ | async) !== true">
      <spacer>{{ 'aisecurity.noSecurityAccess' | translate : '{ "securityProductName": "Guard" }' }}</spacer>
    </ng-container>
    <ng-container *ngIf="(hasSecurity$ | async) === true">
      <spacer>{{ 'aisecurity.securityPrivacyAccess' | translate }}</spacer>
      <div class="control">
        <button class="action medium securityToggler" (click)="getSecurityToken()">
          {{ togglerMessage | translate }}
          <span class="timer" *ngIf="timer.show">
            ({{ 'qoe.liveViewCountDownMinSec' | translate : { mins: timer.mins, secs: timer.secs } }})
          </span>
          <div class="toggler">
            <div class="checkbox">
              <label class="toggle" [class.checked]="securityToken"></label>
            </div>
          </div>
        </button>
        <drop-down class="selector medium" *ngIf="securityToken && securityEvents">
          {{ selector.current | macAddr : true | translate }}
          <ng-container dropdown>
            <li (click)="selectDevice('aisecurity.allDevices')" *ngIf="selector.current !== 'aisecurity.allDevices'">
              {{ 'aisecurity.allDevices' | translate }}
            </li>
            <li
              (click)="selectDevice('aisecurity.unassignedDevices')"
              *ngIf="selector.current !== 'aisecurity.unassignedDevices'"
            >
              {{ 'aisecurity.unassignedDevices' | translate }}
            </li>
            <li (click)="selectDevice(device)" *ngFor="let device of selector.list">
              {{ device.name | macAddr : true }}
            </li>
          </ng-container>
        </drop-down>
      </div>

      <div class="secure-area" *ngIf="securityToken">
        <div class="chart">
          <securitychart
            [events]="securityEvents"
            [filterEnabled]="filterEnabled"
            [filterType]="filterType"
            (filter)="filter($event)"
            [dayView]="dayView"
            (view)="changeView($event)"
          ></securitychart>
        </div>
        <div class="settings" *ngIf="securityPolicy">
          <h3>{{ 'aisecurity.settingsFor' | translate }} {{ selector.current | translate }}</h3>
          <div class="label person" *ngIf="deviceAssignedToPerson">
            {{ 'aisecurity.unableToEditPersonPolicy' | translate }}
          </div>
          <div class="columns">
            <div class="data-table split">
              <div class="data-row setting">
                <div class="data-cell label" [class.disable]="deviceAssignedToPerson">
                  {{ 'aisecurity.secureAndProtect' | translate }}
                </div>
                <slidetoggler
                  [options]="secureAndProtectItems"
                  [editable]="!deviceAssignedToPerson && (isFlexLocation$ | async) === false"
                  (toggle)="securitySettingToggle($event)"
                ></slidetoggler>
              </div>

              <div class="data-row setting">
                <div class="data-cell label" [class.disable]="deviceAssignedToPerson">
                  {{ 'aisecurity.iotProtection' | translate }}
                </div>
                <slidetoggler
                  [options]="iotProtectItems"
                  [editable]="!deviceAssignedToPerson && (isFlexLocation$ | async) === false"
                  (toggle)="updateSettings('iotProtect', $event)"
                ></slidetoggler>
              </div>

              <div class="data-row setting">
                <div class="data-cell label" [class.disable]="deviceAssignedToPerson">
                  {{ 'aisecurity.adBlocking' | translate }}
                </div>
                <slidetoggler
                  [options]="adBlockingItems"
                  [editable]="!deviceAssignedToPerson"
                  (toggle)="updateSettings('adBlocking', $event)"
                ></slidetoggler>
              </div>
            </div>
            <div class="split content" *ngIf="(location$ | async)?.profile !== 'property'">
              <div class="title" *ngIf="securityPolicy?.device">{{ 'aisecurity.contentAccess' | translate }}</div>
              <div class="title" *ngIf="!securityPolicy?.device && selector.current === 'aisecurity.allDevices'">
                {{ 'aisecurity.contentAccessAllDevices' | translate }}
              </div>
              <div class="title" *ngIf="!securityPolicy?.device && selector.current === 'aisecurity.unassignedDevices'">
                {{ 'aisecurity.contentAccessUnassignedDevices' | translate }}
              </div>
              <div class="data-table split">
                <div class="data-row setting" *ngIf="(location$ | async)?.profile === 'smallbusiness'">
                  <div class="data-cell label" [class.disable]="deviceAssignedToPerson">
                    {{ 'aisecurity.workAppropriate' | translate }}
                  </div>
                  <slidetoggler
                    [options]="workAppropriateItems"
                    [editable]="!deviceAssignedToPerson"
                    (toggle)="updateSettings('workAppropriate', $event)"
                  ></slidetoggler>
                </div>

                <div class="data-row setting" *ngIf="(location$ | async)?.profile === 'auto'">
                  <div class="data-cell label" [class.disable]="deviceAssignedToPerson">
                    {{ 'aisecurity.adultAndSensitive' | translate }}
                  </div>
                  <slidetoggler
                    [options]="adultAndSensitiveItems"
                    [editable]="!deviceAssignedToPerson"
                    (toggle)="updateSettings('adultAndSensitive', $event)"
                  ></slidetoggler>
                </div>

                <div class="data-row setting" *ngIf="(location$ | async)?.profile === 'auto'">
                  <div class="data-cell label" [class.disable]="deviceAssignedToPerson">
                    {{ 'aisecurity.teenagers' | translate }}
                  </div>
                  <slidetoggler
                    [options]="teenagersItems"
                    [editable]="!deviceAssignedToPerson"
                    (toggle)="updateSettings('teenagers', $event)"
                  ></slidetoggler>
                </div>

                <div class="data-row setting" *ngIf="(location$ | async)?.profile === 'auto'">
                  <div class="data-cell label" [class.disable]="deviceAssignedToPerson">
                    {{ 'aisecurity.kids' | translate }}
                  </div>
                  <slidetoggler
                    [options]="kidsItems"
                    [editable]="!deviceAssignedToPerson"
                    (toggle)="updateSettings('kids', $event)"
                  ></slidetoggler>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="events">
          <h3>{{ 'aisecurity.securityEventsList' | translate }}</h3>
          <div class="header">
            <div class="date">{{ 'aisecurity.timeReported' | translate }}</div>
            <div class="eventType">{{ 'aisecurity.profile' | translate }}</div>
            <div class="riskScore">{{ 'aisecurity.riskScore' | translate }}</div>
            <div class="device">{{ 'aisecurity.device' | translate }}</div>
          </div>
          <div class="eventsList">
            <div class="event" *ngFor="let event of securityEvents">
              <div class="date">{{ event.date }}</div>
              <div class="eventType">{{ 'aisecurity.profiles.' + event.eventType | translate }}</div>
              <div class="riskScore" *ngIf="event.eventData?.riskScore">
                {{ 'aisecurity.score.' + event.eventData.riskScore | translate }}
              </div>
              <div class="device">{{ (event.device.name | macAddr : true) || 'aisecurity.unknown' | translate }}</div>
            </div>
            <div class="event" *ngIf="!securityEvents">
              <div class="noEvents">{{ 'aisecurity.loading' | translate }}</div>
            </div>

            <div class="event" *ngIf="securityEvents && !securityEvents.length">
              <div class="noEvents">{{ 'aisecurity.noEvents' | translate }}</div>
            </div>
          </div>
        </div>

        <div class="whitelistBlacklist" *ngIf="securityPolicy">
          <h3>{{ 'aisecurity.whitelistBlacklist' | translate }}</h3>
          <div class="buttons">
            <button class="action" (click)="openWBModal(true)" [disabled]="(isFlexLocation$ | async) === true">
              {{ 'aisecurity.whitelist' | translate }}
            </button>
            <button class="action" (click)="openWBModal(false)">{{ 'aisecurity.blacklist' | translate }}</button>
          </div>
        </div>

        <div
          class="whitelistBlacklist"
          *ngIf="securityPolicy && (plume.getRole() === 'admin' || plume.getRole() === 'groupAdmin')"
        >
          <h3>{{ 'aisecurity.demoAnomalyDetection.title' | translate }}</h3>
          <div class="buttons">
            <button class="action" (click)="openDemoAnomalyModal()">
              {{ 'aisecurity.demoAnomalyDetection.showDialogButton' | translate }}
            </button>
          </div>
        </div>

        <ng-container *ngIf="ohpDevices?.length > 0">
          <div class="ohpChart" *ngIf="isHomePass$ | async">
            <collapsiblebox
              mode="fill"
              [show]="ohpChartExpanded"
              [collapsible]="true"
              toggleSide="left"
              (toggle)="toggleOhpChart()"
            >
              <div header>
                <div class="group">
                  <div class="chartTitle">{{ 'aisecurity.ohp' | translate }}</div>
                </div>
                <drop-down class="selector medium skip-toggle" *ngIf="ohpChartExpanded">
                  <div *ngIf="!ohpSelectedDevice?.name">{{ 'aisecurity.allDevices' | translate }}</div>
                  <div class="ohpSelectedDevice" *ngIf="ohpSelectedDevice?.name">{{ ohpSelectedDevice.name }}</div>
                  <ng-container dropdown>
                    <li (click)="filterOhpEvents(null)">
                      {{ 'aisecurity.allDevices' | translate }}
                    </li>
                    <li
                      [class.noDeviceEvents]="!device.hasEvents"
                      *ngFor="let device of ohpDevices"
                      (click)="filterOhpEvents(device)"
                    >
                      {{ device.name }}
                    </li>
                  </ng-container>
                </drop-down>
              </div>
              <div container *ngIf="ohpChartExpanded">
                <barchart [data]="ohpChartData"></barchart>
              </div>
            </collapsiblebox>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<modal mode="compact" (overlayAction)="closeModal()" *ngIf="securityToken && (whitelistModal || blacklistModal)">
  <ng-container
    title
    *ngIf="selector.current !== 'aisecurity.allDevices' && selector.current !== 'aisecurity.unassignedDevices'"
  >
    {{
      (whitelistModal ? 'aisecurity.whitelistDomain' : 'aisecurity.blacklistDomain')
        | translate : { device: selector.current }
    }}
  </ng-container>
  <ng-container title *ngIf="selector.current === 'aisecurity.allDevices'">
    {{ (whitelistModal ? 'aisecurity.whitelistDomainAllDevices' : 'aisecurity.blacklistDomainAllDevices') | translate }}
  </ng-container>
  <ng-container title *ngIf="selector.current === 'aisecurity.unassignedDevices'">
    {{
      (whitelistModal ? 'aisecurity.whitelistDomainUnassignedDevices' : 'aisecurity.blacklistDomainUnassignedDevices')
        | translate
    }}
  </ng-container>
  <ng-container content>
    <div class="grid input-wrap" [class.disabled]="deviceAssignedToPerson">
      <div class="value">
        <input
          [formControl]="domainFormControl"
          [readonly]="deviceAssignedToPerson"
          placeholder="{{ 'aisecurity.enterSite' | translate }}"
          (keyup.enter)="addDomain()"
          autofocus
        />
        <div class="modalCommands">
          <slidetoggler [options]="addressTypes" (toggle)="addressType = $event"></slidetoggler>
          <button class="action medium" (click)="addDomain()">
            {{ (whitelistModal ? 'aisecurity.addWhitelist' : 'aisecurity.addBlacklist') | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="groups domain-container">
      <div class="label">{{ 'aisecurity.domainsList' | translate }}:</div>
      <div class="grid" *ngIf="loadingDomains">
        <div class="domain-loading">{{ 'aisecurity.loading' | translate }}</div>
      </div>
      <div class="grid" *ngIf="domainList?.length && !loadingDomains">
        <ul class="domain-list">
          <li class="domain" *ngFor="let domain of domainList">
            <span class="name">{{ domain.value }}</span>
            <div
              class="circle"
              tooltip="{{ 'aisecurity.remove' | translate }}"
              (click)="removeDomain(domain)"
              *ngIf="!deviceAssignedToPerson"
            >
              <icon name="icon-close" class="size-0_5"></icon>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="closeModal()">
      {{ 'customerGroups.close' | translate }}
    </button>
  </ng-container>
</modal>

<modal mode="compact" (overlayAction)="demoAnomalyModal = $event" *ngIf="securityToken && demoAnomalyModal">
  <ng-container title>{{ 'aisecurity.demoAnomalyDetection.title' | translate }}</ng-container>
  <ng-container content>
    <div class="grid input-wrap">
      <div class="value">
        <drop-down class="selector medium" *ngIf="securityToken">
          {{ anomalyDeviceSelector.current | macAddr : true | translate }}
          <ng-container dropdown>
            <li (click)="selectAnomalyDetectionDevice(device)" *ngFor="let device of selector.list">
              {{ device.name | macAddr : true }}
            </li>
          </ng-container>
        </drop-down>
      </div>
      <div class="value triggerAnomaly" *ngIf="anomalyDeviceSelector.current !== 'aisecurity.selectDevice'">
        <input
          [formControl]="domainFormControl"
          placeholder="{{ 'aisecurity.enterSite' | translate }}"
          (keyup.enter)="triggerAnomaly()"
          autofocus
        />
        <div class="modalCommands">
          <button class="action medium" (click)="triggerAnomaly()">
            {{ 'aisecurity.demoAnomalyDetection.trigger' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="divider" *ngIf="anomalyDeviceSelector.current !== 'aisecurity.selectDevice'"></div>
    <div class="blockCountContainer" *ngIf="anomalyDeviceSelector.current !== 'aisecurity.selectDevice'">
      <span>
        {{ 'aisecurity.demoAnomalyDetection.dnsBlockCount' | translate }}:
        <span class="count" *ngIf="!anomalyDeviceSelector.dnsBlockCountLoading">
          {{ anomalyDeviceSelector.dnsBlockCount }}
        </span>
      </span>
      <icon
        name="icon-spinner"
        class="size-0_8 spinner loading"
        *ngIf="anomalyDeviceSelector.dnsBlockCountLoading"
      ></icon>
      <button class="action medium" (click)="rollBackAnomaly()">
        {{ 'aisecurity.demoAnomalyDetection.rollback' | translate }}
      </button>
    </div>
  </ng-container>
  <ng-container controls>
    <button class="tertiary light" (click)="closeDemoAnomalyModal()">
      {{ 'customerGroups.close' | translate }}
    </button>
  </ng-container>
</modal>
