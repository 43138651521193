<toolbar #toolbar *ngIf="!barebone" [menuitems]="toolbarMenu" (toggle)="toggleModal($event)"></toolbar>

<toolmodal
  class="toolmodal-views"
  *ngIf="toolbars.views.selected && !barebone"
  [pos]="toolbar.getElementPosition('views')"
  [stack]="toolbars.views.stackIndex"
  (mousedown)="stack('views')"
  (toTop)="stack('views')"
>
  <div id="toolmodal-views-content">
    <div class="legend">
      <ng-container *ngIf="ui !== 'noc'">
        <div class="radio mlo" *ngIf="hasMloLinks()">
          <div class="mlo-wrap">
            <span class="ultrafast"></span>
            <span class="fast"></span>
            <span class="slow"></span>
          </div>
          {{ 'charts.force.legend.radioMlo' | translate }}
        </div>
        <div class="radio"><span class="ultrafast"></span> {{ 'charts.force.legend.radio6g' | translate }}</div>
        <div class="radio"><span class="fast"></span> {{ 'charts.force.legend.radio5g' | translate }}</div>
        <div class="radio"><span class="slow"></span> {{ 'charts.force.legend.radio2g' | translate }}</div>
        <div class="radio"><span class="ethernet"></span> {{ 'charts.force.legend.ethernet' | translate }}</div>
      </ng-container>
      <ng-container *ngIf="ui === 'noc'">
        <div class="radio noc" *ngIf="!show5GNetwork && hasMloLinks()">
          <svg #waveMlo>
            <svg:g *ngFor="let wave of getLegendMloWaves(waveMlo)">
              <svg:path [attr.d]="wave"></svg:path>
            </svg:g>
          </svg>
          {{ 'charts.force.legend.radioMlo' | translate }}
        </div>
        <div class="radio noc" *ngIf="!show5GNetwork">
          <svg #wave6g><svg:path [attr.d]="getLegendWave(wave6g, 'radio6g')"></svg:path></svg>
          {{ 'charts.force.legend.radio6g' | translate }}
        </div>
        <div class="radio noc" *ngIf="!show5GNetwork">
          <svg #wave5g><svg:path [attr.d]="getLegendWave(wave5g, 'radio5g')"></svg:path></svg>
          {{ 'charts.force.legend.radio5g' | translate }}
        </div>
        <div class="radio noc" *ngIf="!show5GNetwork">
          <svg #wave2g><svg:path [attr.d]="getLegendWave(wave2g, 'radio2g')"></svg:path></svg>
          {{ 'charts.force.legend.radio2g' | translate }}
        </div>
        <div class="radio noc" *ngIf="!show5GNetwork">
          <svg #ethernet><svg:path [attr.d]="getLegendWave(ethernet, 'ethernet')"></svg:path></svg>
          {{ 'charts.force.legend.ethernet' | translate }}
        </div>
        <div *ngIf="show5GNetwork" class="radio noc">
          <svg #ethernet><svg:path [attr.d]="getLegendWave(ethernet, 'ethernet')"></svg:path></svg>
          {{ 'charts.force.legend.activeCell' | translate }}
        </div>
      </ng-container>
      <div *ngIf="!showThreadNetwork && !show5GNetwork">
        <div class="toggler" (click)="toggleChannel()">
          <div class="checkbox"><label for="toggleChannel" class="toggle" [class.checked]="showChannel"></label></div>
          <span *ngIf="showChannel">{{ 'charts.force.legend.hideChannel' | translate }}</span>
          <span *ngIf="!showChannel">{{ 'charts.force.legend.showChannel' | translate }}</span>
        </div>
      </div>
      <div *ngIf="!showThreadNetwork && !show5GNetwork">
        <div class="toggler" (click)="toggleBandwidth()" *ngIf="ui === 'noc'">
          <div class="checkbox">
            <label for="toggleBandwidth" class="toggle" [class.checked]="showBandwidth"></label>
          </div>
          <span *ngIf="showBandwidth">{{ 'charts.force.legend.hideLinkBandwidth' | translate }}</span>
          <span *ngIf="!showBandwidth">{{ 'charts.force.legend.showLinkBandwidth' | translate }}</span>
        </div>
      </div>
      <div *ngIf="!showThreadNetwork && !show5GNetwork">
        <div class="toggler" (click)="toggleSerial()">
          <div class="checkbox"><label for="toggleSerial" class="toggle" [class.checked]="showSerial"></label></div>
          <span *ngIf="showSerial">{{ 'charts.force.legend.showNodeName' | translate }}</span>
          <span *ngIf="!showSerial">{{ 'charts.force.legend.showSerial' | translate }}</span>
        </div>
      </div>
      <div *ngIf="!showThreadNetwork && !show5GNetwork">
        <div class="togglerWithHint">
          <div class="toggler" (click)="toggleRadios()">
            <div class="checkbox"><label for="toggleRadios" class="toggle" [class.checked]="showRadios"></label></div>
            <span *ngIf="showRadios">{{ 'charts.force.legend.hideRadios' | translate }}</span>
            <span *ngIf="!showRadios">{{ 'charts.force.legend.showRadios' | translate }}</span>
          </div>
          <div class="hint">
            <icon name="icon-info"></icon>
            <div class="info" #infoHideRadios [class.hide]="tooltipBottomOverflow(infoHideRadios)">
              {{ 'charts.force.legend.hints.nodeRadios' | translate }}
            </div>
            <div class="infoTop" [class.hide]="!tooltipBottomOverflow(infoHideRadios)">
              {{ 'charts.force.legend.hints.nodeRadios' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="configAndState?.state?.capabilities?.thread && !show5GNetwork">
        <div class="togglerWithHint">
          <div class="toggler" [class.disabled]="isHistory" (click)="toggleThreadNetwork()">
            <div class="checkbox">
              <label for="toggleThreadNetwork" class="toggle" [class.checked]="showThreadNetwork"></label>
            </div>
            <span *ngIf="showThreadNetwork">{{ 'charts.force.legend.hideThreadNetwork' | translate }}</span>
            <span *ngIf="!showThreadNetwork">{{ 'charts.force.legend.showThreadNetwork' | translate }}</span>
          </div>
          <div class="hint">
            <icon
              *ngIf="showThreadNetwork && !isHistory"
              name="icon-swap"
              tooltip="{{ 'charts.force.controls.refreshThreadNetwork' | translate }}"
              (click)="fetchThreadNetwork()"
            ></icon>
            <icon *ngIf="isHistory" name="icon-info"></icon>
            <div *ngIf="isHistory">
              <div class="info" #infoThreadNetwork [class.hide]="tooltipBottomOverflow(infoThreadNetwork)">
                {{ 'charts.force.legend.hints.disabledThreadNetwork' | translate }}
              </div>
              <div class="infoTop" [class.hide]="!tooltipBottomOverflow(infoThreadNetwork)">
                {{ 'charts.force.legend.hints.disabledThreadNetwork' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="enable5gNeighborReports && configAndState.state.capabilities?.cellularStatistics">
        <div class="toggler" (click)="toggle5GNetwork()">
          <div class="checkbox">
            <label for="togglePCNetwork" class="toggle" [class.checked]="show5GNetwork"></label>
          </div>
          <span>{{ 'charts.force.legend.5GNeighborReport' | translate }}</span>
        </div>
      </div>
    </div>
    <div class="controls">
      <ng-container *ngIf="!showThreadNetwork && !show5GNetwork">
        <div class="slidetoggler" *ngIf="location?.profile !== 'auto' || isIoT">
          <slidetoggler class="small" [options]="smallbusinessItems" (toggle)="changeVapFilter($event)"></slidetoggler>
        </div>
        <div class="slidetoggler" *ngIf="location?.flex && !flexSupportUser">
          <slidetoggler class="small" [options]="flexItems" (toggle)="changeFlexFilter($event)"></slidetoggler>
        </div>
        <div class="slidetoggler" *ngIf="haahsEnabled">
          <slidetoggler class="small" [options]="haahsItems" (toggle)="changeHaahsFilter($event)"></slidetoggler>
        </div>
      </ng-container>
      <div class="zoom">
        <div class="sliderMsg">{{ 'charts.force.controls.zoom' | translate }}</div>
        <div class="zoomSlider show"><div #zoomSlider></div></div>
        <icon
          name="icon-swap"
          [class.disabled]="transform.offset.zoom < 1"
          tooltip="{{ 'charts.force.controls.resetZoom' | translate }}"
          (click)="reset()"
        ></icon>
      </div>
    </div>
  </div>
</toolmodal>
<toolmodal
  class="toolmodal-overlays"
  *ngIf="toolbars.overlays.selected && !barebone && !show5GNetwork"
  [pos]="toolbar.getElementPosition('overlays')"
  [stack]="toolbars.overlays.stackIndex"
  (mousedown)="stack('overlays')"
  (toTop)="stack('overlays')"
>
  <div class="overlays" *ngIf="isHistory" id="toolmodal-overlays-content">
    {{ 'charts.force.overlays.notEnabled' | translate }}
  </div>

  <div class="overlays" *ngIf="!isHistory" id="toolmodal-overlays-content">
    <div class="togglerWithHint">
      <div class="toggler" (click)="toggleOverlayFilter('usage24')">
        <div class="checkbox">
          <label for="toggleMoreData" class="toggle" [class.checked]="overlayData === 'usage24'"></label>
        </div>
        <span>{{ 'charts.force.overlays.usage24' | translate }}</span>
      </div>
      <div class="hint">
        <icon name="icon-info"></icon>
        <div class="info" #infoUsage24 [class.hide]="tooltipBottomOverflow(infoUsage24)">
          {{ 'charts.force.overlays.hints.usage24' | translate }}
        </div>
        <div class="infoTop" [class.hide]="!tooltipBottomOverflow(infoUsage24)">
          {{ 'charts.force.overlays.hints.usage24' | translate }}
        </div>
      </div>
    </div>
    <div class="togglerWithHint">
      <div class="toggler" (click)="toggleOverlayFilter('usageNow')">
        <div class="checkbox">
          <label for="toggleMoreData" class="toggle" [class.checked]="overlayData === 'usageNow'"></label>
        </div>
        <span>{{ 'charts.force.overlays.usageNow' | translate }}</span>
      </div>
      <div class="hint">
        <icon name="icon-info"></icon>
        <div class="info" #infoUsageNow [class.hide]="tooltipBottomOverflow(infoUsageNow)">
          {{ 'charts.force.overlays.hints.usageNow' | translate }}
        </div>
        <div class="infoTop" [class.hide]="!tooltipBottomOverflow(infoUsageNow)">
          {{ 'charts.force.overlays.hints.usageNow' | translate }}
        </div>
      </div>
    </div>
    <div class="togglerWithHint">
      <div class="toggler" (click)="toggleOverlayFilter('potentialThroughput')">
        <div class="checkbox">
          <label for="toggleMoreData" class="toggle" [class.checked]="overlayData === 'potentialThroughput'"></label>
        </div>
        <span>{{ 'charts.force.overlays.predictedThroughput' | translate }}</span>
      </div>
      <div class="hint">
        <icon name="icon-info"></icon>
        <div class="info" #infoPotentialThroughput [class.hide]="tooltipBottomOverflow(infoPotentialThroughput)">
          {{ 'charts.force.overlays.hints.potentialThroughput' | translate }}
        </div>
        <div class="infoTop" [class.hide]="!tooltipBottomOverflow(infoPotentialThroughput)">
          {{ 'charts.force.overlays.hints.potentialThroughput' | translate }}
        </div>
      </div>
    </div>
    <div class="togglerWithHint">
      <div class="toggler" (click)="toggleOverlayFilter('rssi')">
        <div class="checkbox">
          <label for="toggleMoreData" class="toggle" [class.checked]="overlayData === 'rssi'"></label>
        </div>
        <span>{{ 'charts.force.overlays.rssi' | translate }}</span>
      </div>
      <div class="hint">
        <icon name="icon-info"></icon>
        <div class="info" #infoRssi [class.hide]="tooltipBottomOverflow(infoRssi)">
          {{ 'charts.force.overlays.hints.rssi' | translate }}
        </div>
        <div class="infoTop" [class.hide]="!tooltipBottomOverflow(infoRssi)">
          {{ 'charts.force.overlays.hints.rssi' | translate }}
        </div>
      </div>
    </div>
    <div class="togglerWithHint">
      <div class="toggler" (click)="toggleOverlayFilter('capable5g')">
        <div class="checkbox">
          <label for="toggleMoreData" class="toggle" [class.checked]="overlayData === 'capable5g'"></label>
        </div>
        <span>{{ 'charts.force.overlays.bandWarning' | translate }}</span>
      </div>
      <div class="hint">
        <icon name="icon-info"></icon>
        <div class="info" #infoBandWarning [class.hide]="tooltipBottomOverflow(infoBandWarning)">
          {{ 'charts.force.overlays.hints.bandWarning' | translate }}
        </div>
        <div class="infoTop" [class.hide]="!tooltipBottomOverflow(infoBandWarning)">
          {{ 'charts.force.overlays.hints.bandWarning' | translate }}
        </div>
      </div>
    </div>
    <div class="togglerWithHint">
      <div class="toggler" (click)="toggleOverlayFilter('averageSpeedTest')">
        <div class="checkbox">
          <label for="toggleMoreData" class="toggle" [class.checked]="overlayData === 'averageSpeedTest'"></label>
        </div>
        <span>{{ 'charts.force.overlays.averageSpeedTest' | translate }}</span>
      </div>
      <div class="hint">
        <icon name="icon-info"></icon>
        <div class="info" #infoAverageSpeedTest [class.hide]="tooltipBottomOverflow(infoAverageSpeedTest)">
          {{ 'charts.force.overlays.hints.averageSpeedTest' | translate }}
        </div>
        <div class="infoTop" [class.hide]="!tooltipBottomOverflow(infoAverageSpeedTest)">
          {{ 'charts.force.overlays.hints.averageSpeedTest' | translate }}
        </div>
      </div>
    </div>
  </div>
</toolmodal>
<toolmodal
  class="toolmodal-timemachine"
  *ngIf="toolbars.timemachine.selected && !barebone && !show5GNetwork"
  [pos]="toolbar.getElementPosition('timemachine')"
  [stack]="toolbars.timemachine.stackIndex"
  (mousedown)="stack('timemachine')"
  (toTop)="stack('timemachine')"
>
  <div class="controls">
    <div class="toggler" (click)="toggleHistory()">
      <div class="timeMachineEnable">
        <div class="checkbox">
          <label for="toggleMoreData" class="toggle" [class.checked]="isHistory === true"></label>
        </div>
        <div class="msg">
          {{ 'charts.force.history.enableTimeMachine' | translate }}
        </div>
      </div>
    </div>

    <div class="historyMenu" *ngIf="isHistory">
      <div class="msg">{{ 'charts.force.controls.msg' | translate }}</div>
      <div class="dateInput">
        <calendar (event)="updateDate($event)"></calendar>
      </div>
      <div class="advanced" (click)="toggleTtmAdvanced()">
        {{ 'charts.force.controls.advanced' | translate }}
        <icon name="icon-arrow-down" class="size-0_6" [class.rotate180]="showAdvanced"></icon>
      </div>
      <div class="advancedSlider" [class.show]="showAdvanced">
        <div #advancedSlider></div>
        <div class="advancedMsg">{{ ttmDuration }} {{ 'charts.force.controls.hourWindow' | translate }}</div>
      </div>
      <ng-container *ngIf="!ttmErrorDate">
        <div class="dataButton" (click)="timemachine()">{{ historySlider.button | translate }}</div>
      </ng-container>
      <ng-container *ngIf="ttmErrorDate">
        <div class="error">{{ 'charts.force.history.errorDate' | translate }}</div>
      </ng-container>
      <div class="ttmSlider" [class.show]="history.length">
        <div #ttmSlider></div>
        <div class="ttmControls">
          <div class="button" (click)="previous()">&lt;</div>
          <div class="status">
            <div>
              {{ 'charts.force.history.displayingTopology' | translate }}: {{ ttmCurrent + 1 }} / {{ history.length }}
            </div>
            <div>{{ getDate(historySlider.timestamp, 'L LTS') }}</div>
          </div>
          <div class="button" (click)="next()">&gt;</div>
        </div>
      </div>
      <div class="noData" *ngIf="historySlider.noData">{{ 'charts.force.history.noData' | translate }}</div>
      <div class="noData" *ngIf="historySlider.error">{{ 'charts.force.history.error' | translate }}</div>
      <div class="noData" *ngIf="historySlider.wrongDate">{{ 'charts.force.history.wrongDate' | translate }}</div>
    </div>
  </div>
</toolmodal>
<toolmodal
  class="toolmodal-filters"
  *ngIf="toolbars.filters.selected && !barebone && !show5GNetwork"
  [pos]="toolbar.getElementPosition('filters')"
  [stack]="toolbars.filters.stackIndex"
  (mousedown)="stack('filters')"
  (toTop)="stack('filters')"
>
  <div class="controls">
    <div class="overlays" [class.noFilter]="!isHistory && !onlineDevices">
      <ng-container *ngIf="!isHistory && onlineDevices">
        <div class="searchInput">
          <labeled-input>
            <div label>{{ 'charts.force.overlays.searchDevices' | translate }}</div>
            <input [(ngModel)]="searchStr" (ngModelChange)="searchDevice()" />
            <icon name="icon-close" class="size-0_6" (click)="clearSearch()"></icon>
          </labeled-input>
        </div>
        <div class="filterTitleLabel">
          {{ 'charts.force.overlays.filters' | translate }}
        </div>
        <div class="category togglerWithHint" *ngFor="let category of categoryFilters">
          <div class="toggler" (click)="toggleDeviceFilter(category)" *ngIf="category.count > 0">
            <div class="checkbox">
              <label class="toggle" [class.checked]="category.enabled"></label>
            </div>
            <span class="name">{{ category.translation | translate }}</span>
          </div>
          <span class="count" *ngIf="category.count > 0">({{ category.count }})</span>
        </div>
      </ng-container>
      <ng-container *ngIf="!isHistory && !onlineDevices">
        {{ 'charts.noDevicesToFilter' | translate }}
      </ng-container>
      <ng-container *ngIf="isHistory">
        {{ 'charts.noDeviceFilterWhenTimeMachine' | translate }}
      </ng-container>
    </div>
  </div>
</toolmodal>

<toolmodal
  class="toolmodal-realtimeUsage"
  *ngIf="toolbars.realtimeUsage.selected && !barebone && !show5GNetwork"
  [pos]="toolbar.getElementPosition('realtimeUsage')"
  [stack]="toolbars.realtimeUsage.stackIndex"
  (mousedown)="stack('realtimeUsage')"
  (toTop)="stack('realtimeUsage')"
>
  <div id="toolmodal-realtimeUsage-content" class="controls">
    <div class="overlays">
      <ng-container *ngIf="!isHistory">
        <div class="toggler" (click)="toggleRealtimeUsage()">
          <div class="checkbox">
            <label for="toggleRealtimeUsage" class="toggle" [class.checked]="showRealtimeUsage"></label>
          </div>
          <span *ngIf="showRealtimeUsage">{{ 'charts.tree.hideTreeChart' | translate }}</span>
          <span *ngIf="!showRealtimeUsage">{{ 'charts.tree.showTreeChart' | translate }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="isHistory">
        {{ 'charts.tree.notEnabled' | translate }}
      </ng-container>
    </div>
  </div>
</toolmodal>

<ng-container *ngIf="!showRealtimeUsage">
  <div class="filters" *ngIf="!barebone">
    <div class="pill" (click)="toggleDeviceFilter(filter)" *ngFor="let filter of activeFilters">
      <div class="name">{{ filter.translation | translate }}</div>
      <div class="count">({{ filter.count }})</div>
      <icon name="icon-close" class="size-0_5"></icon>
    </div>
  </div>

  <div class="resetView" *ngIf="barebone">
    <icon
      name="icon-swap"
      tooltip="{{ 'charts.force.controls.resetView' | translate }}"
      (click)="reset()"
      *ngIf="transform.offset.zoom || transform.offset.x || transform.offset.y"
    ></icon>
  </div>

  <div class="volt-tag" *ngIf="powerMode === 'LPM' && isHistory && history.length > 0">
    <icon name="icon-leaf" class="size-1_5 volt-tag-icon"></icon>
    <div class="volt-tag-text">{{ 'charts.force.controls.voltTag' | translate }}</div>
  </div>

  <svg
    #forcechart
    [class.mini]="barebone"
    (mousedown)="pan('enter', $event)"
    (mousemove)="pan('move', $event)"
    (mouseup)="pan('leave', $event)"
    (touchstart)="pan('enter', $event)"
    (touchmove)="pan('move', $event)"
    (touchend)="pan('leave', $event)"
  >
    <svg:g
      #content
      *ngIf="ready"
      [class.transition]="!transform.offset.drag"
      [attr.transform]="'translate(' + transform.x + ',' + transform.y + ')  scale(' + transform.zoom + ')'"
    >
      <svg:g
        class="link"
        *ngFor="let link of links; let i = index"
        [linkVisual]="link"
        (tooltipState)="updateTooltip($event, i, 'link')"
        [showChannel]="showChannel"
        [showBandwidth]="showBandwidth"
      ></svg:g>
      <svg:g
        class="node"
        *ngFor="let node of nodes; let i = index; trackBy: trackNodes"
        (tooltipState)="updateTooltip($event, i, 'node')"
        [nodeVisual]="node"
        [showSerial]="showSerial"
        [showRadios]="showRadios"
        [attr.transform]="'translate(' + node.x + ',' + node.y + ')'"
        [simulation]="simulation"
        [barebone]="barebone"
        [vpn]="vpn"
        draggable
        selectable
        (dragInProgress)="dragInProgress = $event"
      ></svg:g>
      <svg:g class="tooltip" [attr.transform]="tooltip.position" *ngIf="tooltip.show">
        <svg:rect
          class="tooltip"
          width="260"
          [attr.height]="20 * tooltip.values.length + 10"
          *ngIf="tooltip.show"
        ></svg:rect>
        <ng-container *ngFor="let data of tooltip.values; let i = index">
          <svg:text x="10" [attr.y]="20 * (i + 1)" class="label">
            {{ 'charts.force.tooltip.' + data.property | translate }}:
          </svg:text>
          <svg:text x="120" [attr.y]="20 * (i + 1)">
            {{ data.value }}
          </svg:text>
        </ng-container>
      </svg:g>
    </svg:g>
  </svg>

  <div id="forcechart-noData" class="empty" *ngIf="!loading && !nodes?.length && !links?.length">
    <div class="title">{{ 'charts.force.nodataTitle' | translate }}</div>
    <div class="message">{{ 'charts.force.nodataMsg' | translate }}</div>
  </div>

  <div class="empty" *ngIf="loading && !barebone">
    <div class="title">{{ 'charts.force.loading' | translate }}</div>
  </div>

  <div class="vpn" *ngIf="vpn">
    <div class="box">
      <icon name="icon-vpn-big-globe"></icon>
      <div class="data">
        <div class="title">{{ 'charts.force.vpn.internet' | translate }}</div>
        <div class="state">
          <div class="bullet" [class.connected]="vpn.internet.status === 'connected'"></div>
          {{ 'charts.force.vpn.' + vpn.internet.status | translate }}
        </div>
        <div class="bandwidth" *ngIf="vpn.internet.downloadSpeed">{{ vpn.internet.speed }}</div>
      </div>
    </div>
    <div class="box">
      <icon name="icon-vpn-big-servers"></icon>
      <div class="data">
        <div class="title">{{ 'charts.force.vpn.dataCenter' | translate }}</div>
        <div class="state">
          <div class="bullet" [class.connected]="vpn.ipsec.status === 'connected'"></div>
          {{ 'charts.force.vpn.' + vpn.ipsec.status | translate }}
        </div>
        <div class="bandwidth" *ngIf="vpn.ipsec.downloadSpeed">{{ vpn.ipsec.speed }}</div>
      </div>
    </div>
    <div class="box">
      <icon name="icon-vpn-big-authentication"></icon>
      <div class="data">
        <div class="title">{{ 'charts.force.vpn.authentication' | translate }}</div>
        <div class="state">
          <div class="bullet" [class.connected]="vpn.radius.status === 'connected'"></div>
          {{ 'charts.force.vpn.' + vpn.radius.status | translate }}
        </div>
      </div>
    </div>
  </div>
</ng-container>
<treechart *ngIf="showRealtimeUsage"></treechart>
