import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { PageScrollService } from 'ngx-page-scroll-core';
import { of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { FilterdropdownComponent } from 'src/app/components/filterdropdown/filterdropdown.component';
import {
  DeepReadonly,
  ICurrentLanLatency,
  IDHCPLeases,
  IDevice,
  IDeviceLanLatency,
  IDeviceQoe,
  ILocation,
  INetworkAccessNetworks,
  INode
} from 'src/app/lib/interfaces/interface';
import { MacAddrPipe } from 'src/app/lib/pipes/mac-addr.pipe';
import { CustomerService } from 'src/app/lib/services/customer.service';
import { DeviceService } from 'src/app/lib/services/device.service';
import { DeviceIconRefService } from 'src/app/lib/services/deviceIconRef.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { NetworkAccessService } from 'src/app/lib/services/network-access.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { QoeService } from 'src/app/lib/services/qoe.service';
import { SecondaryNetworksService } from 'src/app/lib/services/secondary-networks.service';
import { selectCapabilityWpa3 } from 'src/app/store/customer/capabilities.selector';
import { getSecondaryNetworks } from 'src/app/store/customer/customer.actions';
import { selectPipeLocationOnChange } from 'src/app/store/customer/customer.selectors';
import {
  selectCurrentLanLatency,
  selectDevices,
  selectLocationInternet,
  selectLocationQoE,
  selectNodes
} from 'src/app/store/polling/polling.selector';

@UntilDestroy()
@Component({
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit, OnDestroy {
  qoeResponse: any = null;
  deviceResponse: IDevice[] = null;
  allDevices: any = [];
  filteredDevices: any = [];
  searchDevices: any = [];
  devices: (IDevice & { currentLanLatency?: IDeviceLanLatency })[] = [];
  online: any[] = [];
  offline: any[] = [];
  alarms: any[] = [];
  message: string;
  debouncerTimeout: any;
  updateTimeout: any;
  onboarded: string = null;
  deviceid: string = null;
  sortType: string = 'online';
  firstScroll: boolean = true;
  location: DeepReadonly<ILocation> = {} as any;
  smallbusinessItems = [
    { value: 'home', translation: 'devices.home', selected: true },
    { value: 'fronthaul', translation: 'devices.fronthaul', selected: false },
    { value: 'captivePortal', translation: 'devices.captivePortal', selected: false }
  ];
  upriseHomePassItems = [
    { value: 'home', translation: 'devices.customer', selected: true },
    { value: 'iot', translation: 'devices.iot', selected: false }
  ];
  smallbusinessFilter: 'home' | 'fronthaul' | 'captivePortal' | 'iot' = 'home';
  networkAccess: INetworkAccessNetworks[];
  currentFilterOptions: any;
  selectedFilters: any[] = [];
  position: any[] = [];
  ui: string = '';
  showDeviceGroupManagement: boolean = false;
  authorizedResponse: { mac: string }[] = null;
  coverageAlarms: string[] = [];
  initialLoad: boolean = true;
  haahsEnabled: boolean = false;
  haahsItems: any = [];
  haahsFilter: string = 'all';
  flexItems = [
    { value: 'all', translation: 'flex.toggler.all', selected: true },
    { value: 'home', translation: 'flex.toggler.home', selected: false },
    { value: 'work', translation: 'flex.toggler.work', selected: false }
  ];
  flexFilter: string = 'all';
  flexSupportUser: boolean = false;
  customer: any = {};
  location$ = this.store.pipe(selectPipeLocationOnChange);
  nodes: INode[] = [];
  DHCPLeases: IDHCPLeases;
  isUprise: boolean = false;
  isUpriseHomePass: boolean = false;

  // Device modals
  selectedDevice: IDevice & { rawQoe?: IDeviceQoe } = null;
  focusModalOpen: boolean = false;
  ipv6ModalOpen: boolean = false;
  deviceDetailsModalOpen: boolean = false;
  cloudSteeringModalOpen: boolean = false;
  steerModalOpen: boolean = false;
  qoeModalOpen: boolean = false;
  priorityModalOpen: boolean = false;
  ohpDetailsModalOpen: boolean = false;
  lanLatencyModalOpen: boolean = false;

  @ViewChild('searchFilter')
  searchFilter: any = '';

  @ViewChild('filter')
  filter: FilterdropdownComponent;

  currentFilterState: any;
  deviceFiltersFrozen = {
    order: 2,
    name: 'freeze',
    key: 'freeze.frozen',
    translation: 'devices.filter.filterFrozen',
    type: 'tristate',
    option: 'devices.filter.optionFrozen',
    state: null,
    filterStates: [
      { value: true, translation: 'devices.filter.filterState.deviceFrozen' },
      { value: false, translation: 'devices.filter.filterState.deviceNotFrozen' },
      { value: null, translation: 'devices.filter.filterState.clear' }
    ]
  };

  deviceFilters: any[] = [
    {
      order: 1,
      name: 'online',
      key: 'connectionState',
      translation: 'devices.filter.onlineState',
      type: 'tristate',
      state: null,
      option: 'devices.filter.onlineState',
      filterStates: [
        { value: 'connected', translation: 'devices.filter.filterState.online' },
        { value: 'disconnected', translation: 'devices.filter.filterState.offline' },
        { value: null, translation: 'devices.filter.filterState.clear' }
      ]
    },
    {
      order: 3,
      name: 'alarms',
      key: 'inAlarm',
      translation: 'devices.filter.filterInAlarm',
      type: 'tristate',
      option: 'devices.filter.optionInAlarm',
      state: null,
      filterStates: [
        { value: true, translation: 'devices.filter.filterState.alarmOn' },
        { value: false, translation: 'devices.filter.filterState.alarmOff' },
        { value: null, translation: 'devices.filter.filterState.clear' }
      ]
    },
    {
      order: 4,
      name: 'bandSteeringEnabled',
      key: 'bandSteering.enable',
      translation: 'devices.filter.filterBandSteeringEnabled',
      option: 'devices.filter.optionBandSteeringEnabled',
      type: 'tristate',
      state: null,
      filterStates: [
        { value: true, translation: 'devices.filter.filterState.enabled' },
        { value: false, translation: 'devices.filter.filterState.disabled' },
        { value: null, translation: 'devices.filter.filterState.clear' }
      ]
    },
    {
      order: 5,
      name: 'clientSteeringEnabled',
      key: 'clientSteering.enable',
      translation: 'devices.filter.filterClientSteeringEnabled',
      option: 'devices.filter.optionClientSteeringEnabled',
      type: 'tristate',
      state: null,
      filterStates: [
        { value: true, translation: 'devices.filter.filterState.enabled' },
        { value: false, translation: 'devices.filter.filterState.disabled' },
        { value: null, translation: 'devices.filter.filterState.clear' }
      ]
    },
    {
      order: 6,
      name: 'connectedBy',
      key: 'medium',
      translation: 'devices.filter.filterConnectedBy',
      option: 'devices.filter.optionConnectedBy',
      type: 'tristate',
      state: null,
      filterStates: [
        { value: 'wifi', translation: 'devices.filter.filterState.wifi' },
        { value: 'ethernet', translation: 'devices.filter.filterState.ethernet' },
        { value: null, translation: 'devices.filter.filterState.clear' }
      ]
    },
    {
      order: 7,
      name: 'freqBand',
      key: 'freqBand',
      translation: 'devices.filter.filterFrequencyBand',
      option: 'devices.filter.optionFrequencyBand',
      type: 'tristate',
      state: null,
      filterStates: [
        { value: '2.4G', translation: '2.4G' },
        { value: '5G', translation: '5G' },
        { value: '5GL', translation: '5GL' },
        { value: '5GU', translation: '5GU' },
        { value: '6G', translation: '6G' },
        { value: null, translation: 'devices.filter.filterState.clear' }
      ]
    },
    {
      order: 8,
      name: 'deviceCategory',
      key: 'category',
      translation: 'devices.filter.filterDeviceCategory',
      option: 'devices.filter.optionDeviceCategory',
      type: 'tristate',
      state: null,
      filterStates: [
        { value: 'Camera', translation: 'devices.filter.filterState.camera' },
        { value: 'Game Console', translation: 'devices.filter.filterState.gameConsole' },
        { value: 'Laptop', translation: 'devices.filter.filterState.laptop' },
        { value: 'Printer', translation: 'devices.filter.filterState.printer' },
        { value: 'Set Top Box', translation: 'devices.filter.filterState.stb' },
        { value: 'Smart Device', translation: 'devices.filter.filterState.smartDevice' },
        { value: 'Smart Phone', translation: 'devices.filter.filterState.smartPhone' },
        { value: 'Speaker', translation: 'devices.filter.filterState.speaker' },
        { value: 'Tablet', translation: 'devices.filter.filterState.tablet' },
        { value: 'Thermostat', translation: 'devices.filter.filterState.thermostat' },
        { value: 'TV', translation: 'devices.filter.filterState.tv' },
        { value: 'Voice Assistant', translation: 'devices.filter.filterState.voiceAssistant' },
        { value: -1, translation: 'devices.filter.filterState.others' },
        { value: null, translation: 'devices.filter.filterState.clear' }
      ]
    },
    {
      order: 9,
      name: 'locallyAdministeredMac',
      key: 'locallyAdministeredMac',
      translation: 'devices.filter.filterLocallyAdministered',
      type: 'tristate',
      state: null,
      option: 'devices.filter.optionLocallyAdministered',
      filterStates: [
        { value: true, translation: 'devices.filter.filterState.on' },
        { value: false, translation: 'devices.filter.filterState.off' },
        { value: null, translation: 'devices.filter.filterState.clear' }
      ]
    }
  ];

  smallbusinessDeviceFilters: any[] = [...this.deviceFilters];
  currentLanLatency: ICurrentLanLatency = null;

  constructor(
    private plume: PlumeService,
    private iconRef: DeviceIconRefService,
    private route: ActivatedRoute,
    private pagescroll: PageScrollService,
    private qoe: QoeService,
    private mixpanel: MixpanelService,
    private deviceService: DeviceService,
    private secondaryNetworks: SecondaryNetworksService,
    private store: Store,
    private macAddrPipe: MacAddrPipe,
    private customerService: CustomerService,
    private networkAccessService: NetworkAccessService
  ) {}

  ngOnInit(): void {
    this.mixpanel.storeEvent('DEVICES_SCREEN');

    this.init();

    this.ui = this.plume.getUI();

    this.store
      .pipe(selectPipeLocationOnChange)
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.location = response;
        this.updateDeviceFilters();
      });

    this.getNetworkAccess();
    this.checkCoverageAlarms();
  }

  updateDeviceFilters(): void {
    const additionalShowPrioritiesFilter: any = {
      order: 10,
      name: 'macPrioritization',
      key: 'qos.prioritization.mode',
      translation: 'devices.filter.macPrioritization',
      type: 'tristate',
      option: 'devices.filter.optionMacPrioritization',
      state: null,
      filterStates: [
        { value: 'voice', translation: 'devices.device.prioritize.priorityTypes.voice' },
        { value: 'video', translation: 'devices.device.prioritize.priorityTypes.video' },
        { value: 'bestEffort', translation: 'devices.device.prioritize.priorityTypes.bestEffort' },
        { value: 'background', translation: 'devices.device.prioritize.priorityTypes.background' },
        { value: 'none', translation: 'devices.device.prioritize.priorityTypes.none' },
        { value: 'ignore', translation: 'devices.device.prioritize.priorityTypes.ignore' },
        { value: null, translation: 'devices.filter.filterState.clear' }
      ]
    };

    const additionalShowPrioritiesRealizedFilter: any = {
      order: 11,
      name: 'macPrioritizationActual',
      key: 'qos.prioritization.realizedState',
      translation: 'devices.filter.macPrioritizationActual',
      type: 'tristate',
      option: 'devices.filter.optionMacPrioritizationActual',
      state: null,
      filterStates: [
        { value: 'voice', translation: 'devices.device.prioritize.priorityTypes.voice' },
        { value: 'video', translation: 'devices.device.prioritize.priorityTypes.video' },
        { value: 'bestEffort', translation: 'devices.device.prioritize.priorityTypes.bestEffort' },
        { value: 'background', translation: 'devices.device.prioritize.priorityTypes.background' },
        { value: 'none', translation: 'devices.device.prioritize.priorityTypes.none' },
        { value: 'ignore', translation: 'devices.device.prioritize.priorityTypes.ignore' },
        { value: null, translation: 'devices.filter.filterState.clear' }
      ]
    };
    const deviceFiltersFocus: any = {
      order: 12,
      name: 'focused',
      key: 'focused',
      translation: 'devices.filter.filterFocus',
      type: 'tristate',
      option: 'devices.filter.optionFocused',
      state: null,
      filterStates: [
        { value: true, translation: 'devices.filter.filterState.deviceFocused' },
        { value: false, translation: 'devices.filter.filterState.deviceNotFocused' },
        { value: null, translation: 'devices.filter.filterState.clear' }
      ]
    };

    const deviceMlo: any = {
      order: 13,
      name: 'mlo',
      key: 'mldAddr',
      translation: 'devices.filter.mldAddr',
      type: 'tristate',
      option: 'devices.filter.mldAddr',
      state: null,
      filterStates: [
        { value: undefined, translation: 'devices.filter.filterState.notCapable' },
        { value: -1, translation: 'devices.filter.filterState.capable' },
        { value: null, translation: 'devices.filter.filterState.clear' }
      ]
    };

    this.deviceFilters.push(additionalShowPrioritiesFilter);
    this.smallbusinessDeviceFilters.push(additionalShowPrioritiesFilter);
    this.deviceFilters.push(additionalShowPrioritiesRealizedFilter);
    this.smallbusinessDeviceFilters.push(additionalShowPrioritiesRealizedFilter);
    this.deviceFilters.push(deviceFiltersFocus);
    this.smallbusinessDeviceFilters.push(deviceFiltersFocus);
    this.deviceFilters.push(deviceMlo);
    this.smallbusinessDeviceFilters.push(deviceMlo);
  }

  updateHomePassDeviceFilter(nonWpa3HomePass: boolean): void {
    const accessZone = {
      order: 12,
      name: 'homePassAccessZone',
      key: 'accessZoneType',
      translation: 'devices.filter.homePassAccessZone',
      type: 'tristate',
      state: null,
      option: 'devices.filter.optionHomePassAccessZone',
      filterStates: [
        { value: 'home', translation: 'devices.filter.filterState.accessZoneHome' },
        { value: 'guests', translation: 'devices.filter.filterState.accessZoneGuests' },
        { value: 'internetAccessOnly', translation: 'devices.filter.filterState.accessZoneInternetAccessOnly' },
        { value: null, translation: 'devices.filter.filterState.clear' }
      ]
    };

    if (nonWpa3HomePass) {
      if (!this.deviceFilters.find((item) => item.name === 'homePassAccessZone')) {
        this.deviceFilters.push(accessZone);
      }
    } else {
      this.deviceFilters = this.deviceFilters.filter((item) => item.name !== 'homePassAccessZone');
    }
  }

  getNetworkAccess(): void {
    this.networkAccessService.networks$().subscribe((response) => {
      this.networkAccess = response;
    });
  }

  timeout(): void {
    this.updateTimeout = setTimeout(() => {
      if (!this.deviceResponse || !this.qoeResponse || !this.coverageAlarms) {
        if (!this.deviceResponse) {
          this.deviceResponse = [];
        }

        if (!this.qoeResponse) {
          this.qoeResponse = { devices: [] };
        }

        if (!this.coverageAlarms) {
          this.coverageAlarms = [];
        }

        this.debounce(() => this.populate());
      }
    }, 3000);
  }

  initSmallbusiness(location: DeepReadonly<ILocation>): void {
    this.changeVapFilter('home');
    if (this.isUprise) {
      this.smallbusinessItems.push({ value: 'iot', translation: 'devices.iot', selected: false });
    }

    if (location.profile === 'property') {
      this.smallbusinessItems = this.smallbusinessItems
        .filter((item) => item.value !== 'fronthaul')
        .map((item) =>
          item.value === 'captivePortal' ? { ...item, translation: 'devices.propertyCaptivePortal' } : item
        );
    }

    this.initFilters();
  }

  initFlex(): void {
    this.flexSupportUser = this.plume.isFlexRole();

    if (this.location?.flex) {
      this.changeFlexFilter(this.flexSupportUser ? 'work' : 'all');
    }
  }

  init(): void {
    this.allDevices = [];
    this.onboarded = null;
    this.message = 'devices.messageLoading';

    if (!this.plume.isFlexRole()) {
      this.store.dispatch(getSecondaryNetworks());
    }

    this.store
      .select(selectNodes)
      .pipe(untilDestroyed(this))
      .subscribe((nodes) => (this.nodes = nodes));

    this.location$.pipe(untilDestroyed(this)).subscribe((location) => {
      this.location = location;
    });

    this.location$.pipe(take(1)).subscribe((location) => {
      this.isUprise = this.location.uprise && this.location.upriseConfig?.iotConfig?.iotNetwork;
      this.isUpriseHomePass = this.isUprise && this.location.profile === 'auto';
      this.initSmallbusiness(location);
      this.initFlex();
      this.initFilters();
      this.checkHomePass();
      this.checkDHCP();
    });

    this.route.params.pipe(untilDestroyed(this)).subscribe((params) => {
      if (params.deviceid) {
        this.deviceid = params.deviceid;
      }

      this.store
        .select(selectLocationInternet)
        .pipe(untilDestroyed(this))
        .subscribe((response) => {
          if (response) {
            this.isOnboarded(response);
          }
        });

      this.store
        .select(selectDevices)
        .pipe(
          untilDestroyed(this),
          filter((devices) => !!devices)
        )
        .subscribe((devices) => {
          this.deviceResponse = JSON.parse(JSON.stringify(devices));
          this.initScrollToDevice();
          this.initHaahs();

          if (this.location?.profile !== 'auto') {
            this.getAuthorizedClients();
          } else {
            this.debounce(() => this.populate());
          }
        });

      this.store
        .select(selectLocationQoE)
        .pipe(untilDestroyed(this))
        .subscribe((response) => {
          if (response) {
            this.qoeResponse = JSON.parse(JSON.stringify(response));
            this.debounce(() => this.populate());
          } else {
            this.qoeResponse = { devices: [] };
            this.debounce(() => this.populate());
          }
        });

      this.store
        .select(selectCurrentLanLatency)
        .pipe(untilDestroyed(this))
        .subscribe((response) => {
          if (response) {
            this.currentLanLatency = response;
            this.debounce(() => this.populate());
          } else {
            this.currentLanLatency = null;
            this.debounce(() => this.populate());
          }
        });
    });
  }

  initScrollToDevice(): void {
    if (this.deviceid) {
      const device = this.deviceResponse.find((device) => device.mac.toLowerCase() === this.deviceid.toLowerCase());

      if (device) {
        if (this.firstScroll && (device?.vapType === 'fronthaul' || device?.vapType === 'captivePortal')) {
          if (this.isUpriseHomePass) {
            this.upriseHomePassItems = this.upriseHomePassItems.map((item) => ({
              ...item,
              selected: (device.networkId === 'iot' ? 'iot' : device.vapType) === item.value
            }));
          } else {
            this.smallbusinessItems = this.smallbusinessItems.map((item) => ({
              ...item,
              selected: (device.networkId === 'iot' ? 'iot' : device.vapType) === item.value
            }));
          }

          this.changeVapFilter(device.networkId === 'iot' ? 'iot' : device.vapType);
        } else {
          this.debounce(() => this.populate());
        }
      } else {
        this.debounce(() => this.populate());
      }
    } else {
      this.debounce(() => this.populate());
    }
  }

  initHaahs(): void {
    this.haahsEnabled = this.location.haahs?.enable || false;
    this.haahsItems = [
      { value: 'all', translation: 'devices.vapAll', selected: this.haahsFilter === 'all' },
      { value: 'home', translation: 'devices.vapHome', selected: this.haahsFilter === 'home' },
      { value: 'haahs', translation: 'devices.vapHaahs', selected: this.haahsFilter === 'haahs' }
    ];
  }

  checkDHCP(): void {
    if (this.plume.cloudVersionAbove1_114()) {
      this.customerService.getDHCPLeases$().subscribe((response: IDHCPLeases) => {
        if (response) {
          this.DHCPLeases = response;
        }
      });
    }
  }

  checkHomePass(): void {
    if (this.location?.profile !== 'auto') {
      this.updateHomePassDeviceFilter(false); // small business is never homePass
    } else {
      this.store
        .select(selectCapabilityWpa3)
        .pipe(
          take(1),
          switchMap(
            (capable) =>
              capable
                ? this.customerService
                    .getWifiNetwork$()
                    .pipe(map((response) => response.wifiNetwork.wpaMode !== 'sae-mixed')) // wpa3 disabled - show filter
                : of(true) // non capable - show filter
          )
        )
        .subscribe((nonWpa3HomePass) => {
          this.updateHomePassDeviceFilter(nonWpa3HomePass);
        });
    }
  }

  checkCoverageAlarms(): void {
    this.deviceService.coverageAlarms$().subscribe((alarmIds) => {
      this.coverageAlarms = alarmIds;
      this.debounce(() => this.populate());
    });
  }

  debounce(fn: () => void): void {
    clearTimeout(this.debouncerTimeout);
    this.debouncerTimeout = setTimeout(fn, 1000);
  }

  populate(): void {
    if (this.deviceResponse && this.qoeResponse) {
      const devices = [];
      this.online = [];
      this.offline = [];
      this.alarms = [];
      this.deviceResponse.forEach((device: any) => {
        let addDevice = false;
        let addHaahs = false;

        if (device.networkId === 'iot' && this.smallbusinessFilter === 'iot') {
          addDevice = true;
        }

        if (this.location?.profile !== 'auto') {
          if (device.vapType === this.smallbusinessFilter) {
            addDevice = true;
          }
          if (this.smallbusinessFilter === 'home' && device.medium === 'ethernet') {
            addDevice = true;
          }

          if (this.authorizedResponse) {
            // if authorizedResponse not arrived authorized should stay undefined
            device.authorized = !!this.authorizedResponse?.find((d) => d.mac === device.mac);
          }
        } else if (this.location?.flex) {
          if (this.flexFilter === 'all') {
            addDevice = true;
          }
          if (this.flexFilter === 'home' && device.networkId !== 'flex') {
            addDevice = true;
          }
          if (this.flexFilter === 'work' && device.networkId === 'flex') {
            addDevice = true;
          }
        } else if (this.isUpriseHomePass) {
          if (device.vapType === this.smallbusinessFilter) {
            addDevice = true;
          }
        } else {
          addDevice = true;
        }

        if (this.haahsEnabled) {
          addHaahs =
            this.haahsFilter === 'all' ||
            (this.haahsFilter === 'home' && device.vapType !== 'haahs') ||
            (this.haahsFilter === 'haahs' && device.vapType === 'haahs');
        } else {
          addHaahs = true;
        }

        if (addDevice && addHaahs) {
          const oldDevice = this.allDevices.find((oldDevice: any) => oldDevice.mac === device.mac) || null;

          device.icon = this.iconRef.get(device.icon);
          device.rssi = oldDevice ? oldDevice.rssi : null;
          device.history = oldDevice ? oldDevice.history : null;
          device.busyness = oldDevice ? oldDevice.busyness : null;
          device.nodeConnections = oldDevice ? oldDevice.nodeConnections : null;
          device.bandsteering = oldDevice ? oldDevice.bandsteering : null;
          device.clientsteering = oldDevice ? oldDevice.clientsteering : null;
          device.viewHistoryMode = oldDevice ? oldDevice.viewHistoryMode : true;
          device.deviceHistory = oldDevice ? oldDevice.deviceHistory : false;
          device.steeringTimeMachine = oldDevice ? oldDevice.steeringTimeMachine : false;

          const capabilities = [];

          if (device.health && device.health.details) {
            device.rssi = device.health.details.channelGain + 20;

            if (device.health.details.maxCapByRadio) {
              if (device.health.details.maxCapByRadio['2.4G']) {
                const radio24 = device.health.details.maxCapByRadio['2.4G'];
                const mimo = radio24.nss + 'x' + radio24.nss;
                const bw = 10 * Math.pow(2, radio24.bw + 1);
                const protocol = 'n';

                capabilities.push('2.4GHz, ' + mimo + ' 802.11' + protocol + ', ' + bw + 'MHz');
              } else {
                if (device.capabilities && device.capabilities.radio24) {
                  const mimo = '1x1';
                  const bw = 10 * Math.pow(2, 1);
                  const protocol = 'n';

                  capabilities.push('2.4GHz, ' + mimo + ' 802.11' + protocol + ', ' + bw + 'MHz');
                }
              }

              if (
                device.health.details.maxCapByRadio['5G'] ||
                device.health.details.maxCapByRadio['5GL'] ||
                device.health.details.maxCapByRadio['5GU']
              ) {
                const radio5 = device.health.details.maxCapByRadio['5G'] || { nss: 0, bw: 0 };
                const radio5l = device.health.details.maxCapByRadio['5GL'] || { nss: 0, bw: 0 };
                const radio5u = device.health.details.maxCapByRadio['5GU'] || { nss: 0, bw: 0 };

                const maxNss = Math.max(radio5.nss, radio5l.nss, radio5u.nss);
                const maxBw = Math.max(radio5.bw, radio5l.bw, radio5u.bw);

                const mimo = maxNss + 'x' + maxNss;
                const bw = 10 * Math.pow(2, maxBw + 1);
                const protocol = bw === 80 ? 'ac' : bw === 40 ? 'n' : 'b';

                capabilities.push('5GHz, ' + mimo + ' 802.11' + protocol + ', ' + bw + 'MHz');
              }
            }
          }

          // fallback without radio info
          if (device.capabilities && device.capabilities.radio24 && capabilities.indexOf('2.4GHz') < 0) {
            capabilities.push('2.4GHz');
          }
          if (device.capabilities && device.capabilities.radio50 && capabilities.indexOf('5GHz') < 0) {
            capabilities.push('5GHz');
          }
          if (device.capabilities && device.capabilities.radio60 && capabilities.indexOf('6GHz') < 0) {
            capabilities.push('6GHz');
          }

          device.capabilityString = capabilities;
          device.connectionStateMsg =
            device.connectionState === 'connected' ? 'devices.device.onlineSince' : 'devices.device.offlineSince';
          device.connectionStateTime = moment.utc(device.connectionStateChangeAt).local().format('L LT');

          if (device.connectionState === 'connected') {
            this.online.push(device);
          } else {
            this.offline.push(device);
          }

          const coverageAlarm = this.coverageAlarms.find((mac: string) => mac === device.mac) || null;

          device.coverageAlarm = coverageAlarm ? true : false;

          if ((device.alerts && device.alerts.length > 0) || coverageAlarm) {
            this.alarms.push(device);
            device.inAlarm = true;
          } else {
            device.inAlarm = false;
          }

          if (device.kind && device.kind.type && device.kind.type.name && device.kind.type.model) {
            device.model = device.kind.type.name + ' ' + device.kind.type.model;
          } else if (device.kind && device.kind.name) {
            device.model = device.kind.name;
          } else {
            device.model = null;
          }

          if (device.kind && device.kind.id) {
            device.deviceType = device.kind.id;
          } else {
            device.deviceType = null;
          }

          if (!this.plume.isFlexRole()) {
            this.deviceService
              .getDeviceSsid$(device.networkId, device.vapType)
              .subscribe((ssid: string) => (device.ssid = ssid));
          }

          if (device.leafToRoot?.length) {
            if (device.leafToRoot[0].id === device.leafToRoot[0].nickname) {
              const node = this.nodes.find((node: any) => node.id === device.leafToRoot[0].id);

              device.connectedTo = node
                ? { id: node.id, nickname: node.nickname || node.defaultName }
                : device.leafToRoot[0];
            } else {
              device.connectedTo = device.leafToRoot[0];
            }
          } else {
            device.connectedTo = null;
          }

          if (this.deviceid && this.firstScroll && device.mac === this.deviceid) {
            device.expand = true;
          } else {
            if (oldDevice && oldDevice.expand) {
              device.expand = true;
            } else {
              device.expand = false;
            }

            if (oldDevice && 'isRename' in oldDevice) {
              device.isRename = oldDevice.isRename;
            } else {
              device.isRename = false;
            }

            if (oldDevice?.freeze?.timeTemplates?.length) {
              const freezeEdit = {};

              oldDevice.freeze.timeTemplates.forEach((template: any) => {
                if (template.editTime) {
                  freezeEdit[template.id] = true;
                }
              });

              if (device?.freeze?.timeTemplates?.length) {
                device.freeze.timeTemplates.forEach((template: any) => {
                  if (freezeEdit[template.id]) {
                    template.editTime = true;
                  }
                });
              }
            }
          }

          if (this.DHCPLeases) {
            const lease = this.DHCPLeases.dhcpLeases.find((lRecord) => lRecord.mac === device.mac);

            if (lease) {
              device.dhcpLease = lease;
            }

            const v6Lease = this.DHCPLeases.dhcpV6Leases.find((lRecord) => lRecord.mac === device.mac);

            if (v6Lease) {
              device.dhcpV6Leases = v6Lease;
            }
          }

          const deviceQoe = this.qoeResponse.devices.find((qoe: any) => qoe.mac === device.mac) || {};
          device.rawQoe = deviceQoe;

          device.currentLanLatency =
            this.currentLanLatency?.devices.find((d) => d.mac?.toLowerCase() === device?.mac?.toLowerCase()) || null;

          devices.push(this.qoe.getDeviceQoeData(deviceQoe, device));
        }
      });

      this.allDevices = JSON.parse(JSON.stringify(devices));

      if (!this.allDevices.length) {
        this.searchDevices = [];
        this.message = 'devices.messageNoDevices';
      } else {
        this.search();
      }
    }
  }

  track(index: number, device: any): string {
    return device.mac;
  }

  sortBy(type: string): void {
    this.sortType = type;
    this.sort();
  }

  sort(): void {
    let sorted = [];
    const online = [];
    const offline = [];

    const sortAlphabet = (a: any, b: any) => {
      const aName = a.nickname || a.name;
      const bName = b.nickname || b.name;
      return aName.localeCompare(bName);
    };

    const sortConnectionChange = (a: any, b: any) => {
      const aDate = new Date(a.connectionStateChangeAt).getTime();
      const bDate = new Date(b.connectionStateChangeAt).getTime();
      return bDate - aDate;
    };

    if (this.searchDevices.length) {
      if (this.sortType === 'online') {
        this.filteredDevices.forEach((device: any) => {
          if (device.connectionState === 'connected') {
            online.push(device);
          } else {
            offline.push(device);
          }
        });

        sorted = [...online.sort(sortConnectionChange), ...offline.sort(sortConnectionChange)];
      }

      if (this.sortType === 'alphabet') {
        sorted = this.filteredDevices.sort(sortAlphabet);
      }

      if (this.sortType === 'connection') {
        sorted = this.filteredDevices.sort(sortConnectionChange);
      }

      if (this.deviceid && this.firstScroll) {
        this.firstScroll = false;
        this.scrollTo('#' + this.deviceid);
      }
    }

    this.devices = sorted;
    this.initialLoad = false;
  }

  getAuthorizedClients(): void {
    this.secondaryNetworks.getCaptivePortalAuthorizedClients$().subscribe((authorized) => {
      this.authorizedResponse = authorized;
      this.debounce(() => this.populate());
    });
  }

  changeFlexFilter(event: any): void {
    this.flexFilter = event;
    this.populate();
  }

  changeHaahsFilter(event: any): void {
    this.haahsFilter = event;
    this.populate();
  }

  changeVapFilter(event: any): void {
    this.smallbusinessFilter = event;
    this.allDevices = [];
    this.searchDevices = [];
    this.message = 'devices.messageLoading';

    if (this.isUpriseHomePass) {
      this.upriseHomePassItems.map((item) => (item.selected = item.value === this.smallbusinessFilter));
      this.debounce(() => this.populate());
      return;
    }

    this.smallbusinessItems.map((item) => (item.selected = item.value === this.smallbusinessFilter));

    if (event === 'captivePortal') {
      const authorizeFilter = {
        order: 12,
        name: 'portalAuthorization',
        key: 'authorized',
        translation: 'devices.filter.portalAuthorization',
        type: 'tristate',
        option: 'devices.filter.portalAuthorization',
        state: null,
        filterStates: [
          { value: true, translation: 'devices.filter.filterState.authorized' },
          { value: false, translation: 'devices.filter.filterState.notAuthorized' },
          { value: null, translation: 'devices.filter.filterState.clear' }
        ]
      };

      const exist = this.smallbusinessDeviceFilters.find((option: any) => option.name === 'portalAuthorization');

      if (!exist) {
        this.smallbusinessDeviceFilters.push(authorizeFilter);
      }

      this.smallbusinessDeviceFilters = this.smallbusinessDeviceFilters.filter((filter) => filter.name !== 'freeze');
    } else {
      this.smallbusinessDeviceFilters = this.smallbusinessDeviceFilters.filter(
        (option: any) => option.name !== 'portalAuthorization'
      );

      if (
        !this.smallbusinessDeviceFilters.find((filter) => filter.name === 'freeze') &&
        !Boolean(this.location.isUtilizingFocuses)
      ) {
        this.smallbusinessDeviceFilters.splice(1, 0, this.deviceFiltersFrozen);
      }
    }

    if (event === 'home' || event === 'fronthaul') {
      const approvalState = {
        order: 13,
        name: 'networkAccess',
        key: 'networkAccess.mode',
        translation: 'devices.filter.filterApprovalState',
        type: 'tristate',
        option: 'devices.filter.optionApproval',
        state: null,
        filterStates: [
          { value: 'blocked', translation: 'devices.filter.filterState.blocked' },
          { value: 'approved', translation: 'devices.filter.filterState.approved' },
          { value: 'auto', translation: 'devices.filter.filterState.notSet' },
          { value: null, translation: 'devices.filter.filterState.clear' }
        ]
      };

      const exist = this.smallbusinessDeviceFilters.find((option: any) => option.name === 'networkAccess');

      if (!exist) {
        this.smallbusinessDeviceFilters.push(approvalState);
      }
    } else {
      this.smallbusinessDeviceFilters = this.smallbusinessDeviceFilters.filter(
        (option: any) => option.name !== 'networkAccess'
      );
    }

    this.debounce(() => this.populate());
  }

  updateFilter(devices: any[] = null): void {
    this.filteredDevices = devices;
    this.sort();
  }

  disableFilterOption(filter: any): void {
    this.filter.disableFilter(filter.option);
  }

  filterOpenStateChanged(): void {
    this.filter.initFilters(null); // close all sub menus
  }

  updateSelectedFilters(filters: any): void {
    this.selectedFilters = filters;
  }

  initFilters(): void {
    if (this.location?.profile !== 'auto') {
      this.smallbusinessDeviceFilters.forEach((filter: any) => (filter.expand = false));
    } else {
      this.deviceFilters.forEach((filter: any) => (filter.expand = false));
    }
  }

  search(): void {
    let value = null;

    if (this.searchFilter && this.searchFilter.nativeElement.value.length) {
      value = this.searchFilter.nativeElement.value;
    }

    const filtered = this.allDevices.filter((device: any) => {
      if (
        value?.length &&
        this.macAddrPipe.transform(device.name, true).toLowerCase().indexOf(value.toLowerCase()) >= 0
      ) {
        return true;
      }

      if (
        value?.length &&
        this.macAddrPipe.transform(device.mac, true).toLowerCase().indexOf(value.toLowerCase()) >= 0
      ) {
        return true;
      }

      if (value?.length && device.ip?.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
        return true;
      }

      if (!value || !value.length) {
        return true;
      }
    });

    if (!filtered.length) {
      this.devices = [];
      this.searchDevices = [];
      this.message = 'devices.messageNoSearchResults';
    } else {
      this.searchDevices = filtered;
    }
  }

  scrollTo(selector: string): void {
    setTimeout(() => {
      const view = document.getElementById('customer-view');

      this.pagescroll.scroll({
        document: window.document,
        scrollTarget: selector,
        scrollViews: [view],
        scrollOffset: 10
      });
    }, 100);
  }

  isOnboarded(response: any): void {
    try {
      const permissions = this.plume.getPermissions();

      if (permissions.uiFeatures.overrideOnboarded) {
        this.onboarded = 'complete';
      } else {
        if (['OnboardingComplete', 'PodsAdded'].includes(response.summary.onboardingStatus)) {
          this.onboarded = 'complete';
        } else {
          this.onboarded = 'uncomplete';
        }
      }
    } catch (err) {
      this.onboarded = 'uncomplete';
    }
  }

  // Device modal functions
  refreshDevice(device: IDevice): void {
    this.deviceService.device$(device.mac).subscribe((response: IDevice) => {
      this.selectedDevice = response;
      const deviceToRefresh = this.devices.find((device) => device.mac === response.mac);
      deviceToRefresh.focused = response.focused;
    });
  }

  openModal(
    modal: 'focus' | 'ipv6' | 'details' | 'cloudSteering' | 'steer' | 'qoe' | 'priority' | 'ohp' | 'lanLatency',
    device: IDevice
  ): void {
    switch (modal) {
      case 'focus':
        this.focusModalOpen = true;
        break;
      case 'ipv6':
        this.ipv6ModalOpen = true;
        break;
      case 'details':
        this.deviceDetailsModalOpen = true;
        break;
      case 'cloudSteering':
        this.cloudSteeringModalOpen = true;
        break;
      case 'steer':
        this.steerModalOpen = true;
        break;
      case 'qoe':
        this.qoeModalOpen = true;
        break;
      case 'priority':
        this.priorityModalOpen = true;
        break;
      case 'ohp':
        this.ohpDetailsModalOpen = true;
        break;
      case 'lanLatency':
        this.lanLatencyModalOpen = true;
        break;
    }
    this.selectedDevice = device;
  }

  closeModal(
    modal: 'focus' | 'ipv6' | 'details' | 'cloudSteering' | 'steer' | 'qoe' | 'priority' | 'ohp' | 'lanLatency'
  ): void {
    switch (modal) {
      case 'focus':
        this.focusModalOpen = false;
        break;
      case 'ipv6':
        this.ipv6ModalOpen = false;
        break;
      case 'details':
        this.deviceDetailsModalOpen = false;
        break;
      case 'cloudSteering':
        this.cloudSteeringModalOpen = false;
        break;
      case 'steer':
        this.steerModalOpen = false;
        break;
      case 'qoe':
        this.qoeModalOpen = false;
        break;
      case 'priority':
        this.priorityModalOpen = false;
        break;
      case 'ohp':
        this.ohpDetailsModalOpen = false;
        break;
      case 'lanLatency':
        this.lanLatencyModalOpen = false;
        break;
    }
    this.selectedDevice = null;
  }

  ngOnDestroy(): void {
    if (this.debouncerTimeout) {
      clearTimeout(this.debouncerTimeout);
    }

    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
    }
  }
}
