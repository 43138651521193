import * as moment from 'moment';
import { Line } from '../d3/models/objects/line';
import { Point } from '../d3/models/objects/point';
import { Series } from '../d3/models/objects/series';

type Charts = {
  [qoeMetric: string]: Chart;
};

type Chart = {
  axis?: [string, string, string];
  nodechart?: NodeChart;
  ticks?: {
    timestamp: string;
    time: string;
    color: string;
    border?: string;
    state: string | number;
    tooltip?: string;
    busy?: string;
  }[];
};

type NodeChart = {
  [channel: number]: {
    [timestamp: string]: number;
  };
};

export class QoeHelper {
  moment = moment;
  nodeColors: any = {};
  gradeColors: string[] = [
    'rgb(255, 0, 48)',
    'rgb(255, 182, 0)',
    'rgb(165, 218, 31)',
    'rgb(30, 181, 0)',
    'rgb(0, 181, 0)'
  ];

  chartNames: string[] = [
    'weightedQoeScore',
    'effPhyrateRx',
    'effPhyrateTx',
    'weightedPrrRx',
    'weightedPrrTx',
    'channelUtilization',
    'rssi',
    'p25Rssi',
    'p75Rssi',
    'busyRatio',
    'avgRxAirTime',
    'avgTxAirTime',
    'p25Snr',
    'p75Snr',
    'snr',
    'connectivityBasedScore',
    'lpmRatio',
    'signalBars'
  ];

  constructor() {}

  setLocale(locale: string): void {
    this.moment.locale(locale);
  }

  getPhyColor(value: number): string {
    if (value <= 20) {
      return this.gradeColors[0];
    }
    if (value < 30) {
      return this.gradeColors[1];
    }
    return this.gradeColors[4];
  }

  getPRRColor(value: number): string {
    if (value >= 70) {
      return this.gradeColors[0];
    }
    if (value >= 60) {
      return this.gradeColors[1];
    }
    return this.gradeColors[4];
  }

  getChanUtilColor(value: number): string {
    if (value >= 70) {
      return this.gradeColors[0];
    }
    if (value >= 60) {
      return this.gradeColors[1];
    }
    return this.gradeColors[4];
  }

  getColor(index: number): string {
    const colors = [
      'rgb(94, 189, 62)',
      'rgb(255, 185, 0)',
      'rgb(247, 130, 0)',
      'rgb(226, 56, 56)',
      'rgb(151, 57, 153)',
      'rgb(0, 156, 223)',
      'rgb(153, 204, 51)',
      'rgb(204, 153, 102)',
      'rgb(153, 102, 51)',
      'rgb(153, 0, 0)',
      'rgb(102, 51, 102)',
      'rgb(89, 199, 194)',
      'rgb(255, 102, 178)',
      'rgb(0, 76, 153)',
      'rgb(102, 102, 0)'
    ];

    return colors[index - colors.length * Math.floor(index / colors.length)];
  }

  round(interval: number, moment: any): any {
    const roundedMinutes = Math.floor(moment.minute() / interval) * interval;
    return moment.clone().minute(roundedMinutes).second(0);
  }

  nocCharts(
    charts: Charts,
    chartMode: string,
    seriesColors: any = {},
    translations: { [qoeMetric: string]: string } = null,
    mode?: string
  ): any {
    const nodeCharts = {};
    let line;

    this.chartNames.forEach((chartName: string) => {
      if (!charts?.[chartName]?.nodechart) return;

      const chartLines = [];
      let maxValue: number | null = null;

      Object.keys(charts[chartName].nodechart).forEach((channel: string) => {
        const pointsArray: Point[] = [];
        const start = this.round(15, this.moment());

        for (let i = 0; i < (chartMode === '24h' ? 96 : 168); i++) {
          // 168 = 24 * 7 (hours in week); 96 = 4*24 (15min block in day)
          const timestamp = new Date(start.valueOf()).toString();
          const value: number = charts[chartName].nodechart[channel][timestamp];
          // Cast to null or number
          const filledValue: number | null = typeof value !== 'number' ? null : value;

          pointsArray.push(new Point(timestamp, filledValue));

          this.moment.utc(start.subtract(chartMode === '24h' ? 15 : 60, 'minutes')).local();

          if (maxValue === null) {
            maxValue = filledValue;
          } else {
            if (filledValue !== null && filledValue > maxValue) {
              maxValue = filledValue;
            }
          }
        }

        const translation = translations ? translations[chartName] : chartName;
        if (mode === '5g') {
          line = new Line(
            new Series(
              seriesColors[chartName] ? seriesColors[chartName] : 'rgb(0, 156, 223)',
              `${translation}`,
              `${translation}`
            ),
            'left',
            pointsArray
          );
        } else {
          line = new Line(
            new Series(
              seriesColors[chartName] ? seriesColors[chartName] : 'rgb(0, 156, 223)',
              channel === 'joint' ? translation : `${translation} (Ch ${channel})`,
              channel === 'joint' ? translation : `${translation} (Ch ${channel})`
            ),
            'left',
            pointsArray
          );
        }

        chartLines.push(line);
      });

      nodeCharts[chartName] = {
        graph: chartLines,
        maxValue
      };
    });

    return nodeCharts;
  }

  lineCharts(data: any = null, mock: any = {}): any {
    if (data) {
      if (!('score' in data)) {
        data['score'] = [];
      }

      Object.keys(data).forEach((key: any, index: number) => {
        if (data[key] instanceof Array) {
          const points = [];
          const allPoints = [];

          data[key].forEach((point: any) => {
            const value = mock[key]
              ? point.value !== null
                ? mock[key]
                : null
              : point.value !== null
              ? key === 'snr'
                ? parseFloat(point.value) - 95
                : parseFloat(point.value)
              : null;

            if (Date.now() - point.timestamp < 15 * 60 * 1000) {
              points.push(new Point(new Date(point.timestamp).toString(), value));
            }

            allPoints.push({ timestamp: point.timestamp, value });
          });

          data[key] = new Line(
            new Series(this.getColor(index + 4), key, 'qoe.charts.' + key),
            'left',
            points,
            allPoints
          );
        }
      });
    }

    return data;
  }

  procValue(property: string, value: number): number {
    if (property === 'p25Snr' || property === 'p75Snr') {
      return value ? value - 95 : null;
    }
    return value;
  }

  horizontalCharts(data: any = null, nodes: any[] = [], mode: string = '24h') {
    if (!data) return null;

    const history = {};
    const nodechart = {};

    for (const id of Object.keys(data)) {
      if (id !== 'statsDateRange') {
        if (!history['online']) {
          history['online'] = {};
        }
        if (!history['qoe']) {
          history['qoe'] = {};
        }
        if (!history['busy']) {
          history['busy'] = {};
        }

        for (const channel of Object.keys(data[id])) {
          if (!history['channel']) {
            history['channel'] = {};
          }

          for (const property of Object.keys(data[id][channel])) {
            if (!history[property]) {
              history[property] = {};
            }

            for (const tick of data[id][channel][property]) {
              const time = new Date(tick.timestamp).toString();

              if (!history[property].hasOwnProperty(time) || history[property][time] === null) {
                const value = parseFloat(tick.value) || null;

                history[property][time] = value;

                if (value !== null) {
                  history['online'][time] = id || null;
                  history['channel'][time] = parseFloat(channel) || null;
                }
              }
            }

            if (this.chartNames.includes(property)) {
              if (!nodechart[property]) {
                nodechart[property] = {};
              }
              if (!nodechart[property][channel]) {
                nodechart[property][channel] = {};
              }

              for (const tick of data[id][channel][property]) {
                const time = new Date(tick.timestamp).toString();
                const filledValue =
                  tick.value === undefined || tick.value === null
                    ? null
                    : this.procValue(property, parseFloat(tick.value));

                const convertPercent = property === 'busyRatio' ? filledValue * 100 : filledValue;

                if (!nodechart[property][channel].hasOwnProperty(time) || nodechart[property][channel][time] === null) {
                  nodechart[property][channel][time] = convertPercent;
                }
              }
            }
          }
        }
      }
    }

    nodes.forEach((node: any, index: number) => {
      this.nodeColors[node.id] = this.getColor(index);
    });

    for (const metric of Object.keys(history)) {
      if (metric === 'rssi') {
        history['rssi'] = {
          ticks: this.calculateHistory(null, mode, history['rssi']),
          axis: this.getAxis(mode)
        };
        history['rssi'].nodechart = nodechart['rssi'];
      } else {
        history[metric] = {
          ticks: this.fillEmpty(history[metric], metric, nodes, mode),
          axis: this.getAxis(mode)
        };
        if (this.chartNames.includes(metric)) {
          history[metric].nodechart = nodechart[metric];
        }
      }
    }

    if (history['qoe']) {
      for (let i = 0; i < history['qoe']?.ticks.length; i++) {
        const value =
          history['weightedQoeScore'] && history['weightedQoeScore']?.ticks[i].state
            ? history['weightedQoeScore']?.ticks[i].state
            : history['weightedUsageBasedScore']?.ticks[i].state
            ? history['weightedUsageBasedScore']?.ticks[i].state
            : history['averageNeedBasedScore']?.ticks[i].state
            ? history['averageNeedBasedScore']?.ticks[i].state
            : null;

        const busy = history['weightedUsageBasedScore']?.ticks[i].state === null ? false : true;

        history['qoe'].ticks[i].color = this.gradeColors[Math.floor(value) - 1];
        history['qoe'].ticks[i].state = value || null;
        history['qoe'].ticks[i].busy = busy ? 'rgb(66, 68, 98)' : 'rgb(255, 255, 255)';
      }
    }

    return Object.keys(history).length ? history : null;
  }

  horizontalChartsV2(
    metrics: {
      statsDateRange: { start: number; end: number };
      data: {
        connection: Record<string, string | number | null>;
        links: Record<string, string | number | null>[];
        timestamp: number;
      }[];
    } = null,
    nodes: any[] = [],
    mode: string = '24h'
  ) {
    const chartNames = [
      'online',
      'channel',
      'qoe',
      'weightedQoeScore',
      'effPhyrateRx',
      'effPhyrateTx',
      'weightedPrrRx',
      'weightedPrrTx',
      'channelUtilization',
      'rssi',
      'p25Rssi',
      'p75Rssi',
      'busyRatio',
      'avgRxAirTime',
      'avgTxAirTime',
      'p25Snr',
      'p75Snr',
      'snr',
      'connectivityBasedScore',
      'lpmRatio',
      'signalBars',
      'averageNeedBasedScore',
      'averagePredictedWifiSpeed',
      'weightedUsageBasedScore'
    ];

    const connectionBasedCharts = [
      'busyRatio',
      'weightedQoeScore',
      'weightedUsageBasedScore',
      'averageNeedBasedScore',
      'online',
      'averagePredictedWifiSpeed',
      'connectivityBasedScore',
      'qoe'
    ];

    if (!metrics?.data) return null;

    const charts: {
      [frequencyBand: string]: {
        [chartName: string]: Chart;
      };
    } & {
      joint: {
        [chartName: string]: Chart;
      };
    } = {
      joint: {}
    };

    nodes.forEach((node: any, index: number) => {
      this.nodeColors[node.id] = this.getColor(index);
    });

    metrics.data.forEach((dataPoint) => {
      const timestamp = new Date(dataPoint.timestamp).toString();

      chartNames.forEach((chartName) => {
        if (!dataPoint.connection.isMlo) {
          const band = connectionBasedCharts.includes(chartName) ? 'joint' : dataPoint.connection.freqBand;
          const channel = dataPoint.connection.channel;

          if (!charts[band]) charts[band] = {};
          // Assign channel for channel chart
          if (band !== 'joint') {
            if (!charts[band].channel) charts[band].channel = {};
            charts[band].channel[timestamp] = parseFloat(channel as string) || null;
          }

          // Set up node chart
          if (!charts[band][chartName]?.nodechart)
            charts[band][chartName] = {
              nodechart: {},
              axis: this.getAxis(mode)
            };
          if (!charts[band][chartName].nodechart[channel]) charts[band][chartName].nodechart[channel] = {};

          // Assign value from QoE metrics to the corresponding chart based on frequency band and channel
          const dataPointValue = dataPoint.connection[chartName];

          const value =
            dataPointValue !== null && dataPointValue !== undefined
              ? this.procValue(chartName, parseFloat(dataPoint.connection[chartName]?.toString()))
              : null;
          const convertedValue = chartName === 'busyRatio' ? value * 100 : value;
          if (band === 'joint') {
            if (!charts[band][chartName].nodechart['joint']) charts[band][chartName].nodechart['joint'] = {};
            charts[band][chartName].nodechart['joint'][timestamp] = convertedValue;
          } else {
            charts[band][chartName].nodechart[channel][timestamp] = convertedValue;
          }

          // Generate values for ticks for charts based on frequency band
          if (chartName === 'online') {
            charts[band][chartName][timestamp] = dataPoint.connection.parent;
          } else {
            charts[band][chartName][timestamp] = value;
          }
        } else {
          const links = dataPoint.links;

          links.forEach((link) => {
            const band = connectionBasedCharts.includes(chartName) ? 'joint' : link.freqBand;
            const channel = link.channel;

            // Create channel chart
            if (!charts[band]) charts[band] = {};
            if (band !== 'joint') {
              if (!charts[band].channel) charts[band].channel = {};
              charts[band].channel[timestamp] = parseFloat((channel as string) || null);
            }

            // Set up node chart
            if (!charts[band][chartName]?.nodechart)
              charts[band][chartName] = {
                nodechart: {},
                axis: this.getAxis(mode)
              };
            if (!charts[band][chartName].nodechart[channel]) charts[band][chartName].nodechart[channel] = {};

            // Assign value from QoE metrics to the corresponding chart based on frequency band and channel
            const dataPointValue = connectionBasedCharts.includes(chartName)
              ? dataPoint.connection[chartName]
              : link[chartName];
            const value =
              dataPointValue !== null && dataPointValue !== undefined
                ? this.procValue(chartName, parseFloat(dataPointValue?.toString()))
                : null;
            const convertedValue = chartName === 'busyRatio' ? value * 100 : value;
            if (band === 'joint') {
              if (!charts[band][chartName].nodechart['joint']) charts[band][chartName].nodechart['joint'] = {};
              charts[band][chartName].nodechart['joint'][timestamp] = convertedValue;
            } else {
              charts[band][chartName].nodechart[channel][timestamp] = convertedValue;
            }

            // Generate values for ticks for charts based on frequency band
            if (chartName === 'online') {
              charts[band][chartName][timestamp] = dataPoint.connection.parent;
            } else {
              charts[band][chartName][timestamp] = value;
            }
          });
        }
      });
    });

    Object.keys(charts)
      .filter((band) => band !== 'joint')
      .forEach((band) => {
        // Generate combined RSSI and SNR values from p75 and p25 RSSI and SNR values
        charts[band].rssi = this.getJointValueChart(charts, band, mode, 'Rssi');
        charts[band].snr = this.getJointValueChart(charts, band, mode, 'Snr');
      });

    // Generate ticks for solid line stats charts
    for (const band of Object.keys(charts)) {
      chartNames.forEach((chartName) => {
        if (!charts[band][chartName]) return;
        charts[band][chartName].ticks = this.fillEmpty(charts[band][chartName], chartName, nodes, mode);
      });
    }

    // Generate qoe chart
    for (let i = 0; i < charts['joint'].qoe?.ticks.length; i++) {
      const value =
        charts['joint'].weightedQoeScore && charts['joint'].weightedQoeScore?.ticks[i].state
          ? charts['joint'].weightedQoeScore?.ticks[i].state
          : charts['joint'].weightedUsageBasedScore?.ticks[i].state
          ? charts['joint'].weightedUsageBasedScore?.ticks[i].state
          : charts['joint'].averageNeedBasedScore?.ticks[i].state
          ? charts['joint'].averageNeedBasedScore?.ticks[i].state
          : null;

      const busy = !charts['joint'].busyRatio?.ticks[i].state || !value ? false : true;

      charts['joint'].qoe.ticks[i].color = this.gradeColors[Math.floor(value as number) - 1];
      charts['joint'].qoe.ticks[i].state = value || null;
      charts['joint'].qoe.ticks[i].busy = busy ? 'rgb(66, 68, 98)' : 'rgb(255, 255, 255)';
    }

    return charts;
  }

  getJointValueChart(
    charts: {
      [frequencyBand: string]: {
        [chartName: string]: {
          nodechart?: NodeChart;
          axis?: [string, string, string];
          ticks?: object[];
        };
      };
    },
    band: string,
    mode: string,
    property: 'Rssi' | 'Snr'
  ) {
    const data = {};
    const nodechart: NodeChart = {};
    const p75 = charts[band][`p75${property}`];
    const p25 = charts[band][`p25${property}`];

    if (!p75 || !p25)
      return {
        ticks: [],
        axis: this.getAxis(mode),
        nodechart: {}
      };

    const p75Channels = Object.keys(p75.nodechart);
    const p25Channels = Object.keys(p25.nodechart);

    for (const channel of p75Channels) {
      if (p25Channels.includes(channel)) {
        const p75Ticks = p75.nodechart[channel];
        const p25Ticks = p25.nodechart[channel];

        for (const timestamp of Object.keys(p75Ticks)) {
          if (p25Ticks[timestamp] !== null && p75Ticks[timestamp] !== null) {
            const rssiValue =
              Math.round(
                (parseFloat(p25Ticks[timestamp]) * 0.67 + parseFloat(p75Ticks[timestamp]) * 0.33 + Number.EPSILON) * 100
              ) / 100 || null;
            data[new Date(timestamp).toString()] = rssiValue;
            nodechart[channel] = { ...nodechart[channel], [timestamp]: rssiValue };
          }
        }
      }
    }

    return {
      ticks: this.calculateHistory(null, mode, data),
      axis: this.getAxis(mode),
      nodechart
    };
  }

  qoeHorizontalCharts(data: any = null, mode?: string): any {
    if (data) {
      const history = {};
      if (!history['qoe']) {
        history['qoe'] = {};
      }

      for (const property of Object.keys(data)) {
        if (!history[property]) {
          history[property] = {};
        }
        const propertyData = data[property];
        if (data[property]) {
          if (Array.isArray(propertyData)) {
            for (const tick of data[property]) {
              const time = new Date(tick.timestamp).toString();

              if (!history[property].hasOwnProperty(time) || history[property][time] === null) {
                const value = parseFloat(tick.value) || null;
                history[property][time] = value;
              }
            }
          }
        }
      }

      for (const metric of Object.keys(history)) {
        history[metric] = {
          ticks: this.fillEmpty(history[metric], metric, [], mode ? (mode === '24h' ? '24h' : '7d') : '24h'),
          axis: this.getAxis(mode ? (mode === '24h' ? '24h' : '7d') : '24h')
        };
      }

      if (history['qoe']) {
        for (let i = 0; i < history['qoe'].ticks.length; i++) {
          const value =
            history['weightedQoeScore'] && history['weightedQoeScore'].ticks[i].state
              ? history['weightedQoeScore'].ticks[i].state
              : null;
          const busy = history['busyRatio']?.ticks[i].state === null ? false : true;
          history['qoe'].ticks[i].color = this.gradeColors[Math.floor(value) - 1];
          history['qoe'].ticks[i].state = Math.round((value + Number.EPSILON) * 10) / 10 || null;
          history['qoe'].ticks[i].busy = busy ? 'rgb(66, 68, 98)' : 'rgb(255, 255, 255)';
        }
      }
      if (history['signalBars']) {
        for (let i = 0; i < history['signalBars'].ticks.length; i++) {
          const value =
            history['signalBars'] && history['signalBars'].ticks[i].state ? history['signalBars'].ticks[i].state : null;
          const busy = history['busyRatio']?.ticks[i].state === null ? false : true;
          history['signalBars'].ticks[i].color = this.gradeColors[Math.floor(value) - 1];
          history['signalBars'].ticks[i].state = Math.round((value + Number.EPSILON) * 10) / 10 || null;
          history['signalBars'].ticks[i].busy = busy ? 'rgb(66, 68, 98)' : 'rgb(255, 255, 255)';
        }
      }

      return Object.keys(history).length ? history : null;
    } else {
      return null;
    }
  }

  qoeHorizontalChartsV2(data: any = null, mode?: string): any {
    if (!data) return null;

    const charts: {
      [qoeMetric: string]: Chart;
    } = {};

    if (!charts.qoe) charts.qoe = {};

    Object.keys(data).forEach((metric) => {
      if (!charts[metric]) charts[metric] = {};
      for (const tick of data[metric]) {
        const time = new Date(tick.timestamp).toString();
        charts[metric][time] = tick.value;
      }
    });

    for (const metric of Object.keys(charts)) {
      charts[metric] = {
        ticks: this.fillEmpty(charts[metric], metric, [], mode ? (mode === '24h' ? '24h' : '7d') : '24h'),
        axis: this.getAxis(mode ? (mode === '24h' ? '24h' : '7d') : '24h')
      };
    }

    for (let i = 0; i < charts.qoe?.ticks.length; i++) {
      const value =
        charts.weightedQoeScore && charts.weightedQoeScore?.ticks[i].state
          ? charts.weightedQoeScore?.ticks[i].state
          : null;

      const busy = !charts.busyRatio?.ticks[i].state || !value ? false : true;

      charts.qoe.ticks[i].color = this.gradeColors[Math.floor(value as number) - 1];
      charts.qoe.ticks[i].state = value || null;
      charts.qoe.ticks[i].busy = busy ? 'rgb(66, 68, 98)' : 'rgb(255, 255, 255)';
    }

    return charts;
  }

  convertQoeMetrics(data: any = null, keepConverted: boolean = false): any {
    if (data) {
      for (const id of Object.keys(data)) {
        if (id !== 'statsDateRange') {
          for (const channel of Object.keys(data[id])) {
            const convert = (parameter: string) => {
              if ('p25' + parameter in data[id][channel] && 'p75' + parameter in data[id][channel]) {
                const p25 = data[id][channel]['p25' + parameter];
                const p75 = data[id][channel]['p75' + parameter];
                const converted = [];

                if (p25.length === p75.length) {
                  for (let i = 0; i < p25.length; i++) {
                    let value =
                      Math.round(
                        (parseFloat(p25[i].value) * 0.67 + parseFloat(p75[i].value) * 0.33 + Number.EPSILON) * 100
                      ) / 100 || null;
                    if (value && parameter === 'Snr') {
                      value = value - 95;
                    }
                    converted.push({
                      timestamp: p25[i].timestamp,
                      value
                    });
                  }
                }

                if (!keepConverted) {
                  delete data[id][channel]['p25' + parameter];
                  delete data[id][channel]['p75' + parameter];
                }

                return converted;
              } else {
                return [];
              }
            };

            data[id][channel]['snr'] = convert('Snr');
            data[id][channel]['rssi'] = convert('Rssi');
          }
        }
      }
    }

    return data;
  }

  getAxis(mode: string): [string, string, string] {
    const axis = [];

    if (mode === '7d') {
      axis.push(
        new Date(this.moment().subtract(7, 'days').valueOf()).toLocaleString(this.moment().locale(), {
          day: '2-digit',
          month: 'short'
        })
      );
      axis.push(
        new Date(this.moment().subtract(3, 'days').valueOf()).toLocaleString(this.moment().locale(), {
          day: '2-digit',
          month: 'short'
        })
      );
      axis.push('devices.device.now');
    } else {
      axis.push(
        new Date(this.moment().subtract(24, 'hours').valueOf()).toLocaleTimeString(this.moment().locale(), {
          hour: '2-digit',
          minute: '2-digit'
        })
      );
      axis.push(
        new Date(this.moment().subtract(12, 'hours').valueOf()).toLocaleTimeString(this.moment().locale(), {
          hour: '2-digit',
          minute: '2-digit'
        })
      );
      axis.push('devices.device.now');
    }

    return axis as [string, string, string];
  }

  fillEmpty(data: any, metric: string, nodes: any[], mode: string) {
    const start = this.round(15, this.moment());
    const chartData: {
      timestamp: string;
      time: string;
      color: string;
      border: string;
      state: string;
      tooltip: string;
    }[] = [];

    for (let i = 0; i < (mode === '24h' ? 96 : 168); i++) {
      // 168 = 24 * 7 (hours in week); 96 = 4*24 (15min block in day)
      const tick = {
        timestamp: start.valueOf(),
        time: this.moment.utc(start).local().format('lll'),
        color: 'rgb(255, 255, 255)',
        border: 'rgb(238, 238, 236)',
        state: null,
        tooltip: null
      };

      if (
        metric === 'averagePredictedWifiSpeed' ||
        metric === 'minPredictedWifiSpeed' ||
        metric === 'speedTestLatency'
      ) {
        tick.time = new Date(start).toString();
      }

      const time = new Date(start.valueOf()).toString();

      if (data && data[time]) {
        tick.state = data[time];

        switch (metric) {
          case 'online':
            const node = nodes.find((node) => node.id === tick.state);
            tick.state = node ? node.nickname || node.name || tick.state : tick.state;
            tick.border = tick.color = node ? this.nodeColors[data[time]] : this.getColor(10);
            break;
          case 'channel':
            tick.border = tick.color = this.getColor(data[time]);
            break;
          case 'busyRatio':
            const color = Math.floor(255 - data[time] * 255);
            tick.border = tick.color = 'rgb(' + [color, color, color].join(',') + ')';
            break;
          case 'lpmRatio':
            const lpmColor = Math.floor(255 - data[time] * 255);
            tick.border = tick.color = 'rgb(' + [lpmColor, lpmColor, lpmColor].join(',') + ')';
            break;
          case 'effPhyrateRx':
            tick.border = tick.color = this.getPhyColor(data[time]);
            break;
          case 'effPhyrateTx':
            tick.border = tick.color = this.getPhyColor(data[time]);
            break;
          case 'weightedPrrRx':
            tick.border = tick.color = this.getPRRColor(data[time]);
            break;
          case 'weightedPrrTx':
            tick.border = tick.color = this.getPRRColor(data[time]);
            break;
          case 'channelUtilization':
            tick.border = tick.color = this.getChanUtilColor(data[time]);
            break;
          case 'averagePredictedWifiSpeed':
            tick.border = tick.color = 'rgb(0, 0, 255)';
            tick.tooltip = 'averagePredictedWifiSpeed';
            break;
          case 'minPredictedWifiSpeed':
            tick.border = tick.color = 'rgb(0, 255, 0)';
            tick.tooltip = 'minPredictedWifiSpeed';
            break;
          case 'actualThroughput':
            tick.border = tick.color = this.getPhyColor(data[time]);
            tick.tooltip = 'actualThroughput';
            break;
          case 'estimatedThroughput':
            tick.border = tick.color = this.getPhyColor(data[time]);
            tick.tooltip = 'estimatedThroughput';
            break;
          case 'speedTestLatency':
            tick.border = tick.color = 'rgb(0, 0, 255)';
            tick.tooltip = 'speedTestLatency';
            break;
          default:
            tick.border = tick.color = this.gradeColors[Math.floor(data[time]) - 1];
        }
      }

      chartData.unshift(tick);
      start.subtract(mode === '24h' ? 15 : 60, 'minutes');
    }

    return chartData;
  }

  calculateHistory(rssi: any, mode: string, converted: any = null): any[] {
    let data = {};

    if (converted) {
      rssi = {};
      Object.keys(converted).forEach((timestamp: any) => {
        rssi[timestamp] = { rssi: converted[timestamp] };
      });
      data = rssi;
    } else {
      data = this.convertRssi(rssi);
    }

    const start = this.round(15, this.moment());
    const history = [];

    for (let i = 0; i < (mode === '24h' ? 96 : 168); i++) {
      // 168 = 24 * 7 (hours in week); 96 = 4*24 (15min block in day)
      const tick = {
        timestamp: start.valueOf(),
        time: this.moment.utc(start).local().format('lll'),
        color: 'rgb(255, 255, 255)',
        border: 'rgb(238, 238, 236)',
        state: null
      };

      const time = new Date(start.valueOf()).toString();

      if (data[time] && data[time].rssi !== null) {
        if (data[time].rssi > -65) {
          tick.border = tick.color = 'rgb(94, 189, 62)';
          tick.state = 'Online';
        }
        if (data[time].rssi <= -65 && data[time].rssi >= -70) {
          tick.border = tick.color = 'rgb(255, 197, 0)';
          tick.state = 'Warning';
        }
        if (data[time].rssi < -70) {
          tick.border = tick.color = 'rgb(237, 30, 121)';
          tick.state = 'Alarm';
        }
      }

      history.unshift(tick);
      start.subtract(mode === '24h' ? 15 : 60, 'minutes');
    }

    return history;
  }

  convertRssi(rssi: any): any {
    const rssi2g = {};
    const rssi5g = {};

    rssi['2g']['perc25'].forEach((tick: any) => {
      if (tick.value != null) {
        if (rssi2g[tick.timestamp] && rssi2g[tick.timestamp].rssi != null) {
          rssi2g[tick.timestamp].rssi = 0.33 * rssi2g[tick.timestamp].rssi + 0.67 * tick.value;
        } else {
          rssi2g[tick.timestamp] = {
            rssi: tick.value,
            node: tick.node_id
          };
        }
      }
    });

    rssi['2g']['perc75'].forEach((tick: any) => {
      if (tick.value != null) {
        if (rssi2g[tick.timestamp] && rssi2g[tick.timestamp].rssi != null) {
          rssi2g[tick.timestamp].rssi = 0.33 * tick.value + 0.67 * rssi2g[tick.timestamp].rssi;
        } else {
          rssi2g[tick.timestamp] = {
            rssi: tick.value,
            node: tick.node_id
          };
        }
      }
    });

    rssi['5g']['perc25'].forEach((tick: any) => {
      if (tick.value != null) {
        if (rssi5g[tick.timestamp] && rssi5g[tick.timestamp].rssi != null) {
          rssi5g[tick.timestamp].rssi = 0.33 * rssi5g[tick.timestamp].rssi + 0.67 * tick.value;
        } else {
          rssi5g[tick.timestamp] = {
            rssi: tick.value,
            node: tick.node_id
          };
        }
      }
    });

    rssi['5g']['perc75'].forEach((tick: any) => {
      if (tick.value != null) {
        if (rssi5g[tick.timestamp] && rssi5g[tick.timestamp].rssi != null) {
          rssi5g[tick.timestamp].rssi = 0.33 * tick.value + 0.67 * rssi5g[tick.timestamp].rssi;
        } else {
          rssi5g[tick.timestamp] = {
            rssi: tick.value,
            node: tick.node_id
          };
        }
      }
    });

    return { ...rssi2g, ...rssi5g };
  }

  convertBandwidth(bandwidth: any): any {
    const rxtx = {};

    bandwidth.transmitted.forEach((bw: any) => {
      const timestamp = new Date(bw.timestamp).toString();

      if (rxtx[timestamp]) {
        rxtx[timestamp] = rxtx[timestamp] + bw.value;
      } else {
        rxtx[timestamp] = bw.value;
      }
    });

    bandwidth.received.forEach((bw: any) => {
      const timestamp = new Date(bw.timestamp).toString();

      if (rxtx[timestamp]) {
        rxtx[timestamp] = rxtx[timestamp] + bw.value;
      } else {
        rxtx[timestamp] = bw.value;
      }
    });

    return rxtx;
  }

  convertBandsteering(steeringData: any): any {
    const bandsteeringhistory = {};
    let finalTimestampFrom24h = '';

    steeringData.data24h.points.forEach((point: any) => {
      finalTimestampFrom24h = point.timestamp;
      if (bandsteeringhistory[point.timestamp]) {
        if (point.bs_attempted > bandsteeringhistory[point.timestamp].attempted) {
          bandsteeringhistory[point.timestamp] = {
            attempted: point.bs_attempted,
            failed: point.bs_failed,
            succeeded: point.bs_succeeded,
            troubled: point.bs_troubled
          };
        }
      } else {
        bandsteeringhistory[point.timestamp] = {
          attempted: point.bs_attempted,
          failed: point.bs_failed,
          succeeded: point.bs_succeeded,
          troubled: point.bs_troubled
        };
      }
    });

    steeringData.data7d.points.forEach((point: any) => {
      if (finalTimestampFrom24h === '' || point.timestamp < finalTimestampFrom24h) {
        if (bandsteeringhistory[point.timestamp]) {
          if (point.bs_attempted > bandsteeringhistory[point.timestamp].attempted) {
            bandsteeringhistory[point.timestamp] = {
              attempted: point.bs_attempted,
              failed: point.bs_failed,
              succeeded: point.bs_succeeded,
              troubled: point.bs_troubled
            };
          }
        } else {
          bandsteeringhistory[point.timestamp] = {
            attempted: point.bs_attempted,
            failed: point.bs_failed,
            succeeded: point.bs_succeeded,
            troubled: point.bs_troubled
          };
        }
      }
    });

    return bandsteeringhistory;
  }

  convertClientsteering(steeringData: any): any {
    const clientsteeringhistory = {};
    let finalTimestampFrom24h = '';

    steeringData.data24h.points.forEach((point: any) => {
      finalTimestampFrom24h = point.timestamp;
      if (clientsteeringhistory[point.timestamp]) {
        if (point.cs_attempted > clientsteeringhistory[point.timestamp].attempted) {
          clientsteeringhistory[point.timestamp] = {
            attempted: point.cs_attempted,
            failed: point.cs_failed,
            succeeded: point.cs_succeeded,
            troubled: point.cs_troubled
          };
        }
      } else {
        clientsteeringhistory[point.timestamp] = {
          attempted: point.cs_attempted,
          failed: point.cs_failed,
          succeeded: point.cs_succeeded,
          troubled: point.cs_troubled
        };
      }
    });

    steeringData.data7d.points.forEach((point: any) => {
      if (finalTimestampFrom24h === '' || point.timestamp < finalTimestampFrom24h) {
        if (clientsteeringhistory[point.timestamp]) {
          if (point.cs_attempted > clientsteeringhistory[point.timestamp].attempted) {
            clientsteeringhistory[point.timestamp] = {
              attempted: point.cs_attempted,
              failed: point.cs_failed,
              succeeded: point.cs_succeeded,
              troubled: point.cs_troubled
            };
          }
        } else {
          clientsteeringhistory[point.timestamp] = {
            attempted: point.cs_attempted,
            failed: point.cs_failed,
            succeeded: point.cs_succeeded,
            troubled: point.cs_troubled
          };
        }
      }
    });

    return clientsteeringhistory;
  }

  calculateBusyness(bandwidth: any, mode: string): any[] {
    const data = this.convertBandwidth(bandwidth);
    const start = this.round(15, this.moment());
    const busyness = [];

    for (let i = 0; i < (mode === '24h' ? 96 : 168); i++) {
      const tick = {
        time: this.moment
          .utc(start)
          .local()
          .format(mode === '24h' ? 'DD MMM YYYY h:mm a' : 'DD MMM YYYY h a'),
        color: 'rgb(255, 255, 255)',
        border: 'rgb(238, 238, 236)',
        bw: '0 Mb'
      };

      const time = new Date(start.valueOf()).toString();

      if (mode === '24h') {
        if (data[time] && data[time] > 0) {
          const value = Math.round(data[time] * 100) / 100;
          const color = Math.floor(255 - (value * 255) / 337);

          tick.bw = value + ' Mb';
          tick.color = 'rgb(' + [color, color, color].join(',') + ')';
        }
      } else {
        const clonedStart = start.clone();

        let wholeHourValue = 0;

        for (let hour = 0; hour < 60; hour = hour + 15) {
          const cloneTime = new Date(clonedStart.valueOf()).toString();

          if (data[cloneTime] && data[cloneTime] > 0) {
            wholeHourValue = wholeHourValue + data[cloneTime];
          }

          clonedStart.subtract(15, 'minutes');
        }

        const value = Math.round(wholeHourValue * 100) / 100;
        const color = Math.floor(255 - (value * 255) / 337);

        tick.bw = value + ' Mb';
        tick.color = 'rgb(' + [color, color, color].join(',') + ')';
      }

      busyness.unshift(tick);
      start.subtract(mode === '24h' ? 15 : 60, 'minutes');
    }

    return busyness;
  }

  calculateSteering(steeringData: any, mode: string, typeOfSteering: string): any[] {
    const data =
      typeOfSteering === 'bandsteering'
        ? this.convertBandsteering(steeringData)
        : this.convertClientsteering(steeringData);

    const start = this.round(15, this.moment());
    const bandsteering = [];

    for (let i = 0; i < (mode === '24h' ? 96 : 168); i++) {
      const tick = {
        time: this.moment
          .utc(start)
          .local()
          .format(mode === '24h' ? 'DD MMM YYYY h:mm a' : 'DD MMM YYYY h a'),
        color: 'rgb(255, 255, 255)',
        border: 'rgb(238, 238, 236)',
        attempted: '0',
        succeeded: '0',
        failed: '0',
        troubled: '0'
      };

      const time = new Date(start.valueOf()).toString();

      if (mode === '24h') {
        if (data[time] && data[time].attempted > 0) {
          tick.attempted = data[time].attempted;
          tick.succeeded = data[time].succeeded;
          tick.failed = data[time].failed;
          tick.troubled = data[time].troubled;

          if (parseInt(tick.failed, 10) + parseInt(tick.troubled, 10) > 0) {
            if (parseInt(tick.succeeded, 10) > 0) {
              tick.color = 'rgb(255, 197, 0)'; // WARNING
            } else {
              tick.color = 'rgb(237, 30, 121)'; // ALARM
            }
          } else {
            tick.color = 'rgb(94, 189, 62)'; // GOOD
          }
        }
      } else {
        const clonedStart = start.clone();
        let wholeHourAttemptedValue = 0;
        let wholeHourSucceededValue = 0;
        let wholeHourFailedValue = 0;
        let wholeHourTroubledValue = 0;

        for (let hour = 0; hour < 60; hour = hour + 15) {
          const cloneTime = new Date(clonedStart.valueOf()).toString();

          if (data[cloneTime] && data[cloneTime].attempted > 0) {
            wholeHourAttemptedValue = wholeHourAttemptedValue + data[cloneTime].attempted;
            wholeHourFailedValue = wholeHourFailedValue + data[cloneTime].failed;
            wholeHourSucceededValue = wholeHourSucceededValue + data[cloneTime].succeeded;
            wholeHourTroubledValue = wholeHourTroubledValue + data[cloneTime].troubled;
          }

          clonedStart.subtract(15, 'minutes');
        }

        if (wholeHourAttemptedValue > 0) {
          tick.attempted = wholeHourAttemptedValue.toString();
          tick.succeeded = wholeHourSucceededValue.toString();
          tick.failed = wholeHourFailedValue.toString();
          tick.troubled = wholeHourTroubledValue.toString();

          if (wholeHourFailedValue + wholeHourTroubledValue > 0) {
            if (wholeHourSucceededValue > 0) {
              tick.color = 'rgb(255, 197, 0)'; // WARNING
            } else {
              tick.color = 'rgb(237, 30, 121)'; // ALARM
            }
          } else {
            tick.color = 'rgb(94, 189, 62)'; // GOOD
          }
        }
      }

      bandsteering.unshift(tick);
      start.subtract(mode === '24h' ? 15 : 60, 'minutes');
    }

    return bandsteering;
  }
}
