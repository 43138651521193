import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { INode } from 'src/app/lib/interfaces/interface';
import { selectNodes } from 'src/app/store/polling/polling.selector';
import { NodeService } from 'src/app/lib/services/nodes.service';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { map, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'speedtestnodesummary',
  templateUrl: './speedtestnodesummary.component.html',
  styleUrls: ['./speedtestnodesummary.component.scss']
})
export class SpeedtestNodeSummaryComponent implements OnInit {
  helper: GeneralHelper = new GeneralHelper();
  nodes: (INode & { isGateway?: boolean })[] = null;
  nodeSpeedTestResult: Record<
    string,
    {
      highestDownload: number;
      highestUpload: number;
      latestResult: {
        download: number;
        upload: number;
        date: string;
      };
    }
  > = {};

  highestDownloadSpeed: number = 0;
  highestUploadSpeed: number = 0;
  private processedNodeIds = new Set<string>();

  constructor(private store: Store, private nodeService: NodeService) {}

  ngOnInit(): void {
    this.store
      .select(selectNodes)
      .pipe(
        untilDestroyed(this),
        map((nodes) =>
          nodes?.map((node) => ({
            ...node,
            isGateway: this.helper.isGateway(node.id, nodes)
          }))
        )
      )
      .subscribe((nodes) => {
        this.nodes = nodes;
        nodes?.sort((a, b) => {
          if (a.isGateway && !b.isGateway) {
            return -1;
          }
          if (!a.isGateway && b.isGateway) {
            return 1;
          }
          return 0;
        });
        nodes?.forEach((node) => {
          if (!this.processedNodeIds.has(node.id)) {
            this.processedNodeIds.add(node.id);
            this.getNodeSpeedTestResult(node.id);
          }
        });
      });
  }

  private getNodeSpeedTestResult(nodeId: string) {
    this.nodeService
      .speedTestResults$(nodeId, 'days', 30)
      .pipe(take(1))
      .subscribe((response: any) => {
        const highestDownloadSpeed = response?.downloadSpeeds?.reduce(
          (prev, current) => (prev.value > current.value ? prev : current),
          response.downloadSpeeds[0]
        );
        const highestUploadSpeed = response?.uploadSpeeds?.reduce(
          (prev, current) => (prev.value > current.value ? prev : current),
          response.uploadSpeeds[0]
        );

        this.nodeSpeedTestResult[nodeId] = {
          highestDownload: highestDownloadSpeed?.value,
          highestUpload: highestUploadSpeed?.value,
          latestResult: {
            download: response?.downloadSpeeds[0]?.value,
            upload: response?.uploadSpeeds[0]?.value,
            date: response?.downloadSpeeds[0]?.timestamp
          }
        };

        this.calculateSpeedStats();
      });
  }

  private calculateSpeedStats() {
    Object.keys(this.nodeSpeedTestResult).forEach((nodeId) => {
      if (this.nodeSpeedTestResult[nodeId].highestDownload > this.highestDownloadSpeed) {
        this.highestDownloadSpeed = this.nodeSpeedTestResult[nodeId].highestDownload;
      }
      if (this.nodeSpeedTestResult[nodeId].highestUpload > this.highestUploadSpeed) {
        this.highestUploadSpeed = this.nodeSpeedTestResult[nodeId].highestUpload;
      }
    });
  }
}
