type Idp = {
  id: string;
  description: string;
  domains?: string[];
  value?: string;
  mode?: string;
};

type PartnerGroup = {
  idps?: number[];
  oktaAuth?: boolean;
};

type Partner = {
  idps?: number[];
  loopbackAuth?: boolean;
  groups?: Record<string, PartnerGroup>;
  oktaAuth?: boolean;
};

export type CloudEnvironment = {
  id: string;
  cloudId?: string;
  name: string;
  pollingInterval?: number;
  loopbackAuth: boolean;
  oktaAuth: boolean;
  oktaMode?: string;
  dynamicIdps?: boolean;
  defaultSearch?: string;
  url: string;
  lteUrl?: string;
  flexUrl?: string;
  upriseUrl?: string;
  upriseApplicationUrl?: string;
  metasaurusUrl?: string;
  signalUrl?: string;
  partnerPortalUrl?: string;
  statuspage?: string;
  privacyUrl?: string;
  mywifi?: string;
  idps: Idp[];
  partners?: Record<string, Partner>;
  secondaryClouds?: string[];
};

const beta: CloudEnvironment = {
  id: 'beta',
  cloudId: 'beta',
  name: 'Beta',
  loopbackAuth: true,
  oktaAuth: true,
  dynamicIdps: false,
  url: 'https://piranha-beta.prod.us-west-2.aws.plumenet.io',
  lteUrl: 'https://piranha-beta.prod.us-west-2.aws.plumenet.io',
  flexUrl: 'https://piranha-beta.prod.us-west-2.aws.plumenet.io/flex',
  upriseUrl: 'https://eks-ext.usw2.prod.beta.plumenet.io/uprise',
  upriseApplicationUrl: 'https://beta.uprise.plume.com',
  statuspage: 'https://status-beta-prod.plumenet.io',
  mywifi: 'https://captiveportal.plume.me',
  signalUrl: 'https://signal-beta-ext.usw2.prod.datavinci.plumenet.io',
  partnerPortalUrl: 'https://portal-api-v2.plume.com',
  idps: [],
  partners: {}
};

const chiStaging: CloudEnvironment = {
  id: 'chi-staging',
  cloudId: 'chi-staging',
  name: 'Chi Staging',
  loopbackAuth: true,
  oktaAuth: false,
  dynamicIdps: true,
  url: 'https://piranha-chi.staging.us-west-2.plume.comcast.net',
  mywifi: 'https://captiveportal.plume.me',
  idps: [
    {
      id: 'comcastStaging',
      description: 'Comcast Cable Staging',
      domains: ['comcast.com', 'cable.comcast.com']
    }
  ]
};

const chiDev: CloudEnvironment = {
  id: 'chi-dev',
  cloudId: 'chi-dev',
  name: 'Chi Dev',
  loopbackAuth: true,
  oktaAuth: false,
  dynamicIdps: true,
  url: 'https://piranha-chi.dev.us-west-2.plume.comcast.net',
  mywifi: 'https://captiveportal.plume.me',
  idps: [
    {
      id: 'comcastDev',
      description: 'Comcast Cable Dev',
      domains: ['comcast.com', 'cable.comcast.com']
    }
  ]
};

const ci: CloudEnvironment = {
  id: 'ci',
  cloudId: 'ci',
  name: 'CI',
  loopbackAuth: true,
  oktaAuth: true,
  oktaMode: 'vanity',
  dynamicIdps: true,
  url: 'https://piranha-ci.dev.us-west-2.aws.plume.tech',
  lteUrl: 'https://piranha-ci.dev.us-west-2.aws.plume.tech',
  flexUrl: 'https://piranha-ci.dev.us-west-2.aws.plume.tech/flex',
  upriseUrl: 'https://eks-ext.usw2.dev.ci.plume.tech/uprise',
  upriseApplicationUrl: 'https://ci.uprise.plume.com',
  metasaurusUrl: 'https://dwb-apptime-dev.plume.tech',
  mywifi: 'https://captiveportal-staging.plume.me',
  signalUrl: 'https://signal-ext.us-west-2.datavinci.dev.plume.tech',
  partnerPortalUrl: 'https://portal-api-dev.plume.com',
  idps: [
    {
      id: 'vodafoneCav',
      description: 'Sigma-CAV',
      domains: ['vodafone.com']
    },
    {
      id: 'vodafone',
      description: 'Sigma-ES',
      domains: ['vodafone.com']
    },
    {
      id: 'okta',
      description: 'LGI UAT (Staging)',
      value: '0oakoytwm1nTIe99q0h7'
    },
    {
      id: 'okta',
      description: 'JCOM Test Bed',
      value: '0oamrnpc3sZAucc7b0h7'
    },
    {
      id: 'okta',
      description: 'NOS (TEST)',
      value: '0oas0d2of3H6zaw9d0h7'
    },
    {
      id: 'okta',
      description: 'auth0',
      value: '0oa11rkhhk3piCqUC0h8',
      mode: 'domain'
    },
    {
      id: 'okta',
      description: 'auth0 NEW',
      value: '0oa11rm2wmb0tzW250h8',
      mode: 'vanity'
    },
    {
      id: 'okta',
      description: 'VF-NEW',
      value: '0oa2ceru7cdp3APET0h8',
      mode: 'vanity'
    }
  ],
  partners: {
    vodafone: {
      idps: [0, 1],
      groups: {
        es: {
          idps: [0, 1]
        }
      }
    }
  }
};

const delta: CloudEnvironment = {
  id: 'delta',
  cloudId: 'delta',
  name: 'Delta',
  defaultSearch: 'id',
  loopbackAuth: true,
  oktaAuth: true,
  dynamicIdps: false,
  url: 'https://piranha-delta.prod.ca-central-1.aws.plumenet.io',
  mywifi: 'https://ca.captiveportal.plume.me',
  statuspage: 'https://status-delta-prod.plumenet.io/',
  partnerPortalUrl: 'https://portal-api-v2.plume.com',
  idps: [
    {
      id: 'okta',
      description: 'Bell Prod',
      value: '0oad1g0l6tf441eI8357',
      mode: 'vanity'
    }
  ]
};

const dogfood: CloudEnvironment = {
  id: 'dogfood',
  cloudId: 'dog1',
  name: 'Dogfood',
  pollingInterval: 60,
  loopbackAuth: true,
  oktaAuth: true,
  dynamicIdps: false,
  url: 'https://piranha-dog1.dogfood.us-west-2.aws.plume.tech',
  lteUrl: 'https://piranha-dog1.dogfood.us-west-2.aws.plume.tech',
  flexUrl: 'https://piranha-dog1.dogfood.us-west-2.aws.plume.tech/flex',
  upriseUrl: 'https://eks-ext.usw2.dogfood.dog1.plume.tech/uprise',
  upriseApplicationUrl: 'https://dogfood.uprise.plume.com',
  metasaurusUrl: 'https://dwb-apptime-dev.plume.tech',
  mywifi: 'https://captiveportal-staging.plume.me',
  signalUrl: 'https://signal-dog1-ext.us-west-2.datavinci.dev.plume.tech',
  partnerPortalUrl: 'https://portal-api-stg-v2.plume.com',
  idps: [],
  partners: {}
};

// TODO - remove after testing
dogfood.secondaryClouds = [dogfood.id];

const etna: CloudEnvironment = {
  id: 'etna',
  cloudId: 'etna',
  name: 'Etna',
  defaultSearch: 'id',
  loopbackAuth: true,
  oktaAuth: true,
  dynamicIdps: false,
  url: 'https://piranha-etna.prod.us-west-2.aws.plume.tech',
  metasaurusUrl: 'https://dwb-apptime-dev.plume.tech',
  mywifi: 'https://captiveportal-staging.plume.me',
  partnerPortalUrl: 'https://portal-api-v2.plume.com',
  idps: [
    {
      id: 'okta',
      description: 'Bell PreProd',
      value: '0oad1fyhok5QrIRpI357',
      mode: 'vanity'
    },
    {
      id: 'okta',
      description: 'Bell Prod',
      value: '0oad1g0l6tf441eI8357',
      mode: 'vanity'
    }
  ]
};

const etna2: CloudEnvironment = {
  id: 'etna2',
  cloudId: 'etna2',
  name: 'Etna2 Dev',
  pollingInterval: 60,
  loopbackAuth: false,
  oktaAuth: true,
  dynamicIdps: false,
  url: 'https://piranha-etna2.dev.us-west-2.aws.plume.tech',
  mywifi: 'https://captiveportal.plume.me',
  idps: [],
  partners: {}
};

const func4: CloudEnvironment = {
  id: 'func4',
  cloudId: 'func4',
  name: 'Func4',
  pollingInterval: 60,
  loopbackAuth: false,
  oktaAuth: true,
  dynamicIdps: false,
  url: 'https://piranha-func4.qa.us-west-2.aws.plume.tech',
  mywifi: 'https://captiveportal.plume.me',
  idps: [],
  partners: {}
};

const gamma: CloudEnvironment = {
  id: 'gamma',
  cloudId: 'gamma',
  name: 'Gamma',
  loopbackAuth: true,
  oktaAuth: true,
  dynamicIdps: false,
  url: 'https://piranha-gamma.prod.us-west-2.aws.plumenet.io',
  lteUrl: 'https://piranha-gamma.prod.us-west-2.aws.plumenet.io',
  flexUrl: 'https://piranha-gamma.prod.us-west-2.aws.plumenet.io/flex',
  upriseUrl: 'https://eks-ext.usw2.prod.gamma.plumenet.io/uprise',
  upriseApplicationUrl: 'https://gamma.uprise.plume.com',
  statuspage: 'https://status-gamma-prod.plumenet.io',
  mywifi: 'https://captiveportal.plume.me',
  signalUrl: 'https://signal-gamma-ext.usw2.prod.datavinci.plumenet.io',
  partnerPortalUrl: 'https://portal-api-v2.plume.com',
  idps: [],
  partners: {
    jcom: {
      loopbackAuth: true,
      oktaAuth: true
    }
  }
};

const kappa: CloudEnvironment = {
  id: 'kappa',
  cloudId: 'kappa',
  name: 'Kappa',
  loopbackAuth: true,
  oktaAuth: true,
  dynamicIdps: false,
  url: 'https://piranha.eu-central-1.prod.kappa.plumenet.io',
  lteUrl: 'https://piranha.eu-central-1.prod.kappa.plumenet.io',
  flexUrl: 'https://piranha.eu-central-1.prod.kappa.plumenet.io/flex',
  upriseUrl: 'https://eks-ext.euc1.prod.kappa.plumenet.io/uprise',
  upriseApplicationUrl: 'https://kappa.uprise.plume.com',
  statuspage: 'https://status-kappa-prod.plumenet.io',
  mywifi: 'https://eu.captiveportal.plume.me',
  signalUrl: 'https://signal-kappa-ext.euc1.prod.datavinci.plumenet.io',
  partnerPortalUrl: 'https://portal-api-v2.plume.com',
  idps: [],
  partners: {}
};

const iota: CloudEnvironment = {
  id: 'iota',
  cloudId: 'iota',
  name: 'Iota',
  loopbackAuth: true,
  oktaAuth: true,
  dynamicIdps: false,
  url: 'https://piranha.apne1.prod.iota.plumenet.io',
  upriseApplicationUrl: 'https://iota.uprise.plume.com',
  statuspage: 'https://status-iota-prod.plumenet.io',
  mywifi: 'https://captiveportal.plume.me',
  signalUrl: 'https://signal-iota-ext.apne1.prod.datavinci.plumenet.io',
  partnerPortalUrl: 'https://portal-api-v2.plume.com',
  idps: [],
  partners: {}
};

const aioDev01: CloudEnvironment = {
  id: 'aio-dev01',
  name: 'Aio-dev01',
  pollingInterval: 60,
  loopbackAuth: false,
  oktaAuth: true,
  dynamicIdps: false,
  url: 'https://piranha.usw2.dev01.aio.plume.tech',
  mywifi: 'https://captiveportal.plume.me',
  idps: [],
  partners: {}
};

const aioQa03: CloudEnvironment = {
  id: 'aio-qa03',
  name: 'Aio-qa03',
  pollingInterval: 60,
  loopbackAuth: true,
  oktaAuth: true,
  dynamicIdps: false,
  url: 'https://piranha.usw2.qa03.aio.plume.tech',
  mywifi: 'https://captiveportal.plume.me',
  idps: [],
  partners: {}
};

const aioQa4: CloudEnvironment = {
  id: 'aio-qa04',
  name: 'Aio-qa04',
  pollingInterval: 60,
  loopbackAuth: false,
  oktaAuth: true,
  dynamicIdps: false,
  url: 'https://piranha.usw2.qa04.aio.plume.tech',
  mywifi: 'https://captiveportal.plume.me',
  idps: [],
  partners: {}
};

const padev2: CloudEnvironment = {
  id: 'padev2',
  name: 'Padev2',
  pollingInterval: 60,
  loopbackAuth: true,
  oktaAuth: true,
  dynamicIdps: false,
  url: 'https://piranha-padev2.dev.us-west-2.aws.plume.tech',
  mywifi: 'https://captiveportal.plume.me',
  idps: [],
  partners: {}
};

const phi: CloudEnvironment = {
  id: 'phi',
  cloudId: 'phi',
  name: 'Phi',
  loopbackAuth: true,
  oktaAuth: true,
  dynamicIdps: false,
  url: 'https://piranha.apse1.prod.phi.plumenet.io',
  lteUrl: 'https://piranha.apse1.prod.phi.plumenet.io',
  flexUrl: 'https://piranha.apse1.prod.phi.plumenet.io/flex',
  upriseUrl: 'https://piranha.apse1.prod.phi.plumenet.io/uprise',
  upriseApplicationUrl: 'https://phi.uprise.plume.com',
  statuspage: 'https://status-phi-prod.plumenet.io',
  mywifi: 'https://captiveportal.plume.me',
  partnerPortalUrl: 'https://portal-api-v2.plume.com',
  idps: [],
  partners: {}
};

const sigma: CloudEnvironment = {
  id: 'sigma',
  cloudId: 'kappa',
  name: 'Sigma',
  pollingInterval: 90,
  defaultSearch: 'id',
  loopbackAuth: false,
  oktaAuth: false,
  url: 'https://piranha.eu-central-1.prod.sigma.plumenet.io',
  mywifi: 'https://captiveportal.plume.me',
  partnerPortalUrl: 'https://portal-api-v2.plume.com',
  idps: [
    {
      id: 'vodafoneGdi',
      description: 'VF-ES External',
      domains: ['vodafone.com']
    }
  ],
  partners: {
    vodafone: {
      idps: [0],
      loopbackAuth: false,
      groups: {
        es: {
          idps: [0],
          oktaAuth: false
        }
      }
    }
  }
};

const slodev1: CloudEnvironment = {
  id: 'slodev1',
  cloudId: 'slodev1',
  name: 'Slodev1',
  loopbackAuth: true,
  oktaAuth: false,
  dynamicIdps: false,
  url: 'https://piranha-slodev1.dev.us-west-2.aws.plume.tech',
  mywifi: 'https://captiveportal.plume.me',
  idps: []
};

const tauDev3: CloudEnvironment = {
  id: 'tau-dev3',
  cloudId: 'tau-dev3',
  name: 'Tau-dev3',
  loopbackAuth: true,
  oktaAuth: true,
  dynamicIdps: false,
  url: 'https://piranha-dev3.tau.dev-charter.net',
  mywifi: 'https://captiveportal.plume.me',
  lteUrl: 'https://piranha-dev3.tau.dev-charter.net',
  idps: [
    {
      id: 'okta',
      description: 'Charter AD',
      value: '0oa3zowf5zY8ycGlK357'
    }
  ]
};

const tauInt: CloudEnvironment = {
  id: 'tau-int',
  cloudId: 'tau-int',
  name: 'Tau-int',
  loopbackAuth: true,
  oktaAuth: true,
  dynamicIdps: false,
  url: 'https://piranha-int.tau.dev-charter.net',
  mywifi: 'https://captiveportal.plume.me',
  lteUrl: 'https://piranha-int.tau.dev-charter.net',
  idps: [
    {
      id: 'okta',
      description: 'Charter AD',
      value: '0oa3zowf5zY8ycGlK357'
    }
  ]
};

const tauPerf: CloudEnvironment = {
  id: 'tau-perf',
  cloudId: 'tau-perf',
  name: 'Tau-perf',
  loopbackAuth: true,
  oktaAuth: true,
  dynamicIdps: false,
  url: 'https://piranha-perf.tau.dev-charter.net',
  mywifi: 'https://captiveportal.plume.me',
  lteUrl: 'https://piranha-perf.tau.dev-charter.net',
  idps: [
    {
      id: 'okta',
      description: 'Charter AD',
      value: '0oa3zowf5zY8ycGlK357'
    }
  ]
};

const tauStage: CloudEnvironment = {
  id: 'tau-stage',
  cloudId: 'tau-stage',
  name: 'Tau-stage',
  loopbackAuth: true,
  oktaAuth: true,
  dynamicIdps: false,
  url: 'https://piranha-stage.tau.spectrum.net',
  mywifi: 'https://captiveportal.plume.me',
  lteUrl: 'https://piranha-stage.tau.spectrum.net',
  idps: [
    {
      id: 'okta',
      description: 'Charter AD',
      value: '0oa3zowf5zY8ycGlK357'
    }
  ]
};

const tauProd: CloudEnvironment = {
  id: 'tau-prod',
  cloudId: 'tau-prod',
  name: 'Tau-prod',
  loopbackAuth: true,
  oktaAuth: true,
  dynamicIdps: false,
  url: 'https://piranha-prod.tau.spectrum.net',
  mywifi: 'https://captiveportal.plume.me',
  lteUrl: 'https://piranha-prod.tau.spectrum.net',
  idps: [
    {
      id: 'okta',
      description: 'Charter Prod AD',
      value: '0oa9911fz2l5AxxuT357'
    }
  ]
};

const openSync: CloudEnvironment = {
  id: 'opensync',
  cloudId: 'opensync',
  name: 'OpenSync Development',
  pollingInterval: 60,
  loopbackAuth: true,
  oktaAuth: true,
  dynamicIdps: false,
  url: 'https://piranha-opensync.dev.us-west-2.aws.plume.tech',
  lteUrl: 'https://piranha-opensync.dev.us-west-2.aws.plume.tech',
  flexUrl: 'https://piranha-opensync.dev.us-west-2.aws.plume.tech/flex',
  metasaurusUrl: 'https://dwb-apptime-dev.plume.tech',
  mywifi: 'https://captiveportal.plume.me',
  partnerPortalUrl: 'https://portal-api-v2.plume.com',
  idps: []
};

const rhoSandbox: CloudEnvironment = {
  id: 'rhosandbox',
  cloudId: 'rho-sandbox',
  name: 'Rho-sandbox',
  defaultSearch: 'id',
  loopbackAuth: true,
  oktaAuth: false,
  dynamicIdps: false,
  url: 'https://piranha-api.as1.sandbox.rho.jiohs.net',
  lteUrl: 'https://piranha-api.as1.sandbox.rho.jiohs.net',
  privacyUrl: 'https://www.jio.com/jcms/en-in/privacy-policy/',
  idps: []
};

const rhoSit: CloudEnvironment = {
  id: 'rho-sit',
  cloudId: 'rho-sit',
  name: 'Rho-sit',
  defaultSearch: 'id',
  loopbackAuth: true,
  oktaAuth: true,
  dynamicIdps: false,
  url: 'https://sit.rho.jiohs.net',
  lteUrl: 'https://sit.rho.jiohs.net',
  privacyUrl: 'https://www.jio.com/jcms/en-in/privacy-policy/',
  idps: [
    {
      id: 'okta',
      description: 'Jio Prod',
      value: '0oavrmoxh60cD0nYF357'
    }
  ]
};

const rhoDev: CloudEnvironment = {
  id: 'rho-dev',
  cloudId: 'rho-dev',
  name: 'Rho-Dev',
  defaultSearch: 'id',
  loopbackAuth: true,
  oktaAuth: true,
  dynamicIdps: false,
  url: 'https://piranha.aps1.dev.rho.jiohs.net',
  lteUrl: 'https://piranha.aps1.dev.rho.jiohs.net',
  flexUrl: 'https://piranha.aps1.dev.rho.jiohs.net/flex',
  privacyUrl: 'https://www.jio.com/jcms/en-in/privacy-policy/',
  idps: [
    {
      id: 'okta',
      description: 'Jio Prod',
      value: '0oavrmoxh60cD0nYF357'
    }
  ],
  secondaryClouds: [rhoSit.id]
};
rhoSit.secondaryClouds = [rhoDev.id];

const rhoGProd: CloudEnvironment = {
  id: 'rho-gprod',
  cloudId: 'rho-gprod',
  name: 'Rho GCP Prod',
  defaultSearch: 'id',
  loopbackAuth: true,
  oktaAuth: true,
  dynamicIdps: false,
  url: 'https://prod-gke.gprod.rho.jiohs.net',
  lteUrl: 'https://prod-gke.gprod.rho.jiohs.net',
  flexUrl: 'https://prod-gke.gprod.rho.jiohs.net/flex',
  signalUrl: 'https://signal.aps1.prod.rho.jiohs.net',
  privacyUrl: 'https://www.jio.com/jcms/en-in/privacy-policy/',
  idps: [
    {
      id: 'okta',
      description: 'Jio Prod',
      value: '0oavrmoxh60cD0nYF357'
    }
  ]
};

const rho: CloudEnvironment = {
  id: 'rho',
  cloudId: 'rho-prod',
  name: 'Rho',
  defaultSearch: 'id',
  loopbackAuth: true,
  oktaAuth: true,
  dynamicIdps: false,
  url: 'https://piranha.aps1.prod.rho.jiohs.net',
  lteUrl: 'https://piranha.aps1.prod.rho.jiohs.net',
  flexUrl: 'https://piranha.aps1.prod.rho.jiohs.net/flex',
  signalUrl: 'https://signal.aps1.prod.rho.jiohs.net',
  privacyUrl: 'https://www.jio.com/jcms/en-in/privacy-policy/',
  idps: [
    {
      id: 'okta',
      description: 'Jio Prod',
      value: '0oavrmoxh60cD0nYF357'
    }
  ],
  secondaryClouds: [rhoGProd.id]
};

rhoGProd.secondaryClouds = [rho.id];

const theta: CloudEnvironment = {
  id: 'theta',
  cloudId: 'theta',
  name: 'Theta',
  defaultSearch: 'node',
  loopbackAuth: false,
  oktaAuth: true,
  url: 'https://piranha.eu-central-1.prod.theta.plumenet.io',
  mywifi: 'https://lg.captiveportal.plume.me',
  statuspage: 'https://status-theta-prod.plumenet.io',
  idps: [
    {
      id: 'okta',
      description: 'LGI',
      value: '0oa1b9a2z8fsmwG1x357'
    },
    {
      id: 'okta',
      description: 'VodafoneZiggo Azure',
      value: '0oa6qeav2aIkcm47F357'
    }
  ]
};

const thetaStage: CloudEnvironment = {
  id: 'theta-stage',
  cloudId: 'theta-stage',
  name: 'Theta-Stage',
  defaultSearch: 'node',
  loopbackAuth: true,
  oktaAuth: true,
  url: 'https://piranha.euc1.stage.theta.plumenet.io',
  mywifi: 'https://lg.captiveportal.plume.me',
  idps: [
    {
      id: 'okta',
      description: 'LGI',
      value: '0oa1b9a2z8fsmwG1x357'
    },
    {
      id: 'okta',
      description: 'VodafoneZiggo Azure',
      value: '0oa6qeav2aIkcm47F357'
    }
  ]
};

const thetaDev: CloudEnvironment = {
  id: 'thetadev',
  cloudId: 'thetadev',
  name: 'ThetaDev',
  defaultSearch: 'node',
  loopbackAuth: true,
  oktaAuth: true,
  url: 'https://piranha-thetadev.partner.us-west-2.aws.plume.tech',
  metasaurusUrl: 'https://dwb-apptime-dev.plume.tech',
  mywifi: 'https://captiveportal-staging.plume.me',
  idps: [
    {
      id: 'okta',
      description: 'LGI',
      value: '0oa1b9a2z8fsmwG1x357'
    },
    {
      id: 'okta',
      description: 'VodafoneZiggo Azure',
      value: '0oa6qeav2aIkcm47F357'
    }
  ]
};

const tomasz: CloudEnvironment = {
  id: 'tomasz',
  name: 'Tomasz',
  loopbackAuth: true,
  oktaAuth: false,
  dynamicIdps: false,
  url: 'https://piranha-tomasz.dev.us-west-2.aws.plume.tech',
  mywifi: 'https://captiveportal.plume.me',
  idps: []
};

export const environments: CloudEnvironment[] = [
  beta,
  chiStaging,
  chiDev,
  ci,
  delta,
  dogfood,
  etna,
  etna2,
  func4,
  gamma,
  kappa,
  iota,
  aioDev01,
  aioQa03,
  aioQa4,
  padev2,
  phi,
  sigma,
  slodev1,
  tauDev3,
  tauInt,
  tauPerf,
  tauStage,
  tauProd,
  openSync,
  rhoSandbox,
  rhoSit,
  rhoDev,
  rho,
  rhoGProd,
  theta,
  thetaStage,
  thetaDev,
  tomasz
];
