import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IDevice, INetworkAccessNetworks } from 'src/app/lib/interfaces/interface';
import { NetworkAccessService } from 'src/app/lib/services/network-access.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ThemeService } from 'src/app/lib/services/theme.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { selectLocationProfile } from 'src/app/store/customer/customer.selectors';

enum MoveToPageAction {
  STAY, // stay on same page
  LAST, // go to last page, f.e. when adding new item always
  LAST_IF_OVERFLOW // go to last page only if current page is no available, f.e. when deleting
}

interface IGroups {
  editName: UntypedFormControl;
  assignedDevices: {
    name: string;
    mac: string;
  }[];
  updateDevices: {
    editSelected: string[];
    selected: string[];
    list: {
      text: string;
      value: string;
      object: {
        name: string;
        mac: string;
      };
    }[];
  };
  groupId: string;
  networkId: string;
  name: string;
  devices: string[];
  groupShares: string[];
  standalone: boolean;

  edit: boolean;
  updateShares?: {
    selected: string[];
    list: {
      text: string;
      value: string;
      object: {
        id: string;
        name: string;
        selected: boolean;
      };
    }[];
  };
}

@UntilDestroy()
@Component({
  selector: 'devicegroupmanagement',
  templateUrl: './devicegroupmanagement.component.html',
  styleUrls: ['./devicegroupmanagement.component.scss']
})
export class DeviceGroupManagementComponent implements OnInit {
  networks: INetworkAccessNetworks[] = [];
  networkId: string = 'default';
  secureDisabled: boolean = false;
  employeeDisabled: boolean = false;
  isInvalidRole: boolean = false;

  secure = {
    loading: true,
    groupName: new UntypedFormControl(''),
    offset: 0,
    limit: 10,
    groups: [] as IGroups[],
    allGroups: [] as IGroups[],
    devices: {
      list: [],
      selected: [] as string[]
    }
  };

  employee = {
    loading: true,
    groupName: new UntypedFormControl(''),
    offset: 0,
    limit: 10,
    groups: [] as IGroups[],
    allGroups: [] as IGroups[],
    devices: {
      list: [],
      selected: [] as string[]
    }
  };

  profile$ = this.store.select(selectLocationProfile);

  @Input()
  mode: 'home' | 'fronthaul' | 'captivePortal' | 'iot' = 'home';

  @Input()
  allDevices: IDevice[] = [];

  @HostBinding('class')
  theme: string;

  constructor(
    private networkAccess: NetworkAccessService,
    private toast: ToastService,
    private themes: ThemeService,
    private store: Store,
    private plume: PlumeService
  ) {}

  ngOnInit(): void {
    this.prepareDevices();
    this.getNetworks();

    this.themes.listener.pipe(untilDestroyed(this)).subscribe((theme: string) => {
      this.theme = theme;
    });

    this.isInvalidRole = this.plume.isSomeGroupSupportRole() || this.plume.isSomeSupportRole();
  }

  toggleMode(mode: 'home' | 'fronthaul'): void {
    this.mode = mode;
    this.prepareDevices();
    this.checkNetwork();
  }

  getNetworks(): void {
    this.networkAccess.networks$().subscribe(
      (response) => {
        this.networks = response;
        this.checkNetwork();
        this.getAllDeviceGroups();
      },
      (error) => {
        this.toast.error(error.error.error.message, error.error.error.name);
      }
    );
  }

  action(mode: string, group: string): void {
    if (mode === 'next') {
      if (this[group].groups.length === this[group].limit) {
        this[group].offset += this[group].limit;
      }
    }

    if (mode === 'previous') {
      this[group].offset -= this[group].limit;
    }

    if (this[group].offset < 0) {
      this[group].offset = 0;
    }

    this[group].groups = this[group].allGroups.slice(this[group].offset, this[group].offset + this[group].limit);
  }

  prepareDevices(): void {
    const devices = this.allDevices.filter((device) => device.vapType === this.mode);

    if (this.mode === 'home') {
      this.secure.devices.list = devices.map((device) => ({
          text: device.name,
          value: device.mac,
          object: device
        }));
    }

    if (this.mode === 'fronthaul') {
      this.employee.devices.list = devices.map((device) => ({
          text: device.name,
          value: device.mac,
          object: device
        }));
    }
  }

  checkNetwork(): void {
    this.employeeDisabled = false;

    if (this.mode === 'home') {
      this.networkId = 'default';

      if (!this.networks.length) {
        this.secureDisabled = true;
      }
    }

    if (this.mode === 'fronthaul') {
      if (this.networks.length > 1) {
        this.networks.forEach((network) => {
          if (network.networkId !== 'default') {
            this.networkId = network.networkId;
          }
        });
      } else {
        this.networkId = 'default';
        this.employeeDisabled = true;
      }
    }
  }

  getAllDeviceGroups(moveToPage = MoveToPageAction.STAY): void {
    this.networks.forEach((network) => {
      this.getDeviceGroups(network.networkId === 'default' ? 'home' : 'fronthaul', network.networkId, moveToPage);
    });
  }

  getDeviceGroups(
    mode: string = this.mode,
    networkId: string = this.networkId,
    moveToPage = MoveToPageAction.STAY
  ): void {
    this.networkAccess.deviceGroups$(networkId).subscribe(
      (response) => {
        const dataBlock = mode === 'home' ? this.secure : this.employee;

        dataBlock.loading = false;
        dataBlock.allGroups = response
          .filter((group) => !group.standalone)
          .map((group) => {
            const devicesList: IGroups['updateDevices']['list'] = [];
            const devices: { [key: string]: IGroups['assignedDevices'][0] } = {};

            this.allDevices.forEach((device) => {
              if (device.vapType === mode) {
                devices[device.mac] = { name: device.name, mac: device.mac };

                devicesList.push({
                  text: device.name,
                  value: device.mac,
                  object: device
                });
              }
            });

            return {
              ...group,
              edit: false,
              editName: new UntypedFormControl(group.name),
              assignedDevices: group.devices.map((mac: string) => devices[mac] ? devices[mac] : { name: mac, mac }),
              updateDevices: {
                selected: group.devices,
                editSelected: [],
                list: [
                  ...devicesList,
                  ...group.devices
                    .filter((mac: string) => !devices[mac])
                    .map((mac: string) => ({
                        text: mac,
                        value: mac,
                        object: {
                          name: mac,
                          mac
                        }
                      }))
                ]
              }
            };
          });

        if (moveToPage !== MoveToPageAction.STAY) {
          const lastPage = Math.max(
            Math.floor((dataBlock.allGroups.length - 1) / dataBlock.limit) * dataBlock.limit,
            0
          );

          dataBlock.offset = moveToPage === MoveToPageAction.LAST ? lastPage : Math.min(lastPage, dataBlock.offset);
        }

        if (dataBlock.offset + dataBlock.limit >= dataBlock.allGroups.length) {
          dataBlock.groups = dataBlock.allGroups.slice(dataBlock.offset);
        } else {
          dataBlock.groups = dataBlock.allGroups.slice(dataBlock.offset, dataBlock.offset + dataBlock.limit);
        }
      },
      (error) => {
        this.toast.error(error.error.error.message, error.error.error.name);
      }
    );
  }

  enableEdit(group: IGroups): void {
    this.prepareForEdit(group);
    group.edit = true;
  }

  prepareForEdit(group: IGroups): void {
    const sharesList: { id: string; name: string; selected: boolean }[] = [];
    const shares = {};

    if (this.mode === 'home') {
      this.employee.allGroups.forEach((employeeGroup) => {
        shares[employeeGroup.groupId] = { id: employeeGroup.groupId, name: employeeGroup.name };
        sharesList.push({
          id: employeeGroup.groupId,
          name: employeeGroup.name,
          selected: employeeGroup.groupShares.includes(group.groupId)
        });
      });
    }

    if (this.mode === 'fronthaul') {
      this.secure.allGroups.forEach((secureGroup) => {
        shares[secureGroup.groupId] = { id: secureGroup.groupId, name: secureGroup.name };
        sharesList.push({
          id: secureGroup.groupId,
          name: secureGroup.name,
          selected: secureGroup.groupShares.includes(group.groupId)
        });
      });
    }

    group.updateDevices.editSelected = [...group.updateDevices.selected];

    group.updateShares = {
      selected: [...group.groupShares],
      list: sharesList.map((share) => ({
          text: share.name,
          value: share.id,
          object: share
        }))
    };
  }

  addGroup(): void {
    if (
      (this.mode === 'home' && !this.secure.groupName.value) ||
      (this.mode === 'fronthaul' && !this.employee.groupName.value)
    ) {
      this.toast.error('devicegroup.errorEmptyGroup', 'devices.device.error');
      return;
    }

    (
      (this.mode === 'home'
        ? this.networkAccess.addHomeGroup$(this.networkId, this.secure.groupName.value, this.secure.devices.selected)
        : this.networkAccess.addFronthaulGroup$(
            this.employee.groupName.value,
            this.employee.devices.selected
          )) as Observable<unknown>
    ).subscribe(
      () => {
        if (this.mode === 'home') {
          this.secure.groupName.reset();
          this.secure.devices.selected = [];
        }

        if (this.mode === 'fronthaul') {
          this.employee.groupName.reset();
          this.employee.devices.selected = [];
        }

        this.getDeviceGroups(this.mode, this.networkId, MoveToPageAction.LAST);
      },
      (error) => {
        this.toast.error(error.error.error.message, error.error.error.name);
      }
    );
  }

  cancelEdit(group: IGroups, mode: string): void {
    const foundIndex = this[mode].groups.findIndex((element) => element.groupId === group.groupId);
    this[mode].groups[foundIndex].edit = false;
    this[mode].groups[foundIndex].editName.setValue(this[mode].groups[foundIndex].name);
  }

  editGroup(group: IGroups): void {
    if (this.mode === 'captivePortal' || this.mode === 'iot') {
      return;
    }

    this.networkAccess
      .editGroups$(
        this.mode,
        this.networkId,
        group.groupId,
        group.editName.value,
        group.updateDevices.editSelected,
        group.updateShares.selected
      )
      .subscribe(
        () => {
          this.getAllDeviceGroups();
        },
        (error) => {
          this.toast.error(error.error.error.message, error.error.error.name);
        }
      );
  }

  deleteGroup(group: IGroups): void {
    (this.mode === 'home'
      ? this.networkAccess.deleteHomeGroup$(this.networkId, group.groupId)
      : this.networkAccess.deleteFronthaulGroup$(group.groupId)
    ).subscribe(
      () => {
        this.getAllDeviceGroups(MoveToPageAction.LAST_IF_OVERFLOW);
      },
      (error) => {
        this.toast.error(error.error.error.message, error.error.error.name);
      }
    );
  }

  getGroupName(id: string): string {
    let group = { name: '' };

    if (this.mode === 'home') {
      group = this.employee.allGroups.find((group) => group.groupId === id);
    }

    if (this.mode === 'fronthaul') {
      group = this.secure.allGroups.find((group) => group.groupId === id);
    }

    return group ? group.name : '';
  }
}
